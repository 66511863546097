import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';
import { MasterServiceService } from 'src/app/common/services/master-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AdminUserService } from 'src/app/common/services/adminUserService/admin-user.service';
import { debounce } from '@syncfusion/ej2-base';
export interface Country {
  countryID: string;
  countryName: string;
}
@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  form: FormGroup;
  countries: any[] = [];
  hide = true;
  hide1 = true;
  roles: any;
  bloodGroups: any;
  genders: any;
  countryCtrl = new FormControl();
  filteredCountry: Observable<Country[]>;
  stateFlag: boolean;
  @ViewChild('phone') phone: any;

  maxDate = new Date();
  minDate = new Date(1900, 0, 1);

  constructor(
    private formBuilder: FormBuilder,
    private manageStaffService: ManageStaffService,
    private masterServiceService: MasterServiceService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private commonService: CommonService,
    private adminService: AdminUserService

  ) {
    this.filteredCountry = this.countryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(country => country ? this._filterStates(country) : this.countries.slice())
      );
  }
  private _filterStates(value: string): Country[] {
    const filterValue = value.toLowerCase();

    return this.countries.filter(country => country.countryName.toLowerCase().includes(filterValue));
  }
  ngOnInit(): void {
    this.stateFlag = false;
    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      username: ['', Validators.required],
      bloodGroupID: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      contactNumber: ['', Validators.required],
      countryID: ['', Validators.required],
      stateID: ['', Validators.required],
      zipcode: ['', Validators.required],
      roleID: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      profilePic: ['']
    });

    // let date = new Date();
    // date.setFullYear( date.getFullYear() - 1 );
    // this.maxDate =  new Date(date);

    this.GetCountiresList();
    this.GetRolesList();
    this.GetBloodGroupList();
    this.GetGenderList();

  }
  GetCountiresList() {
    this.masterServiceService.GetCountryDropdown().subscribe((res: any) => {
      this.countries = res.responseData;
      console.log("countries", this.countries);
    })
  }

  GetRolesList() {
    this.manageStaffService.GetRolesDropdown().subscribe((res: any) => {
      this.roles = res.responseData;
    })
  }
  GetBloodGroupList() {
    this.masterServiceService.GetBloodGroupDropdown().subscribe((res: any) => {
      this.bloodGroups = res.responseData;
    })
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }

  // getting the form control elements
  get password(): AbstractControl {
    return this.form.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.form.controls['confirmPassword'];
  }
  profileImage: string = '';
  AddProfile(file: any) {
    this.masterServiceService.postData(file).subscribe((res: any) => {
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }

    })
  }


  states: any;
  GetStateList(countryId: any) {
    this.masterServiceService.GetStateDropdown(countryId).subscribe((res: any) => {
      this.states = res.responseData;
      console.log(res);
    })
  }
  lastFlag: boolean;
  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    if (this.form.value.stateID == '') {
      this.stateFlag = true;
    }
    else {
      this.stateFlag = false;
    }
    if (this.profileImage != '') {
      this.form.controls.profilePic.setValue(this.profileImage);
    }
    if (this.form.value.lastName == "") {
      this.lastFlag = true;
    }
    else if (this.form.value.lastName != "") {
      this.lastFlag = false;
    }

    console.log("PHONENO", this.form.value)
    this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
    this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
    this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);

    this.manageStaffService.AddStaff(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        this.router.navigate(['/manage-staff']);

      } else {
        this.toaster.error(res.message, 'Error');
      }


    });
  }
  imageUrl: any;
  uploadFile(event: any) {
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    this.AddProfile(formData);
    // if (event.target.files && event.target.files[0]) {
    //   reader.readAsDataURL(file);

    //   // When file uploads set it to file formcontrol
    //   reader.onload = () => {
    //     this.imageUrl = reader.result;
    //     // this.registrationForm.patchValue({
    //     //   file: reader.result
    //     // });
    //     // this.editFile = false;
    //     // this.removeUpload = true;
    //   }
    //   // ChangeDetectorRef since file is loading outside the zone
    //   //this.cd.markForCheck();        
    // }
  }


  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/display.jpg';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  get confirm_DuplicateUsername(): AbstractControl {
    return this.form.controls['username'];
  }
  isDuplicateUsername: boolean;

  CheckDuplicateUserName = debounce((event: any) => {
   // this.isDuplicateUsername = false;
    if (event.target.value != '') {
      this.adminService.CheckDuplicateUserName(event.target.value).subscribe((res: any) => {
        if (res.statusCode == 200) {
          this.confirm_DuplicateUsername.setErrors({ isDuplicateUsername: true });
          this.form.setErrors({ isDuplicateUsername: true });
         // this.isDuplicateUsername = true;
         // this.toaster.error(res.message, 'Error');
        }
        else if (res.statusCode == 404) {
          //this.confirm_DuplicateUsername.setErrors({ isDuplicateUsername: false });
         // this.form.setErrors({ isDuplicateUsername: false });
         // this.isDuplicateUsername = false;
          //this.toaster.success(res.message, 'Success');
        }
      })
    }
  }, 1000)

  GetGenderList() {
    this.masterServiceService.GetGenderDropdown().subscribe((res: any) => {
      this.genders = res.responseData;
    })
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  noWhitespaceValidator(event:any){
    console.log("CONTROL",event.target.value)
    const isWhitespace = (event.target.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : event.target.value = '';
  }
  removespace(event:any){
    this.commonService.removeSpace(event);
  }

}

