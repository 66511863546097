<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title">Manage Roles</h2>

    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Roles</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Roles</h3>
        <a routerLink="/add-role" class="btn btn-primary btnsmall">Add Role</a>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <!-- <a (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden">Filter</span></a> -->
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Role Name</mat-label>
                      <input matInput type="text" formControlName="roleName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Role Description</mat-label>
                      <input matInput type="text" formControlName="roleDescription">
                    </mat-form-field>
                  </div>
                 
                
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>

                </div>
              </form>
            </div>
          </div>
          <div class="rightTableActions centerItemVert">


          </div>

        </div>
        <div class="mat-elevation-z8">

        </div>
      </div>

      <app-data-table [inputColumns]="displayedColumns" [inputSource]="usersData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
    </div>
  </div>
</main>

<ng-template #loginPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Reset Password</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <form>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline" floatLabel="always">
            <mat-label>Username</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
            <input matInput value="Sharma">
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17 center">
          <button type="button" class="btn btn-primary" mat-dialog-close>Send</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #hideShowColPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Hide/Show Colums</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a href="#" class="underlinedLink">Select All</a> / <a href="#" class="underlinedLink">Unselect All</a>
      </p>
      <form>
        <div class="row-flex">
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              First Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Last Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Grade
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Section
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Student Enrollment Status
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Subject Enrollment Status
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Admission No.
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin">
              Middle Name
            </mat-checkbox>
          </div>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>


