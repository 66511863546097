<app-spinner [show]="show" [size]="150"></app-spinner>
<div class="loginWidget">
    <div>
        <p class="login-logo center"><img src="../../assets/img/logo.png" alt=""></p>
        <div class="matCard">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
               
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Username </mat-label>
                    <input required formControlName="email" matInput placeholder="Username">
                    <mat-error *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                        Username is required
                    </mat-error>
                </mat-form-field>
                
                
                <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input required type="password" formControlName="password" matInput placeholder="Password">
                    <mat-error *ngIf="form.get('password')?.touched  && form.get('password')?.errors?.required">
                        Password is required
                    </mat-error>
                </mat-form-field>
                <section class="example-section">
                    <mat-checkbox value="remember-me" formControlName="RememberMe" class="example-margin">Remember me</mat-checkbox>
                </section>
               <br>
                <!-- <div class="form-group17">
                    <ngx-recaptcha2 #captchaElem formControlName="recaptcha" [siteKey]="siteKey" required>
                    </ngx-recaptcha2>
                </div> -->
             
                <div class="forgotpassword form-group17">
                    <button [disabled]="form.invalid" type="submit"  class="btn btn-primary">Log In</button>    
                    <a routerLink="/forgot-password" class="underlinedLink primaryText">Forgot password?</a>        
                </div>

            </form>
        </div> 
    </div>
    
</div>


