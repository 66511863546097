import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { EnquiriesService } from "../../common/services/enquiriesServices/enquiries.service";
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { EnquiryModel } from '../InquiryModel';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailTemplateService } from 'src/app/common/services/emailTemplateService/email-template.service';
@Component({
  selector: 'app-enquiry-response',
  templateUrl: './enquiry-response.component.html',
  styleUrls: ['./enquiry-response.component.css']
})
export class EnquiryResponseComponent implements OnInit {
  enquiryID: number;
  name: string;
  address: string;
  phoneNumber: string;
  comments: string;
  enquiryStatusName: string;
  email: string;
  ress: any;
  enquiryTypeName: any;
  responseForm: FormGroup;
  enquiryResponseData: any;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  enquiryListData: EnquiryModel[];
  classToggled = false;
  user: any;
  adminUserID: number;
  response: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private enquiriesService: EnquiriesService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private emailTemplateService: EmailTemplateService,
    private commonService: CommonService
  ) { }

  @ViewChild('textEditorValue')
  private rteEle: RichTextEditorComponent;
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  toolbarSettings: { items: [] }
  ngOnInit(): void {
    //this.enquiryID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.enquiryID = params.id == undefined ? null : params.id;
    });
    this.GetEnqueryDetailsById();
    this.getEnquiryResponse();
    this.responseForm = this.formBuilder.group({
      responseDetails: ['', Validators.required],
    });
  }

  getEnquiryResponse() {
    debugger;
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    console.log("USERR", currentUser);
    this.adminUserID = currentUser.userId;
    this.enquiryResponseData = {
      "enquiryID": this.enquiryID,
      "adminUserID": this.adminUserID
    }
    this.enquiriesService.getResponse(this.enquiryResponseData).subscribe((res: any) => {
      for(var i=0; i< res.responseData.length;i++)
         { 
            var str = res.responseData[i].responseDetails;   
            var cleanStr = str.replace(/<p>/g,"");
            var cleanStr = cleanStr.replace(/<\/p>/g,"");
            res.responseData[i].responseDetails = cleanStr 
         }
      this.response = res.responseData;
      console.log("this.response", this.response);
    });

    
  }

  //index
//    removeTags(str) {
//     if ((str===null) || (str===''))
//         return false;
//     else
//         str = str.toString();
          
//     // Regular expression to identify HTML tags in 
//     // the input string. Replacing the identified 
//     // HTML tag with a null string.
//     return str.replace( /(<([^>]+)>)/ig, '');
// }
  sendEnquiryResponse() {
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    console.log("USERR", currentUser);
    this.adminUserID = currentUser.userId;

    this.enquiryResponseData = {
      "enquiryID": this.enquiryID,
      "responseDetails": this.responseForm.controls.responseDetails.value,
      "adminUserID": this.adminUserID
    }
    console.log("hi", this.enquiryResponseData);
    this.enquiriesService.sendResponse(this.enquiryResponseData).subscribe((res: any) => {
      console.log("RESPONSE", res)
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.responseForm.reset();
        this.getEnquiryResponse();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  GetEnqueryDetailsById() {
    this.enquiriesService.GetEnqueryDetailsById(this.enquiryID).subscribe((res: any) => {
      this.ress = res.responseData
      this.name = res.responseData.name
      this.email = res.responseData.email
      this.address = res.responseData.address
      this.phoneNumber = res.responseData.phoneNumber
      this.comments = res.responseData.comments
      this.enquiryStatusName = res.responseData.enquiryStatusName
      this.enquiryTypeName = res.responseData.enquiryTypeName
    });
    console.log("res.responseData", this.ress);
  }

}
