import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../content-service.service';


@Component({
  selector: 'app-contact-us-content',
  templateUrl: './contact-us-content.component.html',
  styleUrls: ['./contact-us-content.component.css']
})
export class ContactUsContentComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) {

  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      contactUsID: 0,
      title: ['', [Validators.required]],
      mainText: ['', [Validators.required]],
      subText: ['', [Validators.required]],
      email: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
    this.getContactUs();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.form.controls.contactUsID.setValue(this.id);
    this.contentServiceService.addContactUSdata(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.handleTabChange.next({ tab: "ViewQuote", id: 0});
        this.id = res.responseData;

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }


  getContactUs() {
    this.contentServiceService.getContactUSdata().subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.id = res.responseData['contactUsID'];
        this.form.patchValue({
          contactUsID: res.responseData['contactUsID'],
          title: res.responseData['title'],
          mainText: res.responseData['mainText'],
          subText: res.responseData['subText'],
          email: res.responseData['email'],
          phone: res.responseData['phone'],
          address: res.responseData['address']
        });

      } else {
        this.toaster.warning("Please add record!", 'Warning');
      }
    });
  }

}
