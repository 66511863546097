import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../content-service.service';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';


@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      privacyPolicyID: 0,
      privacyPolicyTitle: ['', [Validators.required]],
      privacyPolicyDescription: ['', [Validators.required]],
    });

    this.getPolicy();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);

    //this.form.controls.contactUsID.setValue(this.id);
    this.contentServiceService.addPolicy(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        this.id = res.responseData;

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });

  }


  getPolicy(){
    this.contentServiceService.getPolicy().subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.form.patchValue({
          privacyPolicyID:res.responseData['privacyPolicyID'],
          privacyPolicyTitle:res.responseData['privacyPolicyTitle'],
          privacyPolicyDescription:res.responseData['privacyPolicyDescription'],
        });

      }else{
        this.toaster.warning("Please add record!", 'Warning');
      }
    });
  }

}
