<div class="loginWidget">
     <div>
         <p class="login-logo center"><img src="../../assets/img/logo.png" alt=""></p>
         <div class="matCard">
             <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
                 <div class="form-group17">
                   <mat-form-field class="w-100" appearance="outline">
                     <mat-label>New Password</mat-label>
                     <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
                     <input matInput formControlName="password" placeholder="New Password" [type]="hide ? 'password' : 'text'" required autocomplete="off" 
                     pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
                     <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                       <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                     </span>
                     <mat-error *ngIf="forgotPasswordForm.get('password')?.touched  && forgotPasswordForm.get('password')?.errors?.required">
                                                      New password is required
                                                   </mat-error>
                     <mat-error *ngIf="forgotPasswordForm.get('password')?.errors?.pattern">
                       Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
                     </mat-error>
                   </mat-form-field>
                 </div>
                 <div class="form-group17">
                   <mat-form-field class="w-100" appearance="outline">
                     <mat-label>Confirm Password</mat-label>
                     <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
                     <input matInput formControlName="confirmPassword" placeholder="Confirm Password" required autocomplete="off" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
                     <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                       <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                     </span>
                     <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
                   </mat-form-field>
                 </div>
                 <div class="form-group17 center">
                   <button type="submit" class="btn btn-primary" [disabled]="forgotPasswordForm.invalid" >Update</button>
                 </div>
               </form>
         </div> 
     </div>
        
 </div>