import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, organization , Dashboard} from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ManageOrganizationService {

  constructor(private http : HttpClient) { }

  GetOrganizationAll(data:any) {
    return this.http.post(`${environment.baseUrl}${organization.getOrganizationAllAPI}`,data);
  }

  GetOrganizationDetailsById(Id:any) {
     return this.http.get(`${environment.baseUrl}${organization.getOrganizationDetailsAPI}${Id}`);
  }
  GetOrganizationTransactionDetailsById(Id:any) {
    return this.http.get(`${environment.baseUrl}${organization.GetOrgTransactionById}${'?organizationID=' + Id}`);
 }
  RaisedOrganizationInvoice(data:any) {
    return this.http.post(`${environment.baseUrl}${organization.RaisedOrganizationInvoice}`,data);
  }
  GetOrganizationById(Id:any) {
    debugger;
    return this.http.get(`${environment.baseUrl}${organization.getOrganizationByIDAPI}${'?id=' + Id}`);
 }

  AllowedNotAllowedOrgById(Id:any) {
    return this.http.get(`${environment.baseUrl}${organization.allowedNotAllowedAPI}${'?id=' + Id}`);
 }

 EnableNotEnabledOrgById(Id:any) {
  return this.http.get(`${environment.baseUrl}${organization.enableNotEnabledAPI}${'?id=' + Id}`);
}

GetAllowedNotAllowedDemoFromMasterTable() {
  return this.http.get(`${environment.baseUrl}${organization.GetAllowedNotAllowedDemoFromMasterTable}`);
}
SetAllowedNotAllowedDemoFromMasterTable(data:any) {
  return this.http.post(`${environment.baseUrl}${organization.SetAllowedNotAllowedDemoFromMasterTable}`,data);
}
  addOrganization(data:any) {
    return this.http.post(`${environment.baseUrl}${organization.addOrganizationAPI}`,data);
  }
  updateOrganization(data:any) {
    return this.http.post(`${environment.baseUrl}${organization.updateOrganizationAPI}`,data);
  }
  resetPassword(data:any) {
    return this.http.post(`${environment.baseUrl}${organization.resetPassword}`,data);
  }
GetActiveGridTable(Id:any) {
  return this.http.get(`${environment.baseUrl}${organization.getActivecustomgrid}${'?id=' + Id}`);
}
AddActiveCustomGrid(data:any) {
  return this.http.post(`${environment.baseUrl}${organization.addActiveCustomGrid}`,data);
}
GetEnquiryTbalbecolumn(Id:any) {
  return this.http.get(`${environment.baseUrl}${organization.getEnquirycustomGrid}${'?id=' + Id}`);
}
AddEnquirytableColumn(data:any) {
  return this.http.post(`${environment.baseUrl}${organization.addEnquirycustomGrid}`,data);
}

GetDashboardStats() {
  return this.http.get(`${environment.baseUrl}${Dashboard.DashboardStats}`);
}
GetDashboardAmmount() {
  return this.http.get(`${environment.baseUrl}${Dashboard.GetDashboardAmmountStats}`);
}

CheckSubdomain(data:any) {
  return this.http.post(`${environment.baseUrl}${organization.checkSubdomainAPI}`, data);
}
}
