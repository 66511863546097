import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, emailTemplate } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(private http: HttpClient) { }

  AllEmailTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${emailTemplate.allEmailTemplateAPI}`, data);
  }
  GetEmailTemplateById(Id: any) {
    return this.http.get(`${environment.baseUrl}${emailTemplate.getEmailTemplateByIdAPI}${Id}`);
  }
  UpdateEmailTemplate(data: any) {
    debugger;
    return this.http.post(`${environment.baseUrl}${emailTemplate.updateEmailTemplateAPI}`, data);
  }
  AddCustomEmailTemplate(data: any) {
    return this.http.post(`${environment.baseUrl}${emailTemplate.customEmailTemplateAPI}`, data);
  }

  GetAllEmailTemplates() {
    return this.http.get(`${environment.baseUrl}${emailTemplate.GetAllEmailTemplateAPI}`);
  }

  DeleteEmailTemplates(Id: any) {
    debugger;
    return this.http.get(`${environment.baseUrl}${emailTemplate.DeleteEmailTemplateAPI}${'?id=' + Id}`);
  }
}
