<main>
     <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
       <h2 class="page-title">Payment Gateway</h2>
       <!-- <app-breadcrumb></app-breadcrumb> -->
       <div class="breadcrumbWidget">
         <ol class="breadcrumb">
           <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
           <li class="breadcrumb-item active" aria-current="page">Payment Gateway</li>
         </ol>
       </div>
     </div>
     <div class="container-fluid">
       <div class="matCard">
         <div class="matCrdTitle centerItemVert justify-content-between">
           <h3 class="matCardTitle">No Content</h3>
   
         </div>
   
         <div class="matCardBody">
           <div class="tableActionTop mb-3">
             <div class="tableFilter">
              
   
             
   
             </div>
             <div class="rightTableActions centerItemVert">
   
   
             </div>
   
           </div>
           <div class="mat-elevation-z8">
   
           </div>
         </div>
        
       </div>
     </div>
   </main>