<app-header (toggleSidebarForMe)="sidebarToggle()"></app-header>
<section id="layoutSidenav">
    <div id="layoutSidenav_nav" [class.menu-open]="sideBarOpen">
        <app-sidebar></app-sidebar>
    </div>
    
    <div id="layoutSidenav_content">
        <router-outlet></router-outlet>
        <app-footer></app-footer>
    </div>
</section>

<svg xmlns="http://www.w3.org/2000/svg" style="display:none;">
    <symbol id="search-icon" viewBox="0 0 16 16">
        <path id="Search_Icon" data-name="Search Icon" d="M12.7,11.23a6.777,6.777,0,0,0,1.4-4.174A7.02,7.02,0,0,0,7.1,0,7.105,7.105,0,0,0,0,7.056a7.105,7.105,0,0,0,7.1,7.056,6.667,6.667,0,0,0,4.2-1.391l3,2.981a.971.971,0,0,0,1.4,0,.957.957,0,0,0,0-1.391Zm-5.6.8A5.022,5.022,0,0,1,2,7.056a5.1,5.1,0,0,1,10.2,0A5.022,5.022,0,0,1,7.1,12.025Z" fill="currentColor"/>
    </symbol>
    <symbol id="bell-icon" viewBox="0 0 16 16">
        <path id="Notification_Icon" data-name="Notification Icon" d="M-1371,81h4a2.006,2.006,0,0,1-2,2A2.006,2.006,0,0,1-1371,81Zm-5-1a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1h.5a4.354,4.354,0,0,0,1.5-3V72a4.952,4.952,0,0,1,5-5,4.951,4.951,0,0,1,5,5v3a4.351,4.351,0,0,0,1.5,3h.5a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1Z" transform="translate(1377 -67)" fill="currentColor"/>
    </symbol>

    <symbol id="total-org-icon" viewBox="0 0 30 20.323">
        <path id="Icon_awesome-users" data-name="Icon awesome-users" d="M4.5,10.96a2.956,2.956,0,0,0,3-2.9,2.956,2.956,0,0,0-3-2.9,2.956,2.956,0,0,0-3,2.9A2.956,2.956,0,0,0,4.5,10.96Zm21,0a2.956,2.956,0,0,0,3-2.9,3,3,0,0,0-6,0A2.956,2.956,0,0,0,25.5,10.96ZM27,12.412H24a3.042,3.042,0,0,0-2.114.844,6.64,6.64,0,0,1,3.52,4.963H28.5A1.475,1.475,0,0,0,30,16.767V15.315A2.956,2.956,0,0,0,27,12.412Zm-12,0a5.165,5.165,0,0,0,5.25-5.081A5.165,5.165,0,0,0,15,2.25,5.165,5.165,0,0,0,9.75,7.331,5.165,5.165,0,0,0,15,12.412Zm3.6,1.452h-.389a7.465,7.465,0,0,1-6.422,0H11.4A5.317,5.317,0,0,0,6,19.089V20.4a2.215,2.215,0,0,0,2.25,2.177h13.5A2.215,2.215,0,0,0,24,20.4V19.089A5.316,5.316,0,0,0,18.6,13.863ZM8.114,13.255A3.042,3.042,0,0,0,6,12.412H3a2.956,2.956,0,0,0-3,2.9v1.452a1.475,1.475,0,0,0,1.5,1.452H4.589A6.658,6.658,0,0,1,8.114,13.255Z" transform="translate(0 -2.25)" fill="currentColor"/>
    </symbol>

    <symbol id="active-org-icon" viewBox="0 0 33.354 24.833">
        <g id="Group_1865" data-name="Group 1865" transform="translate(-40.998 -46)">
            <path id="Subtraction_6" data-name="Subtraction 6" d="M-618.629-14550.677h-13.119a2.219,2.219,0,0,1-2.253-2.18v-1.3a5.321,5.321,0,0,1,5.4-5.226h.388a7.622,7.622,0,0,0,3.211.728,7.57,7.57,0,0,0,3.211-.728h.391a5.511,5.511,0,0,1,2.908.822,5.3,5.3,0,0,1,1.958,2.134,6.7,6.7,0,0,0-2.149,4.909,6.773,6.773,0,0,0,.053.844Zm-16.783-4.355H-638.5a1.48,1.48,0,0,1-1.5-1.451v-1.452a2.958,2.958,0,0,1,3-2.9h3a3.019,3.019,0,0,1,2.114.845,6.7,6.7,0,0,0-3.525,4.961Zm20.064-2.272h0a6.782,6.782,0,0,0-2.766-2.688,3.04,3.04,0,0,1,2.117-.845h3a2.956,2.956,0,0,1,3,2.9v.038a6.66,6.66,0,0,0-2-.3,6.685,6.685,0,0,0-3.347.9Zm-9.653-3.534a5.171,5.171,0,0,1-5.248-5.079A5.173,5.173,0,0,1-625-14571a5.175,5.175,0,0,1,5.251,5.083A5.173,5.173,0,0,1-625-14560.839Zm10.5-1.452a2.958,2.958,0,0,1-3-2.9,2.958,2.958,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-614.5-14562.291Zm-21,0a2.956,2.956,0,0,1-3-2.9,2.956,2.956,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-635.5-14562.291Z" transform="translate(681 14617)" fill="currentColor"/>
            <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M11.267,5.915A5.352,5.352,0,1,1,5.915.563,5.352,5.352,0,0,1,11.267,5.915ZM5.3,8.749,9.267,4.778a.345.345,0,0,0,0-.488L8.778,3.8a.345.345,0,0,0-.488,0L5.051,7.039,3.539,5.527a.345.345,0,0,0-.488,0l-.488.488a.345.345,0,0,0,0,.488L4.807,8.749a.345.345,0,0,0,.488,0Z" transform="translate(63.085 59.565)" fill="currentColor"/>
          </g>
    </symbol>

    <symbol id="pending-org-icon" viewBox="0 0 33.354 24.833">
        <g id="Group_1932" data-name="Group 1932" transform="translate(-40.999 -44.999)">
            <path id="Subtraction_7" data-name="Subtraction 7" d="M-918.489-14550.677h-13.263a2.217,2.217,0,0,1-2.249-2.18v-1.3a5.321,5.321,0,0,1,5.4-5.226h.388a7.622,7.622,0,0,0,3.211.728,7.57,7.57,0,0,0,3.211-.728h.391a5.4,5.4,0,0,1,4.97,3.185,5.907,5.907,0,0,0-2.14,4.553,5.946,5.946,0,0,0,.08.972Zm-16.923-4.355H-938.5a1.48,1.48,0,0,1-1.5-1.451v-1.452a2.958,2.958,0,0,1,3-2.9h3a3.019,3.019,0,0,1,2.114.845,6.7,6.7,0,0,0-3.525,4.961Zm25.411-1.911h0a5.84,5.84,0,0,0-2.646-.625,5.851,5.851,0,0,0-2.54.572,6.721,6.721,0,0,0-2.927-3,3.035,3.035,0,0,1,2.114-.845h3a2.956,2.956,0,0,1,3,2.9v.992Zm-15-3.9a5.171,5.171,0,0,1-5.248-5.079A5.173,5.173,0,0,1-925-14571a5.175,5.175,0,0,1,5.251,5.083A5.173,5.173,0,0,1-925-14560.839Zm10.5-1.452a2.958,2.958,0,0,1-3-2.9,2.958,2.958,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-914.5-14562.291Zm-21,0a2.956,2.956,0,0,1-3-2.9,2.956,2.956,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-935.5-14562.291Z" transform="translate(981 14616)" fill="currentColor"/>
            <path id="Path_17506" data-name="Path 17506" d="M15.786,11a4.786,4.786,0,1,0,4.786,4.786A4.792,4.792,0,0,0,15.786,11Zm1.625,6.411a.736.736,0,0,1-1.041,0l-1.1-1.1a.733.733,0,0,1-.216-.521V13.945a.736.736,0,0,1,1.473,0v1.536l.889.889A.735.735,0,0,1,17.411,17.411Z" transform="translate(52.564 48.563)" fill="currentColor"/>
          </g>
    </symbol>

    <symbol id="cancelled-org-icon" viewBox="0 0 33.482 24.718">
        <g id="Group_1866" data-name="Group 1866" transform="translate(-40.999 -45.999)">
            <path id="Subtraction_8" data-name="Subtraction 8" d="M-1218.442-14550.677h-13.309a2.217,2.217,0,0,1-2.249-2.18v-1.3a5.321,5.321,0,0,1,5.4-5.226h.388a7.623,7.623,0,0,0,3.211.728,7.57,7.57,0,0,0,3.211-.728h.391a5.441,5.441,0,0,1,4.882,3,6.571,6.571,0,0,0-2,4.738,6.748,6.748,0,0,0,.071.976Zm-16.97-4.355h-3.086a1.48,1.48,0,0,1-1.5-1.451v-1.452a2.958,2.958,0,0,1,3-2.9h3a3.019,3.019,0,0,1,2.114.845,6.7,6.7,0,0,0-3.525,4.961Zm20.064-2.27h0a6.77,6.77,0,0,0-2.766-2.691,3.035,3.035,0,0,1,2.114-.845h3a3.032,3.032,0,0,1,2.1.828,2.848,2.848,0,0,1,.9,2.008,6.608,6.608,0,0,0-1.886-.272,6.622,6.622,0,0,0-3.46.974Zm-9.652-3.537a5.171,5.171,0,0,1-5.248-5.079A5.173,5.173,0,0,1-1225-14571a5.175,5.175,0,0,1,5.251,5.083A5.173,5.173,0,0,1-1225-14560.839Zm10.5-1.452a2.958,2.958,0,0,1-3-2.9,2.958,2.958,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-1214.5-14562.291Zm-21,0a2.956,2.956,0,0,1-3-2.9,2.956,2.956,0,0,1,3-2.9,2.956,2.956,0,0,1,3,2.9A2.956,2.956,0,0,1-1235.5-14562.291Z" transform="translate(1281 14617)" fill="currentColor"/>
            <path id="Icon_awesome-times-circle" data-name="Icon awesome-times-circle" d="M5.931.563A5.368,5.368,0,1,0,11.3,5.931,5.367,5.367,0,0,0,5.931.563ZM8.563,7.34a.26.26,0,0,1,0,.368l-.857.855a.26.26,0,0,1-.368,0L5.931,7.143,4.522,8.563a.26.26,0,0,1-.368,0L3.3,7.706a.26.26,0,0,1,0-.368l1.42-1.407L3.3,4.522a.26.26,0,0,1,0-.368L4.156,3.3a.26.26,0,0,1,.368,0L5.931,4.718,7.34,3.3a.26.26,0,0,1,.368,0l.857.857a.26.26,0,0,1,0,.368L7.143,5.931Z" transform="translate(63.182 59.418)" fill="currentColor"/>
        </g>
    </symbol>

    <symbol id="potential-revenue-icon" viewBox="0 0 25.97 23.494">
        <g id="XMLID_90_" transform="translate(0.001 -15.004)">
            <path id="XMLID_91_" d="M8.656,22.423c4.954,0,8.656-1.958,8.656-3.71S13.61,15,8.656,15,0,16.963,0,18.714,3.7,22.423,8.656,22.423Z" transform="translate(-0.001)" fill="currentColor"/>
            <path id="XMLID_92_" d="M8.656,232.7a18.122,18.122,0,0,0,2.081-.119,9.9,9.9,0,0,1-1.365-2.368c-.238.008-.476.014-.716.014a17.264,17.264,0,0,1-7.548-1.579A9.8,9.8,0,0,1,0,228v.987C0,230.742,3.7,232.7,8.656,232.7Z" transform="translate(-0.001 -195.438)" fill="currentColor"/>
            <path id="XMLID_93_" d="M8.655,172.709h.076a9.947,9.947,0,0,1,0-2.473H8.655a17.265,17.265,0,0,1-7.548-1.579A9.806,9.806,0,0,1,0,168.013V169C0,170.751,3.7,172.709,8.655,172.709Z" transform="translate(0 -140.393)" fill="currentColor"/>
            <path id="XMLID_102_" d="M8.655,112.717q.368,0,.726-.014a9.908,9.908,0,0,1,1.548-2.583,20.784,20.784,0,0,1-2.275.125,17.265,17.265,0,0,1-7.548-1.579A9.809,9.809,0,0,1,0,108.021v.987C0,110.759,3.7,112.717,8.655,112.717Z" transform="translate(0 -85.348)" fill="currentColor"/>
            <path id="Subtraction_9" data-name="Subtraction 9" d="M-376.611-14763.079a7.388,7.388,0,0,1-5-1.943,7.428,7.428,0,0,1-1.573-2.036,7.238,7.238,0,0,1-.771-2.385,7.113,7.113,0,0,1-.078-1.057,7.586,7.586,0,0,1,.134-1.4,7.38,7.38,0,0,1,1.105-2.7,7.437,7.437,0,0,1,4.94-3.216,7.269,7.269,0,0,1,1.242-.107,7.43,7.43,0,0,1,7.421,7.421A7.43,7.43,0,0,1-376.611-14763.079Zm-.883-6.353a.228.228,0,0,0-.156.063l-.49.479a.233.233,0,0,0-.068.179.231.231,0,0,0,.09.166,2,2,0,0,0,1.22.423v.676a.223.223,0,0,0,.226.226h.449a.223.223,0,0,0,.226-.226v-.679a1.58,1.58,0,0,0,1.487-1.024,1.53,1.53,0,0,0-.081-1.218,1.548,1.548,0,0,0-.938-.777l-1.521-.444a.419.419,0,0,1-.3-.4.416.416,0,0,1,.415-.415H-376a.854.854,0,0,1,.482.148.208.208,0,0,0,.115.033.234.234,0,0,0,.159-.063l.49-.479a.216.216,0,0,0,.067-.17.238.238,0,0,0-.093-.175,1.984,1.984,0,0,0-1.216-.423v-.675a.224.224,0,0,0-.226-.227h-.449a.224.224,0,0,0-.226.227v.675h-.037a1.539,1.539,0,0,0-1.137.5,1.519,1.519,0,0,0-.394,1.179,1.61,1.61,0,0,0,1.179,1.361l1.443.423a.42.42,0,0,1,.3.4.416.416,0,0,1-.415.415h-.935a.872.872,0,0,1-.482-.145A.2.2,0,0,0-377.493-14769.432Z" transform="translate(395.159 14801.577)" fill="currentColor"/>
            </g>
    </symbol>

    <symbol id="amount-due-icon" viewBox="0 0 25.97 23.495">
        <g id="XMLID_90_" transform="translate(0.001 -15.004)">
            <path id="XMLID_91_" d="M8.656,22.423c4.954,0,8.656-1.958,8.656-3.71S13.61,15,8.656,15,0,16.963,0,18.714,3.7,22.423,8.656,22.423Z" transform="translate(-0.001)" fill="currentColor"/>
            <path id="XMLID_92_" d="M8.656,232.7a18.122,18.122,0,0,0,2.081-.119,9.9,9.9,0,0,1-1.365-2.368c-.238.008-.476.014-.716.014a17.264,17.264,0,0,1-7.548-1.579A9.8,9.8,0,0,1,0,228v.987C0,230.742,3.7,232.7,8.656,232.7Z" transform="translate(-0.001 -195.438)" fill="currentColor"/>
            <path id="XMLID_93_" d="M8.655,172.709h.076a9.947,9.947,0,0,1,0-2.473H8.655a17.265,17.265,0,0,1-7.548-1.579A9.806,9.806,0,0,1,0,168.013V169C0,170.751,3.7,172.709,8.655,172.709Z" transform="translate(0 -140.393)" fill="#f25b5b"/>
            <path id="XMLID_102_" d="M8.655,112.717q.368,0,.726-.014a9.908,9.908,0,0,1,1.548-2.583,20.784,20.784,0,0,1-2.275.125,17.265,17.265,0,0,1-7.548-1.579A9.809,9.809,0,0,1,0,108.021v.987C0,110.759,3.7,112.717,8.655,112.717Z" transform="translate(0 -85.348)" fill="currentColor"/>
            <path id="Path_17507" data-name="Path 17507" d="M18.42,11a7.42,7.42,0,1,0,7.42,7.42A7.429,7.429,0,0,0,18.42,11Zm2.519,9.939a1.141,1.141,0,0,1-1.614,0l-1.712-1.712a1.137,1.137,0,0,1-.334-.807V15.566a1.142,1.142,0,0,1,2.283,0v2.381l1.378,1.378A1.14,1.14,0,0,1,20.939,20.939Z" transform="translate(0.129 12.659)" fill="currentColor"/>
          </g>
    </symbol>

    <symbol id="recieved-amount-icon" viewBox="0 0 25.969 23.495">
        <g id="XMLID_90_" transform="translate(0.001 -15.004)">
            <path id="XMLID_91_" d="M8.656,22.423c4.954,0,8.656-1.958,8.656-3.71S13.61,15,8.656,15,0,16.963,0,18.714,3.7,22.423,8.656,22.423Z" transform="translate(-0.001)" fill="currentColor"/>
            <path id="XMLID_92_" d="M8.656,232.7a18.122,18.122,0,0,0,2.081-.119,9.9,9.9,0,0,1-1.365-2.368c-.238.008-.476.014-.716.014a17.264,17.264,0,0,1-7.548-1.579A9.8,9.8,0,0,1,0,228v.987C0,230.742,3.7,232.7,8.656,232.7Z" transform="translate(-0.001 -195.438)" fill="currentColor"/>
            <path id="XMLID_93_" d="M8.655,172.709h.076a9.947,9.947,0,0,1,0-2.473H8.655a17.265,17.265,0,0,1-7.548-1.579A9.806,9.806,0,0,1,0,168.013V169C0,170.751,3.7,172.709,8.655,172.709Z" transform="translate(0 -140.393)" fill="currentColor"/>
            <path id="XMLID_102_" d="M8.655,112.717q.368,0,.726-.014a9.908,9.908,0,0,1,1.548-2.583,20.784,20.784,0,0,1-2.275.125,17.265,17.265,0,0,1-7.548-1.579A9.809,9.809,0,0,1,0,108.021v.987C0,110.759,3.7,112.717,8.655,112.717Z" transform="translate(0 -85.348)" fill="currentColor"/>
            <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M15.4,7.983A7.42,7.42,0,1,1,7.983.562,7.42,7.42,0,0,1,15.4,7.983ZM7.125,11.912,12.63,6.406a.479.479,0,0,0,0-.677l-.677-.677a.479.479,0,0,0-.677,0l-4.49,4.49-2.1-2.1a.479.479,0,0,0-.677,0l-.677.677a.479.479,0,0,0,0,.677l3.112,3.112A.479.479,0,0,0,7.125,11.912Z" transform="translate(10.565 23.096)" fill="currentColor"/>
          </g>
    </symbol>

    <symbol id="total-plans-icon" viewBox="0 0 23.207 23.207">
        <g id="_064---Subscription-Models" data-name="064---Subscription-Models">
            <path id="Shape" d="M8.969.975A.912.912,0,0,0,8.141,0H4.825A.912.912,0,0,0,4,.975V6.63H8.969ZM6.9,5.311v.076a.414.414,0,1,1-.829,0V5.3a1.243,1.243,0,0,1-.663-.539.414.414,0,0,1,.717-.414.414.414,0,1,0,.36-.622,1.24,1.24,0,0,1-.414-2.41V1.243a.414.414,0,1,1,.829,0v.083a1.243,1.243,0,0,1,.663.539.414.414,0,0,1-.717.414.414.414,0,1,0-.36.622A1.24,1.24,0,0,1,6.9,5.311Z" transform="translate(-2.339)" fill="currentColor"/>
            <path id="Shape-2" data-name="Shape" d="M26.969.975A.912.912,0,0,0,26.141,0H22.825A.912.912,0,0,0,22,.975V6.63h4.973ZM24.9,5.311v.076a.414.414,0,0,1-.829,0V5.3a1.243,1.243,0,0,1-.663-.539.414.414,0,0,1,.717-.414.414.414,0,1,0,.36-.622,1.24,1.24,0,0,1-.414-2.41V1.243a.414.414,0,1,1,.829,0v.083a1.243,1.243,0,0,1,.663.539.414.414,0,0,1-.717.414.414.414,0,1,0-.36.622,1.24,1.24,0,0,1,.414,2.41Z" transform="translate(-12.88)" fill="currentColor"/>
            <path id="Shape-3" data-name="Shape" d="M22.825,25.459h3.315a.912.912,0,0,0,.829-.975V18H22v6.484A.912.912,0,0,0,22.825,25.459Zm.414-6.63h2.486a.414.414,0,0,1,0,.829H23.24a.414.414,0,0,1,0-.829Zm0,1.658h2.486a.414.414,0,0,1,0,.829H23.24a.414.414,0,0,1,0-.829Zm0,1.658h2.486a.414.414,0,1,1,0,.829H23.24a.414.414,0,1,1,0-.829Z" transform="translate(-12.88 -10.541)" fill="currentColor"/>
            <path id="Shape-4" data-name="Shape" d="M40.825,25.459h3.315a.912.912,0,0,0,.829-.975V18H40v6.484A.912.912,0,0,0,40.825,25.459Zm.414-6.63h2.486a.414.414,0,1,1,0,.829H41.24a.414.414,0,1,1,0-.829Zm0,1.658h2.486a.414.414,0,1,1,0,.829H41.24a.414.414,0,1,1,0-.829Zm0,1.658h2.486a.414.414,0,1,1,0,.829H41.24a.414.414,0,1,1,0-.829Zm0,1.658h2.486a.414.414,0,1,1,0,.829H41.24a.414.414,0,1,1,0-.829Z" transform="translate(-23.42 -10.541)" fill="currentColor"/>
            <path id="Shape-5" data-name="Shape" d="M44.969.975A.912.912,0,0,0,44.141,0H40.825A.912.912,0,0,0,40,.975V6.63h4.973ZM42.9,5.311v.076a.414.414,0,0,1-.829,0V5.3a1.243,1.243,0,0,1-.663-.539.414.414,0,0,1,.717-.414.414.414,0,1,0,.36-.622,1.24,1.24,0,0,1-.414-2.41V1.243a.414.414,0,0,1,.829,0v.083a1.243,1.243,0,0,1,.663.539.414.414,0,0,1-.717.414.414.414,0,1,0-.36.622,1.24,1.24,0,0,1,.414,2.41Z" transform="translate(-23.42)" fill="currentColor"/>
            <path id="Shape-6" data-name="Shape" d="M3.315,19.5H23.207V5.414A.414.414,0,0,0,22.792,5h-.414V16.871a1.737,1.737,0,0,1-1.658,1.8H17.4a1.737,1.737,0,0,1-1.658-1.8V5h-.829V16.871a1.737,1.737,0,0,1-1.658,1.8H9.946a1.737,1.737,0,0,1-1.658-1.8V5H7.459V16.871a1.737,1.737,0,0,1-1.658,1.8H2.486a1.737,1.737,0,0,1-1.658-1.8V5H.414A.414.414,0,0,0,0,5.414V19.5H1.658a.414.414,0,1,1,0,.829H0v1.243a.414.414,0,0,0,.414.414H22.792a.414.414,0,0,0,.414-.414V20.333H3.315a.414.414,0,1,1,0-.829Z" transform="translate(0 -2.928)" fill="currentColor"/>
            <path id="Shape-7" data-name="Shape" d="M4.825,25.459H8.141a.912.912,0,0,0,.829-.975V18H4v6.484A.912.912,0,0,0,4.825,25.459Zm.414-6.63H7.726a.414.414,0,1,1,0,.829H5.24a.414.414,0,0,1,0-.829Zm0,1.658H7.726a.414.414,0,1,1,0,.829H5.24a.414.414,0,1,1,0-.829Z" transform="translate(-2.339 -10.541)" fill="currentColor"/>
            <path id="Shape-8" data-name="Shape" d="M24.847,54H12.414a.414.414,0,0,0,0,.829H24.847a.414.414,0,0,0,0-.829Z" transform="translate(-7.027 -31.622)" fill="#5ca4eb"/>
            <path id="Shape-9" data-name="Shape" d="M19.447,48l-.829,1.658h7.776L25.565,48Z" transform="translate(-10.903 -28.109)" fill="currentColor"/>
          </g>
    </symbol>

    <symbol id="successCheck" viewBox="0 0 130 130">
        <g id="success-check" transform="translate(-895 -211)">
            <g id="Ellipse_9" data-name="Ellipse 9" transform="translate(895 211)" fill="none" stroke="#fff" stroke-width="3">
              <circle cx="65" cy="65" r="65" stroke="none"/>
              <circle cx="65" cy="65" r="63.5" fill="none"/>
            </g>
            <path id="Icon_feather-check" data-name="Icon feather-check" d="M56,9,21.625,43.375,6,27.75" transform="translate(929.5 249.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </g>
      </symbol>
  
  </svg>