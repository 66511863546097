<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title">Enquiries</h2>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Enquiries</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Enquiries</h3>

      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>

            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label> Name</mat-label>
                      <input matInput type="text" formControlName="name">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput type="text" formControlName="phoneNumber">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Status</mat-label>
                      <mat-select placeholder="Status" formControlName="enquiryStatusName">
                        <mat-option value="Select Status">Status</mat-option>
                        <mat-option *ngFor="let planType of enquiryStatusDropdown " [value]="planType.enquiryStatusName">
                          {{planType.enquiryStatusName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Status</mat-label>
                      <input matInput type="text" formControlName="enquiryStatusName">
                    </mat-form-field> -->
                  </div>
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
            </div>

          </div>
          <div class="rightTableActions centerItemVert">


          </div>

        </div>
        <div class="mat-elevation-z8">

        </div>
      </div>
      <app-data-table [inputColumns]="displayedColumns" [inputSource]="enquiryListData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
    </div>
  </div>
</main>

<ng-template #addDepartment>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Enquiry Response</h2>
    </div>
    <form [formGroup]="responseForm" (ngSubmit)="sendEnquiryResponse()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Response Details</mat-label>
        <input matInput formControlName="responseDetails" required />
        <!-- <mat-error *ngIf="addDepartmentName.errors?.required">Department Name is required</mat-error> -->
      </mat-form-field>
      <div class="btnsWidget form-group17 center">
        <button type="submit" class="btn btn-primary btn-sm" mat-dialog-close>Save</button>
        <button type="button" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
      </div>

      <!-- <div class="forgotpassword form-group17 center"> //  [disabled]="addDepartmentName.invalid"
        <button type="button" class="btn btn-primary" mat-dialog-close name="deptBtn" 
          [disabled]="addDepartmentName.invalid">Save</button>
      </div> -->
    </form>
  </div>
</ng-template>