<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Role </h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </div>
    </div> -->

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Contact Us Content</h3>
                <!-- <a routerLink="/manage-role" class="btn btn-primary btnsmall">Back</a> -->
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s6 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title <span class="req">*</span></mat-label>
                                            <input maxlength="100" formControlName="title" matInput placeholder="title">
                                            <mat-error *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s6 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Main Text <span class="req">*</span></mat-label>
                                            <input maxlength="100" formControlName="mainText" matInput placeholder="Main Text">
                                            <mat-error *ngIf="form.get('mainText')?.touched  && form.get('mainText')?.errors?.required">
                                                Main Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Sub Text <span class="req">*</span></mat-label>
                                            <textarea maxlength="255" formControlName="subText" matInput placeholder="Sub Text" ></textarea>
                                            <mat-error *ngIf="form.get('subText')?.touched  && form.get('subText')?.errors?.required">
                                                Sub Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s6 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email Id <span class="req">*</span></mat-label>
                                            <input maxlength="75" formControlName="email" matInput placeholder="email">
                                            <mat-error *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                Email is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s6 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone No. <span class="req">*</span></mat-label>
                                            <input maxlength="15" formControlName="phone" matInput placeholder="Main Text">
                                            <mat-error *ngIf="form.get('phone')?.touched  && form.get('phone')?.errors?.required">
                                                Phone No. is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Address <span class="req">*</span></mat-label>
                                            <textarea maxlength="256" formControlName="address" matInput placeholder="Address" ></textarea>
                                            <mat-error *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                Address is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                   
                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>

