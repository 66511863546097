import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, siteSMSSubscriptionPlan } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SMSSubscriptionPlanService {

  constructor(private http : HttpClient) { }

  AddSMSSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSMSSubscriptionPlan.addSMSSubscriptionPlanAPI}`,data);
  }

  AllSMSSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSMSSubscriptionPlan.allSMSSubscriptionPlanAPI}`,data);
  }
  
  GetSMSSubscriptionPlanById(Id:any) {
     return this.http.get(`${environment.baseUrl}${siteSMSSubscriptionPlan.getSMSSubscriptionPlanByIdAPI}${Id}`);
  }

  UpdateSMSSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSMSSubscriptionPlan.updateSMSSubscriptionPlanAPI}`,data);
  }

  DeleteSMSSubscriptionPlan(Id:any) {
    return this.http.get(`${environment.baseUrl}${siteSMSSubscriptionPlan.deleteSMSSubscriptionPlanAPI}${'?id=' + Id}`);
  }

}
