import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate  {

  user:any;
  constructor(private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
      
      this.user = localStorage.getItem('currentUser');
      const currentUser = JSON.parse(this.user);
      if (currentUser != null && currentUser != undefined) {
        console.log("authorized");
          return true;
          
      }
      console.log("Not authorized");
      this.router.navigate(['/']);
      return false;
  }
}
