<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Email Subscription Plan</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">AddEmailSubscriptionPlan</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Email Subscription Plan</h3>
                <a routerLink="/email-subs" class="btn btn-primary btnsmall">Back</a>
            </div>

            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="proSection">
                                <h5>Subscription Details</h5>
                                <!-- <div class="row">
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Plan Name <span class="req">*</span></mat-label>
                                            <input matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email Pack<span class="req">*</span></mat-label>
                                            <mat-select>
                                                <mat-option value="one">500/Day</mat-option>
                                                <mat-option value="two">1000/Day</mat-option>
                                                <mat-option value="two">1500/Day</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Pricing($)<span class="req">*</span></mat-label>
                                            <input matInput>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Status<span class="req">*</span></mat-label>
                                            <mat-select>
                                                <mat-option value="one">Active</mat-option>
                                                <mat-option value="two">In-Active</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>


                                </div> -->
                            
                                <div class="row">
                                    <input matInput formControlName="emailSubscriptionPlanID" hidden>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Plan Name <span class="req">*</span></mat-label>
                                            <input (keypress)="allowAlphabetOnly($event)" pattern="(\s*\S\s*)+"
                                            maxlength="49"  matInput formControlName="emailPlanName">
                                            <mat-error
                                                *ngIf="form.get('emailPlanName')?.touched  && form.get('emailPlanName')?.errors?.required">
                                                Plan Name is required</mat-error>
                                            <mat-error *ngIf="form.get('emailPlanName')?.hasError('pattern')">
                                                Please enter valid plan name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Plan Type<span class="req">*</span></mat-label>
                                            <mat-select placeholder="Plan Type" formControlName="emailPackID">
                                                <mat-option value="Select plan Type">Plan Type</mat-option>
                                                <mat-option *ngFor="let planType of planTypeDropdown "
                                                    [value]="planType.planID">
                                                    {{planType.planTypeName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('emailPackID')?.touched  && form.get('emailPackID')?.errors?.required">
                                                Plan Type is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Number Of User<span class="req">*</span></mat-label>
                                            <input matInput formControlName="numberOfUserAllowed" type="number">
                                            <mat-error
                                            *ngIf="form.get('numberOfUserAllowed')?.touched  && form.get('numberOfUserAllowed')?.errors?.required">
                                            Number of user is required</mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Limit<span class="req">*</span></mat-label>
                                            <input (keyup)="allowOnlyNumber($event)" matInput formControlName="limit" maxlength="10">
                                            <mat-error
                                                *ngIf="form.get('limit')?.touched  && form.get('limit')?.errors?.required">
                                                Limit is required</mat-error>
                                                <mat-error *ngIf="form.get('limit')?.hasError('pattern')">
                                                    Only numbers allowerd more than 0
                                                </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Pricing($)<span class="req">*</span></mat-label>
                                            <input (keyup)="allowOnlyNumber($event)" matInput formControlName="emailPrice" maxlength="10">
                                            <mat-error
                                                *ngIf="form.get('emailPrice')?.touched  && form.get('emailPrice')?.errors?.required">
                                                Price is required</mat-error>
                                                <mat-error *ngIf="form.get('emailPrice')?.hasError('pattern')">
                                                    Only numbers allowerd more than 0
                                                </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Status<span class="req">*</span></mat-label>
                                            <mat-select placeholder="Status" formControlName="emailPlanStatusID">
                                                <mat-option value="Select planStatus">Plan Status</mat-option>
                                                <mat-option *ngFor="let status of planTypeStautusDropdown "
                                                    [value]="status.planStatusID">
                                                    {{status.planStatusName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('emailPlanStatusID')?.touched  && form.get('emailPlanStatusID')?.errors?.required">
                                                Status is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Public Status<span class="req">*</span></mat-label>
                                            <mat-select placeholder="Public Status"
                                                formControlName="isPublic">
                                                <mat-option [value]="true">Public </mat-option>
                                                <mat-option [value]="false"> Non Public </mat-option> -->
                                    <!-- <mat-option *ngFor="let status of planTypeStautusDropdown " [value]="status.planStatusID">
                                                    {{status.planStatusName}}  
                                                </mat-option> -->
                                    <!-- </mat-select>
                                            <mat-error
                                                *ngIf="form.get('isPublic')?.touched  && form.get('isPublic')?.errors?.required">
                                                Public status is required</mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Description<span class="req">*</span></mat-label>
                                            <textarea matInput formControlName="description" pattern="(\s*\S\s*)+"
                                                placeholder="Description"></textarea>
                                            <mat-error
                                                *ngIf="form.get('description')?.touched  && form.get('description')?.errors?.required">
                                                Description is required</mat-error>
                                            <mat-error *ngIf="form.get('description')?.hasError('pattern')">
                                                Please enter valid description
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="proSection center">
                                <button class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>