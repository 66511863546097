<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
      <h2 class="page-title">Manage Staff</h2>
  
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Manage Staff</li>
        </ol>
      </div>
    </div> -->
    <div class="container-fluid">
      <div class="matCard">
        <div class="matCrdTitle centerItemVert justify-content-between">
          <h3 class="matCardTitle">Quotes</h3>
          <a (click)="goto()" class="btn btn-primary btnsmall">Add Quote</a>
        </div>
        <div class="tableActionTop mb-3">

            <div class="tableFilter">
                <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
                <div class="filterForm" [class.toggled]="classToggled">
                  <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="row">
                      <div class="col s12 m6">
                        <mat-form-field appearance="outline" class="w-100">
                          <mat-label>Search Quote</mat-label>
                          <input matInput type="text" formControlName="searchValue">
                        </mat-form-field>
                      </div>
                     
                    </div>
                    <div class="btnsWidget form-group">
                      <button type="submit" class="btn btn-primary btn-sm">Search</button>
                      <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
    
                    </div>
                  </form>
                </div>
              </div>
        </div>
       
  

        <app-data-table [inputColumns]="displayedColumns" [inputSource]="quoteData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
        </div>
  
        
      </div>
  
  </main>
