import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, masters, profile } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterServiceService {

  constructor(private http : HttpClient, ) { }


GetCountryDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getCountryDropdown}`);
}

GetBloodGroupDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getBloodGroupDropdown}`);
}

GetStateDropdown(CountryID:any) {
  return this.http.get(`${environment.baseUrl}${masters.getStateDropdown}`+'?CountryID='+CountryID);
}

AddProfilePhoto(data:any){

  return this.http.post(`${environment.baseUrl}${profile.getProfilePhoto}`,data);
}
GetDataLogs(data: any) {
  return this.http.post(`${environment.baseUrl}${masters.getDataLogs}`, data);
}

GetRolesListDropdown(data: any) {
  return this.http.post(`${environment.baseUrl}${masters.getRolesList}`, data);
}

GetAdminUsersList(data: any) {
  return this.http.post(`${environment.baseUrl}${masters.alladminUsersList}`, data);
}


 // common post method for all http requests
  // tslint:disable-next-line
  postData<T>(data: {}): Observable<any> {
    let url = profile.getProfilePhoto;
    let newHTTPParams = new HttpParams();
    // if (reqAPIParams != null) {
    //   reqAPIParams.forEach(element => {
    //     newHTTPParams = newHTTPParams.append(element.Key, element.Value);
    //   });
    // }
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  // attach base url
  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
  // common error handling method
  public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };


  GetGenderDropdown() {
    return this.http.get(`${environment.baseUrl}${masters.getGenderDropdownDropdown}`);
  }
}

export interface ApiParams {
  Key: string;
  Value: string;
}