<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title"> Settings</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a routerLink="/email-templates">Settings</a>
                </li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                 <h3 class="matCardTitle">Organization</h3>
                 <!-- <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a> -->
             </div>


            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                         <p class="chngePic">
                             <input type="file">
                             <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                             <img src="../../../assets/img/display.jpg" alt="">
                         </p>
                     </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <form [formGroup]="form">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">

                                        <!-- <mat-form-field class="w-100 mat-form-field-readonly" appearance="outline">
                                             <mat-label>Is Demo Allow <span class="req">*</span></mat-label>
                                             <input matInput >
                                         </mat-form-field> -->
                                        <div class="d-flex" style="vertical-align: middle;">
                                            <h5 class="ml-2 mt-1 display">Is Auto Demo Allow</h5>
                                            <mat-slide-toggle [(ngModel)]="checked" formControlName="isDemoAllowed" (change)="onChange($event)" class="ml-5"></mat-slide-toggle>
                                        
                                        </div>
                                        <!-- <mat-radio-group aria-label="Is Demo Allow">
                                            <mat-radio-button value="1">Enable</mat-radio-button>
                                            <mat-radio-button value="2">Disable</mat-radio-button>
                                        </mat-radio-group> -->
                                    </div>
                  
                                   <div class="row">
                                       <div class="col l6 s12 ">
                                            <div class="col l12 s12  form-group">
                                                <div class="matCrdTitle centerItemVert justify-content-between">
                                                    <h3 class="matCardTitle">Twilio</h3>
                                                    <!-- <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a> -->
                                                </div>
                                                <!-- <h3 _ngcontent-lgc-c182="">Subscription</h3> -->
                                                <div class="col s12 m12 form-group"></div>
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Authentication Token<span class="req">*</span></mat-label>
                                                    <input [(ngModel)]="authenticationToken" formControlName="authenticationToken" matInput>
                                                </mat-form-field>
                                            </div>
                                            <div class="col l12 s12  form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Account SID<span class="req">*</span></mat-label>
                                                    <input [(ngModel)]="accountSID" formControlName="accountSID" matInput>
                                                </mat-form-field>
                                            </div>
                                            <div class="col l12 s12  form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Phone Number<span class="req">*</span></mat-label>
                                                    <input [(ngModel)]="phoneNumber" formControlName="phoneNumber"matInput>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    <div class="col l6 s12 m12">
                                        <div class="col s12 m12 form-group">
                                            <div class="matCrdTitle centerItemVert justify-content-between">
                                                <h3 class="matCardTitle">Send Grid</h3>
                                                <!-- <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a> -->
                                            </div>
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Sender Name<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="senderName" formControlName="senderName" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 form-group">
                                                <mat-form-field class="w-100" appearance="outline">
                                                    <mat-label>Sender Email<span class="req">*</span></mat-label>
                                                    <input [(ngModel)]="senderEmail" formControlName="senderEmail" matInput>
                                                </mat-form-field>
                                        </div>
                                        <div class="col s12 m12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Send Grid Key<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="sendGridKey" formControlName="sendGridKey" matInput>
                                            </mat-form-field>
                                        </div>
                                    <!-- <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Size<span class="req">*</span></mat-label>
                                            <input matInput>
                                        </mat-form-field>
                                    </div> -->
                                        <div class="col s12 m12 form-group">
                                            <!-- <ejs-richtexteditor id='defaultRTE'>
                                                <ng-template #valueTemplate>
                                                <p>The Rich Text Editor component is WYSIWYG ("what you see is what you get") editor that provides the best user
                                                    experience to create and update the content.
                                                    Users can format their content using standard toolbar commands.</p>
                                        
                                                </ng-template>
                                            </ejs-richtexteditor> -->

                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div class="proSection center">
                                <button type="submit" (click)="updateSetting()" [disabled]="form.invalid" class="btn btn-primary">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>