<nav class="sb-topnav navbar navbar-expand">
    <a class="navbar-brand" href="/dashboard"><img alt="Admim Theme" src="assets/img/logo.png"></a><button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle" (click)="toggleSidebar()"><svg height="15.424" viewBox="0 0 23.136 15.424" width="23.136" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.427,25.707H29.563V23.136H6.427v2.571Zm0-6.427H29.563V16.71H6.427V19.28Zm0-9v2.571H29.563V10.283H6.427Z" data-name="Icon metro-menu" id="Icon_metro-menu" transform="translate(-6.427 -10.283)"></path></svg></button><!-- Navbar Search-->
   
    <ul class="navbar-nav ml-auto align-items-center">
        <li class="circleLink mr-3 mr-md-4">
            <a><svg width="16" height="16" class="icon">
                <use xlink:href="#search-icon" ></use>
            </svg></a>
        </li>
        <li class="circleLink mr-3 mr-md-3">
            <a mat-button [matMenuTriggerFor]="notificationMenu"><svg width="16" height="16" class="icon">
                <use xlink:href="#bell-icon" ></use>
            </svg><span class="badge"></span></a>
            <mat-menu #notificationMenu="matMenu" xPosition="before" class="notificationsDDMenu">
                <button mat-menu-item>
                    <b>New session has been created by Staff</b>
                    <span class="date">5 hours ago</span>
                </button>
                <button mat-menu-item>
                    <b>Notification 1</b>
                    <span class="date">5 hours ago</span>
                </button>
                <button mat-menu-item>
                    <b>Notification 1</b>
                    <span class="date">5 hours ago</span>
                </button>
            </mat-menu>
        </li>
        <li class="nav-item dropdown px-2 pl-md-3 profile-dropdown">
            <a 
            class="nav-link dropdown-toggle d-flex align-items-center"> <span class="d-none d-md-block">{{user.firstName}} {{user.lastName}}</span><div class="header-dp mr-1" mat-button [matMenuTriggerFor]="beforeMenu"  id="userDropdown"><img src="assets/img/display.jpg" alt=""></div></a>
            <mat-menu #beforeMenu="matMenu" xPosition="before" class="profileDDMenu">
                <p class="userName">{{user.firstName}} {{user.lastName}}</p>
                <!-- <button mat-menu-item>Setting</button> -->
                <button mat-menu-item (click)="profile()">My Profile</button>
                <!-- <button mat-menu-item routerLink="/settings">Setting</button> -->
                <button mat-menu-item (click)="resetPassword(resetPassword1)">Reset Password</button>
                <!-- <button mat-menu-item>Profile</button> -->
                <button mat-menu-item (click)="logout()">Log Out</button>
            </mat-menu>
        </li>
    </ul>
</nav>

<ng-template #resetPassword1>
    <div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Reset Password</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="resetForm" (ngSubmit)="savePassword()">
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Current Password</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
              <input matInput  formControlName="currentPassword"  autocomplete="new-password"  required />
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
              <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required autocomplete="new-password" autocomplete="off" 
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
              <button mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="resetForm.get('password')?.touched  && resetForm.get('password')?.errors?.required">
                                                Password is required
                                            </mat-error>
              <mat-error *ngIf="resetForm.get('password')?.errors?.pattern">
                Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
              <input matInput formControlName="confirmPassword" required autocomplete="off" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
              <span matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </span>
              <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group17 center">
            <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid" >Update</button>
          </div>
        </form>
  
      </mat-dialog-content>
    </div>
  </ng-template>