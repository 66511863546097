import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, Transaction } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http:HttpClient) { }

  AllTransaction(data:any) {
    return this.http.post(`${environment.baseUrl}${Transaction.allTransactionAPI}`,data);
  }

  GetTransactionDetailsById(Id:any) {
    return this.http.get(`${environment.baseUrl}${Transaction.getTransactionDetailsByIdAPI}${Id}`);
  }
  
}
