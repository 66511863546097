import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { MasterDropdownService } from 'src/app/common/services/MasterDropdownServices/master-dropdown.service';
import { SMSSubscriptionPlanService } from 'src/app/common/services/smsSubscriptionPlanService/sms-subscription-plan.service';

@Component({
  selector: 'app-edit-sms-subscription',
  templateUrl: './edit-sms-subscription.component.html',
  styleUrls: ['./edit-sms-subscription.component.css']
})
export class EditSmsSubscriptionComponent implements OnInit {

  form: FormGroup;
  subscriptionID: number;
  data: string;
  planTypeDropdown: any;
  planTypeStautusDropdown: any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private smsSubscriptionPlanService: SMSSubscriptionPlanService,
    private toaster: ToastrService,
    private router: Router,
    private masterDropdownService: MasterDropdownService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    //this.subscriptionID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.subscriptionID = params.id == undefined ? null : params.id;
    });

    this.form = this.formBuilder.group({
      smsSubscriptionPlanID: [0, Validators.required],
      smsPlanName: ['', Validators.required], 
      smsPrice: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      limit: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      smsPlanStatusID: ['', Validators.required],
      smsPackID: ['', Validators.required],
      description: ['', Validators.required],
      isTwilio: [true],
    });

    this.masterDropdown();
    this.GetSMSSubscriptionPlanById();
  }

  GetSMSSubscriptionPlanById() {
    //debugger;
    this.smsSubscriptionPlanService.GetSMSSubscriptionPlanById(this.subscriptionID).subscribe((res: any) => {
      this.form.patchValue({
        smsSubscriptionPlanID: res['smsSubscriptionPlanID'],
        smsPlanName: res['smsPlanName'],
        smsPrice: res['smsPrice'],
        limit: res['limit'],
        smsPlanStatusID: res['smsPlanStatusID'],
        smsPackID: res['smsPackID'],
        description: res['description'],
        isTwilio: JSON.parse(res['isTwilio']),
      });
      this.data = res;
    });
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.updateSMSSubscriptionPlan();
    // this.form.reset();
    // this.form.controls.smsSubscriptionPlanID.clearValidators();
    // this.form.controls.smsPlanName.clearValidators();
    // this.form.controls.smsPrice.clearValidators();
    // this.form.controls.limit.clearValidators();
    // this.form.controls.smsPlanStatusID.clearValidators();
    // this.form.controls.smsPackID.clearValidators();
    // this.form.controls.description.clearValidators();
  }

  updateSMSSubscriptionPlan() {
    console.log("this.form.value", this.form.value)
    this.smsSubscriptionPlanService.UpdateSMSSubscriptionPlan(this.form.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/sms-subs'])
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

}
