import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';
import { MasterServiceService } from 'src/app/common/services/master-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.css']
})
export class AdminProfileComponent implements OnInit {

  form: FormGroup;
  adminUserID:number;
  data:string;
  previewUrl:any = '../../../assets/img/display.jpg';


  constructor(
    private formBuilder : FormBuilder,
    private activatedRoute:ActivatedRoute,
    private manageStaffService:ManageStaffService,
    private masterServiceService:MasterServiceService,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    
  ) { }
  countries:any;
  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
  roles:any;
  bloodGroups:any;
  genders:any;
  user:any;
  ngOnInit(): void { 
    this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
          console.log("USERR",currentUser);
          this.adminUserID = currentUser.userId;
    //  this.activatedRoute.queryParams.subscribe(params => {
    //   this.adminUserID = params.id==undefined?null:params.id;
    // });
  

     this.form = this.formBuilder.group({
     adminUserID:this.adminUserID,
     firstName: ['', Validators.required],
     lastName: ['', Validators.required],
  
     dob: ['', Validators.required],
      
      gender: [0, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      
     
      username: ['', Validators.required],
      
      bloodGroupID: [0, Validators.required],
      address:['', Validators.required],
      city:['', Validators.required],
      contactNumber: ['', Validators.required],
      countryID: [0, Validators.required],
      stateID: [0, Validators.required],
      zipCode: ['', Validators.required],
      roleID: [0, Validators.required],
      //password: ['', Validators.required],
      //confirmPassword: [''],
      profilePic:[''],
      isVerified:[true]
  
  
  
    });

    this.GetStaffById();

this.GetCountiresList();
this.GetRolesList();
this.GetBloodGroupList();
this.GetGenderList();

}


  GetStaffById(){
    debugger;
        this.manageStaffService.GetStaffById(this.adminUserID).subscribe((res:any) => {
          if(res.responseData.profilePic != '' && res.responseData.profilePic != null){
            this.previewUrl=res.responseData.profilePic;
            console.log("inside profileurl",this.previewUrl)
          }
          console.log("ress",res)
      this.form.patchValue({
        adminUserID:res.responseData['adminUserID'],

        firstName: res.responseData['firstName'],
        lastName: res.responseData['lastName'],
        
        dob: res.responseData['dob'],
          
         gender:res.responseData['gender'],
         email: res.responseData['email'],
         
        
         username:res.responseData['username'],
         
         bloodGroupID: res.responseData['bloodGroupID'],
         address:res.responseData['address'],
         city:res.responseData['city'],
         contactNumber: res.responseData['contactNumber'],
         countryID: res.responseData['countryID'],
         stateID: res.responseData['stateID'],
         zipCode: res.responseData['zipCode'],
         roleID: res.responseData['roleID'],
         password: res.responseData['password'],
         confirmPassword: res.responseData['password'],
         profilePic:res.responseData['profilePic'],
          isVerified:res.responseData['isVerified']
  



      }); 
      this.GetStateList(res.responseData['countryID']);
      this.data = res.responseData;
      console.log("sae",this.data)
    });
}
GetCountiresList() {
  this.masterServiceService.GetCountryDropdown().subscribe((res: any) => {
    this.countries = res.responseData;
  })
  }
  
  GetRolesList() {
  this.manageStaffService.GetRolesDropdown().subscribe((res: any) => {
    this.roles = res.responseData;
  })
  }
  
  GetBloodGroupList() {
  this.masterServiceService.GetBloodGroupDropdown().subscribe((res: any) => {
    this.bloodGroups = res.responseData;
  })
  }
  


onSubmit(){
  this.updateStaff();
}
updateImg: boolean = false;
updateStaff(){
  debugger;
  console.log("Updatedata",this.form.value);
  if (this.profileImage != '') {
    this.form.controls.profilePic.setValue(this.profileImage);
  }

  if(!this.updateImg) {
    delete this.form.value.profilePic;
  }
  this.manageStaffService.UpdateStaff(this.form.value).subscribe((res: any) =>{
  console.log("update", res);
  console.log(res);
  if(res.status > 0){
    this.toaster.success(res.message, 'Success');
    this.router.navigate(['/manage-staff']);

  }else{
    this.toaster.error(res.message, 'Error');
  }

  })
}
GetGenderList() {
  this.masterServiceService.GetGenderDropdown().subscribe((res: any) => {
    this.genders= res.responseData;
  })
}

states:any;
GetStateList(countryId:any) {
  this.masterServiceService.GetStateDropdown(countryId).subscribe((res: any) => {
    this.states = res.responseData;
    console.log(res);
  })
}
finalUpload() {
  //alert('called');
  this.uploadFile(this.imageFile);
}
imageUrl: any;
imageFile: any;
profileImage: string = '';
  uploadFile(event: any) {
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    this.AddProfile(formData);
  }
  AddProfile(file: any) {
    debugger;
    this.masterServiceService.postData(file).subscribe((res: any) => {
      if (res.body.status > 0) {
        this.updateImg = true;
        this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }

    })
  }
  imageChangedEvent: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.previewUrl = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
}


