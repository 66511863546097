<main>


    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Feature</h3>
                <a (click)="goto()" class="btn btn-primary btnsmall">Back</a>
            </div>
            <div class="col s12 m12 form-group">
                <p>Feature Details Image</p>
            </div>
            <div class="col s12 m6 form-group">

                <!-- <img [src]="croppedImage" style="height: 270px;width: 300px;" alt="Add Image">
                                                   <br>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <label for="file-upload3" class="custom-file-upload">
                                                        <i class="fa fa-cloud-upload"></i>Upload Image
                                                        <input id="file-upload3" type="file"  accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                                    </label> -->


                <div class="col s12 m6 form-group " *ngIf="flag">
                    <img [src]="croppedImage" style="height: 200px;width: 200px;" alt="Add Image">
                </div>
                <div class="col s12 m6 form-group position-relative">
                    <ngx-drag-drop-crop-resize (croppedImage)="getCroppedImage($event)"
                        (isImageDeleted)="isTheImageDeleted($event)">

                    </ngx-drag-drop-crop-resize>
                </div>

            </div>
            <form [formGroup]="form">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group" style="display: none;">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Page Title</mat-label>
                                            <input maxlength="20" matInput placeholder="title" required name="title"
                                                formControlName="title">
                                            <mat-error
                                                *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Page title name is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="col s12 m12 form-group" style="display: none;">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Page Sub Title</mat-label>
                                            <textarea maxlength="255" formControlName="subTitle" required matInput
                                                placeholder="Page Sub Title"></textarea>
                                            <mat-error
                                                *ngIf="form.get('subTitle')?.touched  && form.get('subTitle')?.errors?.required">
                                                Page sub title name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- Feature Details -->


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Image Alt</mat-label>
                                            <input maxlength="20" matInput placeholder="featureImageAlt" required
                                                name="featureImageAlt" formControlName="featureImageAlt">
                                            <mat-error
                                                *ngIf="form.get('featureImageAlt')?.touched  && form.get('featureImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="featureImageDescription"
                                                matInput placeholder="featureImageDescription"></textarea>
                                            <mat-error
                                                *ngIf="form.get('featureImageDescription')?.touched  && form.get('featureImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Title</mat-label>
                                            <input maxlength="50" matInput placeholder="featureTitle" required
                                                name="featureTitle" formControlName="featureTitle">
                                            <mat-error
                                                *ngIf="form.get('featureTitle')?.touched  && form.get('featureTitle')?.errors?.required">
                                                Feature Title name is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Quote</mat-label>
                                            <input matInput placeholder="Feature Quote" required name="featureQuote"
                                                formControlName="featureQuote">
                                            <mat-error
                                                *ngIf="form.get('featureQuote')?.touched  && form.get('featureQuote')?.errors?.required">
                                                Feature Quote is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Summary</mat-label>
                                            <input matInput placeholder="Feature Summary" required name="featureSummary"
                                                formControlName="featureSummary">
                                            <mat-error
                                                *ngIf="form.get('featureSummary')?.touched  && form.get('featureSummary')?.errors?.required">
                                                Feature Feature Summary is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <p>Feature Description</p>
                                        <ejs-richtexteditor #textEditorValue formControlName="featureDescription"
                                            title="Add Feature Description ">
                                        </ejs-richtexteditor>
                                    </div>


                                </div>
                            </div>



                            <div class="proSection center">
                                <button [disabled]="form.invalid" (click)="onSubmit()" type="submit"
                                    class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>



        </div>
    </div>
</main>
<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="5 / 6" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [roundCropper]="false">
                </image-cropper>

                <!-- <img [src]="croppedImage" /> -->
            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection center">
        <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> close </button>
    </div>
</ng-template>