import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EnquiriesService } from "../../common/services/enquiriesServices/enquiries.service";
@Component({
  selector: 'app-inquery-detail',
  templateUrl: './inquery-detail.component.html',
  styleUrls: ['./inquery-detail.component.css']
})

export class InqueryDetailComponent implements OnInit {
  enquiryID: number;
  name: string;
  address:string;
  phoneNumber: string;
  comments: string;
  enquiryStatusName: string;
  email: string;
  ress: any;
  enquiryTypeName: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private enquiriesService: EnquiriesService
  ) { }

  ngOnInit(): void {
    //this.enquiryID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.enquiryID = params.id==undefined?null:params.id;
    });
    this.GetEnqueryDetailsById();
    
    
  }


  GetEnqueryDetailsById() {
    debugger;
    this.enquiriesService.GetEnqueryDetailsById(this.enquiryID).subscribe((res: any) => {
      this.ress = res.responseData
      this.name = res.responseData.name
      this.email = res.responseData.email
      this.address = res.responseData.address
      this.phoneNumber = res.responseData.phoneNumber
      this.comments = res.responseData.comments
      this.enquiryStatusName = res.responseData.enquiryStatusName
      this.enquiryTypeName = res.responseData.enquiryTypeName
    });
    console.log("res.responseData",  this.ress);
  }


}
