import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) { 
     
    }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      quotesID: 0,
      quoteText: ['', [Validators.required]],
      quoteBy: ['', [Validators.required]],
    });

    if(this.id > 0){
      this.getQuote();
    }
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;


    }
    console.log(this.form.value);

    if(this.id > 0)
    {
      this.form.controls.quotesID.setValue(this.id);
      this.contentServiceService.updateQuotedata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if(res.status > 0){
          this.toaster.success(res.message, 'Success');
          this.handleTabChange.next({ tab: "Quotes", id: 0});
  
        }else{
          this.toaster.error(res.message, 'Error');
        }
      });
    }else{

      this.contentServiceService.addQuotedata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if(res.status > 0){
          this.toaster.success(res.message, 'Success');
          this.handleTabChange.next({ tab: "Quotes", id: 0});
  
        }else{
          this.toaster.error(res.message, 'Error');
        }
      });
    }
    

  }

  getQuote(){
    this.contentServiceService.getQuote(this.id).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.form.patchValue({
          quotesID:res.responseData['quotesID'],
          quoteText:res.responseData['quoteText'],
          quoteBy:res.responseData['quoteBy'],
        });

      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  goto(){
    this.handleTabChange.emit({ tab: "Quotes", id: 0});
  }


}
