// import {HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CKEditorModule } from 'ng2-ckeditor';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LayoutsComponent } from './layouts/layouts.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ManageAdminsComponent } from './manage-admins/manage-admins.component';
import { AddAdminComponent } from './manage-admins/add-admin/add-admin.component';
import { ViewAdminComponent } from './manage-admins/view-admin/view-admin.component';
import { ManageOrganizationsComponent } from './manage-organizations/manage-organizations.component';
import { OrganizationDetailsComponent } from './manage-organizations/organization-details/organization-details.component';
import { SiteSubscriptionPlanComponent } from './Subscription Plans/site-subscription-plan/site-subscription-plan.component';
import { AddSiteSubscriptionComponent } from './Subscription Plans/site-subscription-plan/add-site-subscription/add-site-subscription.component';
import { SmsSubscriptionComponent } from './Subscription Plans/sms-subscription/sms-subscription.component';
import { AddSmsSubscriptionComponent } from './Subscription Plans/sms-subscription/add-sms-subscription/add-sms-subscription.component';
import { EmailSubscriptionComponent } from './Subscription Plans/email-subscription/email-subscription.component';
import { AddEmailSubscriptionComponent } from './Subscription Plans/email-subscription/add-email-subscription/add-email-subscription.component';
import { EditEmailSubscriptionComponent } from './Subscription Plans/email-subscription/edit-email-subscription/edit-email-subscription.component';
import { EditSiteSubscriptionComponent } from './Subscription Plans/site-subscription-plan/edit-site-subscription/edit-site-subscription.component';
import { EditSmsSubscriptionComponent } from './Subscription Plans/sms-subscription/edit-sms-subscription/edit-sms-subscription.component';
import { RoleAndPermissionsComponent } from './role-and-permissions/role-and-permissions.component';
import { AddRoleAndPermissionComponent } from './role-and-permissions/add-role-and-permission/add-role-and-permission.component';
import { EditRoleAndPermissionComponent } from './role-and-permissions/edit-role-and-permission/edit-role-and-permission.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EditEmailTemplateComponent } from './email-template/edit-email-template/edit-email-template.component';
import { EmailPlaceHolderComponent } from './email-template/email-place-holder/email-place-holder.component';
import { LoginComponent } from './login/login.component';
import { NgxMatIntlTelInputModule } from 'ngx-mat-intl-tel-input';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EditAdminComponent } from './manage-admins/edit-admin/edit-admin.component';

import { InquiryComponent } from './inquiry/inquiry.component';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { TextEditorComponent } from './email-template/text-editor/text-editor.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { InqueryDetailComponent } from './inquiry/inquery-detail/inquery-detail.component';
import { ToasterComponent } from './toaster/toaster.component';

import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from './toaster/spinner/spinner.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TreeviewModule } from 'ngx-treeview';
import { TransactionDetailsComponent } from './transaction-list/transaction-details/transaction-details.component';
import { AddOrganizationComponent } from './manage-organizations/add-organization/add-organization.component';
import { DataTableComponent } from './shared/data-table/data-table.component';
import { DialogComponent } from './common/Modal/DialogComponent';
import { HomePageContentComponent } from './content-management/content/home-page-content/home-page-content.component';
import { QuoteComponent } from './content-management/content/QuoteManage/Quote/quote.component';
import { ViewQuoteComponent } from './content-management/content/QuoteManage/view-quote/view-quote.component';
import { FeatureComponent } from './content-management/content/FeatureManage/feature/feature.component';
import { ViewFeatureComponent } from './content-management/content/FeatureManage/view-feature/view-feature.component';
import { PriceComponent } from './content-management/content/PriceManage/price/price.component';
import { ContactUsContentComponent } from './content-management/content/contact-us-content/contact-us-content.component';
import { PolicyComponent } from './content-management/content/policy/policy.component';
import { TermsComponent } from './content-management/content/terms/terms.component';
import { ViewObeComponent } from './content-management/content/ObeManage/view-obe/view-obe.component';
import { ObeComponent } from './content-management/content/ObeManage/obe/obe.component';
import { InquiryTemplateComponent } from './inquiry/inquiry-template/inquiry-template.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ResetpwpopupTemplateComponent } from './manage-organizations/resetpwpopup-template/resetpwpopup-template.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AdminProfileComponent } from './manage-admins/admin-profile/admin-profile.component';
import { SettingComponent } from './setting/setting/setting.component';
import { AboutUsComponent } from './content-management/content/about-us/about-us.component';
// <<<<<<< src/app/app.module.ts
import { FileUploadModule } from 'ng2-file-upload';
import { DragDropCropResizeModule } from "drag-drop-crop-resize"

import { CustomerSayComponent } from './content-management/content/customerManage/customer-say/customer-say.component';
import { ViewCustomerComponent } from './content-management/content/customerManage/view-customer/view-customer.component';
import { PartnersComponent } from './content-management/content/partnersManage/partners/partners.component';
import { ViewPartnerComponent } from './content-management/content/partnersManage/view-partner/view-partner.component';
import { RequestDemoComponent } from './content-management/content/request-demo/request-demo.component';
import { TransactionComponent } from './transaction/transaction.component';
import { PaymentComponent } from './payment/payment.component';
import { EnquiryResponseComponent } from './inquiry/enquiry-response/enquiry-response.component';
import { DatePipe } from '@angular/common';
import { NgxCaptchaModule } from 'ngx-captcha';
import { EditOrganizationComponent } from './manage-organizations/edit-organization/edit-organization.component';
import { ClickButtonDirective } from './common/directive/click-button.directive';
import { JwtInterceptor } from './jwt.interceptor';
import { DataLogsComponent } from './logs/data-logs.component';
import { OrganizationTransactionDetailsComponent } from './manage-organizations/organization-transaction-details/organization-transaction-details.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { CreateTemplateComponent } from './email-template/create-template/create-template.component';
import { SocailmediaComponent } from './content-management/content/socailmedia/socailmedia.component';
// >>>>>>> src/app/app.module.ts

@NgModule({
  declarations: [
    AppComponent,
    LayoutsComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ManageAdminsComponent,
    AddAdminComponent,
    ViewAdminComponent,
    ManageOrganizationsComponent,
    OrganizationDetailsComponent,
    SiteSubscriptionPlanComponent,
    AddSiteSubscriptionComponent,
    SmsSubscriptionComponent,
    AddSmsSubscriptionComponent,
    EmailSubscriptionComponent,
    AddEmailSubscriptionComponent,
    EditEmailSubscriptionComponent,
    EditSiteSubscriptionComponent,
    EditSmsSubscriptionComponent,
    RoleAndPermissionsComponent,
    AddRoleAndPermissionComponent,
    EditRoleAndPermissionComponent,
    EmailTemplateComponent,
    EditEmailTemplateComponent,
    EmailPlaceHolderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    EditAdminComponent,
    InquiryComponent,
    TextEditorComponent,
    ContentManagementComponent,
    TransactionListComponent,
    InqueryDetailComponent,
    ToasterComponent,
    SpinnerComponent,
    TransactionDetailsComponent,
    AddOrganizationComponent,
    DataTableComponent,
    DialogComponent,
    HomePageContentComponent,
    QuoteComponent,
    ViewQuoteComponent,
    FeatureComponent,
    ViewFeatureComponent,
    PriceComponent,
    ContactUsContentComponent,
    PolicyComponent,
    TermsComponent,
    ObeComponent,
    ViewObeComponent,
    InquiryTemplateComponent,
    ResetpwpopupTemplateComponent,
    SettingComponent,
    AboutUsComponent,
    AdminProfileComponent,
    SettingComponent,
    CustomerSayComponent,
    ViewCustomerComponent,
    PartnersComponent,
    ViewPartnerComponent,
    RequestDemoComponent,
    TransactionComponent,
    PaymentComponent,
    EnquiryResponseComponent,
    EditOrganizationComponent,
    ClickButtonDirective,
    DataLogsComponent,
    OrganizationTransactionDetailsComponent,
    ForgotPasswordFormComponent,
    CreateTemplateComponent,
    SocailmediaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CKEditorModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    ImageCropperModule,
    NgxMatIntlTelInputModule,
    RichTextEditorAllModule,
    FileUploadModule,
    DragDropCropResizeModule,
    MatDialogModule,
    MatAutocompleteModule,
    NgxCaptchaModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    TreeviewModule.forRoot(),
  ],
  entryComponents:[InquiryTemplateComponent],
  providers: [DatePipe, {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
