import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, masters, profile } from 'src/environments/environment';
import { PlanTypeDropdown, PlanTypeStautusDropdown } from "../../MasterModel/masterModel";
@Injectable({
  providedIn: 'root'
})
export class MasterDropdownService {

  constructor(private http : HttpClient) { }
  
GetPlanTypeDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getPlanTypeDropdown}`);
}

GetPlanTypestausDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getPlanTypeStatusDropdown}`);
}
GetSiteSubscriptionPlanDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getSiteSubscriptionPlanDropdown}`);
}
GetSMSSubscriptionPlanDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getSMSSubscriptionPlanDropdown}`);
}
GetEmailSubscriptionPlanDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getEmailSubscriptionPlanDropdown}`);
}
getOrganizationType() {
  return this.http.get(`${environment.baseUrl}${masters.organizationTypeAPI}`);
}

getAllowedStudent() {
  return this.http.get(`${environment.baseUrl}${masters.allowedStudentAPI}`);
}

getEnquiryStatusDropdown() {
  return this.http.get(`${environment.baseUrl}${masters.getEnquiryStatusDropdown}`);
}

}
