import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ManageOrganizationService } from 'src/app/common/services/manage-organization.service';
import { ContentServiceService } from '../../content-service.service';

@Component({
  selector: 'app-socailmedia',
  templateUrl: './socailmedia.component.html',
  styleUrls: ['./socailmedia.component.css']
})
export class SocailmediaComponent implements OnInit {
  form !: FormGroup;
  faceBookLink = new FormControl();
  twitterLink = new FormControl();
  linkedInLink = new FormControl();
  youTubeLink = new FormControl();
  instagramLink = new FormControl();
  @ViewChild('phone') phone: any;
  constructor(
    private formbuilder:FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private contentServiceService: ContentServiceService,

  ) { }

  ngOnInit(): void {
    
    this.form = this.formbuilder.group({
      faceBookLink :['',Validators.required],
      twitterLink: ['',Validators.required],
      linkedInLink: ['',Validators.required],
      youTubeLink: ['',Validators.required], 
      instagramLink: ['',Validators.required], 
  }),
    this.contentServiceService.getAllSocialMediaLink().subscribe((res:any)=>{
      this.faceBookLink = res.responseData.faceBookLink
      this.twitterLink = res.responseData.twitterLink
      this.linkedInLink = res.responseData.linkedInLink
      this.youTubeLink = res.responseData.youTubeLink
      this.instagramLink = res.responseData.instagramLink
    });
  }
obj:any;
 

// onSubmit(){
//   this.updateSetting();
// }

  updateSetting(){debugger;

    this.obj={
      "faceBookLink":this.form.controls.faceBookLink.value,
      "twitterLink":this.form.controls.twitterLink.value,
      "linkedInLink":this.form.controls.linkedInLink.value,
      "youTubeLink":this.form.controls.youTubeLink.value,
      "instagramLink":this.form.controls.instagramLink.value,
    }
    this.contentServiceService.setSocialMediaLink(this.obj).subscribe((res: any) =>{
    console.log("update", res);
    console.log(res);
    if(res.status > 0){
      this.toaster.success(res.message, 'Success');
    }else{
      this.toaster.error(res.message, 'Error');
    }
  
    })
  }


}
