<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Role </h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Role</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Edit Role</h3>
                <a routerLink="/manage-role" class="btn btn-primary btnsmall">Back</a>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Name </mat-label>
                                            <input (keypress)="allowAlphabetOnly($event)"  pattern="(\s*\S\s*)+" required maxlength="50" formControlName="roleName" matInput placeholder="Name">
                                            <mat-error *ngIf="form.get('roleName')?.touched  && form.get('roleName')?.errors?.required">
                                                Role Name is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('roleName')?.hasError('pattern')">
                                                Please enter valid name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Description</mat-label>
                                            <textarea required maxlength="1000" (keyup)="noWhitespaceValidator($event)" pattern="(\s*\S\s*)+" formControlName="roleDescription" matInput placeholder="Description" ></textarea>
                                            <mat-error *ngIf="form.get('roleDescription')?.touched  && form.get('roleDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('roleDescription')?.hasError('pattern')">
                                                Please enter valid description
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
                            <div class="proSection">
                                <h5>Permissions </h5>
                              
    
    
    
                                
    
                              <ngx-treeview [config]="config" [items]="items" (selectedChange)="onSelectedChange($event)"
                              (filterChange)="onFilterChange($event)">
                               </ngx-treeview>
    
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>
<!-- <button (click)="testB()" ></button> -->

