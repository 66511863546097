<main>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Outcome Based Education</h3>
                <a (click)="goto()" class="btn btn-primary btnsmall">Back</a>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title <span class="req">*</span></mat-label>
                                            <input maxlength="500" formControlName="title" matInput placeholder="Title">
                                            <mat-error *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Main Title <span class="req">*</span></mat-label>
                                            <input maxlength="500" formControlName="mainText" matInput placeholder="Main Title">
                                            <mat-error *ngIf="form.get('mainText')?.touched  && form.get('mainText')?.errors?.required">
                                                Main title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Sub Text <span class="req">*</span></mat-label>
                                            <textarea maxlength="255" formControlName="subText" matInput placeholder="Sub Text" ></textarea>
                                            <mat-error *ngIf="form.get('subText')?.touched  && form.get('subText')?.errors?.required">
                                                Sub Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m12 form-group">
                                        <p>OBE Image</p>
                                    </div>


                                    <div class="col s12 m6 form-group d-flex">
                                        <ng-container *ngIf="!url">
                                            <img [src]="croppedImage1" style="height: 153px;width: 199px;" alt="">
                                        </ng-container>
                                       
                                       
                                        
                                        <div class="profilePicLeft form-group30 ml-4">
                                            <p class="chngePic">
                                                <input type="file" accept="image/*" (change)="onSelectFile($event)" >
                                                <span class="icon centerItem"> 
                                                    <mat-icon>edit</mat-icon>
                                                </span>
                                                <img [src]="croppedImagetest" alt="">
                                            </p>
    
                                        </div>
                                    </div>
                                    
                                 

                                    <!-- <div class="col s12 m6 form-group">
                                       
                                        <img [src]="croppedImage1" style="height: 200px;width: 350px;" alt="">
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload" type="file" title="Please Banner Image 1" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'1')">
                                        </label>
                                     
                                       
                                    </div> -->
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> Image Alt Name</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="obeImageAlt"
                                                formControlName="obeImageAlt">
                                            <mat-error
                                                *ngIf="form.get('obeImageAlt')?.touched  && form.get('obeImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="obeImageDescription" matInput placeholder="Image Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('obeImageDescription')?.touched  && form.get('obeImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Key Feature Title </mat-label>
                                            <input maxlength="500" required formControlName="obeKeyFeatureTitle" matInput placeholder="Feature Title">
                                            <mat-error *ngIf="form.get('obeKeyFeatureTitle')?.touched  && form.get('obeKeyFeatureTitle')?.errors?.required">
                                                Feature Main title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Key Feature Sub Title <span class="req">*</span></mat-label>
                                            <textarea maxlength="255" formControlName="obeKeyFeatureSubTitle" matInput placeholder="Sub Title" ></textarea>
                                            <mat-error *ngIf="form.get('obeKeyFeatureSubTitle')?.touched  && form.get('obeKeyFeatureSubTitle')?.errors?.required">
                                                Feature Sub Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- key Feature -->

                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Feature Title </mat-label>
                                            <input maxlength="500" required formControlName="featuresTitle" matInput placeholder="Feature Title">
                                            <mat-error *ngIf="form.get('featuresTitle')?.touched  && form.get('featuresTitle')?.errors?.required">
                                                Feature  title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <p>Feature Description</p>
                                            <ejs-richtexteditor
                                            [toolbarSettings]='tools'
                                              #textEditorValue 
                                              formControlName="subTitle"
                                               title="Add Description " >
                                            </ejs-richtexteditor>
                                    </div>
                                   

                                    <div class="col s12 m12 form-group">
                                        <p>Key Feature Image </p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       
                                        <img [src]="croppedImage2" style="height: 200px;width: 350px;" alt="">
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload2" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload2" type="file" title="Please Banner Image 1" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'2')">
                                        </label>
                                        <!-- <input type="file" title="Please Banner Image 2" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'2')"> -->
                                    </div>

                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> Image Alt Name</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="imageAlt"
                                                formControlName="imageAlt">
                                            <mat-error
                                                *ngIf="form.get('imageAlt')?.touched  && form.get('imageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="imageDescription" matInput placeholder="Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('imageDescription')?.touched  && form.get('imageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>



                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>


<ng-template #uploadPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title>Select Image Area</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <div class="row">
         <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="20/10" [resizeToWidth]="500" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event,'1')"
                            (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                           >
                        </image-cropper>

                        <!-- <img [src]="croppedImage" /> -->
        </div>
  
      </mat-dialog-content>
    </div>
    <div class="proSection center">
        <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> close </button>
    </div>
   
  </ng-template>