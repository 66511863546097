import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, adminUsers } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AdminUserService {

  constructor(private http: HttpClient) { }

  AllAdminUser(data: any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.alladminUsersAPI}`, data);
  }
  GetAdminTableGrid(id: any) {
    return this.http.get(`${environment.baseUrl}${adminUsers.getcustomGrid}` + '?id=' + id);
  }
  addAdminTableGrid(data: any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.addCustomGrid}`, data);
  }
  CheckDuplicateUserName(username: any) {
    return this.http.get(`${environment.baseUrl}${adminUsers.checkDuplicateUserName}` + '?username=' + username);
  }

  CheckAdminEmail(data: any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.checkEmail}`, data);
  }

  ForgotPassword(data: any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.forgotPassword}`, data);
  }
}
