<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Email Template</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/email-template">Email Template</a></li>
                <li class="breadcrumb-item"><a routerLink="/add-email-template">Add Email Template</a></li>                
                <li class="breadcrumb-item active" aria-current="page">Placeholder</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Placeholder</h3>
                <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a>
            </div>
            <div class="matCardBody">
                <div class="placehoderWidget">
                    <h4>Email Templates</h4>
                    <h5>Welcome Email</h5>
                    <div class="placeHolderContent">
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Title</label>
                            <span>[title]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>First Name</label>
                            <span>[firstname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Last Name</label>
                            <span>[lastname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Email</label>
                            <span>[email]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Name</label>
                            <span>[username]</span>
                        </div>
                    </div>

                    <h5>Forgot Password</h5>
                    <div class="placeHolderContent">
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Name</label>
                            <span>[username]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Reset Link</label>
                            <span>[resetlink]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Title</label>
                            <span>[title]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>First Name</label>
                            <span>[firstname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Last Name</label>
                            <span>[lastname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Email</label>
                            <span>[email]</span>
                        </div>
                        
                    </div>
                    <h5>New Staff Email</h5>
                    <div class="placeHolderContent">
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Staff Name</label>
                            <span>[staffname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Organization Name</label>
                            <span>[organizaionName]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Name</label>
                            <span>[username]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Password</label>
                            <span>[password]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Link</label>
                            <span>[link]</span>
                        </div>
                        <!-- <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Title</label>
                            <span>[title]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>First Name</label>
                            <span>[firstname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Last Name</label>
                            <span>[lastname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Email</label>
                            <span>[email]</span>
                        </div> -->
                        
                    </div>

                    <h5>Signup Confirmation Email</h5>
                    <div class="placeHolderContent">
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Admin Name</label>
                            <span>[adminname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Activation Link</label>
                            <span>[activationlink]</span>
                        </div>
                        <!-- <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Title</label>
                            <span>[title]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>First Name</label>
                            <span>[firstname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Last Name</label>
                            <span>[lastname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Email</label>
                            <span>[email]</span>
                        </div> -->
                        
                    </div>
                    
                    <h5>Enquiry Response</h5>
                    <div class="placeHolderContent">
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Name</label>
                            <span>[username]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Commits</label>
                            <span>[commits]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Topic</label>
                            <span>[topic]</span>
                        </div>
                        <!-- <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Title</label>
                            <span>[title]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>First Name</label>
                            <span>[firstname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>Last Name</label>
                            <span>[lastname]</span>
                        </div>
                        <div class="placeHolderContentRow d-flex align-items-center">
                            <label>User Email</label>
                            <span>[email]</span>
                        </div> -->
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>