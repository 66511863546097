<div class="dialog-md">
  <i class="material-icons popupClose" mat-dialog-close>clear</i>
  <div class="dialogTitle center-align loginTtile">
    <h2 mat-dialog-title>Enquiry Response</h2>
  </div>
  <form [formGroup]="responseForm" (ngSubmit)="sendEnquiryResponse()">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Response Message</mat-label>
      <textarea matInput formControlName="responseDetails" placeholder="Response Message" required></textarea>
      <mat-error *ngIf="responseForm.get('responseDetails')?.touched  && responseForm.get('responseDetails')?.errors?.required">
        Response Message is required
      </mat-error>
    </mat-form-field>
    <div class="btnsWidget form-group17 center">
      <button type="submit" [disabled]="responseForm.invalid" class="btn btn-primary btn-sm"
        mat-dialog-close>Save</button>
      <button type="button" class="btn btn-reset btn-sm" mat-dialog-close>Cancel</button>
    </div>
  </form>
</div>
