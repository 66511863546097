import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) {

  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      princingID: 0,
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
      enquiryMainText: ['', [Validators.required]],
      enquirySubText: ['', [Validators.required]],
      enquerySectionImage: [],
      enquerySectionImageDescription: ['', [Validators.required]],
      enquerySectionImageAlt: ['', [Validators.required]],

    });

    this.getPrice();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;


    }


    if (this.priceImage != '') {
      this.form.controls.enquerySectionImage.setValue(this.priceImage);
    } else {
      if (this.form.controls.princingID.value == 0) {
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);

    this.form.controls.princingID.setValue(this.id);
    this.contentServiceService.addPricedata(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.handleTabChange.next({ tab: "ViewQuote", id: 0});
        this.id = res.responseData;

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });

  }

  getPrice() {
    this.contentServiceService.getPricedata().subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.id = res.responseData['princingID'];
        this.croppedImage = res.responseData['enquerySectionImageBase64'];
        this.form.patchValue({
          princingID: res.responseData['princingID'],
          title: res.responseData['title'],
          subTitle: res.responseData['subTitle'],
          enquiryMainText: res.responseData['enquiryMainText'],
          enquirySubText: res.responseData['enquirySubText'],
          enquerySectionImage: res.responseData['enquerySectionImage'],
          enquerySectionImageDescription: res.responseData['enquerySectionImageDescription'],
          enquerySectionImageAlt: res.responseData['enquerySectionImageAlt'],
        });

      } else {
        this.toaster.warning("Please add record!", 'Warning');
      }
    });
  }



  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/banner-img.png';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();

    formData.append('files', file);
    this.AddPriceImage(formData);


  }
  priceImage: string = '';
  AddPriceImage(file: any) {
    this.contentServiceService.postDataPriceImg(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.priceImage = res.body.responseData.imageName;
        console.log(this.priceImage);
      }

    })
  }


  /// end

   /// end
   flag = true;
   // new img cropper
   getCroppedImage(event:any){
     debugger
     this.croppedImage = event;
     this.imageFile = this.base64ToFile(
       event,
       'image.png',
     );
     this.uploadFile(this.imageFile);
       this.flag = false;
     //console.log(event);
   }
   isTheImageDeleted(event:any){
     console.log(event);
     if(event){
       this.flag = true;
       this.getPrice();
     }
   
   }

}
