<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Transactions</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"> Transactions</li>
            </ol>
        </div>
    </div>
    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle"> Transactions</h3>
                <!-- <a routerLink="/add-subscription-plan" class="btn btn-primary btnsmall">Add Subscription</a> -->
            </div>

            <div class="matCardBody">
                <div class="tableActionTop mb-3">
                    <div class="tableFilter">
                        <span (click)="toggleField()"><i class="fas fa-filter"></i> <span
                                class="mobileHidden cursor-pointer">Filter</span></span>
                        <div class="filterForm" [class.toggled]="classToggled">
                            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Payer Name</mat-label>
                                            <input matInput type="text" formControlName="payerName">
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Plan Type</mat-label>
                                            <input matInput type="text" formControlName="planType">
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col s12 m6">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Invoice Number</mat-label>
                                            <input matInput type="text" formControlName="invoiceNumber">
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="btnsWidget form-group">
                                    <button type="submit" class="btn btn-primary btn-sm">Search</button>
                                    <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                                </div>
                            </form>
                        </div>
                        <div class="rightTableActions centerItemVert">



                        </div>

                    </div>
                    <div class="mat-elevation-z8">

                    </div>


                </div>
                <app-data-table [inputColumns]="displayedColumns" [inputSource]="transactionListData" [inputMeta]="metaData"
                    [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
                    (onTableActionClick)="onTableActionClick($event)">
                </app-data-table>
            </div>
        </div>
    </div>
</main>