<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Staff</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-staff">Manage Admin</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Admin</li>
            </ol>
        </div>
    </div>


    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Edit Staff</h3>
                <a routerLink="/manage-staff" class="btn btn-primary btnsmall">Back</a>
            </div>
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="profilePicLeft form-group30">
                            <!-- <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Profile pic <span class="req">*</span></mat-label>
                            <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                        </mat-form-field>     -->
                            <p class="chngePic">
                                <input type="file" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                <span class="icon centerItem">
                                    <mat-icon>edit</mat-icon>
                                </span>
                                <!-- <img src="../../../assets/img/display.jpg" alt=""> -->
                                <img [src]="croppedImage" alt="" />
                            </p>
                        </div>
                        <div class="profileContentRight flex-grow-1">

                            <div class="proSection">
                                <h5>Staff Details</h5>

                                <div class="row-flex">

                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>First Name </mat-label>
                                            <input maxlength="20" (keypress)="allowAlphabetOnly($event)" matInput
                                                placeholder="First Name" required name="firstname" pattern="(\s*\S\s*)+"
                                                formControlName="firstName">
                                            <mat-error
                                                *ngIf="form.get('firstName')?.touched  && form.get('firstName')?.errors?.required">
                                                First Name is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('firstName')?.hasError('pattern')">
                                                Please enter valid first name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Last Name</mat-label>
                                            <input maxlength="20" (keypress)="allowAlphabetOnly($event)" matInput
                                                placeholder="Last Name" required name="lastName" pattern="(\s*\S\s*)+"
                                                formControlName="lastName">
                                            <mat-error
                                                *ngIf="form.get('lastName')?.touched  && form.get('lastName')?.errors?.required">
                                                Last Name is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('lastName')?.hasError('pattern')">
                                                Please enter valid last name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Username</mat-label>
                                            <input maxlength="20" [readonly]="true" matInput placeholder="User Name"
                                                required name="username" formControlName="username">
                                            <mat-error
                                                *ngIf="form.get('username')?.touched  && form.get('username')?.errors?.required">
                                                User Name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div formGroupName="passwordGroup">
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Password </mat-label>
                                        <input matInput placeholder="Password" required name="Password" formControlName="password">
                                    </mat-form-field>                                   
                                </div>
                                 <div class="col s12 m6 form-group">
                                      <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Confirm Password</mat-label>
                                        <input matInput placeholder="Confirm Password" formControlName="confirmPassword" type="password">
                        
                                      </mat-form-field>
                                      
                        
                                    </div>
                                    </div> -->


                                    <!-- <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Password</mat-label>
                                            <input type="password" matInput placeholder="Password"
                                                pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}"
                                                required name="password" formControlName="password"
                                                [type]="hide ? 'password' : 'text'">
                                            <button mat-icon-button matSuffix
                                                style="background: transparent;border: none;" (click)="hide = !hide"
                                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </button>
                                            <mat-error
                                                *ngIf="form.get('password')?.touched  && form.get('password')?.errors?.required">
                                                Password is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('password')?.errors?.pattern">
                                                Password must contain minimum eight characters,at least one uppercase
                                                and lowercase letter,one number and one special
                                                characters<strong>(!@#$%_^&*~)</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Confirm Password</mat-label>
                                            <input matInput placeholder="Confirm Password"
                                                [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()"
                                                required formControlName="confirmPassword" type="password">
                                            <button mat-icon-button matSuffix
                                                style="background: transparent;border: none;" (click)="hide1 = !hide1"
                                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                            </button>
                                            <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't
                                                match</mat-error>
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col s12 m6">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <ngx-mat-intl-tel-input [preferredCountries]="['au', 'us']"
                                                [enablePlaceholder]="true" [enableSearch]="true" name="ContactNumber"
                                                formControlName="contactNumber" #phone>
                                            </ngx-mat-intl-tel-input>
                                            <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>

                                            <mat-error
                                                *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                Phone Number is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Date Of Birth</mat-label>
                                            <input matInput placeholder="DateOfBirth" required name="dob"
                                                [min]="minDate" [max]="maxDate" formControlName="dob"
                                                [matDatepicker]="picker">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                            <mat-error
                                                *ngIf="form.get('dob')?.touched  && form.get('dob')?.errors?.required">
                                                Field is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input [readonly]="true" matInput placeholder="email" required name="email"
                                                formControlName="email">
                                            <mat-error
                                                *ngIf="form.get('email')?.touched  && form.get('email')?.errors?.required">
                                                Email is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Gender</mat-label>
                                            <mat-select placeholder="Gender" formControlName="gender">
                                                <mat-option value="Select Gender ">Select Gender</mat-option>

                                                <mat-option *ngFor="let gender of genders " [value]="gender.genderID">
                                                    {{gender.genderName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('gender')?.touched  && form.get('gender')?.errors?.required">
                                                Gender is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>



                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Role</mat-label>
                                            <mat-select placeholder="Role" formControlName="roleID">
                                                <mat-option value="Select Role">Select Roles</mat-option>
                                                <mat-option *ngFor="let role of roles" [value]="role.roleID">
                                                    {{role.roleName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('roleID')?.touched  && form.get('roleID')?.errors?.required">
                                                Role is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="proSection">
                                <h5>Additional Information</h5>
                                <div class="row">
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Blood Group</mat-label>
                                            <mat-select placeholder="Blood Group" required name="bloodGroup"
                                                formControlName="bloodGroupID">
                                                <mat-option value="Select Blood Group">Select Blood Group</mat-option>
                                                <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                    [value]="bloodGroup.bloodGroupID">
                                                    {{bloodGroup.bloodGroupName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('bloodGroupID')?.touched  && form.get('bloodGroupID')?.errors?.required">
                                                Blood Group is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input maxlength="100" matInput placeholder="address" required
                                                name="address" formControlName="address">
                                            <mat-error
                                                *ngIf="form.get('address')?.touched  && form.get('address')?.errors?.required">
                                                Address is required
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col s12 m6 form-group">

                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select placeholder="Country" required name="country"
                                                (valueChange)="GetStateList($event)" formControlName="countryID">
                                                <mat-option value="Select Country">Country</mat-option>
                                                <mat-option *ngFor="let country of countries"
                                                    [value]="country.countryID">
                                                    {{country.countryName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('country')?.touched  && form.get('country')?.errors?.required">
                                                Country is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select placeholder="State" required name="state"
                                                formControlName="stateID">
                                                <mat-option value="Select States ">Select States</mat-option>

                                                <mat-option *ngFor="let states of states " [value]="states.stateID">
                                                    {{states.stateName}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="form.get('stateID')?.touched  && form.get('stateID')?.errors?.required">
                                                State is required
                                            </mat-error>
                                        </mat-form-field>


                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input maxlength="20" matInput placeholder="city" required name="city" 
                                                formControlName="city" (keypress)="allowAlphabetOnly($event)" 
                                                (keyup)="noWhitespaceValidator($event)" pattern="(\s*\S\s*)+">
                                            <mat-error
                                                *ngIf="form.get('city')?.touched  && form.get('city')?.errors?.required">
                                                City is required
                                            </mat-error>
                                            <mat-error *ngIf="form.get('city')?.hasError('pattern')">
                                                Please enter valid city
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Zip Code</mat-label>
                                            <input (keypress)="allowOnlyNumber($event)" maxlength="7" matInput
                                                placeholder="Zip Code" required name="zipcode"
                                                formControlName="zipCode">

                                            <mat-error
                                                *ngIf="form.get('zipcode')?.touched  && form.get('zipcode')?.errors?.required">
                                                Zip Code is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>




                                </div>
                            </div>

                            <div class="proSection center">
                                <button type="submit" [disabled]="form.invalid" class="btn btn-primary">Update</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [roundCropper]="true">
                </image-cropper>

                <!-- <img [src]="croppedImage" /> -->
            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()">Save</button>
    </div>
</ng-template>