<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title"> Enroll Institute</h2>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item"><a routerLink="/manage-organization">Manage Organization</a></li>
        <li class="breadcrumb-item active" aria-current="page">Add Institute</li>
      </ol>
    </div>
  </div>

  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Add Institute</h3>
        <a routerLink="/manage-organization" class="btn btn-primary btnsmall">Back</a>
      </div>

      <div class="matCardBody">
        <div class="profileDetailsWidget pt-4 d-md-flex">
          <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
          <div class="profileContentRight flex-grow-1">
            <form [formGroup]="signUpForm">
              <div class="row-flex">
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Organization Name</mat-label>
                    <input  maxlength="100" (keypress)="allowAlphabetOnly($event)" matInput
                      placeholder="Organization Name" required name="OrganizationName" pattern="(\s*\S\s*)+"
                      formControlName="organizationName">
                    <mat-error
                      *ngIf="signUpForm.get('organizationName')?.touched  && signUpForm.get('organizationName')?.errors?.required">
                      Organization Name is required</mat-error>
                      <mat-error *ngIf="signUpForm.get('organizationName')?.hasError('pattern')">
                        Please enter valid organization name
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Organization Type</mat-label>
                    <mat-select required placeholder="Select Organization Type" name="organizationType"
                      formControlName="organizationType">
                      <mat-option value="Select Organization Type">Select Organization Type</mat-option>
                      <mat-option *ngFor="let orgType of organizationType" [value]="orgType.organizationTypeName">
                        {{orgType.organizationTypeName}}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="signUpForm.get('organizationType')?.touched  && signUpForm.get('organizationType')?.errors?.required">
                      Organization Type is required</mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Organization Short Name</mat-label>
                    <input maxlength="20" (keypress)="allowAlphabetOnly($event)" matInput pattern="(\s*\S\s*)+"
                      placeholder="Organization Short Name" required name="organizationShortName"
                      formControlName="organizationShortName">
                    <mat-error
                      *ngIf="signUpForm.get('organizationShortName')?.touched && signUpForm.get('organizationShortName')?.errors?.required">
                      Organization ShortName is required
                    </mat-error>
                    <mat-error *ngIf="signUpForm.get('organizationShortName')?.hasError('pattern')">
                      Please enter valid organization short name
                  </mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label> Number of Students</mat-label>
                    <input matInput maxlength="5" (keypress)="allowOnlyNumber($event)" placeholder="Number of Students"
                      required formControlName="maximumStudentAllowed">
                    <!-- <mat-select required placeholder="Select Number of Students" formControlName="maximumStudentAllowed"
                                    name="MaximumStudentAllowed">
                                    <mat-option value="Select Number of Students">Select Number of Students</mat-option>
                                    <mat-option *ngFor="let student of students" [value]="student.maximumStudentName">
                                      {{student.maximumStudentName}}
                                    </mat-option>
                                  </mat-select> -->
                    <mat-error
                      *ngIf="signUpForm.get('maximumStudentAllowed')?.touched && signUpForm.get('maximumStudentAllowed')?.errors?.required">
                      Number of Students is required
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Full Name</mat-label>
                    <input maxlength="100" (keypress)="allowAlphabetOnly($event)" pattern="(\s*\S\s*)+" matInput placeholder="Full Name"
                      required name="contactPersonFullName" formControlName="contactPersonFullName">
                    <mat-error
                      *ngIf="signUpForm.get('contactPersonFullName')?.touched && signUpForm.get('contactPersonFullName')?.errors?.required">
                      Full Name is required
                    </mat-error>
                    <mat-error *ngIf="signUpForm.get('contactPersonFullName')?.hasError('pattern')">
                      Please enter valid full name
                  </mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input maxlength="40" matInput placeholder="Email Address" required name="Email"
                      formControlName="email">
                    <mat-error *ngIf="signUpForm.get('email')?.touched  && signUpForm.get('email')?.errors?.required">
                      Email address is required</mat-error>
                    <mat-error *ngIf="signUpForm.get('email')?.hasError('email')">Please enter a valid email address
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>User Name</mat-label>
                    <input maxlength="100" matInput placeholder="Username" required name="UserName" (keypress)="removespace($event)"
                      formControlName="userName" autocomplete="user-name">
                    <mat-error
                      *ngIf="signUpForm.get('userName')?.touched && signUpForm.get('UserName')?.errors?.required">
                      Username is required
                    </mat-error>
                  </mat-form-field>
                </div>




                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" required formControlName="password" type="password"
                      name="Password" pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}"
                      [type]="hide ? 'password' : 'text'" autocomplete="new-password">
                    <span mat-icon-button matSuffix style="background: transparent;border: none;"
                      (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
                    <mat-error
                      *ngIf="signUpForm.get('password')?.touched && signUpForm.get('password')?.errors?.required">
                      Please enter your password
                    </mat-error>
                    <mat-error *ngIf="signUpForm.get('password')?.errors?.pattern">
                      Password must contain minimum eight characters,at least one uppercase and lowercase letter,one
                      number and one special characters<strong>(!@#$%_^&*~)</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput placeholder="Confirm Password" required formControlName="confirmPassword"
                      type="password" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()">
                    <span mat-icon-button matSuffix style="background: transparent;border: none;"
                      (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                      <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                  </span>
                    <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords not match</mat-error>
                  </mat-form-field>
                </div>

                <!-- <div class="col s12 m6">
                              
                               
                                  
                    
                              <mat-form-field appearance="outline">
                                <ngx-mat-intl-tel-input  [preferredCountries]="['ae', 'us']" formControlName="contactNumber" [enablePlaceholder]="true" [enableSearch]="true"
                                  name="phone" #phone>
                                </ngx-mat-intl-tel-input>
                                <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>
                              </mat-form-field>
                              </div> -->


                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <ngx-mat-intl-tel-input [enablePlaceholder]="true" [enableSearch]="true" name="ContactNumber" [preferredCountries]="['au', 'us']"
                      formControlName="contactNumber" #phone [preferredCountries]="['au', 'us']">
                    </ngx-mat-intl-tel-input>
                    <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>
                    <mat-error
                      *ngIf="signUpForm.get('contactNumber')?.touched && signUpForm.get('contactNumber')?.errors?.required">
                      Phone is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Sub-domain</mat-label>

                    <div class="subDomainInput">
                      <input matInput placeholder="Sub-domain" required name="SubDomainName" (keypress)="allowAlphaNumber($event)"
                        formControlName="subDomainName" (blur)="onBlurMethodtxt($event)" >
                      <span class="spanDomain">.creocms.com.au </span>
                    </div>
                    <mat-error
                      *ngIf="signUpForm.get('subDomainName')?.touched  && signUpForm.get('subDomainName')?.errors?.required">
                      Sub-domain Name is required
                    </mat-error>
                    <mat-error *ngIf="confirm_subdomain.hasError('mismatchSubdomain')">
                      Subdomain already exist!
                  </mat-error>
                  </mat-form-field>
                  <div *ngIf="subdomainAvailable">
                    Subdomain is available
                  </div>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Site Subscription Plan</mat-label>
                    <mat-select required placeholder="Select Site Subscription Plan" formControlName="subscriptionID"
                      name="subscriptionID">
                      <mat-option value="Select Site Subscription Plan">Select Site Subscription Plan</mat-option>
                      <mat-option *ngFor="let plan of siteSubscriptionPlan" [value]="plan.subscriptionID">
                        <table style="width:100%">

                          <tr>
                            <td class="majortable">{{plan.planName}}</td>
                            <td class="majortable">Price: {{plan.price}}</td>
                            <td class="majortable">Users: {{plan.numberOfUserAllowed}}</td>
                          </tr>

                        </table>
                        <!-- <span class="ml-3"></span><span class="ml-3"</span>   -->

                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="signUpForm.get('subscriptionID')?.touched && signUpForm.get('subscriptionID')?.errors?.required">
                      Site Subscription Plan is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select Email Subscription Plan</mat-label>
                    <mat-select required placeholder="Select Email Subscription Plan"
                      formControlName="emailSubscriptionPlanID" name="emailSubscriptionPlanID">
                      <mat-option value="Select Email Subscription Plan">Select Email Subscription Plan</mat-option>
                      <mat-option *ngFor="let plan of emailSubscriptionPlan" [value]="plan.emailSubscriptionPlanID">
                        <table style="width:100%">

                          <tr>
                            <td class="majortable">{{plan.emailPlanName}} </td>
                            <td class="majortable">Price: {{plan.emailPrice}}</td>

                          </tr>

                        </table>
                        <!-- <span class="ml-3"></span> -->
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="signUpForm.get('emailSubscriptionPlanID')?.touched && signUpForm.get('emailSubscriptionPlanID')?.errors?.required">
                      Select Email Subscription Plan is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col s12 m6">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Select SMS Subscription Plan</mat-label>
                    <mat-select required placeholder="Select SMS Subscription Plan"
                      formControlName="smsSubscriptionPlanID" name="smsSubscriptionPlanID">
                      <mat-option value="Select SMS Subscription Plan">Select SMS Subscription Plan</mat-option>
                      <mat-option *ngFor="let plan of SMSSubscriptionPlan" [value]="plan.smsSubscriptionPlanID">
                        <table style="width:100%">

                          <tr>
                            <td class="majortable">{{plan.smsPlanName}}</td>
                            <td class="majortable">Price: {{plan.smsPrice}}</td>

                          </tr>
                        </table>
                        <!-- <span class="ml-3"></span> -->
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="signUpForm.get('smsSubscriptionPlanID')?.touched && signUpForm.get('smsSubscriptionPlanID')?.errors?.required">
                      Select SMS Subscription Plan is required
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="form-group17">
                  <ngx-recaptcha2 #captchaElem formControlName="recaptcha" [siteKey]="siteKey" required>
                  </ngx-recaptcha2>
                </div> -->
                <div class="col s12 form-group17 center-align">
                  <button n-submit class="btn btn-primary" [disabled]="signUpForm.invalid" mat-dialog-close
                    (click)="registerOrgApiCall(successPopup)">Sign Up</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<ng-template #successPopup>
  <div class="dialog-lg" id="success-signup">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align">
      <p class="successIcon"><svg width="130" height="130" class="icon">
          <use xlink:href="#successCheck"></use>
        </svg></p>
      <h2 mat-dialog-title>Signup Successful</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <div class="matContent center-align">
        <p class="largerText form-group35">You have registered successfully!
          <span class="d-md-block">Please activate your account by clicking on the account activation link sent to you
            on registered email.</span>
        </p>
        <a routerLink="/manage-organization" mat-dialog-close class="btn btn-primary">OK</a>
      </div>

    </mat-dialog-content>

  </div>
</ng-template>