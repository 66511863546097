import { Component, OnInit, Output, Inject ,EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterModel, Metadata } from '../../common/MasterModel/masterModel';
import { EnquiryModel } from '../InquiryModel';
import { ToastrService } from 'ngx-toastr';
import { EnquiriesService } from '../../common/services/enquiriesServices/enquiries.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/common/core-services/common.Service';

@Component({
  selector: 'app-inquiry-template',
  templateUrl: './inquiry-template.component.html',
  styleUrls: ['./inquiry-template.component.css']
})
export class InquiryTemplateComponent implements OnInit {
  responseForm: FormGroup;
   enquiryID:number;
   enquiryResponseData:any;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  enquiryListData: EnquiryModel[];
  classToggled = false;
  user:any;
  adminUserID:number;
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  //user: string | null;
  constructor(@Inject(MAT_DIALOG_DATA) 
    public data:any,
    private enquiriesService: EnquiriesService,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private commonService:CommonService
  ) { }

  ngOnInit(): void {
    this.responseForm = this.formBuilder.group({
      responseDetails: ['', Validators.required],
    });
  }
  sendEnquiryResponse() {
    this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
          console.log("USERR",currentUser);
          this.adminUserID = currentUser.userId;

    this.enquiryResponseData ={
      "enquiryID":this.data.id,
      "responseDetails": this.responseForm.controls.responseDetails.value,
      "adminUserID":this.adminUserID
    }
    console.log("hi", this.enquiryResponseData);
    this.enquiriesService.sendResponse(this.enquiryResponseData).subscribe((res:any) => {
      console.log("RESPONSE",res)
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.onSubmit.emit();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  allowOnlyNumber(event:any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }
 
}
