import { Component, OnInit } from '@angular/core';
import { TransactionService } from "./../../common/services/transactionService/transaction.service";
import { ActivatedRoute, Router } from '@angular/router';
import { transModel } from 'src/app/transaction-list/transaction-details/transactionModel';
//import { transModel } from './transactionModel';

@Component({
  selector: 'app-organization-transaction-details',
  templateUrl: './organization-transaction-details.component.html',
  styleUrls: ['./organization-transaction-details.component.css']
})
export class OrganizationTransactionDetailsComponent implements OnInit {
  transactionID: Number;
  res: any;
  organizationID:number;
  payerName: string;
  organizationName: string;
  planType: string;
  paymentMethod: string;
  dueDate: string;
  invoiceNumber: string;
  createdDate: any;
  amount: any;
  constructor(
    private transactionService: TransactionService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //this.transactionID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.transactionID = params.id == undefined ? null : params.id;
    });
    this.GetTransactionDetailsById();
  }
  transData = new transModel()
  GetTransactionDetailsById() {
    this.transactionService.GetTransactionDetailsById(this.transactionID).subscribe((res: any) => {

      if (res.status > 0) {
        console.log("tranDetails",res);
        this.payerName = res.responseData[0].payerName
        this.organizationName = res.responseData[0].organizationName
        this.planType = res.responseData[0].planType
        this.paymentMethod = res.responseData[0].paymentMethod
        this.dueDate = res.responseData[0].dueDate
        this.invoiceNumber = res.responseData[0].invoiceNumber
        this.amount = res.responseData[0].amount
        this.createdDate = res.responseData[0].createdDate
        this.transactionID = res.responseData[0].transactionID
        this.organizationID = res.responseData[0].organizationID
        if(res.responseData.length > 0){

        }
        this.transData.transactionTime = res.responseData[1].transactionTime;
        this.transData.transactionID = res.responseData[1].transactionID;
        this.transData.payerAddress = res.responseData[1].payerAddress;
        this.transData.payerEmail = res.responseData[1].payerEmail;
        this.transData.payername = res.responseData[1].payername;
        this.transData.payerId = res.responseData[1].payerId;
        this.transData.payeeEmail = res.responseData[1].payeeEmail;
        this.transData.payeeMerchantId = res.responseData[1].payeeMerchantId;
        this.transData.status = res.responseData[1].status;
        this.transData.paymentsDetails = res.responseData[1].paymentsDetails;
        this.transData.update_time = res.responseData[1].update_time;
        this.transData.currency_code = res.responseData[1].currency_code;
        this.transData.amount = res.responseData[1].amount;

      }

    });
  }

 
  


}
