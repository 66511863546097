import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, roles } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageRolesService {

  constructor(private http : HttpClient) { }

GetRolesList(data:any) {
   
    return this.http.post(`${environment.baseUrl}${roles.allSubscriptionPlanAPI}`,data);
  }

  GetRoleScreens() {
    return this.http.get(`${environment.baseUrl}${roles.GetRoleScreens}`);
  }
  PostRolesdata(data:any) {
    return this.http.post(`${environment.baseUrl}${roles.postRole}`,data);
  }
  GetEditRoleScreens(Id:any) {
    return this.http.get(`${environment.baseUrl}${roles.GetEditRoleScreens}`+'?id='+Id);
  }
  EditRolesdata(data:any) {
    return this.http.post(`${environment.baseUrl}${roles.editRole}`,data);
  }
  deleteRole(Id:any) {
    return this.http.get(`${environment.baseUrl}${roles.deleteRole}`+'?id='+Id);
  }
}
