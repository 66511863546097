import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServiceService } from 'src/app/common/services/AccountService/account-service.service';
import { MenuListModel } from './menuModel';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(private accountServiceService:AccountServiceService,private router: Router,) { }
  user:any;
  roleID = 0;
  ngOnInit(): void {
    this.user = localStorage.getItem('currentUser');
    const currentUser = JSON.parse(this.user);
    console.log("USERR",currentUser);
    this.roleID = currentUser.roleID;

    if(this.roleID > 0){
      this.getMenuList();
    }else{
      this.router.navigate(['/']);
    }
  }


  menuList = new Array<MenuListModel>()
  getMenuList(){
    this.accountServiceService.GetMenu(this.roleID).subscribe((res:any) => {
      console.log(res);
    
      if((res.statusCode >= 400 && res.statusCode < 500) && res.message){
        console.log('error');
      }
      if(res.status > 0){
       
       if(res.responseData.length > 0){
        this.menuList = res.responseData;
       }else{

        this.router.navigate(['/']);
      }
      }
      
    });

  }

}
