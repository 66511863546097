<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title">Content Management</h2>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Content Management</li>
      </ol>
    </div>
  </div>
  <mat-tab-group class="mb-5"  (selectedTabChange)="loadComponent($event)" [selectedIndex]="selectedIndex">
    <mat-tab *ngFor="let tab of tabs; let i = index " [label]="tab">
    </mat-tab>
  </mat-tab-group>
 
</main>