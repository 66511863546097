import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, siteEmailSubscriptionPlan } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EmailSubscriptionPlanService {

  constructor(private http : HttpClient) { }

  AddEmailSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteEmailSubscriptionPlan.addEmailSubscriptionPlanAPI}`,data);
  }

  AllEmailSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteEmailSubscriptionPlan.allEmailSubscriptionPlanAPI}`,data);
  }
  
  GetEmailSubscriptionPlanById(Id:any) {
     return this.http.get(`${environment.baseUrl}${siteEmailSubscriptionPlan.getEmailSubscriptionPlanByIdAPI}${Id}`);
  }

  UpdateEmailSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteEmailSubscriptionPlan.updateEmailSubscriptionPlanAPI}`,data);
  }

  DeleteEmailSubscriptionPlan(Id:any) {
    return this.http.get(`${environment.baseUrl}${siteEmailSubscriptionPlan.deleteEmailSubscriptionPlanAPI}${'?id=' + Id}`);
  }

}
