import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../content-service.service';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      termsOfUseID: 0,
      termsOfUseTitle: ['', [Validators.required]],
      termsOfUseDescription: ['', [Validators.required]],
    });

    this.getTerm();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);

    //this.form.controls.contactUsID.setValue(this.id);
    this.contentServiceService.addTerm(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        this.id = res.responseData;

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });

  }


  getTerm(){
    this.contentServiceService.getTerm().subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.form.patchValue({
          termsOfUseID:res.responseData['termsOfUseID'],
          termsOfUseTitle:res.responseData['termsOfUseTitle'],
          termsOfUseDescription:res.responseData['termsOfUseDescription'],
        });

      }else{
        this.toaster.warning("Please add record!", 'Warning');
      }
    });
  }

}
