import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, ViewAdminMasters } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewAdminDetailsService {

  constructor(private http : HttpClient) { }


  GetAdminListDropdown(Id:any) {
    return this.http.get(`${environment.baseUrl}${ViewAdminMasters.getAdminList}${'?Id=' + Id}`);
  }
}
  