import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterModel, Metadata } from '../../common/MasterModel/masterModel';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl } from '@angular/forms';
import { ManageOrganizationService } from '../../common/services/manage-organization.service'
@Component({
  selector: 'app-resetpwpopup-template',
  templateUrl: './resetpwpopup-template.component.html',
  styleUrls: ['./resetpwpopup-template.component.css']
})
export class ResetpwpopupTemplateComponent implements OnInit {
  resetForm: FormGroup;
  resetFormData:any;
  hide = true;
  hide1 = true;
  confirm : boolean;
  constructor(@Inject(MAT_DIALOG_DATA)
    public data: any,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private manageorganizationService: ManageOrganizationService
  ) { }

  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      userName: [this.data.userName, Validators.required],
      password: ['', [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required]
    });
  }
  savePassword(){
    if(this.resetForm.controls.password.value != '' && this.resetForm.controls.confirmPassword.value != ''){
    this.resetFormData = {
      "organizationID": this.data.organizationID,
      "password": this.resetForm.controls.password.value
    }
    this.manageorganizationService.resetPassword(this.resetFormData).subscribe((res:any) => {
      console.log("REsponse",res)
      if (res.status > 0) {
        this.toaster.success(res.message);
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  else{
    this.toaster.error("Please fill all field")
  }
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
      this.resetForm.setErrors({ mismatch: true });
    }
  }
  
  // getting the form control elements
  get password(): AbstractControl {
    return this.resetForm.controls['password'];
  }
  
  get confirm_password(): AbstractControl {
    return this.resetForm.controls['confirmPassword'];
  }
}
