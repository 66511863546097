export class transModel{
      "transactionTime": string = "";
      "transactionID": string = "";
      "payerAddress": string = "";
      "payerEmail": string = "";
      "payername": string = "";
      "payerId": string = "";
      "payeeEmail":string = "";
      "payeeMerchantId":string = "";
      "status": string = "";
      "paymentsDetails":string = "";
      "update_time": string = "";
      "currency_code": string = "";
      "amount": string = "";
}