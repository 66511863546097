import { Component, OnInit } from '@angular/core';
import { Router }  from "@angular/router";

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css']
})
export class LayoutsComponent implements OnInit {

  sideBarOpen = true;
  ngOnInit(){}

  sidebarToggle(){
    this.sideBarOpen = !this.sideBarOpen;
  }

  constructor(public router: Router){}

}
