import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, 
  Output } from '@angular/core';
  import { Subject } from 'rxjs/Subject';
  import { Subscription } from 'rxjs/Subscription';
  import { debounceTime } from 'rxjs/operators';
  const DISABLE_TIME = 5000;
@Directive({
  selector: 'button[n-submit]'
})
export class ClickButtonDirective {
  constructor(private elementRef: ElementRef) { }
  @HostListener('click', ['$event'])
  clickEvent() {
      this.elementRef.nativeElement.setAttribute('disabled', 'true');
      setTimeout(() => this.elementRef.nativeElement.removeAttribute('disabled'), DISABLE_TIME);
  }
}
