import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutsComponent } from './layouts/layouts.component';
import { ManageAdminsComponent } from './manage-admins/manage-admins.component';
import { AddAdminComponent } from './manage-admins/add-admin/add-admin.component';
import { ViewAdminComponent } from './manage-admins/view-admin/view-admin.component';
import { ManageOrganizationsComponent } from './manage-organizations/manage-organizations.component';
import { OrganizationDetailsComponent } from './manage-organizations/organization-details/organization-details.component';
import { SiteSubscriptionPlanComponent } from './Subscription Plans/site-subscription-plan/site-subscription-plan.component';
import { AddSiteSubscriptionComponent } from './Subscription Plans/site-subscription-plan/add-site-subscription/add-site-subscription.component';
import { SmsSubscriptionComponent } from './Subscription Plans/sms-subscription/sms-subscription.component';
import { AddSmsSubscriptionComponent } from './Subscription Plans/sms-subscription/add-sms-subscription/add-sms-subscription.component';
import { EmailSubscriptionComponent } from './Subscription Plans/email-subscription/email-subscription.component';
import { AddEmailSubscriptionComponent } from './Subscription Plans/email-subscription/add-email-subscription/add-email-subscription.component';
import { EditEmailSubscriptionComponent } from './Subscription Plans/email-subscription/edit-email-subscription/edit-email-subscription.component';
import { EditSiteSubscriptionComponent } from './Subscription Plans/site-subscription-plan/edit-site-subscription/edit-site-subscription.component';
import { EditSmsSubscriptionComponent } from './Subscription Plans/sms-subscription/edit-sms-subscription/edit-sms-subscription.component';
import { RoleAndPermissionsComponent } from './role-and-permissions/role-and-permissions.component';
import { AddRoleAndPermissionComponent } from './role-and-permissions/add-role-and-permission/add-role-and-permission.component';
import { EditRoleAndPermissionComponent } from './role-and-permissions/edit-role-and-permission/edit-role-and-permission.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { EditEmailTemplateComponent } from './email-template/edit-email-template/edit-email-template.component';
import { EmailPlaceHolderComponent } from './email-template/email-place-holder/email-place-holder.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EditAdminComponent } from './manage-admins/edit-admin/edit-admin.component';
import { InquiryComponent } from './inquiry/inquiry.component';
import { ContentManagementComponent } from './content-management/content-management.component';
import { InqueryDetailComponent } from './inquiry/inquery-detail/inquery-detail.component';
import { TextEditorComponent } from './email-template/text-editor/text-editor.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { ToasterComponent } from './toaster/toaster.component';
import { AuthGuardService } from './common/auth/auth-guard.service';
import { TransactionDetailsComponent } from './transaction-list/transaction-details/transaction-details.component';
import { AddOrganizationComponent } from './manage-organizations/add-organization/add-organization.component';
import { AdminProfileComponent } from './manage-admins/admin-profile/admin-profile.component';
import { PaymentComponent } from './payment/payment.component';
import { SettingComponent } from './setting/setting/setting.component';
import { EnquiryResponseComponent } from './inquiry/enquiry-response/enquiry-response.component';
import { EditOrganizationComponent } from './manage-organizations/edit-organization/edit-organization.component'
import { DataLogsComponent } from './logs/data-logs.component';
import { OrganizationTransactionDetailsComponent } from './manage-organizations/organization-transaction-details/organization-transaction-details.component';
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { CreateTemplateComponent } from './email-template/create-template/create-template.component';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'forgot-password-form',
    component: ForgotPasswordFormComponent,
  },   
  {
    path: '',
    component: LayoutsComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },      
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      }, 
      {
        path: 'manage-staff',
        component: ManageAdminsComponent, canActivate:[AuthGuardService]
      }, 
      {
        path: 'toaster',
        component: ToasterComponent,
      },
      {
        path: 'add-staff',
        component: AddAdminComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'view-staff',
        component: ViewAdminComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'edit-staff',
        component: EditAdminComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'manage-organization',
        component: ManageOrganizationsComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'organization-details',
        component: OrganizationDetailsComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'edit-organization',
        component: EditOrganizationComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'admin-profile',
        component: AdminProfileComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'subscription-plan',
        component: SiteSubscriptionPlanComponent, canActivate:[AuthGuardService]
      }, 
      {
        path: 'add-subscription-plan',
        component: AddSiteSubscriptionComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'sms-subs',
        component: SmsSubscriptionComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'add-sms-subs',
        component: AddSmsSubscriptionComponent, canActivate:[AuthGuardService]
      },         
      {
        path: 'email-subs',
        component: EmailSubscriptionComponent, canActivate:[AuthGuardService]
      },
      {
        path: 'add-email-subs',
        component: AddEmailSubscriptionComponent, canActivate:[AuthGuardService]
      },     
      {
        path: 'edit-subscription',
        component: EditSiteSubscriptionComponent, canActivate:[AuthGuardService]
      },     
      {
        path: 'edit-sms-subs',
        component: EditSmsSubscriptionComponent, canActivate:[AuthGuardService]
      },     
      {
        path: 'edit-email-subs',
        component: EditEmailSubscriptionComponent, canActivate:[AuthGuardService]
      },       
      {
        path: 'manage-role',
        component: RoleAndPermissionsComponent, canActivate:[AuthGuardService]
      },     
      {
        path: 'add-role',
        component: AddRoleAndPermissionComponent, canActivate:[AuthGuardService]
      },  
      {
        path: 'edit-role',
        component: EditRoleAndPermissionComponent, canActivate:[AuthGuardService]
      },  
      {
        path: 'email-templates',
        component: EmailTemplateComponent, canActivate:[AuthGuardService]
      },    
     
      {
        path: 'edit-template/:id',
        component: EditEmailTemplateComponent, canActivate:[AuthGuardService]
      },  
      {
        path: 'placeholder',
        component: EmailPlaceHolderComponent,
      },      
      {
        path: 'enquiries',
        component: InquiryComponent, canActivate:[AuthGuardService]
      }, 
      {
        path: 'content-management',
        component: ContentManagementComponent, canActivate:[AuthGuardService]
      },  
      {
        path: 'text',
        component: TextEditorComponent,
      },   
      
      {
        path: 'transactions',
        component: TransactionListComponent, canActivate:[AuthGuardService]
      }, 
      
      {
        path: 'enquiry-details',
        component: InqueryDetailComponent, canActivate:[AuthGuardService]
      },   
      {
        path: 'transaction-details',
        component: TransactionDetailsComponent, canActivate:[AuthGuardService]
      },   

      {
        path: 'enroll-institute',
        component: AddOrganizationComponent, canActivate:[AuthGuardService]
      },   
      {
        path: 'settings',
        component: SettingComponent,
      },  
      
      {
        path: 'Payment-gateway',
        component: PaymentComponent,
      },  
      {
        path: 'Payment-gateway',
        component: PaymentComponent,
      },  
      {
        path: 'enquiry-response',
        component: EnquiryResponseComponent,
      }, 
      {
        path : 'data-logs',
        component : DataLogsComponent
      },
      {
        path : 'create_template',
        component : CreateTemplateComponent
      },
      {
        path: 'org-transaction-details',
        component: OrganizationTransactionDetailsComponent, canActivate:[AuthGuardService]
      },  
      
    ]    
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
