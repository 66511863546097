<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title"> Subscription Plans</h2>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page"> Subscription Plans</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle"> Subscription Plans</h3>
        <a routerLink="/add-subscription-plan" class="btn btn-primary btnsmall">Add Subscription</a>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Plan Name</mat-label>
                      <input (keypress)="allowAlphabetOnly($event)" maxlength="20" matInput type="text" formControlName="planName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Plan Type<span class="req">*</span></mat-label>
                      <mat-select placeholder="Plan Type" formControlName="planTypeName">
                        <mat-option value="Select plan Type">Plan Type</mat-option>
                        <mat-option *ngFor="let planType of planTypeDropdown " [value]="planType.planTypeName">
                          {{planType.planTypeName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="w-100">
                                 <mat-label>Plan Name</mat-label>
                                 <input matInput type="text" formControlName="planTypeName">
                               </mat-form-field> -->
                  </div>
                  
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
            </div>
          </div>
          <div class="rightTableActions centerItemVert">
           
          </div>

        </div>
        <div class="mat-elevation-z8">
         
        </div>
      
      </div>

      <!-- <app-data-table [inputColumns]="displayedColumns" [inputSource]="siteSubscriptionListData" [inputMeta]="metaData"
      [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
      (onTableActionClick)="onTableActionClick($event)">
      </app-data-table> -->

      <table mat-table [dataSource]="dataSource" matSort >

        <!-- Plan ID Column -->
        <ng-container matColumnDef="subscriptionID">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
            PLAN ID
          </th>
          <td mat-cell *matCellDef="let element"> {{element.subscriptionID}} </td>
        </ng-container>

        <!-- Plan Name Column -->
        <ng-container matColumnDef="planName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Plan Name">
            PLAN NAME
          </th>
          <td mat-cell *matCellDef="let element"> {{element.planName}} </td>
        </ng-container>

        <!-- Plan Type Column -->
        <ng-container matColumnDef="planTypeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Plan Type">
            PLAN TYPE
          </th>
          <td mat-cell *matCellDef="let element"> {{element.planTypeName}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="planStatusName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">
            STATUS
          </th>
          <td mat-cell *matCellDef="let element"> {{element.planStatusName}} </td>
        </ng-container>

        <!-- price Column -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Price">
            PRICE
          </th>
          <td mat-cell *matCellDef="let element"> {{element.price}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="numberOfUserAllowed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Users">
            USERS
          </th>
          <td mat-cell *matCellDef="let element"> {{element.numberOfUserAllowed}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            ACTIONS
          </th>
          <td mat-cell *matCellDef="let element"> 
            <button class="tbactionbtn" 
                 (click)='onActionClick("edit", element)' title="Edit">
                  <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button *ngIf="element.subscriptionID != 1" class="tbactionbtn" 
                (click)='onActionClick("delete", element)' title="Delete">
                <i class="fas fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayCol"></tr>
        <tr mat-row *matRowDef="let row; columns: displayCol;"></tr>
      </table>

      <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1" [pageSize]="metaData['pageSize']" showFirstLastButtons></mat-paginator>

    </div>
  </div>
</main>