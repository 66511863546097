import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-place-holder',
  templateUrl: './email-place-holder.component.html',
  styleUrls: ['./email-place-holder.component.css']
})
export class EmailPlaceHolderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
