import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { EmailSubscriptionPlanService } from 'src/app/common/services/emailSubscriptionPlanService/email-subscription-plan.service';
import { MasterDropdownService } from 'src/app/common/services/MasterDropdownServices/master-dropdown.service';
import { SubscriptionPlanService } from 'src/app/common/services/subscriptionPlanService/subscription-plan.service';

@Component({
  selector: 'app-edit-email-subscription',
  templateUrl: './edit-email-subscription.component.html',
  styleUrls: ['./edit-email-subscription.component.css']
})
export class EditEmailSubscriptionComponent implements OnInit {

  form: FormGroup;
  subscriptionID: number;
  data: string;
  planTypeDropdown:any;
  planTypeStautusDropdown:any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private emailSubscriptionPlanService: EmailSubscriptionPlanService,
    private toaster: ToastrService,
    private router: Router,
    private masterDropdownService :MasterDropdownService,
    private commonService: CommonService
  ) { }


  ngOnInit(): void {
    //this.subscriptionID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.subscriptionID = params.id==undefined?null:params.id;
    });

    this.form = this.formBuilder.group({
      emailSubscriptionPlanID: ['', Validators.required],//
      emailPlanName: ['', Validators.required], 
      emailPrice: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      limit: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      emailPlanStatusID: ['', Validators.required],//
      emailPackID: ['', Validators.required],//
      description: ['', Validators.required],//
    });

    this.masterDropdown();
    this.GetEmailSubscriptionPlanById();
  }

  GetEmailSubscriptionPlanById() {
    //debugger;
    this.emailSubscriptionPlanService.GetEmailSubscriptionPlanById(this.subscriptionID).subscribe((res: any) => {
      this.form.patchValue({
        emailSubscriptionPlanID: res['emailSubscriptionPlanID'],
        emailPlanName: res['emailPlanName'],
        emailPrice: res['emailPrice'],
        limit: res['limit'],
        emailPlanStatusID: res['emailPlanStatusID'],
        emailPackID: res['emailPackID'],
        description: res['description'],
      });
      this.data = res;
    });
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.updateEmailSubscriptionPlan();
    // this.form.reset();
    // this.form.controls.emailSubscriptionPlanID.clearValidators();
    // this.form.controls.emailPlanName.clearValidators();
    // this.form.controls.emailPrice.clearValidators();
    // this.form.controls.limit.clearValidators();
    // this.form.controls.emailPlanStatusID.clearValidators();
    // this.form.controls.emailPackID.clearValidators();
    // this.form.controls.description.clearValidators();
  }

  updateEmailSubscriptionPlan() {
    console.log("this.form.value", this.form.value)
    this.emailSubscriptionPlanService.UpdateEmailSubscriptionPlan(this.form.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/email-subs'])
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

}
