import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, AccountLogin } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountServiceService {

  constructor(private http : HttpClient) { }


  LoginUser(data:any) {
    return this.http.post(`${environment.baseUrl}${AccountLogin.loginAPI}`,data);
  }

  LogOutUser(Email:any, ClientIP:any) {
    return this.http.get(`${environment.baseUrl}${AccountLogin.logoutAPI}`+'?email='+Email +'&clientIP='+ClientIP);
  }

  GetMenu(Id:any) {
    return this.http.get(`${environment.baseUrl}${AccountLogin.menuListAPI}`+'?id='+Id);
  }
}
