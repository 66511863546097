<div class="dialog-md">
      <i class="material-icons popupClose" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h2 mat-dialog-title>Reset Password</h2>
      </div>
      <mat-dialog-content class="mat-typography">
        <form [formGroup]="resetForm" (ngSubmit)="savePassword()">
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline" floatLabel="always">
              <mat-label>Username</mat-label>
              <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
              <input matInput readonly="true" formControlName="userName" required />
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Password</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
              <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required 
              pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
              <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </span>
              <mat-error *ngIf="resetForm.get('password')?.touched  && resetForm.get('password')?.errors?.required">
                                                Password is required
                                            </mat-error>
              <mat-error *ngIf="resetForm.get('password')?.errors?.pattern">
                Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-group17">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
              <input matInput formControlName="confirmPassword" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
              <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
              </span>
              <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
            </mat-form-field>
          </div>
          <div class="form-group17 center">
            <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid" mat-dialog-close>Send</button>
          </div>
        </form>
  
      </mat-dialog-content>
    </div>