<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Organizations</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
              <li class="breadcrumb-item"><a routerLink="/manage-organization">Manage Organizations</a></li>
              <li class="breadcrumb-item active" aria-current="page">Details</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle"> Organization Details</h3>
                <a routerLink="/manage-organization" class="btn btn-primary btnsmall">Back</a>
            </div>
            
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <div class="proSection">
                            <h5>Organization Details</h5>
                            <div class="row">
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Organization Name :</label>
                                        <span>{{organizationName}}</span>
                                    </div>
                                </div>
                               
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Organization Type :</label>
                                        <span>{{organizationType}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>No. of Student :</label>
                                        <span>{{maximumStudentAllowed}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Sub-Domain Name :</label>
                                        <span>{{subDomainName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Organization Short Name :</label>
                                        <span>{{organizationShortName}}</span>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                        <div class="proSection">
                            <h5>Admin Details</h5>
                            <div class="row">
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Full Name :</label>
                                        <span>{{contactPersonFullName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Contact No. :</label>
                                        <span>{{contactNumber}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>User Name :</label>
                                        <span>{{userName}}</span>
                                    </div>
                                </div>
                                
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Email :</label>
                                        <span>{{email}}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="proSection">
                            <h5>Site Subscription Plan</h5>
                            <div class="row">
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Plan Name :</label>
                                        <span>{{planName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Price($) :</label>
                                        <span>{{price}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Plan Type :</label>
                                        <span>{{planTypeName}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Start Date :</label>
                                        <span>{{02-06-2021}}</span>
                                    </div>
                                </div> -->
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label> Status :</label>
                                        <span>{{planStatusName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Start Date :</label>
                                        <span>{{subscriptionStartDate}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>End Date :</label>
                                        <span>{{subscriptionEndDate}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Validity Days :</label>
                                        <span>{{noOfDays}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="proSection">
                            <h5>Email Subscription Plan</h5>
                            <div class="row">
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Plan Name :</label>
                                        <span>{{emailPlanName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Price($) :</label>
                                        <span>{{emailPrice}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Email Pack :</label>
                                        <span>{{emailPackID}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Start Date :</label>
                                        <span>{{02-06-2021}}</span>
                                    </div>
                                </div> -->
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label> Status :</label>
                                        <span>{{statusName}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>End Date :</label>
                                        <span>01-06-2022</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="proSection">
                            <h5>SMS Subscription Plan</h5>
                            <div class="row">
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Plan Name :</label>
                                        <span>{{smsPlanName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Price($) :</label>
                                        <span>{{smsPrice}}</span>
                                    </div>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>SMS Pack :</label>
                                        <span>{{smsPackID}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>Start Date :</label>
                                        <span>{{smsPackID}}</span>
                                    </div>
                                </div> -->
                                <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label> Status :</label>
                                        <span>{{statusName}}</span>
                                    </div>
                                </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <div class="detailsContent">
                                        <label>End Date :</label>
                                        <span>01-06-2022</span>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proSection">
                <h5>
                    Transaction Details</h5>
                </div>
                <div class="table-responsive Transactiontable">
                    <table mat-table [dataSource]="transactionData">
                      <ng-container matColumnDef="invoiceNumber">
                        <th mat-header-cell *matHeaderCellDef>Invoice Number</th>
                        <td mat-cell *matCellDef="let element;let i = index;">{{element.invoiceNumber}} </td>
                      </ng-container>
            
                      <!-- Name Column -->
                      <ng-container matColumnDef="payerName">
                        <th mat-header-cell *matHeaderCellDef>Payer</th>
                        <td mat-cell *matCellDef="let element"> {{element.payerName}} </td>
                      </ng-container>
            
                      <!-- Weight Column -->
                      <ng-container matColumnDef="paymentMethod">
                        <th mat-header-cell *matHeaderCellDef>Payment Method</th>
                        <td mat-cell *matCellDef="let element"> {{element.paymentMethod}} </td>
                      </ng-container>
            
                      <!-- Symbol Column -->
                      <ng-container matColumnDef="paymentType">
                        <th mat-header-cell *matHeaderCellDef>Payment Type</th>
                        <td mat-cell *matCellDef="let element"> {{element.paymentType | uppercase}}  </td>
                      </ng-container>
                      <ng-container matColumnDef="paymentDate">
                        <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                        <td mat-cell *matCellDef="let element"> {{element.paymentDate | date:'dd/MM/yyyy'}}  </td>
                      </ng-container>
                      <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let element">$ {{element.amount}}  </td>
                      </ng-container>
                      <!-- <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Payment Amount</th>
                        <td mat-cell *matCellDef="let element"> {{element.amount}}  </td>
                      </ng-container> -->
            
                     <ng-container matColumnDef="button">
                        <th mat-header-cell *matHeaderCellDef> Actions</th>
                        <td mat-cell *matCellDef="let element">
                          <Button (click)="infoCall(element.transactionID)" title="Details"  class="tbactionbtn"><i class="fas fa-eye"></i></Button>
                  
                         
                        </td>
                      </ng-container>
            
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
            </div>
        </div>
    </div>
</main>

