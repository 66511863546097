import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from 'src/app/common/core-services/DialogService';
import { MasterDropdownService } from 'src/app/common/services/MasterDropdownServices/master-dropdown.service';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { EmailSubscriptionPlanService } from 'src/app/common/services/emailSubscriptionPlanService/email-subscription-plan.service';
import { SiteEmailSubscriptionModel } from './SiteEmailSubscriptionModel';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import * as XLSX from 'xlsx';

export interface PeriodicElement {
  emailSubscriptionPlanID:number;
  emailPlanName: string;
  emailPackID: string;
  emailPlanStatusID: string;
  emailPrice: string;
  description: string;
  actions: any;


  isActive: Boolean;
  isDeleted: Boolean;
}

// const ELEMENT_DATA: PeriodicElement[] = [
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
//   { emailPlanName: 'ABC', emailPackID: '1000/Day', emailPlanStatusID: 'Active', emailPrice: '65', actions: 'Active' },
// ];


@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.css']
})
export class EmailSubscriptionComponent implements OnInit {

  classToggled = false;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  siteEmailSubscriptionListData: SiteEmailSubscriptionModel[];
  planTypeDropdown: any = [];
  planTypeStautusDropdown: any = [];
  dataSourceFilter:any = [];


  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  //@ViewChild(MatSort) sort!: MatSort;

  displayedColumns: Array<any> = [
    { displayName: 'Plan ID', key: 'subscriptionID', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Name', key: 'planName', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Type', key: 'planTypeName', isSort: true, class: '', width: '20%' },
    { displayName: 'Status', key: 'emailPlanStatusID', isSort: true, class: '', width: '20%' },
    { displayName: 'price', key: 'emailPrice', isSort: true, class: '', width: '20%' },
    { displayName: 'Description', key: 'description', isSort: true, class: '', width: '20%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];

  actionButtons: Array<any> = [
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];

  constructor(
    private dialog: MatDialog,
    private emailSubscriptionPlanService: EmailSubscriptionPlanService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private masterDropdownService: MasterDropdownService,
    private commonService: CommonService
  ) {
    this.filterModel = new FilterModel();
    this.siteEmailSubscriptionListData = new Array<SiteEmailSubscriptionModel>();
  }

  //displayedColumns: string[] = ['emailPlanName', 'emailPackID', 'limit', 'emailPlanStatusID', 'emailPrice', 'description', 'actions'];
  dataSourceEmail = new MatTableDataSource<PeriodicElement>();
  selection = new SelectionModel<PeriodicElement>(true, []);


  ngOnInit(): void {

    this.form = this.formBuilder.group({
      planName: [''],
      planTypeName: [''],
      pricing: [''],

    });

    this.masterDropdown();
    this.metaData = new Metadata();
    this.getEmailSubscriptionPlanList(this.filterModel)
  }

  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "columnName": "",
      "planName": this.form.controls.planName.value,
      "planType": this.form.controls.planTypeName.value,
      "pricing": this.form.controls.pricing.value,
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getEmailSubscriptionPlanList(this.requestpages)
  }

  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.subscriptionID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'DELETE':
        {

          this.dialogService
            .confirm(`Are you sure to delete this subscription plan?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteEmailSubscription(id);
              }
            });
        }
        break;
    }
  }

  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getEmailSubscriptionPlanList(this.filterModel);
    }
  }

  
  getEmailSubscriptionPlanList(filterModel: any) {

    var planName = "";
    var planType = "";

    if(this.requestpages != undefined){
      console.log("this.requestpages != 'undefined'", this.requestpages)

      planName =  this.requestpages['planName'];
      planType = this.requestpages['planType']
    }

    let searchObj = {
      "pageNumber": this.filterModel.pageNumber,//
      "pageSize": this.filterModel.pageSize,//
      "sortOrder": this.filterModel.sortOrder,//
      "sortColumn": this.filterModel.sortColumn,
      "columnName": this.filterModel.sortColumn,//
      "searchText": this.filterModel.searchText,
      "planName": planName,
      "planType": planType
    }

    this.emailSubscriptionPlanService.AllEmailSubscriptionPlan(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        //console.log(res);
        this.siteEmailSubscriptionListData = res.responseData;
        this.metaData = res.meta;


        this.siteEmailSubscriptionListData.forEach((element :any) => {
          var x = element;

          // var getPackName = this.planTypeDropdown.find((xx : any) => xx.planID === element.emailPackID);
          // x.emailPackID = Object.values(getPackName)[1]

          // var getPackStatusName = this.planTypeStautusDropdown.find((xxx : any) => xxx.planStatusID === element.emailPlanStatusID);
          // x.emailPlanStatusID = Object.values(getPackStatusName)[1];

          if(x.emailPlanStatusID == "1"){
            x.emailPlanStatusID = "Active";
          }
          else{
            x.emailPlanStatusID = "InActive";
          }

          this.planTypeDropdown.forEach((elements :any) => {
            if(elements.planID === element.emailPackID){
              x.emailPackID = elements.planTypeName;
            }
          });

          this.planTypeStautusDropdown.forEach((elements :any) => {
            if(elements.planStatusID === element.emailPlanStatusID){
              x.emailPlanStatusID = elements.planStatusName;
            }
          });

        });
        console.log("this.siteEmailSubscriptionListData", this.siteEmailSubscriptionListData);
        this.dataSourceEmail.data = this.siteEmailSubscriptionListData;


      } else {
        this.metaData = new Metadata();
        this.toaster.error(res.message);
      }
      //console.log(this.siteEmailSubscriptionListData)
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      if (res.status > 0)
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      if (res.status > 0)
      this.planTypeStautusDropdown = res.responseData
    })
  }

  editCall(subscriptionID: any) {
    this.router.navigate(["/edit-email-subs"], { queryParams: { id: subscriptionID } });
  }

  deleteEmailSubscription(id: any) {
    this.emailSubscriptionPlanService.DeleteEmailSubscriptionPlan(id).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.error(res.message);
        this.InitialGridCall();

        this.getEmailSubscriptionPlanList(this.requestpages)
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  editEmailSubscription(id: any) {
    this.router.navigate(['/edit-email-subs'], {queryParams: {id: id}})
  }

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getEmailSubscriptionPlanList(this.requestpages)
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getEmailSubscriptionPlanList(this.filterModel)
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "columnName": "",
      "planType": "",
      "planName": "",
      "sortOrder": "string"
    }
  }


  ExportTOExcel()
  {
    let searchObj = {
      pageNumber:1,
    pageSize:1000,
    sortColumn:'',
    sortOrder:'',
    searchText:'',
    columnName:'',
    }
    this.emailSubscriptionPlanService.AllEmailSubscriptionPlan(searchObj).subscribe((res: any) => {
      if (res.status > 0) {
        let dataToExport = res.responseData
        .map((x :any)=> ({
          //subscriptionID: x.subscriptionID ,
          planName: x.planName ,
          emailPlanStatusID: (x.emailPlanStatusID == 1) ? "Active" : "InActive",
          planTypeName: x.planTypeName ,
          emailPrice: x.emailPrice ,
          numberOfUserAllowed: x.numberOfUserAllowed ,
          emailPackID: x.emailPackID ,
          description: x.description ,
          limit: x.limit

        }));
  
      let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
      let workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
      XLSX.writeFile(workBook, 'Email-Subscription-Plan.xlsx');

      } 
    });
  }



  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
}