<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
      <h2 class="page-title">Manage Logs</h2>
  
      <div class="breadcrumbWidget">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
          <li class="breadcrumb-item active" aria-current="page">Data Logs</li>
        </ol>
      </div>
    </div>
    <div class="container-fluid">
      <div class="matCard">
        <div class="matCrdTitle centerItemVert justify-content-between">
          <h3 class="matCardTitle">Data Logs</h3>
        </div>
  
        <div class="matCardBody">
          <div class="tableActionTop mb-3">
            <div class="tableFilter">
              <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
              <div class="filterForm" [class.toggled]="classToggled">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m3 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-select placeholder="Admin Username" formControlName="userName" >
                        <mat-option *ngFor="let user of adminUsersList " [value]="user.adminUserID">
                          {{user.username}} ( {{user.roleName}} )
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!-- <div class="col s12 m2 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Events</mat-label>
                      <mat-select>
                        <mat-option value="one">Event 1/Day</mat-option>
                        <mat-option value="two">Event 2/Day</mat-option>
                        <mat-option value="two">Event 3/Day</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->

                  <div class="col s12 m3 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Choose a from date</mat-label>
                      <input matInput [matDatepicker]="picker" formControlName="from" [max]="maxDate">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col s12 m3 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Choose a to date</mat-label>
                      <input matInput [matDatepicker]="picker1" formControlName="to" [max]="maxDate">
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="col s12 m3 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Activities</mat-label>
                      <mat-select formControlName="activities">
                        <mat-option value="add">Add</mat-option>
                        <mat-option value="update">Update</mat-option>
                        <mat-option value="delete">Delete</mat-option>
                        <mat-option value="login">Login</mat-option>
                        <mat-option value="logout">LoggedOut</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <!-- <div class="col s12 m2 form-group">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Select Actions</mat-label>
                      <mat-select>
                        <mat-option value="one">500/Day</mat-option>
                        <mat-option value="two">1000/Day</mat-option>
                        <mat-option value="two">1500/Day</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
              </div>
            </div>
            <!-- <div class="rightTableActions centerItemVert">
              <div class="viewColumns tableActions">
                <a (click)="actionDialogPopup(hideShowColPopup)"><i class="fas fa-columns"></i></a>                           
              </div>
              <div class="downloadTable tableActions">
                <a><i class="fas fa-download"></i></a>
              </div>
            </div> -->
          </div>
        </div>
  
        <app-data-table [inputColumns]="displayedColumns" [inputSource]="dataLogs" [inputMeta]="metaData"
        (onChange)="onPageOrSortChange($event)">
        </app-data-table>
      </div>
    </div>
  </main>


  
  
  