<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Enquiry Response</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/enquiries">Enquiries</a></li>
                <li class="breadcrumb-item active" aria-current="page">Enquiry Response</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Enquiry Response</h3>
                <a routerLink="/enquiries" class="btn btn-primary btnsmall">Back</a>
            </div>

            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                         <p class="chngePic">
                             <input type="file">
                             <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                             <img src="../../../assets/img/display.jpg" alt="">
                         </p>
                     </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <div class="proSection">

                            <div class="row">
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Name :</label>
                                            <span>{{name}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Email :</label>
                                            <span>{{email}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Phone Number :</label>
                                        <span>{{phoneNumber}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Status:</label>
                                        <span>{{enquiryStatusName}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Address :</label>
                                            <span>{{address}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Comments :</label>
                                            <h3><span>{{comments}}</span></h3>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s6 m6 form-group">
                                        <div class="detailsContent">
                                            <label>Responded :</label>
                                            <h5 ><ul>
                                                <li *ngFor="let item of response">{{item.responseDetails}} <h5>(Responded By: {{item.fullName}})</h5> <h5>(Responded Date: {{item.createdDate | date:'dd-MM-yyyy'}})</h5> </li>
                                            </ul></h5>
                                        </div>
                                    </div>
                                    <div class="col s6 m6 form-group">
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row">
                                <!-- <div class="col s12  form-group">
                                    <div class="detailsContent">
                                        <label>Name :</label>
                                        <span>{{name}}</span>
                                    </div>
                                </div>

                                <div class="col s12  form-group">
                                    <div class="detailsContent">
                                        <label>Email :</label>
                                        <span>{{email}}</span>
                                    </div>
                                </div>
                                <div class="col s12 form-group">
                                    <div class="detailsContent">
                                        <label>Phone Number :</label>
                                        <span>{{phoneNumber}}</span>
                                    </div>
                                </div>
                                <div class="col s12 form-group">
                                    <div class="detailsContent">
                                        <label>Status:</label>
                                        <span>{{enquiryStatusName}}</span>
                                    </div>
                                </div>

                                <div class="col s12  form-group">
                                    <div class="detailsContent">
                                        <label>Enquiry Type :</label>
                                        <span>{{enquiryTypeName}}</span>
                                    </div>
                                </div>
                                <div class="col s12 form-group">
                                    <div class="detailsContent">
                                        <label>Address :</label>
                                        <span>{{address}}</span>
                                    </div>
                                </div>
                                <div class="col s12 form-group">
                                    <div class="detailsContent">
                                        <label>Comments :</label>
                                        <h3><span>{{comments}}</span></h3>
                                    </div>
                                </div>
                                <div class="col s12 form-group">
                                    <div class="detailsContent" >
                                        <label>Resposonsed :</label>
                                        <h3 ><ul>
                                            <li *ngFor="let item of response">{{item.responseDetails}}</li>
                                        </ul></h3>
                                    </div>
                                </div> -->
                                <form [formGroup]="responseForm" (ngSubmit)="sendEnquiryResponse()">
                                    <div class="col s2 form-group">
                                        <div class="detailsContent">
                                            <label>Response :</label>
                                            <!-- <ejs-richtexteditor id='defaultRTE'>
                                                <ng-template #valueTemplate>
                                                  <p>The Rich Text Editor component is WYSIWYG ("what you see is what you get") editor that provides the best user
                                                    experience to create and update the content.
                                                    Users can format their content using standard toolbar commands.</p>
                                          
                                                </ng-template>
                                              </ejs-richtexteditor> -->


                                            <!-- <mat-form-field class="example-full-width" appearance="fill">
                                        <mat-label>Leave a comment</mat-label>
                                        <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                                    </mat-form-field> -->
                                        </div>
                                    </div>

                                    <div class="col s10 form-group">
                                        <ejs-richtexteditor  formControlName="responseDetails" #textEditorValue
                                            [toolbarSettings]='tools'>
                                        </ejs-richtexteditor>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit"  class="btn btn-primary btn-sm">Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>