import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MasterDropdownService } from "../../common/services/MasterDropdownServices/master-dropdown.service";
import { ManageOrganizationService } from "../../common/services/manage-organization.service";
import { CommonService } from 'src/app/common/core-services/common.Service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.css']
})
export class EditOrganizationComponent implements OnInit {
  signUpForm: FormGroup;
  siteSubscriptionPlan: any;
  emailSubscriptionPlan: any;
  SMSSubscriptionPlan: any;
  organizationTypes: any
  organizationID: any;
  @ViewChild('phone') phone: any;
  addOrgDetailsObject: any;
  constructor(private dialog: MatDialog, private activatedRoute: ActivatedRoute, private toaster: ToastrService,
    private router: Router, public organizationSignupService: ManageOrganizationService, private fb: FormBuilder, private masterService: MasterDropdownService, private commonService: CommonService) {
    this.signUpForm = this.fb.group({
      organizationID: [0],
      organizationName: ['', Validators.required],
      organizationShortName: ['', Validators.required],
      maximumStudentAllowed: ['', Validators.required],
      organizationType: ['', Validators.required],
      contactPersonFullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', Validators.required],
      userName: ['', Validators.required],
      subscriptionID: [0],
      emailSubscriptionPlanID: [0],
      smsSubscriptionPlanID: [0],
      subDomainName: ['', Validators.required]
    }
    )
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.organizationID = params.id == undefined ? null : params.id;
    });
    this.getOrganizationById();
    this.masterDropdown();
  }
  getOrganizationById() {
    debugger;
    this.organizationSignupService.GetOrganizationById(this.organizationID).subscribe((res: any) => {
      console.log("orgData", res);
      this.signUpForm.patchValue({
        organizationID: res['organizationID'],
        organizationName: res['organizationName'],
        organizationShortName: res['organizationShortName'],
        maximumStudentAllowed: res['maximumStudentAllowed'],
        organizationType: res['organizationType'],
        contactPersonFullName: res['contactPersonFullName'],
        email: res['email'],
        contactNumber: res['contactNumber'],
        userName: res['userName'],
        subscriptionID: res['subscriptionID']??0,
        emailSubscriptionPlanID: res['emailSubscriptionPlanID']??0,
        smsSubscriptionPlanID: res['smsSubscriptionPlanID']??0,
        subDomainName: res['subDomainName'],
        //contactNumberCountryCode: res['contactNumberCountryCode'],
      });
    });
  }

  masterDropdown() {
    this.masterService.getOrganizationType().subscribe((res: any) => {
      this.organizationTypes = res.responseData
      console.log("this.organizationTypes ", this.organizationTypes);
    });
    this.masterService.GetSiteSubscriptionPlanDropdown().subscribe((res: any) => {
      this.siteSubscriptionPlan = res.responseData
      console.log("this.siteSubscriptionPlan ", this.siteSubscriptionPlan);

    });

    this.masterService.GetEmailSubscriptionPlanDropdown().subscribe((res: any) => {
      this.emailSubscriptionPlan = res.responseData
      console.log("this.emailSubscriptionPlan", this.emailSubscriptionPlan);

    });
    this.masterService.GetSMSSubscriptionPlanDropdown().subscribe((res: any) => {
      this.SMSSubscriptionPlan = res.responseData
      console.log("this.SMSSubscriptionPlan", this.SMSSubscriptionPlan);

    });
  }
  registerOrgApiCall() {
    console.log("hi");
    debugger;
    this.addOrgDetailsObject = {};
    this.addOrgDetailsObject = this.signUpForm.value;
     this.addOrgDetailsObject.contactNumber = this.phone.phoneNumber
     this.addOrgDetailsObject.contactNumberCountryCode = '+' + (this.phone.selectedCountry.dialCode);
    this.addOrgDetailsObject.statusID = 1,
      console.log(this.phone);
    console.log('this.addOrgDetailsObject', this.addOrgDetailsObject);
    this.organizationSignupService.updateOrganization(this.addOrgDetailsObject).subscribe((resp: any) => {
      if (resp.status > 0) {
        this.toaster.success(resp.message);
        console.log(resp);
        this.signUpForm.reset();
        this.router.navigate(['/manage-organization'])
      }
      else {
        this.toaster.error(resp.message);
      }
      console.log(resp);
      this.signUpForm.reset();
      this.router.navigate(['/manage-organization'])
    });
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  removespace(event:any){
    this.commonService.removeSpace(event);
  }
}
