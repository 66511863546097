<main>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Social Media Links</h3>
                <!-- <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a> -->
            </div>


            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                         <p class="chngePic">
                             <input type="file">
                             <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                             <img src="../../../assets/img/display.jpg" alt="">
                         </p>
                     </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <form [formGroup]="form">
                            <div class="proSection">

                                <div class="row">
                                    <!-- <div class="col l12 s12 "> -->
                                    <div class="col l12 s12  form-group">

                                        <!-- <h3 _ngcontent-lgc-c182="">Subscription</h3> -->
                                        <div class="col l6 s12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Facebook<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="faceBookLink" formControlName="faceBookLink"
                                                    matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col l6 s12  form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Twitter<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="twitterLink" formControlName="twitterLink" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col l6 s12 form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>LinkedIn<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="linkedInLink" formControlName="linkedInLink"
                                                    matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col col l6 s12  form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Youtube<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="youTubeLink" formControlName="youTubeLink" matInput>
                                            </mat-form-field>
                                        </div>
                                        <div class="col col l12 s12  form-group">
                                            <mat-form-field class="w-100" appearance="outline">
                                                <mat-label>Instagram<span class="req">*</span></mat-label>
                                                <input [(ngModel)]="instagramLink" formControlName="instagramLink" matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>

                            <div class="proSection center">
                                <button type="submit" (click)="updateSetting()" [disabled]="form.invalid"
                                    class="btn btn-primary">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>