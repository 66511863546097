import { Component, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { EmailTemplateService } from "../../common/services/emailTemplateService/email-template.service";
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ToastrService } from 'ngx-toastr';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';



@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class CreateTemplateComponent implements OnInit {
  emailTemplateID:number;
  form: FormGroup;
  data:any;
  userEmail: any;

  @ViewChild('textEditorValue')
    private rteEle: RichTextEditorComponent;
    public tools: ToolbarModule = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
          'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
          'LowerCase', 'UpperCase','SuperScript', 'SubScript', '|',
          'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
          'Outdent', 'Indent', '|',
          'CreateTable', 'CreateLink', 'Image', '|',
          'SourceCode', '|', 'Undo', 'Redo']
  };
 
  constructor(
    private emailTemplateService:EmailTemplateService,
    private formBuilder:FormBuilder,
    private activatedRoute:ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //this.emailTemplateID = this.activatedRoute.snapshot.params.Id   
    this.activatedRoute.queryParams.subscribe(params => {
      this.emailTemplateID = params.id==undefined?null:params.id;
    });
    this.userEmail="info@cerocms.co.au";
    this.form = this.formBuilder.group({
      templateName: ['', Validators.required],
      templateSubject: ['', Validators.required],
      emailFrom: new FormControl(),
      contents: [''],
      CustomEmail: [''],
      isEmailTemplateCustom: true,
      isFromEmailDefault: true,
    });
  }

  FromEmailOption:boolean;
  appliedSetting(event: any){
    //debugger
    this.FromEmailOption = event;
    let customClick = this.FromEmailOption
      if(!customClick){
      this.form.controls.CustomEmail.value
      this.form.patchValue({
        isEmailTemplateCustom: event,
      });
      this.form.controls['CustomEmail'].setValidators([Validators.required,
        Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/)])
      this.form.controls['CustomEmail'].updateValueAndValidity();
    }
    else{
      this.form.controls['CustomEmail'].clearValidators();
      this.form.controls['CustomEmail'].updateValueAndValidity();
      this.form.controls['CustomEmail'].reset();
    }

  }

  
  // GetEmailTemplate(){
  //   this.emailTemplateService.GetEmailTemplateById(this.emailTemplateID).subscribe((res:any) => {
  //     this.form.patchValue({
  //       emailTemplateID:res['emailTemplateID'],
  //       templateName: res['templateName'],
  //       templateSubject: res['templateSubject'],
  //       emailFrom: res['emailFrom'],
  //       contents: res['contents'],
  //     }); 
  //     this.data = res;
  //     console.log("sae",this.data)
  //   });
  // }


  onSubmit(){
    //this.updateEmailTemlate();
    let email : any;
    if(this.form.controls.isFromEmailDefault.value){
      email = this.userEmail
      this.form.patchValue({
        isEmailTemplateCustom: false,
      })
    }
    else{
      email = this.form.controls.CustomEmail.value
      this.form.patchValue({
        isEmailTemplateCustom: true,
      })
    }
  this.form.patchValue({
     templateName : this.form.controls.templateName.value,
     templateSubject :this.form.controls.templateSubject.value,
     isFromEmailDefault: this.form.controls.isFromEmailDefault.value,
     emailFrom: email,
     contents: this.form.controls.contents.value,
    // isEmailTemplateCustom: this.form.controls.isEmailTemplateCustom.value,
  })
  console.log(this.form);
  console.log("this.emailTemplateForm.value", this.form.value);

  this.emailTemplateService.AddCustomEmailTemplate(this.form.value).subscribe((res: any) => {
    if (res.status == 0) {
      this.toaster.error(res.message);
    }
    else {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/email-templates']);
      }
      else {
        this.toaster.error(res.message);
      }
    }
  });
  }

  updateEmailTemlate(){
    this.emailTemplateService.UpdateEmailTemplate(this.form.value).subscribe((res: any) =>{
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/email-templates']);
      }
      else {
        this.toaster.error(res.message);
      }
  
    })
  }

 
}
