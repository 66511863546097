import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/common/Models/user';
import { AccountServiceService } from 'src/app/common/services/AccountService/account-service.service';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebarForMe : EventEmitter<any> = new EventEmitter();

  toggle:boolean = true;
  change(){
    this.toggle = !this.toggle;
  }
   users:any;
   user : User
   formRequest: any;
   resetForm: FormGroup;
   hide = true;
   hide1 = true;
   adminUserID:any;
  constructor(private router: Router, private accountServiceService: AccountServiceService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private manageStaffService: ManageStaffService,
    private toaster: ToastrService,
     ) {
    this.users = localStorage.getItem('currentUser');
    var currentUser = JSON.parse(this.users);
    if(currentUser!=null)
    {
      this.user = currentUser;
      this.adminUserID = currentUser.userId;
    }
    console.log("this.adminUserID", this.adminUserID);
   }

   //user:any;
  ngOnInit(): void {
    this.resetForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      password: ['',  [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required]
    });
  }

  toggleSidebar(){
    this.toggleSidebarForMe.emit();
  }

  resetPassword(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
    this.resetForm.reset();
  }
  adminPasswordObj:{}
  savePassword(){
    if(this.resetForm.invalid){
      return;
    }
    this.adminPasswordObj ={
      "adminUserID": this.adminUserID,
      "currentPassword": this.resetForm.value.currentPassword,
      "password": this.resetForm.value.password,
    }
    console.log("ONSAVEPASSWORD",this.adminPasswordObj)
    this.manageStaffService.ChangePassword(this.adminPasswordObj).subscribe((res:any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.resetForm.reset();
        this.dialog.closeAll();
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
      this.resetForm.setErrors({ mismatch: true });
    }
  }
  
  // getting the form control elements
  get password(): AbstractControl {
    return this.resetForm.controls['password'];
  }
  
  get confirm_password(): AbstractControl {
    return this.resetForm.controls['confirmPassword'];
  }


  logout() {
    this.GetUserLogout();
    //localStorage.removeItem('currentUser');
    //sessionStorage.removeItem('AccessToken')
    this.router.navigate(['/']);
    //
  }

  GetUserLogout() {
    this.formRequest = {
      "email": localStorage.getItem('Username'),
      "password": "",
      "ClientIP": localStorage.getItem('Client-IP')
    }
    this.accountServiceService.LogOutUser(localStorage.getItem('Username'), localStorage.getItem('Client-IP')).subscribe((res: any) => {
    });
  }

  profile(){
    this.router.navigate(['/admin-profile'])
  }

}
