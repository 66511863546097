<main>
     <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
          <h2 class="page-title">Transaction</h2>
          <!-- <app-breadcrumb></app-breadcrumb> -->
          <div class="breadcrumbWidget">
               <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                    <li class="breadcrumb-item"><a routerLink="/transactions">Transactions</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Transaction etails</li>
               </ol>
          </div>
     </div>

     <div class="container-fluid">
          <div class="matCard">
               <div class="matCrdTitle centerItemVert justify-content-between">
                    <h3 class="matCardTitle">Payment details</h3>
                    <a routerLink="/manage-organization" class="btn btn-primary btnsmall">Back</a>
               </div>

               <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                         <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                         <div class="profileContentRight flex-grow-1">
                              <div class="proSection">
                                   <div class="row">
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <div class="detailsContent">
                                                       <label>Transaction Id :</label>
                                                       <span>{{transactionID}}</span>
                                                  </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <div class="detailsContent">
                                                       <label>Invoice Number :</label>
                                                       <span>{{invoiceNumber}}</span>
                                                  </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                 <div class="detailsContent">
                                                     <label>Customer Name :</label>
                                                     <span>{{payerName}}</span>
                                                 </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                         </div>
                                         <div class="row">
                                             <div class="col s3 m3 form-group">
                                                 <div class="detailsContent">
                                                     <label>Payment Method :</label>
                                                     <span>{{paymentMethod}}</span>
                                                 </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                         </div>
                                         <div class="row">
                                             <div class="col s3 m3 form-group">
                                                 <div class="detailsContent">
                                                     <label>Payment Type :</label>
                                                     <span>{{planType | uppercase}}</span>
                                                 </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                         </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <div class="detailsContent">
                                                       <label>Payment Date :</label>
                                                       <span>{{createdDate | date:'dd-MM-yyyy'}}</span>
                                                  </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>

                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <div class="detailsContent">
                                                       <label>Amount :</label>
                                                       <span>$ {{amount}}</span>
                                                  </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <div class="detailsContent">
                                                       <label>Paid For :</label>
                                                       <span>{{organizationName}}</span>
                                                  </div>
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>

                                        <hr>

                                        <!-- <div class="matCrdTitle centerItemVert justify-content-between">
                                    <h3 class="matCardTitle">Payment Summary    </h3>
                                 </div>

                                 <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                           <label>Invoice Number :</label>
                                        <span>{{invoiceNumber}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div> -->

                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <!-- <div class="detailsContent">
                                           <label>Invoice Date  :</label>
                                        <span>{{transData.transactionTime | date:'dd-MM-yyyy'}}</span>
                                        </div> -->
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <!-- <div class="detailsContent">
                                           <label>Paid :</label>
                                        <span>$ {{transData.amount}}</span>
                                        </div> -->
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <!-- <div class="detailsContent">
                                           <label>Paid For :</label>
                                        <span>{{organizationName}}</span>
                                        </div> -->
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>
                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <!-- <div class="detailsContent">
                                           <label>Payer Id :</label>
                                        <span>{{transData.payerId}}</span>
                                        </div> -->
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>


                                        <div class="row">
                                             <div class="col s3 m3 form-group">
                                                  <!-- <div class="detailsContent">
                                           <label>Payee MerchantId:</label>
                                        <span>{{transData.payeeMerchantId}}</span>
                                        </div> -->
                                             </div>
                                             <div class="col s9 m9 form-group">
                                             </div>
                                        </div>


                                        <!-- <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                           <label>Status :</label>
                                        <span>{{transData.status}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div> -->

                                   </div>
                              </div>

                         </div>
                    </div>
               </div>
          </div>
     </div>
</main>