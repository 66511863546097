<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Role </h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </div>
    </div> -->

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Customer Sayings</h3>
                <a (click)="goto()" class="btn btn-primary btnsmall">Back</a>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Customer Name </mat-label>
                                            <input maxlength="50" required formControlName="customerName" matInput placeholder="Customer Name">
                                            <mat-error *ngIf="form.get('customerName')?.touched  && form.get('customerName')?.errors?.required">
                                                Customer Name is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Customer Text </mat-label>
                                            <!-- <input maxlength="500" formControlName="quoteText" matInput placeholder="Quote Text"> -->
                                            <textarea required maxlength="500" formControlName="customerText" matInput placeholder="Customer Text" ></textarea>
                                            <mat-error *ngIf="form.get('customerText')?.touched  && form.get('customerText')?.errors?.required">
                                                Customer Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <!-- Customer Photo -->

                                    <div class="col s12 m12 form-group">
                                        <p>Customer Image</p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       
                                        <img [src]="croppedImage" style="height: 250px;width: 250px;" alt="Add Image">
                                       <br>
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload3" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload3" type="file"  accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                        </label>
                                        <!-- <input type="file" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)"> -->
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Alt</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="customerImageAlt"
                                                formControlName="customerImageAlt">
                                            <mat-error
                                                *ngIf="form.get('customerImageAlt')?.touched  && form.get('customerImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="customerImageDescription" matInput placeholder="Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('customerImageDescription')?.touched  && form.get('customerImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                   
                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>

<ng-template #uploadPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title>Select Image Area</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <div class="row">
         <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="20/20" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                            [roundCropper]="false">
                        </image-cropper>

                        <!-- <img [src]="croppedImage" /> -->
        </div>
  
      </mat-dialog-content>
    </div>
    <div class="proSection center">
        <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> close </button>
    </div>
  </ng-template>

