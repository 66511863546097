import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AdminUserService } from "../common/services/adminUserService/admin-user.service";
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ManageStaffService } from '../common/services/manage-staff.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { UserModel } from './UserAdminModel';
import { DialogService } from '../common/core-services/DialogService';
import { CommonService } from '../common/core-services/common.Service';
@Component({
  selector: 'app-manage-admins',
  templateUrl: './manage-admins.component.html',
  styleUrls: ['./manage-admins.component.css']
})
export class ManageAdminsComponent implements OnInit {

  @ViewChild('resetPassword', { read: TemplateRef }) updateCreditialTemplate: TemplateRef<any>;
  requestpages: any;
  form: FormGroup;
  metaData: any;
   private filterModel: FilterModel;
   usersData: UserModel[];
   usersDatas: UserModel[];
   hide = true;
   hide1 = true;
   classToggled = false;
  columnForm: FormGroup;
  resetForm: FormGroup;
  staffID:number;
   public toggleField() {
    this.classToggled = !this.classToggled;
  }
  columnsToDisplay: any;
   displayedColumns: Array<any> = [
    { displayName: 'NAME', key: 'adminName', isSort: true, class: '', width: '20%' },
    { displayName: 'USER NAME', key: 'username', isSort: true, class: '', width: '15%' },
    { displayName: 'EMAIL', key: 'email',isSort: true, class: '', width: '20%' },
    { displayName: 'PHONE NUMBER', key: 'contactNumber',isSort: true, class: '', width: '15%' },
    { displayName: 'ROLE', key: 'roleName',isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '20%' }

  ];
   actionButtons: Array<any> = [
    { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Reset Password', key: 'Reset', class:'fas fa-lock' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];

 
  constructor(
    private dialog: MatDialog,
    private adminUserService: AdminUserService,
    private manageStaffService: ManageStaffService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private commonService:CommonService
  ) { 
    this.filterModel = new FilterModel();
    this.usersData = new Array<UserModel>();
    this.usersDatas = new Array<UserModel>();
  }
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];

 
  currentUser:any
  ngOnInit(): void {
    this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
          this.currentUser =currentUser.userId
          console.log("USERR",currentUser);
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      adminName: [''],
      contactNumber: [''],
      email: [''],
      roleName: [''],
    });
    this.columnForm = this.formBuilder.group({
      name: true,
      userName: true,
      role: true,
      email: true,
      phoneNumber: true,
      userId:''
    })
    this.resetForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      confirmPassword: ['', Validators.required]
    });
    this.getUsersList(this.filterModel, '', '', );
    this.GetRolesList();
    // this.InitialGridCall()
    // this.AdminUserList();
    this.getCustomGrid(currentUser.userId);
  }
  getCustomGrid(id:any){
    this.adminUserService.GetAdminTableGrid(id).subscribe((res:any) =>{
      console.log("tableData",res.responseData)
      this.columnForm.patchValue({
      name: res.responseData?.adminName,
      userName: res.responseData?.userName,
      role: res.responseData?.role,
      email: res.responseData?.email,
      phoneNumber: res.responseData?.contactNumber,
      userId:res.responseData?.userId
      })
      this.onSelectColumn();
    })
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getUsersList(this.filterModel, '', '')
    this.GetRolesList();
  }
  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "adminName": this.form.controls.adminName.value,
      "contactNumber": this.form.controls.contactNumber.value,
      "role": this.form.controls.roleName.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getUsersList(this.requestpages, '', '')
    this.GetRolesList();
    //this.AdminUserList();
  }
  //metaData: any;
  // AdminUserList() {
  //   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
  //     if(res.status > 0){
  //       console.log(res);
  //       this.usersData = res.responseData;
  //       this.metaData = res.meta;
       
  //     }else{
  //       this.metaData = new Metadata();
  //     }
     
  //   });
  // }
  user:any;
  adminUserID:number;
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.adminUserID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
        case 'RESET':
        this.resetpassword(id);
        break;
      case 'DELETE':
        {
          this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
          console.log("USERR",currentUser);
          this.adminUserID = currentUser.userId;
          if(currentUser.userId == id){
            this.toaster.warning("You are not allowed to delete!", 'Warning');
          }else{
            this.dialogService
            .confirm(`Are you sure you want to delete this user?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.DeleteStaff(id);
              }
            });
          }
         
        }
        break;
        case 'DETAILS':
        this.infoCall(id);
        break;
      default:
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getUsersList(this.filterModel, '', '');
    }
  }
  //usersDatas:any;
  getUsersList(filterModel: any, tags: any, roleIds: any) {
    this.adminUserService.AllAdminUser(filterModel).subscribe((res: any) => {
      if(res.status==0){
        this.toaster.error(res.message);
      }
      if(res.status > 0){
        console.log(res);
        if(this.currentUser){
          this.usersData = res.responseData;
          this.usersDatas = this.usersData.filter((a:any)=>a.adminUserID != this.currentUser)
        }
        else{
          this.usersDatas = res.responseData;
        }
        
        this.metaData = res.meta;
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  infoCall(staffId: any){
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/view-staff/"], { queryParams: { id: staffId } });
  }
  editCall(staffId: any){
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/edit-staff/"], { queryParams: { id: staffId } });
  }
  
  deleteId:any;
  actionDialogPopup(templateRef: TemplateRef<any>,id:any) {
    this.dialog.open(templateRef,{
      data:id

    });
    this.deleteId = id;
  }
  
  resetpassword(id:any){
    console.log("resetid",id)
    this.resetForm.reset();
    this.staffID = id;
    this.dialog.open(this.updateCreditialTemplate);
    this.manageStaffService.GetStaffById(id).subscribe((res:any) => {
      console.log("STAFFFF",res.responseData)
      this.resetForm.patchValue({
        userName:res.responseData.username
      })
    })
  }

  adminPasswordObj:{}
  savePassword(){
    if(this.resetForm.invalid){
      return;
    }
    this.adminPasswordObj ={
      "adminUserID": this.staffID,
      "password": this.resetForm.value.password
    }
    console.log("ONSAVEPASSWORD",this.adminPasswordObj)
    this.manageStaffService.ResetPassword(this.adminPasswordObj).subscribe((res:any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
      this.resetForm.setErrors({ mismatch: true });
    }
  }
  
  // getting the form control elements
  get password(): AbstractControl {
    return this.resetForm.controls['password'];
  }
  
  get confirm_password(): AbstractControl {
    return this.resetForm.controls['confirmPassword'];
  }
  DeleteStaff(id: any) {
    this.manageStaffService.DeleteStaff(id).subscribe((res: any) => {

      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getUsersList(this.filterModel, '', '', );
        //this.dialog.closeAll();

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }


  reset() {
    this.form.reset();
    this.InitialGridCall();
   
    this.getUsersList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "adminName": "",
      "email": "",
      "contactNumber": "",
      "role": "",
      "columnName": "",
      "sortOrder": ""
    }
  }
  roles: any;
  GetRolesList() {
    this.manageStaffService.GetRolesDropdown().subscribe((res: any) => {
      this.roles = res.responseData;
    })
  }
  
  allowOnlyNumber(event:any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }
  unselectAll(){
  
    this.columnForm.patchValue({
      name: true,
      userName: false,
      role: false,
      email: false,
      phoneNumber: false,
    })
    this.onSelectColumn();
  }
  selectAll(){
    this.columnForm.patchValue({
      name: true,
      userName: true,
      role: true,
      email: true,
      phoneNumber: true
    })
    this.onSelectColumn();
  }
  onSelectColumn(){
  
    this.columnsToDisplay = [
      { displayName: 'NAME', key: 'adminName', isSort: true, class: '', width: '20%' },
    { displayName: 'USER NAME', key: 'username', isSort: true, class: '', width: '20%' },
    { displayName: 'EMAIL', key: 'email',isSort: true, class: '', width: '10%' },
    { displayName: 'PHONE NUMBER', key: 'contactNumber',isSort: true, class: '', width: '10%' },
    { displayName: 'ROLE', key: 'roleName',isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }
   
    ];
    this.displayedColumns = this.columnsToDisplay
      for(var i=0;i<this.displayedColumns.length;i++){
        if(this.columnForm.value.name == false){
          if(this.displayedColumns[i].key== 'adminName')
          this.displayedColumns.splice(i, 1);
        }
        if(this.columnForm.value.userName == false){
          if(this.displayedColumns[i].key== 'username')
          this.displayedColumns.splice(i, 1);
        }
        if(this.columnForm.value.email == false){
          if(this.displayedColumns[i].key== 'email')
          this.displayedColumns.splice(i, 1);
        }
        if(this.columnForm.value.phoneNumber == false){
          if(this.displayedColumns[i].key== 'contactNumber')
          this.displayedColumns.splice(i, 1);
        }
        if(this.columnForm.value.role == false){
          if(this.displayedColumns[i].key== 'roleName')
          this.displayedColumns.splice(i, 1);
        }
      }
      this.updateColumn();
  }
  updateObj:any
  updateColumn(){
    this.updateObj = {}
    this.updateObj = {
      "userId": this.columnForm.value.userId,
      "adminName": this.columnForm.value.name,
      "userName": this.columnForm.value.userName,
      "role": this.columnForm.value.role,
      "email": this.columnForm.value.email,
      "contactNumber": this.columnForm.value.phoneNumber
    }
    this.adminUserService.addAdminTableGrid(this.updateObj).subscribe((res:any) =>{
      console.log("After Update",this.updateObj)
    })
    console.log("After Update",this.updateObj)
  }
}


