import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { MasterServiceService } from 'src/app/common/services/master-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContentServiceService } from 'src/app/content-management/content-service.service';


@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.css']
})
export class FeatureComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  id: number = 0;
  private rteEle: RichTextEditorComponent;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) { }

  form: FormGroup;
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      featureID: 0,
      // title: ['', [Validators.required]],
      // subTitle: ['', [Validators.required]],
      featureImageName: [],
      featureImageDescription: ['', [Validators.required]],
      featureImageAlt: ['', [Validators.required]],
      featureTitle: ['', [Validators.required]],
      featureDescription: ['', [Validators.required]],
      featureDetailID: 0,
      featureQuote: ['', [Validators.required]],
      featureSummary: ['', [Validators.required]],

    });



    if (this.id > 0) {
      this.getFeatureDeatilsById();
    } else {
      this.getFeatureData();
    }
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;
    }


    // if (this.featureImage != '') {
    //   this.form.controls.featureImageName.setValue(this.featureImage);
    // } else {
    //   this.toaster.error('Upload images first', 'Error');
    //   return;
    // }
    if(this.featureImage != ''){
      this.form.controls.featureImageName.setValue(this.featureImage);
    }else{
      if(this.form.controls.featureDetailID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);

    if (this.id > 0) { //this.form.controls.featureID.value 
      // for update
      this.form.controls.featureDetailID.setValue(this.id);
      this.contentServiceService.updateFeaturedata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          //this.router.navigate(['/manage-staff']);
          this.handleTabChange.next({ tab: "Features", id: 0});
          //this.form.controls.featureDetailID.setValue(this.id);

        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    } else {

      // add feature
      console.log('featureID', this.featureID);
      this.contentServiceService.addFeaturedata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
          //this.router.navigate(['/manage-staff']);
          this.handleTabChange.next({ tab: "Features", id: 0});

        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
  }


  /// crop image
  imageChangedEvent: any = '';
  croppedImage: any = '../../../assets/img/banner-img.png';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
    this.dialog.open(templateRef, {
      data: data

    });

    this.fileChangeEvent(data);

  }
  finalUpload() {
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  uploadFile(event: any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();

    formData.append('files', file);
    this.AddFeatureImage(formData);


  }
  featureImage: string = '';
  AddFeatureImage(file: any) {
    this.contentServiceService.postDataFeatureImg(file).subscribe((res: any) => {
      console.log(res);
      if (res.body.status > 0) {
        this.featureImage = res.body.responseData.imageName;
        console.log(this.featureImage);
      }

    })
  }

  featureID = 0;
  getFeatureData() {
    this.contentServiceService.getFeaturedata().subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {

        //this.id = res.responseData['homePageID'];
        this.featureID = res.responseData['featureID'];
        this.form.patchValue({
          featureID: res.responseData['featureID'],
          title: res.responseData['title'],
          subTitle: res.responseData['subTitle'],


        });
      } else {
        this.toaster.warning("Please add record!", 'Warning');
      }


    });
  }

  // get data for update sathi
  getFeatureDeatilsById() {
    this.contentServiceService.getFeatureDetailsById(this.id).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        let features = res.responseData[0];
        console.log(features);
        let featuresDetails = res.responseData[1];
        this.croppedImage = featuresDetails.featureImageBase64;
        console.log(featuresDetails);

        this.form.patchValue({
          subTitle: features.subTitle,
          title: features.title,
          featureID: features.featureID,

          featureImageName: featuresDetails.featureImageName,
          featureImageDescription: featuresDetails.featureImageDescription,
          featureImageAlt: featuresDetails.featureImageAlt,
          featureTitle: featuresDetails.featureTitle,
          featureDescription: featuresDetails.featureDescription,
          featureDetailID: featuresDetails.featureDetailID,
          featureQuote: featuresDetails.featureQuote,
          featureSummary: featuresDetails.featureSummary,
        });

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }

  goto(){
    this.handleTabChange.emit({ tab: "Features", id: 0});
  }


   /// end
   flag = true;
   // new img cropper
   getCroppedImage(event:any){
     debugger
     this.croppedImage = event;
     this.imageFile = this.base64ToFile(
       event,
       'image.png',
     );
     this.uploadFile(this.imageFile);
       this.flag = false;
     //console.log(event);
   }
   isTheImageDeleted(event:any){
     console.log(event);
     if(event){
       this.flag = true;
       this.form.reset();
      //  this.getFeatureDeatilsById();
     }
   
   }

}
