import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute , Router} from '@angular/router';
import { SubscriptionPlanService } from "../../../common/services/subscriptionPlanService/subscription-plan.service";
import { ToastrService } from 'ngx-toastr';
// { Router, ActivatedRoute } from '@angular/router';
import { MasterDropdownService } from "../../../common/services/MasterDropdownServices/master-dropdown.service";
import { CommonService } from 'src/app/common/core-services/common.Service';
@Component({
  selector: 'app-edit-site-subscription',
  templateUrl: './edit-site-subscription.component.html',
  styleUrls: ['./edit-site-subscription.component.css']
})
export class EditSiteSubscriptionComponent implements OnInit {
  form: FormGroup;
  subscriptionID: number;
  data: string;
  planTypeDropdown:any;
  planTypeStautusDropdown:any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private subscriptionPlanService: SubscriptionPlanService,
    private toaster: ToastrService,
    private router: Router,
    private masterDropdownService :MasterDropdownService,
    private commonService: CommonService
  ) { }
  ngOnInit(): void {
    //this.subscriptionID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.subscriptionID = params.id==undefined?null:params.id;
    });
    this.form = this.formBuilder.group({
      subscriptionID: ['', Validators.required],
      planID: [0, Validators.required],
      planName: ['', [Validators.required, Validators.pattern('^[a-zA-Z \-\]+')]], 
      price: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      planStatusID: [0, Validators.required],
      numberOfUserAllowed: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      description: [null, Validators.required],
      isPublic: [null, Validators.required],
    });



    this.masterDropdown();
    this.GetSubscriptionPlanById();
  }

  GetSubscriptionPlanById() {
    debugger;
    this.subscriptionPlanService.GetSubscriptionPlanById(this.subscriptionID).subscribe((res: any) => {
      this.form.patchValue({
        subscriptionID: res['subscriptionID'],
        planID: res['planID'],
        planName: res['planName'],
        price: res['price'],
        planStatusID: res['planStatusID'],
        numberOfUserAllowed: res['numberOfUserAllowed'],
        description: res['description'],
        isPublic: res['isPublic'],
      });
      this.data = res;
      console.log("GetSubscriptionPlanById", res)
    });
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    else if(Number.isInteger(this.form.value.planID) == false){
      console.log("planID", this.form.value.planID)
      this.toaster.error("Check Plan");
      return;
    }
    else
    {
    this.updateSubscriptionPlan();
    // this.form.reset();
    // this.form.controls.planID.clearValidators();
    // this.form.controls.planName.clearValidators();
    // this.form.controls.price.clearValidators();
    // this.form.controls.planStatusID.clearValidators();
    // this.form.controls.numberOfUserAllowed.clearValidators();
    // this.form.controls.description.clearValidators();
    // this.form.controls.isPublic.clearValidators();
  }
    //   {
    //     'planName':null
    //   }
    //  );
    //this.form.controls.planName.setValue();
    //   this.form.controls.planID.clearAsyncValidators();
    //  this.form.controls.planName.clearAsyncValidators();
    //this.form.controls.planName.re
    //  this.form.controls.price.clearValidators();
    //  this.form.controls.planStatusID.clearValidators();
    //  this.form.controls.numberOfUserAllowed.clearValidators();
    //this.form.controls.planName.value.clearValidators();
    //  this.form.clearValidators(); 
    // this.form.get('planID').clearValidators();
    // this.form.get('planID').updateValueAndValidity();
    // this.form.get('price').clearValidators();
    // this.form.get('price').updateValueAndValidity();
    // this.form.get('planName').clearValidators();
    // this.form.get('planName').updateValueAndValidity();
    //this.form.reset();
    //this.ngOnInit();
    // this.form.markAsUntouched();
    //this.form.clearValidators();
    //this.isSubmitted=false;
    //this.form.controls.planName.clearValidators();
  }

  updateSubscriptionPlan() {
    this.subscriptionPlanService.UpdateSubscriptionPlan(this.form.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/subscription-plan'])
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }
  allowOnlyNumber(event: any) {
    event.target.value = event.target.value.replace('-','')
    if (this.commonService.allowOnlyNumber(event) == true) {
    console.log("allowOnlyNumber", event.target.value)
    }
    else{
      console.log("allowOnlyNumber", event.target.value)
      this.commonService.allowOnlyNumber(event);
    }

  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
}