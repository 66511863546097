import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MasterDropdownService } from '../../common/services/MasterDropdownServices/master-dropdown.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionPlanService } from "../../common/services/subscriptionPlanService/subscription-plan.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SiteSubscriptionModel } from './SiteSubscriptionModel';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { DialogService } from 'src/app/common/core-services/DialogService';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatPaginator } from '@angular/material/paginator';
import { merge } from 'rxjs';
@Component({
  selector: 'app-site-subscription-plan',
  templateUrl: './site-subscription-plan.component.html',
  styleUrls: ['./site-subscription-plan.component.css']
})
export class SiteSubscriptionPlanComponent implements OnInit, AfterViewInit {
  planTypeDropdown: any;
  planTypeStautusDropdown: any;
  // classToggled = false;
  // dataSource: any;
  // requestpages: any;
  // form: FormGroup;
  // Id: number
  // public toggleField() {
  //   this.classToggled = !this.classToggled;
  // }
  // @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  // title = 'Excel';
  // @ViewChild(MatSort)
  // sort!: MatSort;
  // constructor(
  //   private dialog: MatDialog,
  //   private subscriptionPlanService: SubscriptionPlanService,
  //   private formBuilder: FormBuilder,
  //   private activatedRoute: ActivatedRoute,
  //   private router: Router,
  //   private toaster: ToastrService,
  //   private masterDropdownService : MasterDropdownService
  // ) { }
  // displayedColumns: string[] = ['planname', 'plantype', 'status', 'pricing', 'actions'];

  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;
  // }
  // ngOnInit(): void {
  //   this.masterDropdown();
  //   this.Id = this.activatedRoute.snapshot.params['Id'];
  //   this.form = this.formBuilder.group({
  //     planName: [''],
  //     planTypeName: [''],

  //   });
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "planType": "",
  //     "planName": "",
  //     "sortOrder": "string"
  //   }
  //   this.SubscriptionPlanList();
  // }

  // actionDialogPopup(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  // }

  // SubscriptionPlanList() {
  //   this.subscriptionPlanService.AllSubscriptionPlan(this.requestpages).subscribe((res: any) => {
  //     this.dataSource = res;
  //     console.log(this.dataSource)
  //   });
  // }

  // onSubmit() {
  //   console.log(this.Id);
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "planName": this.form.controls.planName.value,
  //     "planType": this.form.controls.planTypeName.value,
  //     "sortOrder": "string"
  //   }
  //   this.SubscriptionPlanList()
  //   this.router.navigate(['/subscription-plan'])
  // }

  // reset() {
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "planType": "",
  //     "planName": "",
  //     "sortOrder": "string"
  //   }
  //   this.SubscriptionPlanList();
  //   this.form.reset();
  // }

  // ExportTOExcel() {
  //   const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.TABLE.nativeElement);
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, 'ScoreSheet.xlsx');
  // }

  // DeleteSubscription(id: any) {
  //   this.subscriptionPlanService.DeleteSubscriptionPlan(id).subscribe((res: any) => {
  //     if (res.status > 0) {
  //       this.toaster.error(res.message);
  //       this.SubscriptionPlanList()
  //     }
  //     else {
  //       this.toaster.error(res.message);
  //     }
  //   });
  // }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  siteSubscriptionListData: SiteSubscriptionModel[];

  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayCol: string[] = ['subscriptionID','planName','planTypeName','planStatusName','price','numberOfUserAllowed','actions'];
  displayedColumns: Array<any> = [
    { displayName: 'Plan ID', key: 'subscriptionID', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Name', key: 'planName', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Type', key: 'planTypeName', isSort: true, class: '', width: '20%' },
    { displayName: 'Status', key: 'planStatusName', isSort: true, class: '', width: '20%' },
    { displayName: 'price', key: 'price', isSort: true, class: '', width: '20%' },
    { displayName: 'Users', key: 'numberOfUserAllowed', isSort: true, class: '', width: '20%' },
    //{ displayName: 'Validity Days', key: 'noOfDays', isSort: false, class: '', width: '20%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];


  constructor(
    private dialog: MatDialog,
    private subscriptionPlanService: SubscriptionPlanService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private masterDropdownService: MasterDropdownService,
    private commonService:CommonService,
    private _liveAnnouncer: LiveAnnouncer
  ) {
    this.filterModel = new FilterModel();
    this.siteSubscriptionListData = new Array<SiteSubscriptionModel>();
  }


  ngOnInit(): void {
    this.metaData = new Metadata();
    this.masterDropdown();
    this.form = this.formBuilder.group({
      planName: [''],
      planTypeName: [''],

    });
    this.getsubscriptionPlanList(this.filterModel);

    // this.requestpages = {
    //   "pageSize": 10,
    //   "pageNumber": 1,
    //   "columnName": "",
    //   "planName": "23",
    //   "planType": this.form.controls.planTypeName.value,
    //   "sortOrder": ""
    // }
    // var key = Object.keys(this.requestpages)[3];
    // console.log("this.requestpages[3]", this.requestpages[key])
    // console.log("this.requestpages", this.requestpages['planName'])
  }
  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .subscribe(() => {
        const changeState = {
          sort: this.sort.active || '' ,
          order: this.sort.direction || '',
          pageNumber: (this.paginator.pageIndex + 1)
        }
        this.onPageOrSortChange(changeState);
      })
  }

  onPageOrSortChange(changeState?: any) {
    debugger;
    console.log("onPageOrSortChange", changeState)
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getsubscriptionPlanList(this.filterModel)
    console.log("onPageOrSortChange this.filterModel ", this.filterModel)
  }

  onSubmit() {

    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "columnName": "",
      "planName": this.form.controls.planName.value,
      "planType": this.form.controls.planTypeName.value,
      "sortOrder": ""
    }

    console.log(this.requestpages);
    this.getsubscriptionPlanList(this.requestpages)
  }
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.subscriptionID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'DELETE':
        {
          if(id != 1) {
            this.dialogService
            .confirm(`Are you sure to delete this subscription plan?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.DeleteSubscription(id);
              }
            });
          } else {
            this.toaster.error("Unable to delete!");
          }
        }
          
        break;
      // case 'INFO':
      //   this.infoCall(id);
      //   break;
      // default:
      //   break;
    }
  }
  onActionClick(action: any, data: any) {
    const id = data && data.subscriptionID;
    switch ((action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'DELETE':
        {
          if(id != 1) {
            this.dialogService
            .confirm(`Are you sure to delete this subscription plan?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.DeleteSubscription(id);
              }
            });
          } else {
            this.toaster.error("Unable to delete!");
          }
        }
          
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getsubscriptionPlanList(this.filterModel);
    }
  }
  dataSource: any;
  getsubscriptionPlanList(filterModel: any) {

    // Jitin - Added to include planName and planType option in search params
    var planName = "";
    var planType = "";

    if(this.requestpages != undefined){
      console.log("this.requestpages != 'undefined'", this.requestpages)

      planName =  this.requestpages['planName'];
      planType = this.requestpages['planType']
    }

    let searchObj = {
      "pageNumber": this.filterModel.pageNumber,//
      "pageSize": this.filterModel.pageSize,//
      "sortOrder": this.filterModel.sortOrder,//
      "sortColumn": this.filterModel.sortColumn,
      "columnName": this.filterModel.sortColumn,//
      "searchText": this.filterModel.searchText,
      "planName": planName,
      "planType": planType
    }
    
    this.subscriptionPlanService.AllSubscriptionPlan(searchObj).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.siteSubscriptionListData = res.responseData;
        this.dataSource = new MatTableDataSource(res.responseData);
        this.metaData = res.meta;

      } else {
        this.metaData = new Metadata();
        this.toaster.error(res.message);
      }
      console.log(this.siteSubscriptionListData)
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }


  editCall(subscriptionID: any) {
    this.router.navigate(["/edit-subscription/"], { queryParams: { id: subscriptionID } });
  }

  DeleteSubscription(id: any) {
    this.subscriptionPlanService.DeleteSubscriptionPlan(id).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.error(res.message);
        this.InitialGridCall();

        this.getsubscriptionPlanList(this.requestpages)
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getsubscriptionPlanList(this.requestpages)
  }

  // announceSortChange(sortState: Sort) {
  //   // This example uses English messages. If your application supports
  //   // multiple language, you would internationalize these strings.
  //   // Furthermore, you can customize the message to add additional
  //   // details about the values being sorted.
  //   if (sortState.direction) {
  //     this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
  //   } else {
  //     this._liveAnnouncer.announce('Sorting cleared');
  //   }
  // }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "columnName": "",
      "planType": "",
      "planName": "",
      "sortOrder": ""
    }
  }
  allowOnlyNumber(event:any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event:any) {
    this.commonService.allowAlphabetOnly(event);
  }
}

