import { StickyDirection } from '@angular/cdk/table';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageOrganizationService } from "../../common/services/manage-organization.service";
import { DatePipe } from '@angular/common';
import {MatTableModule} from '@angular/material/table';

@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.css']
})
// amount: 1023
// dueDate: "2022-02-25T06:21:44.1349724"
// emailSubscriptionPlanName: "fdghjdfgj"
// invoiceNumber: "446255"
// orgName: "Silver Bells Organization"
// organizationID: 4
// payerName: "juan"
// paymentMethod: "stripe"
// paymentType: "site"
// smsSubscriptionPlanName: "Quaterly"
// subscriptionPlanName: "adfadf"
// transactionID: 110

export class OrganizationDetailsComponent implements OnInit {
  collection :any;
  //displayedColumns: string[] = ['srno', 'header','planName', 'payment','date', 'amount'];
  displayedColumns: string[] = ['invoiceNumber', 'payerName','paymentMethod', 'paymentType','paymentDate', 'amount','button'];
  organizationID: number;
  organizationName: string;
  organizationType:string;
  subDomainName: string;
  maximumStudentAllowed: string;
  userName: string;
  contactNumber: string;
  contactPersonFullName: string;
  email: string;
  planName: string;
  price: any;
  planStatusName: string;
  planTypeName: string;
  smsPlanName: string;
  smsPrice: any;
  smsPackID: number;
  statusName: string;
  emailPlanName: string;
  emailPrice: any;
  emailPackID: number;
  organizationShortName: any;
  subscriptionStartDate: any;
  subscriptionEndDate: any;
  noOfDays: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private manageOrganizationService: ManageOrganizationService,
    public datepipe: DatePipe,
    private router: Router,
  ) { }

  ngOnInit(): void {
    //this.organizationID = this.activatedRoute.snapshot.params.Id
    this.activatedRoute.queryParams.subscribe(params => {
      this.organizationID = params.id==undefined?null:params.id;
      this.collection = ELEMENT_DATA;
    });
   
    this.GetOrgaanizatioDetailsById();
    this.GetOrgaanizatioTrnasactionDetailsById();
  }
  transactionData:any;
  GetOrgaanizatioTrnasactionDetailsById() {
    this.manageOrganizationService.GetOrganizationTransactionDetailsById(this.organizationID).subscribe((res: any) => {
      console.log("res.responseData", res.responseData);
    this.transactionData = res.responseData
    console.log("transaction", this.transactionData)
    });
  }


  GetOrgaanizatioDetailsById() {
    this.subscriptionStartDate = new Date();
    this.subscriptionEndDate = new Date();
    this.manageOrganizationService.GetOrganizationDetailsById(this.organizationID).subscribe((res: any) => {
      console.log("res.responseData", res.responseData);
      this.organizationName = res.responseData.organizationName
      this.organizationType = res.responseData.organizationType
      this.subDomainName = res.responseData.subDomainName
      this.maximumStudentAllowed = res.responseData.maximumStudentAllowed
      this.userName = res.responseData.userName
      this.contactNumber = res.responseData.contactNumber
      this.contactPersonFullName = res.responseData.contactPersonFullName
      this.email = res.responseData.email
      this.planName = res.responseData.planName
      this.price = res.responseData.price
      this.planStatusName = res.responseData.planStatusName
      this.planTypeName = res.responseData.planTypeName
      this.smsPlanName = res.responseData.smsPlanName
      this.smsPrice = res.responseData.smsPrice
      this.smsPackID = res.responseData.smsPackID
      this.statusName = res.responseData.statusName
      this.emailPlanName = res.responseData.emailPlanName
      this.emailPrice = res.responseData.emailPrice
      this.emailPackID = res.responseData.emailPackID
      this.noOfDays = res.responseData.noOfDays
      this.organizationShortName = res.responseData.organizationShortName
      this.subscriptionStartDate = this.datepipe.transform(res.responseData.subscriptionStartDate, 'dd/MM/yyyy');
      this.subscriptionEndDate = this.datepipe.transform(res.responseData.subscriptionEndDate, 'dd/MM/yyyy');
      // console.log(this.subscriptionStartDate);
      // console.log(this.subscriptionStartDate);
      
    });

  }
  infoCall(transactionID: any) {
    //this.router.navigate(['/view-staff/']);
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/org-transaction-details/"], { queryParams: { id: transactionID } })
    );
  
    window.open(url, '_blank');
    // console.log("iddd", transactionID);
    // window.open(url, "_blank");
    //this.router.navigate(["/org-transaction-details/"], { queryParams: { id: transactionID } });
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([`/org-transaction-details/${transactionID}`])
    // );
  
    // window.open(url, '_blank');
  }

}
const ELEMENT_DATA: any[] = [
  { srno: 4526, header: 'Jaun',planName:'EmailsSubs',  payment: 'Stripe',date: '06/01/2022',amount:'$15',button:''},
];