import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { MasterDropdownService } from 'src/app/common/services/MasterDropdownServices/master-dropdown.service';
import { SMSSubscriptionPlanService } from 'src/app/common/services/smsSubscriptionPlanService/sms-subscription-plan.service';

@Component({
  selector: 'app-add-sms-subscription',
  templateUrl: './add-sms-subscription.component.html',
  styleUrls: ['./add-sms-subscription.component.css']
})
export class AddSmsSubscriptionComponent implements OnInit {

  form: FormGroup;
  subscriptionID: number;
  data: string;
  planTypeDropdown:any;
  planTypeStautusDropdown:any;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private smsSubscriptionPlanService: SMSSubscriptionPlanService,
    private toaster: ToastrService,
    private router: Router,
    private masterDropdownService :MasterDropdownService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      smsSubscriptionPlanID: [0],
      smsPlanName: ['', Validators.required], 
      smsPrice: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      limit: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      smsPlanStatusID: ['', Validators.required],
      smsPackID: ['', Validators.required],
      description: ['', Validators.required],
      isTwilio: [false], 
    });
    this.masterDropdown();
  }

  onSubmit() {
    debugger
    if (this.form.invalid) {
      return;
    }
    this.addSMSSubscriptionPlan();
    //this.form.reset();
  }

  addSMSSubscriptionPlan() {
    console.log("this.form.value", this.form.value)
    this.form.value.limit = Number(this.form.value.limit)
    this.smsSubscriptionPlanService.AddSMSSubscriptionPlan(this.form.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.router.navigate(['/sms-subs'])
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

}
