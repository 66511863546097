import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { adminUsers, environment, roles } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageStaffService {

  constructor(private http : HttpClient) { }

  AddStaff(data:any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.addStaffAPI}`,data);
  }

GetStaffById(Id:any) {
     return this.http.get(`${environment.baseUrl}${adminUsers.getStaffByIdAPI}${Id}`);
  }

  UpdateStaff(data:any) {
    
    return this.http.post(`${environment.baseUrl}${adminUsers.updateStaffAPI}`,data);
  }

  

  DeleteStaff(Id:any) {
   
    return this.http.get(`${environment.baseUrl}${adminUsers.deleteStaffAPI}`+'?id='+Id);
  }

  GetRolesDropdown() {
    return this.http.get(`${environment.baseUrl}${roles.getRolesDropdown}`);
  }

  ResetPassword(data:any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.resetPasswordAPI}`,data);
  }

  ChangePassword(data:any) {
    return this.http.post(`${environment.baseUrl}${adminUsers.changePasswordAPI}`,data);
  }
}

