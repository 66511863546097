<main>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Terms of Use</h3>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title <span class="req">*</span></mat-label>
                                            <input maxlength="500" formControlName="termsOfUseTitle" matInput placeholder="Title">
                                            <mat-error *ngIf="form.get('termsOfUseTitle')?.touched  && form.get('termsOfUseTitle')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <p>Description</p>
                                            <ejs-richtexteditor
                                            [toolbarSettings]='tools'
                                              #textEditorValue 
                                              formControlName="termsOfUseDescription"
                                               title="Add Description " >
                                            </ejs-richtexteditor>
                                    </div>
                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>


