import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddAdminComponent } from '../manage-admins/add-admin/add-admin.component';
import { AddRoleAndPermissionComponent } from '../role-and-permissions/add-role-and-permission/add-role-and-permission.component';
import { AboutUsComponent } from './content/about-us/about-us.component';
import { ContactUsContentComponent } from './content/contact-us-content/contact-us-content.component';
import { CustomerSayComponent } from './content/customerManage/customer-say/customer-say.component';
import { ViewCustomerComponent } from './content/customerManage/view-customer/view-customer.component';
import { FeatureComponent } from './content/FeatureManage/feature/feature.component';
import { ViewFeatureComponent } from './content/FeatureManage/view-feature/view-feature.component';
import { HomePageContentComponent } from './content/home-page-content/home-page-content.component';
import { ObeComponent } from './content/ObeManage/obe/obe.component';
import { ViewObeComponent } from './content/ObeManage/view-obe/view-obe.component';
import { PartnersComponent } from './content/partnersManage/partners/partners.component';
import { ViewPartnerComponent } from './content/partnersManage/view-partner/view-partner.component';
import { PolicyComponent } from './content/policy/policy.component';
import { PriceComponent } from './content/PriceManage/price/price.component';
import { QuoteComponent } from './content/QuoteManage/Quote/quote.component';
import { ViewQuoteComponent } from './content/QuoteManage/view-quote/view-quote.component';
import { RequestDemoComponent } from './content/request-demo/request-demo.component';
import { SocailmediaComponent } from './content/socailmedia/socailmedia.component';
import { TermsComponent } from './content/terms/terms.component';


@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContentManagementComponent implements AfterViewInit {

  @ViewChild('tabContent', { read: ViewContainerRef })

  //userTabs: any;
  id: number = 0;
  selectedIndex: number = 0; //'Add Quote', 'Add Feature', 'Add OBE',  'Add Partner',  'Add Customer Sayings', 
  tabs = [
    'Home', 
    'Quotes', 
    'Features', 
    'Request Demo', 
    'Pricing', 
    'Contact Us', 
    'About Us', 
    'Social Media', 
    'Partners', 
    'Customer Sayings', 
    'OBE', 
    'Privacy Policy', 
    'Terms of Use'];
  constructor(private tabContent: ViewContainerRef, private cfr: ComponentFactoryResolver, private activatedRoute: ActivatedRoute) {
    //this.userTabs = ["AddHome", "AddQuote","ViewQuote","AddFeature","ViewFeature"]
  }

  // ngOnInit(): void {
  //   this.activatedRoute.queryParams.subscribe(params => {
  //     this.staffId = params.id==undefined?null:params.id;

  //   });
  // }
  ngAfterViewInit(): void {

    this.loadChild("Home");
  }


  loadComponent(eventType: any): any {
    this.loadChild(eventType.tab.textLabel);
  }
  loadChild(childName: string) {

    if (this.tempId > 0) {
      this.id = this.tempId
    } else {
      this.id = 0;
    }
    this.tempId = 0;
    let factory: any;
    if (childName == "Home")
      factory = this.cfr.resolveComponentFactory(HomePageContentComponent);
    else if (childName == "Add Quote")
      factory = this.cfr.resolveComponentFactory(QuoteComponent);
    else if (childName == "Quotes")
      factory = this.cfr.resolveComponentFactory(ViewQuoteComponent);
    else if (childName == "Add Feature")
      factory = this.cfr.resolveComponentFactory(FeatureComponent);
    else if (childName == "Features")
      factory = this.cfr.resolveComponentFactory(ViewFeatureComponent);
    else if (childName == "Pricing")
      factory = this.cfr.resolveComponentFactory(PriceComponent);
    else if (childName == "Contact Us")
      factory = this.cfr.resolveComponentFactory(ContactUsContentComponent);
    else if (childName == "Privacy Policy")
      factory = this.cfr.resolveComponentFactory(PolicyComponent);
    else if (childName == "Terms of Use")
      factory = this.cfr.resolveComponentFactory(TermsComponent);
    else if (childName == "Add OBE")
      factory = this.cfr.resolveComponentFactory(ObeComponent);
    else if (childName == "OBE")
      factory = this.cfr.resolveComponentFactory(ViewObeComponent);
    else if (childName == "About Us")
      factory = this.cfr.resolveComponentFactory(AboutUsComponent);
    else if (childName == "Add Customer Sayings")
      factory = this.cfr.resolveComponentFactory(CustomerSayComponent);
    else if (childName == "Customer Sayings")
      factory = this.cfr.resolveComponentFactory(ViewCustomerComponent);
    else if (childName == "Add Partner")
      factory = this.cfr.resolveComponentFactory(PartnersComponent);
    else if (childName == "Partners")
      factory = this.cfr.resolveComponentFactory(ViewPartnerComponent);
    else if (childName == "Request Demo")
      factory = this.cfr.resolveComponentFactory(RequestDemoComponent);
      else if (childName == "Social Media")
      factory = this.cfr.resolveComponentFactory(SocailmediaComponent);
    this.tabContent.clear();
    let comp: ComponentRef<HomePageContentComponent> = this.tabContent.createComponent(factory);
    comp.instance.id = this.id;
    comp.instance.handleTabChange.subscribe(this.handleTabChange.bind(this))
  }

  tempId = 0;
  handleTabChange(data: any): any {
    this.tempId = data.id;
    this.selectedIndex = data.tab == "AddHome" ? 1 : data.tab == "ViewQuote" ? 2 : 0;
    debugger
    this.loadChild(data.tab);
  }

  navigation(navi: any) {
    this.loadChild(navi);
    this.id = 0;
  }

}
