import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';

import { DialogService } from 'src/app/common/core-services/DialogService';
import { FeatureModel } from './FeatureModel';

@Component({
  selector: 'app-view-feature',
  templateUrl: './view-feature.component.html',
  styleUrls: ['./view-feature.component.css']
})
export class ViewFeatureComponent implements OnInit {


  requestpages: any;
  form: FormGroup;
  form1: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  featureData: FeatureModel[];
  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
   }
 
   displayedColumns: Array<any> = [
     { displayName: 'FEATURE NAME', key: 'featureTitle', isSort: true, class: '', width: '30%' },
     { displayName: 'FEATURE DESCRIPTION', key: 'featureDescriptionText', isSort: false, class: '', width: '60%' },
     { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }
 
   ];
    actionButtons: Array<any> = [
     //{ displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
     { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
     { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
   ];
  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private contentService: ContentServiceService,) 
    { 
      this.filterModel = new FilterModel();
      this.featureData = new Array<FeatureModel>();
    }

  ngOnInit(): void {
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      searchValue: [''],
     
    });

    this.form1 = this.formBuilder.group({ 
      featureID: [2],
      title: ['', Validators.required],
      subTitle: ['', Validators.required],
    });

    this.getFeatureList(this.filterModel, '', '', );
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getFeatureList(this.filterModel, '', '')
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }
  arr:any[];
  getFeatureList(filterModel: any, tags: any, roleIds: any) {
    this.contentService.getFeatureListdata(filterModel).subscribe((res: any) => {
      if(res.status > 0){
        this.form1.patchValue({
          title: res.responseData[0].title,
          subTitle: res.responseData[0].subTitle
        });
        console.log(res);
        this.arr = [];
        //this.featureData = res.responseData;
        this.metaData = res.meta;
        
        res.responseData.forEach((item:any) => {

          item.featureDescriptionText = item.featureDescriptionText.substring(0, 300) +" ...";
          this.arr.push(item);
        });
        this.featureData = this.arr;
        console.log("Filter data",this.arr);
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.featureDetailID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        //this.editCall(id);
        this.handleTabChange.next({ tab: "Add Feature", id: id});
        break;
      case 'DELETE':
        {
          
          this.dialogService
            .confirm(`Are you sure you want to delete this record?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteFeature(id);
              }
            });
        }
        break;
        case 'DETAILS':
        //this.infoCall(id);
        break;
      default:
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getFeatureList(this.filterModel, '', '');
    }
  }

  onSubmit1(){
    if(this.form.valid){
      this.contentService.updateFeatureBasicdata(this.form1.value).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message, 'Success');
        } else {
          this.toaster.error(res.message, 'Error');
        }
      });
    }
  }

  // searching
  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": this.form.controls.searchValue.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getFeatureList(this.requestpages, '', '')
    //this.AdminUserList();
  }
  reset() {
    this.form.reset();
    this.InitialGridCall();
   
    this.getFeatureList(this.requestpages, '', '')
  }



  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": "",
      "columnName": "",
      "sortOrder": ""
    }
  }
  // end

  deleteFeature(id:any){
    this.contentService.deleteFeatureDetailsById(id).subscribe((res: any) => {
      if(res.status > 0){
        console.log(res);
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getFeatureList(this.filterModel, '', '', );
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  goto(){
    this.handleTabChange.emit({ tab: "Add Feature", id: 0});
  }

}
