import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ManageOrganizationService } from 'src/app/common/services/manage-organization.service';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})

export class SettingComponent implements OnInit {
  form !: FormGroup;

  checked: boolean;
  accountSID = new FormControl();
  authenticationToken = new FormControl();
  phoneNumber = new FormControl();
  senderEmail = new FormControl();
  sendGridKey = new FormControl();
  senderName = new FormControl();
  //isDemoAllowed :Boolean;
  @ViewChild('phone') phone: any;
  constructor(
    private formbuilder:FormBuilder,
    private manageStaffService:ManageStaffService,
    private router: Router,
    private manageOrganizationService: ManageOrganizationService,
    private toaster: ToastrService,
  ) { }

  ngOnInit(): void {
    
    this.form = this.formbuilder.group({
      isDemoAllowed :['',Validators.required],
      authenticationToken: ['',Validators.required],
      accountSID: ['',Validators.required],
      phoneNumber: ['',Validators.required],
      senderName: ['',Validators.required],
      senderEmail: ['',[Validators.required,Validators.email]],
      sendGridKey: ['',Validators.required]

      
  }),
    this.manageOrganizationService.GetAllowedNotAllowedDemoFromMasterTable().subscribe((res:any)=>{
      this.checked = res.responseData.isDemoAllowed
      this.accountSID = res.responseData.accountSID
      this.authenticationToken = res.responseData.authenticationToken
      this.phoneNumber = res.responseData.phoneNumber
      this.senderName = res.responseData.senderName
      this.sendGridKey = res.responseData.sendGridKey
      this.senderEmail = res.responseData.senderEmail
      console.log(" this.checked",  this.accountSID);
    });
  }
obj:any;
  onChange($event: MatSlideToggleChange) {
  
   this.checked=$event.checked
   console.log("MyEnent", this.checked);
  
    // this.manageOrganizationService.SetAllowedNotAllowedDemoFromMasterTable(this.obj).subscribe((res: any) => {
    // this.toaster.success(res.message);
    // });
  }

// onSubmit(){
//   this.updateSetting();
// }

  updateSetting(){debugger;

    this.obj={
      "isDemoAllowed" : this.checked,
      "authenticationToken":this.form.controls.authenticationToken.value,
      "accountSID":this.form.controls.accountSID.value,
      "senderName":this.form.controls.senderName.value,
      "senderEmail":this.form.controls.senderEmail.value,
      "phoneNumber":this.form.controls.phoneNumber.value,
      "sendGridKey":this.form.controls.sendGridKey.value  
    }
    // var reqModel={
    //   AuthenticationToken:this.obj.AuthenticationToken,
    //   AccountSID:this.obj.AccountSID,
    //   SenderName:this.obj.SenderName,
    //   SenderEmail:this.obj.SenderEmail,
    //   PhoneNumber:this.obj.PhoneNumber,
    //   SendgridKey:this.obj.SendgridKey  
    //   }
    // this.form.value.AuthenticationToken = this.obj.AuthenticationToken;
    // this.form.value.AccountSID = this.obj.AccountSID;
    // this.form.value.PhoneNumber = this.obj.PhoneNumber;
    // this.form.value.SenderName = this.obj.SenderName;
    // this.form.value.SenderEmail = this.obj.SenderEmail;
    // this.form.value.SendgridKey = this.obj.SendgridKey;
    
    this.manageOrganizationService.SetAllowedNotAllowedDemoFromMasterTable(this.obj).subscribe((res: any) =>{
    console.log("update", res);
    console.log(res);
    if(res.status > 0){
      this.toaster.success(res.message, 'Success');
    }else{
      this.toaster.error(res.message, 'Error');
    }
  
    })
  }
}

