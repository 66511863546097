import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterModel, FilterPageModel, Metadata } from 'src/app/common/MasterModel/masterModel';
import { SiteSMSSubscriptionModel } from './SiteSMSSubscriptionModel';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { MasterDropdownService } from 'src/app/common/services/MasterDropdownServices/master-dropdown.service';
import { DialogService } from 'src/app/common/core-services/DialogService';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SMSSubscriptionPlanService } from 'src/app/common/services/smsSubscriptionPlanService/sms-subscription-plan.service';
import { MatPaginator } from '@angular/material/paginator';
import { filter } from 'rxjs/operators';
import * as XLSX from 'xlsx';


export interface PeriodicElement {
  planname: string;
  smsPack: string;
  status: string;
  pricing: string
  actions: any;
}

export class paginationAndSearchModel {
  totalRecords: number = 0;
  currentPage: number = 0;
  indexPage: number = 0;
  pageSize: number = 5;
  totalPages: number = 0;
  pageNumber: number = 1;
  sortColumn: string = '';
  sortOrder: string = '';
  searchText: string = '';
  columnName: string = '';
  planName: string = '';
  planType: string = '';
}

const ELEMENT_DATA: PeriodicElement[] = [
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
  { planname: 'ABC', smsPack: '1000/Day', status: 'Active', pricing: '65', actions: 'Active' },
];



@Component({
  selector: 'app-sms-subscription',
  templateUrl: './sms-subscription.component.html',
  styleUrls: ['./sms-subscription.component.css']
})
export class SmsSubscriptionComponent implements OnInit {

  classToggled = false;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  smsFilterPageP: FilterPageModel;
  private smsFilterPage: FilterPageModel;
  siteSMSSubscriptionListData: SiteSMSSubscriptionModel[];
  planTypeDropdown: any = [];
  planTypeStautusDropdown: any = [];
  dataSourceFilter: any = [];
  searchObj: { totalRecords: number; currentPage: number; indexPage: number; pageNumber: number; totalPages: number; pageSize: number; sortOrder: string; sortColumn: string; columnName: string; searchText: string; planName: string; planType: string; };

  public toggleField() {
    this.classToggled = !this.classToggled;
  }

  //@ViewChild(MatSort) sort!: MatSort;
  //@ViewChild(MatPaginator) paginator: MatPaginator;


  //displayedColumns: string[] = ['smsSubscriptionPlanID', 'smsPlanName', 'smsPackID', 'limit', 'smsPlanStatusID', 'smsPrice', 'description', 'actions'];
  // dataSource = ELEMENT_DATA;
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  displayedColumns: Array<any> = [
    { displayName: 'Plan ID', key: 'subscriptionID', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Name', key: 'planName', isSort: true, class: '', width: '20%' },
    { displayName: 'Plan Type', key: 'planTypeName', isSort: true, class: '', width: '20%' },
    { displayName: 'Status', key: 'smsPlanStatusID', isSort: true, class: '', width: '20%' },
    { displayName: 'price', key: 'smsPrice', isSort: true, class: '', width: '20%' },
    { displayName: 'Description', key: 'description', isSort: true, class: '', width: '20%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];

  actionButtons: Array<any> = [
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];

  constructor(
    private dialog: MatDialog,
    private smsSubscriptionPlanService: SMSSubscriptionPlanService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private masterDropdownService: MasterDropdownService,
    private commonService: CommonService
  ) {
    this.filterModel = new FilterModel();
    //this.searchAndPageModel = new paginationAndSearchModel();
    this.siteSMSSubscriptionListData = new Array<SiteSMSSubscriptionModel>();
  }


  ngOnInit(): void {

    // this.searchObj = {
    //   "totalRecords": 0,
    //   "currentPage": 0,
    //   "indexPage": 0,
    //   "pageNumber": 0,
    //   "totalPages": 0,
    //   "pageSize": 5,
    //   "sortOrder": "",
    //   "sortColumn": "",
    //   "columnName": "",
    //   "searchText": "",
    //   "planName": "",
    //   "planType": "",
    // }

    this.form = this.formBuilder.group({
      planName: [''],
      planTypeName: [''],
      pricing: [''],
      isTwilioPlan :  false,
      isSercureH3TechPlan : false,

    });

    this.metaData = new Metadata();
    this.smsFilterPage = new FilterPageModel();
    this.smsFilterPageP = new FilterPageModel();

    //console.log("this.smsFilterPage", this.smsFilterPage)
    //console.log("this.smsFilterPageP", this.smsFilterPageP)

    //this.dataSource.sort = this.sort;

    this.masterDropdown();
    //this.autoAssignData();
    this.getSMSSubscriptionPlanList(this.smsFilterPage)
  }

  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  autoAssignData(){
    //this.smsFilterPageP = this.smsFilterPage;

    this.smsFilterPageP.totalRecords = this.smsFilterPage.totalRecords
    this.smsFilterPageP.currentPage = this.smsFilterPage.currentPage
    this.smsFilterPageP.indexPage = this.smsFilterPage.indexPage
    this.smsFilterPageP.pageSize = this.smsFilterPage.pageSize
    this.smsFilterPageP.totalPages = this.smsFilterPage.totalPages
    this.smsFilterPageP.pageNumber = typeof this.smsFilterPage.pageNumber === undefined ? 0 : this.smsFilterPage.pageNumber;
    console.log("this.smsFilterPage.pageNumber", typeof this.smsFilterPage.pageNumber)
    this.smsFilterPageP.sortColumn = this.smsFilterPage.sortColumn
    this.smsFilterPageP.sortOrder = this.smsFilterPage.sortOrder
    this.smsFilterPageP.searchText = this.smsFilterPage.searchText
    this.smsFilterPageP.columnName = this.smsFilterPage.columnName
    this.smsFilterPageP.planName = this.smsFilterPage.planName
    this.smsFilterPageP.planType = this.smsFilterPage.planType
    this.smsFilterPageP.isTwilioPlan = this.smsFilterPage.isTwilioPlan
    this.smsFilterPageP.isSercureH3TechPlan = this.smsFilterPage.isSercureH3TechPlan
  }

  onSubmit() {
    // this.requestpages = {
    //   "pageSize": 10,
    //   "pageNumber": 1,
    //   "columnName": "",
    //   "planName": this.form.controls.planName.value,
    //   "planType": this.form.controls.planTypeName.value,
    //   "sortOrder": ""
    // }
    console.log(this.requestpages);

    this.smsFilterPage.planName = this.form.controls.planName.value,
      this.smsFilterPage.planType = this.form.controls.planTypeName.value,


      //this.autoAssignData();
      this.requestpages = {
        "pageSize": 10,
        "pageNumber": 1,
        "columnName": "",
        "planName": this.form.controls.planName.value,
        "planType": this.form.controls.planTypeName.value,
        "pricing": this.form.controls.pricing.value,
        "sortOrder": "",
        "isTwilioPlan" :  this.form.controls.isTwilioPlan.value,
        "isSercureH3TechPlan" :  this.form.controls.isSercureH3TechPlan.value,
      }

      this.getSMSSubscriptionPlanList(this.requestpages)
  }

  onTableActionClick(actionObj?: any) {
    //("onTableActionClick actionObj", actionObj)
    const id = actionObj.data && actionObj.data.subscriptionID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'DELETE':
        {

          this.dialogService
            .confirm(`Are you sure to delete this subscription plan?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteSMSSubscription(id);
              }
            });
        }
        break;
    }
  }

  applyFilter(searchText: string = '') {
    //this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getSMSSubscriptionPlanList(this.filterModel);
    }
  }

  pagination(obj: any) {
      console.log("obj ", obj)
      console.log("searchObj ", obj)

    this.searchObj.pageSize = obj.pageSize;
    this.searchObj.pageNumber = obj.pageIndex;
    this.searchObj.currentPage = obj.pageIndex;

    console.log("after searchObj ", obj)


      // this.smsFilterPage.pageSize = obj.pageSize;
      // this.smsFilterPage.pageNumber = obj.pageIndex;
      // this.smsFilterPage.currentPage = obj.pageIndex;

      // this.smsFilterPageP.pageSize = obj.pageSize;
      // this.smsFilterPageP.pageNumber = obj.pageIndex;
      // this.smsFilterPageP.currentPage = obj.pageIndex;
      // //this.autoAssignData();
      
      // this.getSMSSubscriptionPlanList(this.smsFilterPageP)
  }

  sortData(obj: any) {
    console.log("obj ", obj)
    this.smsFilterPage.columnName = obj.active;
    this.smsFilterPage.sortOrder = obj.direction;
    console.log("this.smsFilterPage : ", this.smsFilterPage);

    this.smsFilterPageP.columnName = obj.active;
    this.smsFilterPageP.sortOrder = obj.direction;
    //this.autoAssignData();
    console.log("this.smsFilterPageP : ", this.smsFilterPageP);
    this.getSMSSubscriptionPlanList(this.smsFilterPageP)
  }

  getSMSSubscriptionPlanList(filterModel: any) {

    // var planName = "";
    // var planType = "";

    // if(this.requestpages != undefined){
    //   console.log("this.requestpages != 'undefined'", this.requestpages)

    //   planName =  this.requestpages['planName'];
    //   planType = this.requestpages['planType']
    // }

    // let searchObj = {
    //   "pageNumber": this.filterModel.pageNumber,//
    //   "pageSize": this.filterModel.pageSize,//
    //   "sortOrder": this.filterModel.sortOrder,//
    //   "sortColumn": this.filterModel.sortColumn,
    //   "columnName": this.filterModel.sortColumn,//
    //   "searchText": this.filterModel.searchText,
    //   "planName": planName,
    //   "planType": planType
    // }

    //console.log("filterModel.pageNumber", filterModel.pageNumber)
    //filterModel.pageNumber = Number(filterModel.pageNumber) + 1;



    var planName = "";
    var planType = "";
    var isTwilioPlan = false;
    var isSercureH3TechPlan = false;

    if(this.requestpages != undefined){
      console.log("this.requestpages != 'undefined'", this.requestpages)

      planName =  this.requestpages['planName'];
      planType = this.requestpages['planType'];
      isSercureH3TechPlan =  this.requestpages['isSercureH3TechPlan'];
      isTwilioPlan = this.requestpages['isTwilioPlan'];
    }

    let searchObj = {
      "pageNumber": this.filterModel.pageNumber,//
      "pageSize": this.filterModel.pageSize,//
      "sortOrder": this.filterModel.sortOrder,//
      "sortColumn": this.filterModel.sortColumn,
      "columnName": this.filterModel.sortColumn,//
      "searchText": this.filterModel.searchText,
      "planName": planName,
      "planType": planType,
      "isTwilioPlan" :  isTwilioPlan,
      "isSercureH3TechPlan" :  isSercureH3TechPlan,

    }
    this.smsSubscriptionPlanService.AllSMSSubscriptionPlan(searchObj).subscribe((res: any) => {
      if (res.status > 0) {
        //console.log(res);
        this.siteSMSSubscriptionListData = res.responseData;
        this.metaData = res.meta;
        //this.smsFilterPageP = res.meta;
        //this.smsFilterPage = res.meta;

        //this.searchObj = res.meta;
        console.log('searchObj', this.searchObj)

        //this.autoAssignData();

        this.siteSMSSubscriptionListData.forEach((element: any) => {
          var x = element;

          if(x.smsPlanStatusID == "1"){
            x.smsPlanStatusID = "Active";
          }
          else{
            x.smsPlanStatusID = "InActive";
          }

          this.planTypeDropdown.forEach((elements: any) => {
            if (elements.planID === element.smsPackID) {
              x.smsPackID = elements.planTypeName;
            }
          });

          this.planTypeStautusDropdown.forEach((elements: any) => {
            if (elements.planStatusID === element.smsPlanStatusID) {
              x.smsPlanStatusID = elements.planStatusName;
            }
          });

        });
        //console.log("this.siteSMSSubscriptionListData", this.siteSMSSubscriptionListData);
        //this.dataSource = res.responseData;
        
      } else {
        this.metaData = new Metadata();
        this.toaster.error(res.message);
      }
      //console.log(this.siteSMSSubscriptionListData)
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  onPageOrSortChange(changeState?: any) {
    console.log("onPageOrSortChange", changeState)
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getSMSSubscriptionPlanList(this.filterModel)
    console.log("onPageOrSortChange this.filterModel ", this.filterModel)
  }


  editCall(subscriptionID: any) {
    this.router.navigate(["edit-sms-subs"], { queryParams: { id: subscriptionID } });
  }

  deleteSMSSubscription(id: any) {
    this.smsSubscriptionPlanService.DeleteSMSSubscriptionPlan(id).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.error(res.message);
        this.InitialGridCall();

        this.getSMSSubscriptionPlanList(this.requestpages)
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  editSMSSubscription(id: any) {
    this.router.navigate(['/edit-sms-subs'], { queryParams: { id: id } })
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })
  }

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getSMSSubscriptionPlanList(this.requestpages)
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "columnName": "",
      "planType": "",
      "pricing": "",
      "planName": "",
      "sortOrder": ""
    }
  }


  ExportTOExcel()
  {
    let searchObj = {
      "pageNumber": 1,//
      "pageSize": 1000,//
      "sortOrder": "",//
      "sortColumn": "",
      "columnName": "",//
      "searchText": "",
      "planName": "",
      "planType": ""
    }
    this.smsSubscriptionPlanService.AllSMSSubscriptionPlan(searchObj).subscribe((res: any) => {
      if (res.status > 0) {
        let dataToExport = res.responseData
        .map((x :any)=> ({
          //subscriptionID: x.subscriptionID ,
          planName: x.planName ,
          smsPlanStatusID: (x.smsPlanStatusID == 1) ? "Active" : "InActive",
          planTypeName: x.planTypeName ,
          smsPrice: x.smsPrice ,
          numberOfUserAllowed: x.numberOfUserAllowed ,
          smsPackID: x.smsPackID ,
          description: x.description ,
          limit: x.limit

        }));
  
      let workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, <XLSX.Table2SheetOpts>{ sheet: 'Sheet 1' });
      let workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');
      XLSX.writeFile(workBook, 'SMS-Subscription-Plan.xlsx');

      } 
    });
  }


  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

}
