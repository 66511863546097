<div class="container my-5">
    <button class="btn btn-success" (click)="showSuccess()">Success Toaster</button>
    <button class="btn btn-danger" (click)="showError()">Error Toaster</button>
    <button class="btn btn-primary" (click)="showInfo()">Info Toaster</button>
    <button class="btn btn-warning" (click)="showWarning()">Warning Toaster</button>
</div>

<div class="d-flex justify-content-center align-items-center">	
    <app-spinner [show]="show" [size]="150"></app-spinner>
	<div [class.hide]="show">		
		<button class="btn btn-primary" (click)="showSpinner()"> See spinner </button>
	</div>
</div>

<div class="center mt-4">
    <label for="file-upload" class="custom-file-upload">
        <i class="fa fa-cloud-upload"></i>Upload Image
        <input id="file-upload" type="file"/>
    </label>
</div>



<div class="center mt-4">
    <div class="row">
        <div class="col s12 m6 form-group">
            <ngx-drag-drop-crop-resize 
            (croppedImage)="getCroppedImage($event)" oImgHeight = "11rem" 
            (isImageDeleted) = "isTheImageDeleted($event)"
            oImgWidth = "15rem" >
           
            </ngx-drag-drop-crop-resize>
        </div>
    </div>
    <!-- <img [src]="croppedImage" style="height: 200px;width: 200px;" alt="Add Image"> -->

    </div>


