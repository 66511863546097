import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';
import { ViewAdminDetailsService } from 'src/app/common/services/view-admin-details.service';
import { StaffModel } from '../add-admin/StaffModel';


@Component({
  selector: 'app-view-admin',
  templateUrl: './view-admin.component.html',
  styleUrls: ['./view-admin.component.css']
})
export class ViewAdminComponent implements OnInit {

 
  form: FormGroup;
  adminUserID:any;
  data:string;
  firstName:string;
  lastName:string;
  dob:any;
  genderName:string;
  username:string
  
   email: string
  password :string
    profilePic:string
    staffModel:StaffModel
    contactNumber:string
    address:string
    zipCode:string
    stateName: string
    countryName: string
    city: string
    bloodGroupName: string
    roleName:string

    today: number = Date.now();

    


  constructor(
    
    private activatedRoute:ActivatedRoute,
      private ViewAdminDetailsService:ViewAdminDetailsService
    
  ) { }


  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.adminUserID = params.id==undefined?null:params.id;
    });
     //this.adminUserID = this.activatedRoute.snapshot.params.Id
     this.ViewAdminDetailsService.GetAdminListDropdown(this.adminUserID).subscribe((res:any) => {
      this.firstName= res.responseData.firstName;
      this.lastName=res.responseData.lastName;
      this.dob=res.responseData.dob;
      this.genderName=res.responseData.genderName;
      this.username=res.responseData.username;
      this.email=res.responseData.email;
      this.password=res.responseData.password;
      this.profilePic=res.responseData.profilePic ? res.responseData.profilePic : '../../../assets/img/display.jpg';
      this.contactNumber=res.responseData.contactNumber;
     
      this.address=res.responseData.address;
      this.city=res.responseData.city;
      this.countryName=res.responseData.countryName;
      this.stateName=res.responseData.stateName;
      this.roleName=res.responseData.roleName;
      this.zipCode=res.responseData.zipCode;
      this.bloodGroupName=res.responseData.bloodGroupName
     
      console.log(res.responseData);
      

    });
   }
  
  
    





}
