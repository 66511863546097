import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ManageOrganizationService } from "../common/services/manage-organization.service";
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { OrganizationModel } from './OrganizationModel';
import { Router } from '@angular/router';
import { MasterDropdownService } from '../common/services/MasterDropdownServices/master-dropdown.service';
import { ResetpwpopupTemplateComponent } from './resetpwpopup-template/resetpwpopup-template.component';
import { CommonService } from '../common/core-services/common.Service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DialogService } from '../common/core-services/DialogService';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
//import * as moment from 'moment';
@Component({
  selector: 'app-manage-organizations',
  templateUrl: './manage-organizations.component.html',
  styleUrls: ['./manage-organizations.component.css']
})
export class ManageOrganizationsComponent implements OnInit {
  @ViewChild('orgInvoice', { read: TemplateRef }) sendInvoice: TemplateRef<any>;
  organizationID: number;
  organizationName: string;
  organizationType:any;
  subDomainName: string;
  maximumStudentAllowed: string;
  userName: string;
  contactNumber: string;
  contactPersonFullName: string;
  email: string;
  planName: string;
  price: any;
  planStatusName: string;
  planTypeName: string;
  smsPlanName: string;
  smsPrice: any;
  smsPackID: number;
  statusName: string;
  emailPlanName: string;
  emailPrice: any;
  emailPackID: number;
  organizationShortName: any;
  subscriptionStartDate: any;
  subscriptionEndDate: any;
  notificationDate:any
  noOfDays: any;

  // classToggled = false;
  // dataSource= new MatTableDataSource;
  // requestpages:any;
  // form: FormGroup;


  // public toggleField() {
  //   this.classToggled = !this.classToggled;  
  // }

  // @ViewChild(MatSort)
  // sort!: MatSort;
  // constructor(
  //   private dialog: MatDialog,
  //   private manageOrganizationService: ManageOrganizationService,
  //   private formBuilder: FormBuilder,
  //   ) { }
  // displayedColumns: string[] = ['organizationname', 'organizationtype','email', 'subdomainname', 'username', 'contactno', 'subscriptionPlan', 'actions'];

  // ngAfterViewInit(): void {

  //   this.dataSource.sort = this.sort;	
  // }

  // actionDialogPopup(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  // }

  // ngOnInit(): void {
  //   this.form = this.formBuilder.group({
  //     organizationName: [''],
  //     organizationType: [''],
  //     email: [''],
  //     subDomainName: [''],
  //   });

  //   this.dataSource.sort = this.sort;	
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "subDomainName": "",
  //     "organizationName": "",
  //     "organizationType": "",
  //     "email":"",
  //     "columnName": "",
  //     "sortOrder": ""
  //   }
  //   this.OrganizationList()
  // }

  // OrganizationList(){
  //   this.manageOrganizationService.GetOrganizationAll(this.requestpages).subscribe((res:any) => {
  //     this.dataSource = res.responseData;
  //     console.log(this.dataSource)
  //   });
  // }

  // onSubmit(){
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "subDomainName": this.form.controls.subDomainName.value,
  //     "organizationName": this.form.controls.organizationName.value,
  //     "organizationType": this.form.controls.organizationType.value,
  //     "email": this.form.controls.email.value,
  //     "columnName": "",
  //     "sortOrder": ""
  //   }
  //   this.OrganizationList()
  // }

  // reset(){
  //   this.form.reset();
  //   this.OrganizationList()

  // }
  columnForm: FormGroup;
  enquiryTable: FormGroup;
  columnsToDisplay: any;
  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  organizationListData: OrganizationModel[];
  tableCheck:number;
  classToggled = false;
  organizationListDatas: any;
  organizationListDataa: any;
  allowedOrganizationList: OrganizationModel[];
  notAllowedOrganizationList: OrganizationModel[];
  isEnable: true;
  myData: { pageSize: number; pageNumber: number; subDomainName: string; organizationName: string; organizationType: string; email: string; columnName: string; IsDemoAllowed: boolean; };

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    { displayName: 'Organization Name', key: 'organizationName', isSort: true, class: '', width: '20%' },
    { displayName: 'Organization Type', key: 'organizationType', isSort: true, class: '', width: '20%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '20%' },
    { displayName: 'Subdomain Name', key: 'subDomainName', isSort: true, class: '', width: '20%' },
    { displayName: 'User Name', key: 'userName', isSort: false, class: '', width: '20%' },
    { displayName: 'Contact Number', key: 'contactNumber', isSort: true, class: '', width: '20%' },
    { displayName: 'Subscription Plan', key: 'subscriptionPlan', isSort: false, class: '', width: '20%' },
    { displayName: 'Org Url', key: 'orgUrl', isSort: false, class: '', width: '20%' },
    { displayName: 'Button', key: 'totalCount45', isSort: false, class: '', width: '20%' },
    { displayName: 'Is Demo Allowed', key: 'isDemoAllowed', type: ["togglespan"], permission: true, isSort: true, class: '', width: '10%' },
    { displayName: 'Button', key: 'Button', type: ["button"], permission: true, isSort: false, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '15%' }

  ];
  displayedColumn: Array<any> = [
    { displayName: 'Organization Name', key: 'organizationName', isSort: true, class: '', width: '20%' },
    { displayName: 'Organization Type', key: 'organizationType', isSort: true, class: '', width: '20%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '20%' },
    { displayName: 'Subdomain Name', key: 'subDomainName', isSort: true, class: '', width: '20%' },
    { displayName: 'User Name', key: 'userName', isSort: false, class: '', width: '20%' },
    { displayName: 'Contact Number', key: 'contactNumber', isSort: true, class: '', width: '20%' },
    { displayName: 'Subscription Plan', key: 'subscriptionPlan', isSort: false, class: '', width: '20%' },
    { displayName: 'Org Url', key: 'orgUrl', isSort: false, class: '', width: '20%' },
    { displayName: 'Button', key: 'totalCount34', isSort: false, class: '', width: '20%' },
    { displayName: 'Is Enable', key: 'isEnable', type: ["togglespan"], permission: true, isSort: true, class: '', width: '10%' },
    { displayName: 'Button', key: 'Button', type: ["button"], permission: true, isSort: false, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '15%' }

  ];//organizationID:number;
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'details', class: 'fas fa-eye' },
    //{ displayName: 'Reset Password', key: 'resetPassword', class: 'fas fa-lock' },
  ];
  actionButton: Array<any> = [
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Details', key: 'details', class: 'fas fa-eye' },
    { displayName: 'Reset Password', key: 'resetPassword', class: 'fas fa-lock' },
    { displayName: 'Raise Renewal Invoice:', key: 'orgInvoice', class: 'fas fa-file-invoice' },
  ];

  

  constructor(
    private dialog: MatDialog,
    private manageOrganizationService: ManageOrganizationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private masterService: MasterDropdownService,
    private commonService: CommonService,
    private dialogService: DialogService,
    private toaster: ToastrService,
    public datepipe: DatePipe
  ) {
    this.filterModel = new FilterModel();
    this.organizationListData = new Array<OrganizationModel>();
    this.notificationDate = new Date();
  }
  user:any;
  ngOnInit(): void {
    this.user = localStorage.getItem('currentUser');
          const currentUser = JSON.parse(this.user);
    this.tableCheck = 0;
    this.masterDropdown();
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      organizationName: [''],
      organizationType: [''],
      email: [''],
      subDomainName: [''],
    });
    this.columnForm = this.formBuilder.group({
      name: true,
      type: true,
      email: true,
      userName: true,
      subdomainName: true,
      phoneNumber: true,
      subscriptionPlan: true,
      isEnable:true,
      userId:currentUser.userId
    })
    this.enquiryTable = this.formBuilder.group({
      name: true,
      type: true,
      email: true,
      userName: true,
      subdomainName: true,
      phoneNumber: true,
      subscriptionPlan: true,
      isDemo:true,
      userId:currentUser.userId
    })
    this.getOrganizationList(this.filterModel);
    this.getCustomGrid(currentUser.userId);
  }
  getCustomGrid(id:any){
    this.manageOrganizationService.GetActiveGridTable(id).subscribe((res:any) =>{
      if(res.responseData != null){
      this.columnForm.patchValue({
        name: res.responseData.organizationName,
        type: res.responseData.organizationType,
        email: res.responseData.email,
        userName: res.responseData.userName,
        subdomainName: res.responseData.subDomainName,
        phoneNumber: res.responseData.contactNumber,
        subscriptionPlan: res.responseData.userName,
        isEnable:res.responseData.isEnable,
        userId:res.responseData.userId
      })
    }
      this.onSelectColumn();
    })
    this.manageOrganizationService.GetEnquiryTbalbecolumn(id).subscribe((res:any) =>{
      console.log("GRIDE",res.responseData);
      
      if(res.responseData != null){
        this.enquiryTable.patchValue({
          name: res.responseData.organizationName,
          type: res.responseData.organizationType,
          email: res.responseData.email,
          userName: res.responseData.userName,
          subdomainName: res.responseData.subDomainName,
          phoneNumber: res.responseData.contactNumber,
          subscriptionPlan: res.responseData.userName,
          isDemo:res.responseData.isDemoAllowed,
          userId:res.responseData.userId
        })
      }
      this.onSelectColumn();
    })
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getOrganizationList(this.filterModel)
  }

  onSubmit() {

    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "subDomainName": this.form.controls.subDomainName.value,
      "organizationName": this.form.controls.organizationName.value,
      "organizationType": this.form.controls.organizationType.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": ""
    }
    this.getOrganizationList(this.requestpages)
  }
  orgID:any;
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.organizationID;
    this.orgID = id;
    const userData = actionObj.data;
    console.log("actionObj",actionObj)
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        this.editCall(id);
        break;
      case 'RESETPASSWORD':
        this.resetPassword(userData);
        break;
       case 'ORGINVOICE':
          this.SENDINVOICE(id);
          break;
        
      case 'DETAILS':
        this.DetailsCall(id);
        break;
      case "TOGGLE":
        if (actionObj.column.key == "isDemoAllowed") {
          this.dialogService
            .confirm(`Are you sure you want to allowed demo?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.manageOrganizationService
                  .AllowedNotAllowedOrgById(
                    id
                  )
                  .subscribe((res: any) => {
                    this.toaster.success(res.message);
                    this.InitialGridCall();
                    this.getOrganizationList(this.myData);
                  });
              }
              else{
                this.InitialGridCall();
                this.getOrganizationList(this.requestpages)
              }
            });
        }
        else{
          if(actionObj.column.key == "isEnable"){
            if(actionObj.data.isEnable==false){
              this.dialogService
              .confirm(`Are you sure you want to enable organization?`)
              .subscribe((result: any) => {
                if (result == true) {
                  this.manageOrganizationService.EnableNotEnabledOrgById(id)
                    .subscribe((res: any) => {
                      this.toaster.success(res.message);
                      this.InitialGridCall();
                      this.getOrganizationList(this.requestpages)
                    });
                }
                else{
                  this.InitialGridCall();
                  this.getOrganizationList(this.requestpages)
                }
              });
            }
            else{
              this.dialogService
              .confirm(`Are you sure you want to disable organization?`)
              .subscribe((result: any) => {
                if (result == true) {
                  this.manageOrganizationService.EnableNotEnabledOrgById(id)
                    .subscribe((res: any) => {
                      this.toaster.success(res.message);
                      this.InitialGridCall();
                      this.getOrganizationList(this.requestpages)
                    });
                }
                else{
                  this.InitialGridCall();
                  this.getOrganizationList(this.requestpages)
                }
              });
            }
          }
        }
        
       
        
        
    }
    
  }

  SENDINVOICE(id:any){

    

    console.log("org", id);
    this.manageOrganizationService.GetOrganizationDetailsById(id).subscribe((res: any) => {
      debugger;
      console.log("res.responseData", res.responseData);
      this.organizationName = res.responseData.organizationName
      this.organizationType = res.responseData.organizationType
      this.subDomainName = res.responseData.subDomainName
      this.maximumStudentAllowed = res.responseData.maximumStudentAllowed
      this.userName = res.responseData.userName
      this.contactNumber = res.responseData.contactNumber
      this.contactPersonFullName = res.responseData.contactPersonFullName
      this.email = res.responseData.email
      this.planName = res.responseData.planName
      this.price = res.responseData.price
      this.planStatusName = res.responseData.planStatusName
      this.planTypeName = res.responseData.planTypeName
      this.smsPlanName = res.responseData.smsPlanName
      this.smsPrice = res.responseData.smsPrice
      this.smsPackID = res.responseData.smsPackID
      this.statusName = res.responseData.statusName
      this.emailPlanName = res.responseData.emailPlanName
      this.emailPrice = res.responseData.emailPrice
      this.emailPackID = res.responseData.emailPackID
      this.noOfDays = res.responseData.noOfDays
      this.organizationShortName = res.responseData.organizationShortName
      this.subscriptionStartDate =res.responseData.subscriptionStartDate;
      this.subscriptionEndDate = res.responseData.subscriptionEndDate;
    //vacurrentdate: Date;
      let currentdate = new Date();
      var checkDate = new Date(this.subscriptionEndDate);
      var pastDate = new Date(checkDate);
      pastDate.setDate(pastDate.getDate()-7);
      // checkDate.setDate(new Date(res.responseData.subscriptionEndDate)-7)
    // checkDate = moment(new Date(res.responseData.subscriptionEndDate) - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD'); 
      console.log("pastDate", pastDate, this.subscriptionEndDate);
      console.log("currentdate", currentdate);
      
      if(currentdate >= pastDate){
        console.log("hi");
      }
      this.dialog.open(this.sendInvoice);
    });

  }
  OrgInvoiceObj:any;
  submitSendOrgInvoice(){
    this.OrgInvoiceObj = {
      "OrganizationID": this.orgID,
      "PlanName": this.planName,
      "Price": this.price,
      "ExpiryDate":this.subscriptionEndDate
    }
    console.log("OrgInvoiceObj", this.OrgInvoiceObj);
    this.manageOrganizationService.RaisedOrganizationInvoice(this.OrgInvoiceObj).subscribe((res:any)=>{
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });
  }
//  SENDINVOICE(userData: any) {
//     this.dialog.open(ResetpwpopupTemplateComponent, { data: userData });
//   }

  editCall(organizationID: any) {
    this.router.navigate(["/edit-organization/"], { queryParams: { id: organizationID } });
  }
  
  // resetPassword(userData: any) {
  //   this.dialog.open();
  // }
  resetPassword(userData: any) {

    this.dialog.open(ResetpwpopupTemplateComponent, { data: userData });

  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getOrganizationList(this.filterModel);
    }
  }

  notAllowedOrgTab(event: MatTabChangeEvent): void {
    if(event.index == 0){
      this.tableCheck = 0;
      this.onSelectColumn();
    }
    if (event.index == 1) {
       this.myData = {
        "pageSize": 25,
        "pageNumber": 1,
        "subDomainName": "",
        "organizationName": "",
        "organizationType": "",
        "email": "",
        "columnName": "",
        "IsDemoAllowed": false,
      }
      this.tableCheck = 1;
      this.onSelectColumn();
      this.getOrganizationList(this.myData);
    }
    else {
      this.getOrganizationList(this.filterModel);
    }

  }
  getOrganizationList(filterModel: any) {
    this.notAllowedOrganizationList = [];
    this.allowedOrganizationList = [];
    this.manageOrganizationService.GetOrganizationAll(filterModel).subscribe((res: any) => {
      if(res.status==0){
        this.toaster.error(res.message);
      }
      if (res.status > 0) {
        this.organizationListData = res.responseData;
        console.log("this.organizationListData", this.organizationListData);
        
        this.metaData = res.meta;
        this.notAllowedOrganizationList = this.organizationListData
        this.allowedOrganizationList = this.organizationListData
      } else {
        this.metaData = new Metadata();
      }
    });
  }
  organizationTypess:any
  masterDropdown() {
    this.masterService.getOrganizationType().subscribe((res: any) => {
      this.organizationTypess = res.responseData
    });
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }


  DetailsCall(organizationID: any) {
    this.router.navigate(["/organization-details/"], { queryParams: { id: organizationID } });
  }

  // DeleteSubscription(id: any) {
  //   this.subscriptionPlanService.DeleteSubscriptionPlan(id).subscribe((res: any) => {
  //     if (res.status > 0) {
  //       this.toaster.error(res.message);
  //       this.InitialGridCall();

  //       this.getsubscriptionPlanList(this.requestpages)
  //     }
  //     else {
  //       this.toaster.error(res.message);
  //     }
  //   });
  // }

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getOrganizationList(this.requestpages)
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "subDomainName": "",
      "organizationName": "",
      "organizationType": "",
      "email": "",
      "columnName": "",
      "sortOrder": ""
    }
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  actionDialogPopup(templateRef: TemplateRef<any>, id: any) {
    this.dialog.open(templateRef, {


    });
  }
  unselectAll() {
  if(this.tableCheck == 0){
    this.columnForm.patchValue({
      name: true,
      type: false,
      email: false,
      userName: false,
      subdomainName: false,
      phoneNumber: false,
      subscriptionPlan: false,
      isEnable: false
    })
  }
  if(this.tableCheck == 1){
    this.enquiryTable.patchValue({
      name: true,
      type: false,
      email: false,
      userName: false,
      subdomainName: false,
      phoneNumber: false,
      subscriptionPlan: false,
      isDemo: false
    })
  }
    this.onSelectColumn();
  }
  selectAll() {
    if(this.tableCheck ==0){
    this.columnForm.patchValue({
      name: true,
      type: true,
      email: true,
      userName: true,
      subdomainName: true,
      phoneNumber: true,
      subscriptionPlan: true,
      isEnable: true
    })
  }
  if(this.tableCheck == 1){
    this.enquiryTable.patchValue({
      name: true,
      type: true,
      email: true,
      userName: true,
      subdomainName: true,
      phoneNumber: true,
      subscriptionPlan: true,
      isEnable: true
    })
  }
    this.onSelectColumn();
  }
  onSelectColumn() {
    if(this.tableCheck == 0){
    this.columnsToDisplay = [
      { displayName: 'Organization Name', key: 'organizationName', isSort: true, class: '', width: '20%' },
    { displayName: 'Organization Type', key: 'organizationType', isSort: true, class: '', width: '20%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '20%' },
    { displayName: 'Subdomain Name', key: 'subDomainName', isSort: true, class: '', width: '20%' },
    { displayName: 'User Name', key: 'userName', isSort: false, class: '', width: '20%' },
    { displayName: 'Contact Number', key: 'contactNumber', isSort: true, class: '', width: '20%' },
    { displayName: 'Subscription Plan', key: 'subscriptionPlan', isSort: false, class: '', width: '20%' },
    { displayName: 'Org Url', key: 'orgUrl', isSort: false, class: '', width: '20%' },
    { displayName: 'Is Enable', key: 'isEnable', type: ["togglespan"], permission: true, isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }
    ];
    this.displayedColumn = this.columnsToDisplay
    for (var i = 0; i < this.displayedColumn.length; i++) {
      if (this.columnForm.value.name == false) {
        if (this.displayedColumn[i].key == 'organizationName')
          this.displayedColumn.splice(i, 1);
      }
      if (this.columnForm.value.type == false) {
        if (this.displayedColumn[i].key == 'organizationType')
          this.displayedColumn.splice(i, 1);
      }
      if (this.columnForm.value.email == false) {
        if (this.displayedColumn[i].key == 'email')
          this.displayedColumn.splice(i, 1);
      }
      if (this.columnForm.value.phoneNumber == false) {
        if (this.displayedColumn[i].key == 'contactNumber')
          this.displayedColumn.splice(i, 1);
      }

      if (this.columnForm.value.subdomainName == false) {
        if (this.displayedColumn[i].key == 'subDomainName')
          this.displayedColumn.splice(i, 1);
      }
      if (this.columnForm.value.subscriptionPlan == false) {
        if (this.displayedColumn[i].key == 'subscriptionPlan')
          this.displayedColumn.splice(i, 1);
      }
      if (this.columnForm.value.isEnable == false) {
        if (this.displayedColumn[i].key == 'isEnable')
          this.displayedColumn.splice(i, 1);
      }
    }
    this.removeusername();
    this.UpdateColumn();
  }
  if(this.tableCheck == 1) {
    this.columnsToDisplay = [
      { displayName: 'Organization Name', key: 'organizationName', isSort: true, class: '', width: '20%' },
    { displayName: 'Organization Type', key: 'organizationType', isSort: true, class: '', width: '20%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '20%' },
    { displayName: 'Subdomain Name', key: 'subDomainName', isSort: true, class: '', width: '20%' },
    { displayName: 'User Name', key: 'userName', isSort: false, class: '', width: '20%' },
    { displayName: 'Contact Number', key: 'contactNumber', isSort: true, class: '', width: '20%' },
    { displayName: 'Subscription Plan', key: 'subscriptionPlan', isSort: false, class: '', width: '20%' },
    { displayName: 'Org Url', key: 'orgUrl', isSort: false, class: '', width: '20%' },
    { displayName: 'Is Demo Allowed', key: 'isDemoAllowed', type: ["togglespan"], permission: true, isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }
    ];
    this.displayedColumns = this.columnsToDisplay
    for (var i = 0; i < this.displayedColumns.length; i++) {
      if (this.enquiryTable.value.name == false) {
        if (this.displayedColumns[i].key == 'organizationName')
          this.displayedColumns.splice(i, 1);
      }
      if (this.enquiryTable.value.type == false) {
        if (this.displayedColumns[i].key == 'organizationType')
          this.displayedColumns.splice(i, 1);
      }
      if (this.enquiryTable.value.email == false) {
        if (this.displayedColumns[i].key == 'email')
          this.displayedColumns.splice(i, 1);
      }
      if (this.enquiryTable.value.phoneNumber == false) {
        if (this.displayedColumns[i].key == 'contactNumber')
          this.displayedColumns.splice(i, 1);
      }

      if (this.enquiryTable.value.subdomainName == false) {
        if (this.displayedColumns[i].key == 'subDomainName')
          this.displayedColumns.splice(i, 1);
      }
      if (this.enquiryTable.value.subscriptionPlan == false) {
        if (this.displayedColumns[i].key == 'subscriptionPlan')
          this.displayedColumns.splice(i, 1);
      }
      if (this.enquiryTable.value.isDemo == false) {
        if (this.displayedColumns[i].key == 'isDemoAllowed')
          this.displayedColumns.splice(i, 1);
      }
    }
    this.removeusername();
    this.UpdateColumn();
  }
  }
  removeusername() {
    if(this.tableCheck == 0){
    if (this.columnForm.value.userName == false) {
      for (var i = 0; i < this.displayedColumn.length; i++) {
        if (this.displayedColumn[i].key == 'userName')
          this.displayedColumn.splice(i, 1);
      }
    }
  }
    if(this.tableCheck == 1){
      if (this.enquiryTable.value.userName == false) {
        for (var i = 0; i < this.displayedColumns.length; i++) {
          if (this.displayedColumns[i].key == 'userName')
            this.displayedColumns.splice(i, 1);
        }
      }
  }
  }
  activeorgObj:any;
  enquiryorgObj:any;
  UpdateColumn(){
     if(this.tableCheck ==0){
       this.activeorgObj = {
        "userId": this.columnForm.value.userId,
        "organizationName": this.columnForm.value.name,
        "organizationType": this.columnForm.value.type,
        "subDomainName": this.columnForm.value.subdomainName,
        "userName": this.columnForm.value.userName,
        "subscriptionPlan": this.columnForm.value.subscriptionPlan,
        "email": this.columnForm.value.email,
        "contactNumber": this.columnForm.value.phoneNumber,
        "isEnable": this.columnForm.value.isEnable
       }
       this.manageOrganizationService.AddActiveCustomGrid(this.activeorgObj).subscribe((res:any) =>{
       })
     }
     if(this.tableCheck ==1){
      this.enquiryorgObj = {
       "userId": this.enquiryTable.value.userId,
       "organizationName": this.enquiryTable.value.name,
       "organizationType": this.enquiryTable.value.type,
       "subDomainName": this.enquiryTable.value.subdomainName,
       "userName": this.enquiryTable.value.userName,
       "subscriptionPlan": this.enquiryTable.value.subscriptionPlan,
       "email": this.enquiryTable.value.email,
       "contactNumber": this.enquiryTable.value.phoneNumber,
       "isDemoAllowed": this.enquiryTable.value.isDemo
      }
      this.manageOrganizationService.AddEnquirytableColumn(this.enquiryorgObj).subscribe((res:any) =>{
      })
    }
  }
}


