<!-- <div class="schedule-modal">
    <div mat-dialog-content>
      <div>{{message}}</div>
    </div>
    <div mat-dialog-actions>
      <button mat-button *ngFor="let action of actions" (click)="handleClick(action.value)">
        {{action.name}}
      </button>
    </div> -->



    <div class="dialog-md">
        <i class="material-icons popupClose" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
          <h5 mat-dialog-title> {{message}}</h5>
        </div>
        <mat-dialog-content class="mat-typography">
          <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
          <div class="btnsWidget form-group center">
            <!-- <button (click)=confirmDelete() class="btn btn-primary btn-sm">Yes</button> -->
            <button class="btn btn-primary btn-sm" *ngFor="let action of actions" (click)="handleClick(action.value)">
                {{action.name}}
              </button>
    
          </div>
        </mat-dialog-content>
      </div>