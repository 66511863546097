<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Role </h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </div>
    </div> -->

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Quote</h3>
                <a (click)="goto()" class="btn btn-primary btnsmall">Back</a>
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Quote Text </mat-label>
                                            <!-- <input maxlength="500" formControlName="quoteText" matInput placeholder="Quote Text"> -->
                                            <textarea required maxlength="500" formControlName="quoteText" matInput placeholder="Quote Text" ></textarea>
                                            <mat-error *ngIf="form.get('quoteText')?.touched  && form.get('quoteText')?.errors?.required">
                                                Quote Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Quote By </mat-label>
                                            <input maxlength="50" required formControlName="quoteBy" matInput placeholder="Quote By">
                                            <mat-error *ngIf="form.get('quoteBy')?.touched  && form.get('quoteBy')?.errors?.required">
                                                Quote By is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>
