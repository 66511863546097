<nav class="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
        <div class="nav">
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/dashboard.svg" alt=""></span> <span>Dashboard</span></a> 

            <!-- <a class="nav-link" routerLink="/manage-staff" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/admin.svg" alt=""></span> <span>Manage Staff</span></a> 
            <a class="nav-link" routerLink="/manage-role" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/admin.svg" alt=""></span> <span>Manage Role</span></a> 
            <a class="nav-link" routerLink="/manage-organization" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/manage-org.svg" alt=""></span> <span>Manage Organizations</span></a> 
            <a class="nav-link" routerLink="/enroll-institute" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/academic.svg" alt=""></span> <span>Enroll Institute</span></a> 
            <a class="nav-link" routerLink="/subscription-plan" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/subscription-plans.svg" alt=""></span> <span>Subscription Plans</span></a> 
            <a class="nav-link" routerLink="/enquiries" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/enquries.svg" alt=""></span> <span>Enquiries</span></a> 
            <a class="nav-link" routerLink="/transactions" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/transactions.svg" alt=""></span> <span>Transactions</span></a> 
            <a class="nav-link" routerLink="/email-templates" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/email-template.svg" alt=""></span> <span>Email Templates</span></a> 
            <a class="nav-link" routerLink="/email-subs" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/email-management.svg" alt=""></span> <span>Email Management</span></a> 
            <a class="nav-link" routerLink="/sms-subs" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/sms-management.svg" alt=""></span> <span>SMS Management</span></a> 
            <a class="nav-link" routerLink="/enquiries" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/accounting.svg" alt=""></span> <span>Payment Gateway</span></a>
            <a class="nav-link" routerLink="/content-management" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/reports.svg" alt=""></span> <span>Content Management</span></a>  
            <a class="nav-link" routerLink="/enquiries" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/reports.svg" alt=""></span> <span>Logs</span></a> 
            <a class="nav-link" routerLink="/settings" routerLinkActive="active"><span class="sb-nav-link-icon"><img src="../../../assets/img/settings.svg" alt=""></span> <span>Settings</span></a>  -->

            <ng-container *ngFor="let item of menuList">
                <a class="nav-link"
                 routerLink="{{item.menuLink}}"
                  routerLinkActive="active">
                  <span class="sb-nav-link-icon">
                      <img [src]="item.iconType" [alt]="item.screenName">
                    </span> <span>{{item.screenName}}</span>
                </a>
            </ng-container>
        </div>
    </div>
</nav>
