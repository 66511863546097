<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Enquiries</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/enquiries">Enquiries</a></li>
                <li class="breadcrumb-item active" aria-current="page">Enquiry Details</li>
            </ol>
        </div>
    </div>
    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Enquiry Details</h3>
                <a routerLink="/enquiries" class="btn btn-primary btnsmall">Back</a>
            </div>

            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <div class="proSection">
                            <div class="row">
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Name :</label>
                                            <span>{{name}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Phone Number :</label>
                                            <span>{{phoneNumber}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Status:</label>
                                            <span>{{enquiryStatusName}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Enquiry Type :</label>
                                            <span>{{enquiryTypeName}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Comments :</label>
                                            <span>{{comments}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col s3 m3 form-group">
                                        <div class="detailsContent">
                                            <label>Address :</label>
                                            <span>{{address}}</span>
                                        </div>
                                    </div>
                                    <div class="col s9 m9 form-group">
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</main>