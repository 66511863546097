import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';
import { FilterModel, Metadata } from 'src/app/common/MasterModel/masterModel';

import { DialogService } from 'src/app/common/core-services/DialogService';
import { CustomerModel } from './CustomerModel';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.css']
})
export class ViewCustomerComponent implements OnInit {

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  customerData: CustomerModel[];
  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  classToggled = false;

  public toggleField() {
   this.classToggled = !this.classToggled;
  }

  displayedColumns: Array<any> = [
    { displayName: 'CUSTOMER NAME', key: 'customerName', isSort: true, class: '', width: '40%' },
    { displayName: 'CUSTOMER TEXT', key: 'customerText', isSort: true, class: '', width: '50%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
   actionButtons: Array<any> = [
    //{ displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
  constructor(private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialogService: DialogService,
    private contentService: ContentServiceService,
    ) { 

     this.filterModel = new FilterModel();
     this.customerData = new Array<CustomerModel>();
    }

  ngOnInit(): void {
    this.metaData = new Metadata();
    this.form = this.formBuilder.group({
      searchValue: [''],
     
    });

    this.getCustomerList(this.filterModel, '', '', );
  }
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getCustomerList(this.filterModel, '', '')
  }
  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  getCustomerList(filterModel: any, tags: any, roleIds: any) {
    this.contentService.getCustomerdata(filterModel).subscribe((res: any) => {
      if(res.status==0){
        this.toaster.error(res.message);
      }
      if(res.status > 0){
        console.log(res);
        this.customerData = res.responseData;
        this.metaData = res.meta;
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.customerSayID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'EDIT':
        //this.editCall(id);
        this.handleTabChange.next({ tab: "Add Customer Sayings", id: id});
        break;
      case 'DELETE':
        {
          
          this.dialogService
            .confirm(`Are you sure you want to delete this record?`)
            .subscribe((result: any) => {
              if (result == true) {
                this.deleteCustomer(id);
              }
            });
        }
        break;
        case 'DETAILS':
        //this.infoCall(id);
        break;
      default:
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getCustomerList(this.filterModel, '', '');
    }
  }

  // searching
  onSubmit() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": this.form.controls.searchValue.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getCustomerList(this.requestpages, '', '')
    //this.AdminUserList();
  }
  reset() {
    this.form.reset();
    this.InitialGridCall();
   
    this.getCustomerList(this.requestpages, '', '')
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 10,
      "pageNumber": 1,
      "searchValue": "",
      "columnName": "",
      "sortOrder": ""
    }
  }
  // end

  deleteCustomer(id:any){
    this.contentService.deleteCustomer(id).subscribe((res: any) => {
      if(res.status > 0){
        console.log(res);
        this.toaster.success(res.message, 'Success');
        //this.reset();
        this.getCustomerList(this.filterModel, '', '', );
       
      }else{
        this.metaData = new Metadata();
      }
     
    });
  }

  goto(){
    this.handleTabChange.emit({ tab: "Add Customer Sayings", id: 0});
  }

}