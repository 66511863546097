<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Subscription Plan</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/subscription-plan">Subscription Plans</a></li>
                <li class="breadcrumb-item active" aria-current="page">EditSiteSubscriptionPlan</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Edit Subscription Plan</h3>
                <a routerLink="/subscription-plan" class="btn btn-primary btnsmall">Back</a>
            </div>

            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="proSection">
                            <div class="row">
                                <input matInput formControlName="subscriptionID" hidden>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Plan Name <span class="req">*</span></mat-label>
                                        <input (keyup)="allowAlphabetOnly($event)" pattern="(\s*\S\s*)+" maxlength="99" matInput
                                            formControlName="planName">
                                        <mat-error *ngIf="form.get('planName')?.touched  && form.get('planName')?.errors?.required">
                                            Plan Name is required</mat-error>
                                            <mat-error *ngIf="form.get('planName')?.hasError('pattern')">
                                                Please enter valid plan name
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Plan Type<span class="req">*</span></mat-label>
                                        <mat-select placeholder="Plan Type"  formControlName="planID">
                                            <mat-option value="Select plan Type">Plan Type</mat-option>
                                            <mat-option *ngFor="let planType of planTypeDropdown " [value]="planType.planID">
                                                {{planType.planTypeName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                        *ngIf="form.get('planID')?.touched  && form.get('planID')?.errors?.required">
                                        Plan Type is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Number Of User<span class="req">*</span></mat-label>
                                        <input matInput (keyup)="allowOnlyNumber($event)" formControlName="numberOfUserAllowed"  maxlength="10">
                                        <mat-error
                                        *ngIf="form.get('numberOfUserAllowed')?.touched  && form.get('numberOfUserAllowed')?.errors?.required || form.get('numberOfUserAllowed')?.hasError('min')">
                                        Only numbers allowerd more than 0</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Pricing($)<span class="req">*</span></mat-label>
                                        <input matInput (keyup)="allowOnlyNumber($event)" formControlName="price" maxlength="18">
                                        <mat-error *ngIf="form.get('price')?.touched  && form.get('price')?.errors?.required ">
                                                Price is required</mat-error>
                                        <mat-error
                                        *ngIf="form.get('price')?.touched  && form.get('price')?.errors?.required || form.get('price')?.hasError('min')">
                                        Only numbers allowerd more than 0</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Status<span class="req">*</span></mat-label>
                                        <mat-select placeholder="Status"  formControlName="planStatusID">
                                            <mat-option value="Select planStatus" *ngIf="false">Plan Status</mat-option>
                                            <mat-option *ngFor="let status of planTypeStautusDropdown " [value]="status.planStatusID">
                                                {{status.planStatusName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                        *ngIf="form.get('planStatusID')?.touched  && form.get('planStatusID')?.errors?.required">
                                        Status is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Public Status<span class="req">*</span></mat-label>
                                        <mat-select placeholder="Public Status"
                                            formControlName="isPublic">
                                            <mat-option [value]="true">Public </mat-option>
                                            <mat-option [value]="false"> Non Public </mat-option>
                                            <!-- <mat-option *ngFor="let status of planTypeStautusDropdown " [value]="status.planStatusID">
                                                {{status.planStatusName}}  
                                            </mat-option> -->
                                        </mat-select>
                                        <mat-error
                                            *ngIf="form.get('isPublic')?.touched  && form.get('isPublic')?.errors?.required">
                                            Public status is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col s12 m6 form-group">
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Description<span class="req">*</span></mat-label>
                                        <textarea matInput formControlName="description" pattern="(\s*\S\s*)+"
                                            placeholder="Description" ></textarea>
                                        <mat-error *ngIf="form.get('description')?.touched  && form.get('description')?.errors?.required">
                                            Description is required</mat-error>
                                            <mat-error *ngIf="form.get('description')?.hasError('pattern')">
                                                Please enter valid description
                                            </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>

                        <div class="proSection center">
                            <button type="submit" [disabled]="form.invalid" class="btn btn-primary">Update</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>