import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { MasterServiceService } from 'src/app/common/services/master-service.service';
import { ContentServiceService } from '../../content-service.service';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';


@Component({
  selector: 'app-home-page-content',
  templateUrl: './home-page-content.component.html',
  styleUrls: ['./home-page-content.component.css']
})
export class HomePageContentComponent implements OnInit {
  userTabs: any;
  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase','SuperScript', 'SubScript', '|',
        'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
        'Outdent', 'Indent', '|',
        'CreateTable', 'CreateLink', 'Image', '|',
        'SourceCode', '|', 'Undo', 'Redo']
};
autoplaySpeedDD = [
  {"payspeedID": 5000,"speedName": "Very Slow"},
  {"payspeedID": 4000,"speedName": "Slow"},
  {"payspeedID": 3000,"speedName": "Normal(default)"},
  {"payspeedID": 2000,"speedName": "Fast"},
  {"payspeedID": 1000,"speedName": "Very Fast"},
]
  private rteEle: RichTextEditorComponent;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,
    ) {
    this.userTabs = ["User Info", "Custom Fields"]
  }
  form: FormGroup;
  //disabled = false;
  ngOnInit(): void {

    this.form = this.formBuilder.group({
      homePageID: 0,
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
      bannerImage1:[],
      bannerImage1Alt :['', [Validators.required]],
      bannerImage1Description:['', [Validators.required]],
      bannerImage2:[],
      bannerImage2Alt :['', [Validators.required]],
      bannerImage2Description:['', [Validators.required]],
      bannerImage3:[],
      bannerImage3Alt :['', [Validators.required]],
      bannerImage3Description:['', [Validators.required]],
      banner2Title: ['', [Validators.required]],
      banner2SubTitle: ['', [Validators.required]],
      banner3Title: ['', [Validators.required]],
      banner3SubTitle: ['', [Validators.required]],
      autoplaySpeed: [3000, [Validators.required]],

    });

    this.getBannerData();
  }
  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;


    }
    if(this.image1 != ''){
      this.form.controls.bannerImage1.setValue(this.image1);
    }else{
      if(this.form.controls.homePageID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
      
    }
    if(this.image2 != ''){
      this.form.controls.bannerImage2.setValue(this.image2);
    }else{
      if(this.form.controls.homePageID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    if(this.image3 != ''){
      this.form.controls.bannerImage3.setValue(this.image3);
    }else{
      if(this.form.controls.homePageID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);
    
    if(this.form.controls.homePageID.value  > 0){
      // for update
      //this.form.controls.bannerImage2.setValue(this.image2);
      this.contentServiceService.updatebannerdata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if(res.status > 0){
          this.toaster.success(res.message, 'Success');
          //this.router.navigate(['/manage-staff']);
          this.form.controls.homePageID.setValue(this.id);
  
        }else{
          this.toaster.error(res.message, 'Error');
        }
      });
    }else{
     
      this.contentServiceService.addbannerdata(this.form.value).subscribe((res: any) => {
        console.log(res);
        if(res.status > 0){
          this.toaster.success(res.message, 'Success');
          //this.router.navigate(['/manage-staff']);
  
        }else{
          this.toaster.error(res.message, 'Error');
        }
      });
    }
    
      

   

  }


  /// crop image
  imageChangedEvent: any = '';
  croppedImage1: any = '../../../assets/img/banner-img.png';
  croppedImage2: any = '../../../assets/img/banner-img.png';
  croppedImage3: any = '../../../assets/img/banner-img.png';
  imageNumber :any;
  fileChangeEvent(event: any , imgNum:any): void {
    this.imageChangedEvent = event;
    this.imageNumber = imgNum;
  }
  imageFile: any;
  imageCropped(event: ImageCroppedEvent,img:any) {
    if(this.imageNumber == '1'){
      this.croppedImage1 = event.base64;
      debugger
    }else if(this.imageNumber == '2'){
      this.croppedImage2 = event.base64;
    }else if(this.imageNumber == '3') {
      this.croppedImage3 = event.base64;
    }
    

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  finalUpload(){
    //alert('called');
    this.uploadFile(this.imageFile);
  }
  actionDialogPopup(templateRef: TemplateRef<any>,data:any,imgNumner:any) {
    this.dialog.open(templateRef,{
      data:data

    });
    
    this.fileChangeEvent(data,imgNumner);
    
  }

  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }


  imageUrl: any;
  uploadFile(event:any) {
    let reader = new FileReader(); // HTML5 FileReader API
    //let file = event.target.files[0];
    let file = event;

    const formData = new FormData();
   
      formData.append('files', file);
    this.AddBannerUpload(formData);
        
  
  }

  image1: string = '';
  image2: string = '';
  image3: string = '';
  AddBannerUpload(file:any) {
    this.contentServiceService.postDataBanner(file).subscribe((res: any) => {
      console.log(res);
      if(res.body.status > 0){

        switch (this.imageNumber) {
          case '1':
            this.image1 = res.body.responseData.imageName;
            break;
            case '2':
            this.image2 = res.body.responseData.imageName;
            break;
            case '3':
            this.image3 = res.body.responseData.imageName;
            break;
        
          default:
            break;
        }
        this.imageNumber = '';
        console.log(res.body.responseData.imageName);
      }
      
    })
  }

  id :number= 0;
  getBannerData(){
    this.contentServiceService.getbannerdata().subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.croppedImage1 = res.responseData['bannerImage1Base64'];
        this.croppedImage2 = res.responseData['bannerImage2Base64'];
        this.croppedImage3 = res.responseData['bannerImage3Base64'];
        this.id = res.responseData['homePageID'];
        this.form.patchValue({
          homePageID:res.responseData['homePageID'],
          title:res.responseData['title'],
          subTitle:res.responseData['subTitle'],
          bannerImage1:res.responseData['bannerImage1'],
          bannerImage1Description:res.responseData['bannerImage1Description'],
          bannerImage1Alt:res.responseData['bannerImage1Alt'],
          bannerImage2:res.responseData['bannerImage2'],
          bannerImage2Description:res.responseData['bannerImage2Description'],
          bannerImage2Alt:res.responseData['bannerImage2Alt'],
          bannerImage3:res.responseData['bannerImage3'],
          bannerImage3Description:res.responseData['bannerImage3Description'],
          bannerImage3Alt:res.responseData['bannerImage3Alt'],

          banner2Title:res.responseData['banner2Title'],
          banner2SubTitle:res.responseData['banner2SubTitle'],
          banner3Title:res.responseData['banner3Title'],
          banner3SubTitle:res.responseData['banner3SubTitle'],
          autoplaySpeed:res.responseData['autoplaySpeed'],

        });
        // this.image1 = this.croppedImage1;
        // this.image2 = this.croppedImage2;
        // this.image3 = this.croppedImage3;

      }else{
        this.toaster.warning("Please add record!", 'Warning');
      }
      

    });
  }

   /// end
   flag = true;
   flag2 = true;
   flag3 = true;
   // new img cropper
   getCroppedImage(event:any, num:any){
     debugger
     this.imageNumber = num;
     //this.croppedImage1 = event;
     if(this.imageNumber == '1'){
      this.croppedImage1 = event;
      this.flag = false;
    }else if(this.imageNumber == '2'){
      this.croppedImage2 = event;
      this.flag2 = false;
    }else if(this.imageNumber == '3') {
      this.croppedImage3 = event;
      this.flag3 = false;
    }
     this.imageFile = this.base64ToFile(
       event,
       'image.png',
     );
     this.uploadFile(this.imageFile);
       
     //console.log(event);
   }
   isTheImageDeleted(event:any,num:any){
     console.log(event);
     if(event){
      if(num == '1'){
        this.flag = true;
      }else if(num == '2'){
        this.flag2 = true;
      }else if(num == '3') {
        this.flag3 = true;
      }
       this.getBannerData();
     }
   
   }


}
