<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title"> Email Templates</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page"><a routerLink="/email-templates">Email
                        Templates</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Template</li>
            </ol>
        </div>
    </div>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Edit Template</h3>
                <a routerLink="/email-templates" class="btn btn-primary btnsmall">Back</a>
            </div>

            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <!-- <div class="profilePicLeft form-group30">
                        <p class="chngePic">
                            <input type="file">
                            <span class="icon centerItem"><mat-icon>edit</mat-icon></span>
                            <img src="../../../assets/img/display.jpg" alt="">
                        </p>
                    </div> -->
                    <div class="profileContentRight flex-grow-1">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100 " appearance="outline">
                                            <mat-label>Name <span class="req">*</span></mat-label>
                                            <input matInput formControlName="templateName" readonly>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Subject<span class="req">*</span></mat-label>
                                            <input matInput formControlName="templateSubject">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group"
                                        style="padding-bottom: 1.34375em;margin: 0.25em 0;">
                                        <mat-radio-group class="mt-2" formControlName="isFromEmailDefault"
                                            (change)="appliedSetting($event.value)" aria-label="Select an option">
                                            <mat-radio-button class="mt-2 " [value]=true>Default Email
                                            </mat-radio-button>
                                            <mat-radio-button class="mt-2 ml-3" [value]=false>Custom Email</mat-radio-button>
                                        </mat-radio-group>

                                        <!-- <mat-radio-group aria-label="Is Demo Allow">
                                            <mat-radio-button value="1">Deafult Email</mat-radio-button>
                                            <mat-radio-button value="2" class="ml-3">Custom Email</mat-radio-button>
                                        </mat-radio-group> -->
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline" *ngIf="FromEmailOption==false">
                                            <mat-label>From<span class="req">*</span></mat-label>
                                            <!-- <input matInput formControlName="emailFrom"> -->
                                            <input matInput type="text" name="CustomEmail" formControlName="CustomEmail"
                                            pattern="^[^\s]+(\s+[^\s]+)*$" maxlength="50" placeholder="Add Custom Email">
                                        <mat-error
                                            *ngIf="form.get('CustomEmail')?.touched  && form.get('CustomEmail')?.errors?.required">
                                            Custom Email is required
                                        </mat-error>
                                        <mat-error *ngIf="form.get('CustomEmail')?.hasError('pattern')">
                                            Please enter valid Email
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <!-- <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Custom Email<span class="req">*</span></mat-label>
                                            <input matInput formControlName="contents">
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col s12 m12 form-group">
                                        <!-- <ejs-richtextCreateor >
                                            <ng-template #valueTemplate>
                                              <p>The Rich Text Createor component is WYSIWYG ("what you see is what you get") Createor that provides the best user
                                                experience to create and update the content.
                                                Users can format their content using standard toolbar commands.</p>
                                      
                                            </ng-template>
                                          </ejs-richtextCreateor> -->
                                        <ejs-richtexteditor #textEditorValue formControlName="contents"
                                            [toolbarSettings]='tools'>
                                        </ejs-richtexteditor>
                                        <!-- <ejs-richtextCreateor
                                        [toolbarSettings]='tools'
                             [maxLength]='100' 
                              #textCreateorValue 
                              formControlName="title"
                               title="Add Title " >
                            </ejs-richtextCreateor> -->
                                    </div>
                                </div>
                            </div>
                            <!-- <ejs-richtextCreateor
                            [toolbarSettings]='tools'
                             [maxLength]='100' 
                              #textCreateorValue 
                              formControlName="title"
                               title="Add Title " >
                            </ejs-richtextCreateor> -->
                            <div class="proSection center">
                                <button type="submit" class="btn btn-primary">Update</button>
                            </div>
                        </form>
                        <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100 mat-form-field-readonly" appearance="outline">
                                            <mat-label>Name <span class="req">*</span></mat-label>
                                            <input matInput readonly="true" formControlName="templateName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Subject<span class="req">*</span></mat-label>
                                            <input matInput formControlName="templateSubject">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>From<span class="req">*</span></mat-label>
                                            <input matInput formControlName="emailFrom">
                                        </mat-form-field>
                                    </div>
                                  
                                    <div class="col s12 m12 form-group">
                                        
                                          <ejs-richtexteditor #textEditorValue formControlName="contents" [toolbarSettings]='tools'>
                                        </ejs-richtexteditor>
                                    </div>
                                </div>
                            </div>
                         
                            <div class="proSection center">
                                <button type="submit" class="btn btn-primary">Update</button>
                            </div>
                        </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>