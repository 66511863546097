<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Home Page </h2>
        <app-breadcrumb></app-breadcrumb>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </div>
    </div> -->

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Home Page</h3>
                <!-- <a routerLink="/manage-role" class="btn btn-primary btnsmall">Back</a> -->
            </div>
            <form [formGroup]="form" >
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m6">
                                        <mat-form-field class="w-100" appearance="outline">
                                          <mat-label>Select Banner Play Speed<span class="req">*</span></mat-label>
                                          <mat-select placeholder="Banner Play Speed" formControlName="autoplaySpeed">
                                            <mat-option *ngFor="let item of autoplaySpeedDD " [value]="item.payspeedID">
                                              {{item.speedName}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <p>Banner1 Title</p>
                                        <ejs-richtexteditor
                                        [toolbarSettings]='tools'
                                         [maxLength]='100' 
                                          #textEditorValue 
                                          formControlName="title"
                                           title="Add Title "
                                           >
                                        </ejs-richtexteditor>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner1 SubTitle</mat-label>
                                            <textarea maxlength="100" required formControlName="subTitle" matInput placeholder="subTitle" ></textarea>
                                            <mat-error
                                            *ngIf="form.get('subTitle')?.touched  && form.get('subTitle')?.errors?.required">
                                            Sub Title is required
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                    
                                    <!-- image 1 -->

                                    <div class="col s12 m12 form-group">
                                        <p>Banner Image 1</p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       <div class="col s12 m6 form-group " *ngIf="flag">
                                            <img [src]="croppedImage1" style="height: 200px;width: 200px;" alt="Add Image">
                                        </div>
                                        <!-- <img [src]="croppedImage1" style="height: 200px;width: 350px;" alt=""> -->
                                        &nbsp;&nbsp;&nbsp;
                                       
                                        <label for="file-upload" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload" type="file" title="Please Banner Image 1" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'1')">
                                        </label>

                                        <!-- <div class="col s12 m6 form-group " *ngIf="flag">
                                            <img [src]="croppedImage1" style="height: 200px;width: 240px;" alt="Add Image">
                                        </div> -->
                                        <!-- <div class="col s12 m6 form-group position-relative"> -->
                                            <!-- <ngx-drag-drop-crop-resize 
                                            (croppedImage)="getCroppedImage($event,'1')" oImgHeight = "11rem" 
                                            (isImageDeleted) = "isTheImageDeleted($event,'1')"
                                            oImgWidth = "15rem" >
                                            </ngx-drag-drop-crop-resize> -->
                                        <!-- </div> -->
                                     
                                       
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image1 Alt</mat-label>
                                            <input maxlength="20"  matInput placeholder="bannerImage1Alt" required name="bannerImage1Alt"
                                                formControlName="bannerImage1Alt">
                                            <mat-error
                                                *ngIf="form.get('bannerImage1Alt')?.touched  && form.get('bannerImage1Alt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image1 Description</mat-label>
                                            <textarea required maxlength="100" formControlName="bannerImage1Description" matInput placeholder="bannerImage1Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('bannerImage1Description')?.touched  && form.get('bannerImage1Description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="matCrdTitle centerItemVert justify-content-between">
                                       <br>
                                       <br>
                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <p>Banner2 Title</p>
                                        <ejs-richtexteditor
                                        [toolbarSettings]='tools'
                                         [maxLength]='100' 
                                          #textEditorValue 
                                          formControlName="banner2Title"
                                           title="Add Title "
                                           >
                                        </ejs-richtexteditor>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner2 SubTitle</mat-label>
                                            <textarea maxlength="100" required formControlName="banner2SubTitle" matInput placeholder="subTitle" ></textarea>
                                            <mat-error
                                            *ngIf="form.get('banner2SubTitle')?.touched  && form.get('banner2SubTitle')?.errors?.required">
                                            Sub Title is required
                                        </mat-error>
                                        </mat-form-field>
                                    </div>
                                     <!-- image 2 -->

                                     <div class="col s12 m12 form-group">
                                        <p>Banner Image 2<p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                    <div class="col s12 m6 form-group " *ngIf="flag2">
                                        <img [src]="croppedImage2" style="height: 200px;width: 240px;" alt="">
                                    </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload2" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload2" type="file" title="Please Banner Image 1" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'2')">
                                        </label>
                                        
                                        
                                        <!-- <div class="col s12 m6 form-group " *ngIf="flag2">
                                            <img [src]="croppedImage2" style="height: 200px;width: 240px;" alt="Add Image">
                                        </div>
                                        <div class="col s12 m6 form-group position-relative">
                                            <ngx-drag-drop-crop-resize 
                                            (croppedImage)="getCroppedImage($event,'2')" oImgHeight = "11rem" 
                                            (isImageDeleted) = "isTheImageDeleted($event,'2')"
                                            oImgWidth = "15rem" >
                                            </ngx-drag-drop-crop-resize>
                                        </div> -->
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image2 Alt</mat-label>
                                            <input maxlength="20"  matInput placeholder="bannerImage2Alt" required name="bannerImage2Alt"
                                                formControlName="bannerImage2Alt">
                                            <mat-error
                                                *ngIf="form.get('bannerImage2Alt')?.touched  && form.get('bannerImage2Alt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image2 Description</mat-label>
                                            <textarea required maxlength="100" rows="10" formControlName="bannerImage2Description" matInput placeholder="bannerImage2Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('bannerImage2Description')?.touched  && form.get('bannerImage2Description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="matCrdTitle centerItemVert justify-content-between">
                                        <br>
                                        <br>
                                     </div>
 
                                     <div class="col s12 m12 form-group">
                                         <p>Banner3 Title</p>
                                         <ejs-richtexteditor
                                         [toolbarSettings]='tools'
                                          [maxLength]='100' 
                                           #textEditorValue 
                                           formControlName="banner3Title"
                                            title="Add Title "
                                            >
                                         </ejs-richtexteditor>
                                     </div>
                                     <div class="col s12 m12 form-group">
                                         <mat-form-field class="w-100" appearance="outline">
                                             <mat-label>Banner3 SubTitle</mat-label>
                                             <textarea maxlength="100" required formControlName="banner3SubTitle" matInput placeholder="subTitle" ></textarea>
                                             <mat-error
                                             *ngIf="form.get('banner3SubTitle')?.touched  && form.get('banner3SubTitle')?.errors?.required">
                                             Sub Title is required
                                         </mat-error>
                                         </mat-form-field>
                                     </div>
                                     <!-- image 3 -->

                                     <div class="col s12 m12 form-group">
                                        <p>Banner Image 3<p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <div class="col s12 m6 form-group " *ngIf="flag3">   
                                        <img [src]="croppedImage3" style="height: 200px;width: 240px;" alt="">
                                        </div>
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload3" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload3" type="file" title="Please Banner Image 3" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'3')">
                                        </label>
                                        
                                        
                                        <!-- <div class="col s12 m6 form-group " *ngIf="flag3">
                                            <img [src]="croppedImage3" style="height: 200px;width: 240px;" alt="Add Image">
                                        </div>
                                        <div class="col s12 m6 form-group position-relative">
                                            <ngx-drag-drop-crop-resize 
                                            (croppedImage)="getCroppedImage($event,'3')" oImgHeight = "11rem" 
                                            (isImageDeleted) = "isTheImageDeleted($event,'3')"
                                            oImgWidth = "15rem" >
                                            </ngx-drag-drop-crop-resize>
                                        </div> -->
                                        </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image3 Alt</mat-label>
                                            <input required maxlength="20"  matInput placeholder="bannerImage3Alt" required name="bannerImage3Alt"
                                                formControlName="bannerImage3Alt">
                                            <mat-error
                                                *ngIf="form.get('bannerImage3Alt')?.touched  && form.get('bannerImage3Alt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Banner Image3 Description</mat-label>
                                            <textarea required maxlength="100" rows="10" formControlName="bannerImage3Description" matInput placeholder="bannerImage3Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('bannerImage3Description')?.touched  && form.get('bannerImage3Description')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
    
                            <div class="proSection center mt-3">
                                <button [disabled]="form.invalid" (click)="onSubmit()" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>

<ng-template #uploadPopup>
    <div class="chngePic no-borders ml-0 mr-2 ">
    <div class="dialog-md">
      <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title>Select Image Area</h5>
      </div>
      <mat-dialog-content class="mat-typography imgCrop">
        <div class="row">
         <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
                       format="png" (imageCropped)="imageCropped($event,'1')"
                       [resizeToWidth]="600"   
                               (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                           >
         </image-cropper>

                        <!-- <img [src]="croppedImage" /> -->
        </div>
  
      </mat-dialog-content>
    </div>
   
</div><br>
<div class="proSection center">
    <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> Upload </button>
</div>
  </ng-template>



