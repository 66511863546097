<main>

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add About Us</h3>
                <!-- <a (click)="goto()" class="btn btn-primary btnsmall">Back</a> -->
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title</mat-label>
                                            <input required maxlength="500" formControlName="title" matInput placeholder="Title">
                                            <mat-error *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Sub Title</mat-label>
                                            <textarea required maxlength="256" formControlName="subTitle" matInput placeholder="Sub Title" ></textarea>
                                            <mat-error *ngIf="form.get('subTitle')?.touched  && form.get('subTitle')?.errors?.required">
                                                Sub Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <p>About Us Image</p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       
                                        <img [src]="croppedImage1" style="height: 300px;width: 350px;" alt="">
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload" type="file" title="Please Banner Image 1" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'1')">
                                        </label>
                                     
                                       
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> Image Alt Name</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="Image Alt"
                                                formControlName="aboutUsImageAlt">
                                            <mat-error
                                                *ngIf="form.get('aboutUsImageAlt')?.touched  && form.get('aboutUsImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="aboutUsImageDescription" matInput placeholder="Image Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('aboutUsImageDescription')?.touched  && form.get('aboutUsImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>About Us Main Text</mat-label>
                                            <input required maxlength="500" formControlName="aboutUsMainText" matInput placeholder="AboutUs Main Text">
                                            <mat-error *ngIf="form.get('aboutUsMainText')?.touched  && form.get('aboutUsMainText')?.errors?.required">
                                                AboutUs Main Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>About Us Description</mat-label>
                                            <textarea required maxlength="256" formControlName="aboutUsDescription" matInput placeholder="AboutUs Description" ></textarea>
                                            <mat-error *ngIf="form.get('aboutUsDescription')?.touched  && form.get('aboutUsDescription')?.errors?.required">
                                                AboutUs Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>




                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>About Us Additional Text</mat-label>
                                            <input required maxlength="500" formControlName="additionalText" matInput placeholder="About Us Additional Text">
                                            <mat-error *ngIf="form.get('additionalText')?.touched  && form.get('additionalText')?.errors?.required">
                                                About Us Additional Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>About Us Additional Description</mat-label>
                                            <textarea required maxlength="256" formControlName="additionalDescription" matInput placeholder=" AboutUs Additional Description" ></textarea>
                                            <mat-error *ngIf="form.get('additionalDescription')?.touched  && form.get('additionalDescription')?.errors?.required">
                                                AboutUs Additional Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- Goal -->


                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Goal Text</mat-label>
                                            <input required maxlength="500" formControlName="goalText" matInput placeholder="Goal Text">
                                            <mat-error *ngIf="form.get('goalText')?.touched  && form.get('goalText')?.errors?.required">
                                                Goal Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <p>Goal Description</p>
                                        <ejs-richtexteditor 
                                        [toolbarSettings]='tools'
                                        [maxLength]='10000'
                                         #textEditorValue formControlName="goalDescription" title="Goal Description " >
                                        </ejs-richtexteditor>
                                    </div>

                                    <div class="col s12 m12 form-group">
                                        <p>Goal Image</p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       
                                        <img [src]="croppedImage2" style="height: 300px;width: 350px;" alt="">
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload2" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload2" type="file" title="Please Image " accept="image/*" (change)="actionDialogPopup(uploadPopup,$event,'2')">
                                        </label>
                                     
                                       
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label> Image Alt Name</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="goalImageAlt"
                                                formControlName="goalImageAlt">
                                            <mat-error
                                                *ngIf="form.get('goalImageAlt')?.touched  && form.get('goalImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea required maxlength="100" formControlName="goalImageDescription" matInput placeholder="Image Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('goalImageDescription')?.touched  && form.get('goalImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <!-- Customer Say Text -->

                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Customer Say Text</mat-label>
                                            <input required maxlength="500" formControlName="customerSayText" matInput placeholder="Customer Say Text">
                                            <mat-error *ngIf="form.get('customerSayText')?.touched  && form.get('customerSayText')?.errors?.required">
                                                Customer Say Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Customer Say Description</mat-label>
                                            <textarea required maxlength="256" formControlName="customerSayDescription" matInput placeholder="Customer Say Description" ></textarea>
                                            <mat-error *ngIf="form.get('customerSayDescription')?.touched  && form.get('customerSayDescription')?.errors?.required">
                                                Customer Say Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Partnes Text</mat-label>
                                            <input required maxlength="500" formControlName="partnesText" matInput placeholder="Partnes Text">
                                            <mat-error *ngIf="form.get('partnesText')?.touched  && form.get('partnesText')?.errors?.required">
                                                Partnes Text is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Partnes Description</mat-label>
                                            <textarea required maxlength="256" formControlName="partnesDescription" matInput placeholder="Partnes Description" ></textarea>
                                            <mat-error *ngIf="form.get('partnesDescription')?.touched  && form.get('partnesDescription')?.errors?.required">
                                                Partnes Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>


                                </div>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>

<ng-template #uploadPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title>Select Image Area</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <div class="row">
         <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="10/8" [resizeToWidth]="500" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event,'1')"
                            (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                           >
                        </image-cropper>

                        <!-- <img [src]="croppedImage" /> -->
        </div>
  
      </mat-dialog-content>
    </div>
    <div class="proSection center">
        <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> close </button>
    </div>
   
  </ng-template>

