import {SelectionModel} from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageRolesService } from '../common/manage-roles.service';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { RoleModel } from './RoleModel';
import { DialogService } from '../common/core-services/DialogService';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-role-and-permissions',
  templateUrl: './role-and-permissions.component.html',
  styleUrls: ['./role-and-permissions.component.css']
})
export class RoleAndPermissionsComponent implements OnInit {
  requestpages: any;
  form: FormGroup;
  metaData: any;
   private filterModel: FilterModel;
   usersData: RoleModel[];

   classToggled = false;

   public toggleField() {
    this.classToggled = !this.classToggled;
  }
 
   displayedColumns: Array<any> = [
    { displayName: 'NAME', key: 'roleName', isSort: true, class: '', width: '30%' },
    { displayName: 'Description', key: 'roleDescription', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
   actionButtons: Array<any> = [
    // { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
constructor(
  private dialog: MatDialog,
  private manageRolesService: ManageRolesService,
  private formBuilder: FormBuilder,
  private router: Router,
  private toaster: ToastrService,
  private dialogService: DialogService
) { 
  this.filterModel = new FilterModel();
  this.usersData = new Array<RoleModel>();
}
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];



ngOnInit(): void {
  this.metaData = new Metadata();
  this.form = this.formBuilder.group({
    roleName: [''],
    roleDescription: [''],
  });

  this.getUsersList(this.filterModel, '', '', );
  // this.InitialGridCall()
  // this.AdminUserList();
}


onPageOrSortChange(changeState?: any) {
  this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
  this.getUsersList(this.filterModel, '', '')
}

onSubmit() {
  this.requestpages = {
    "pageSize": 10,
    "pageNumber": 1,
    "roleName": this.form.controls.roleName.value,
    "roleDescription": this.form.controls.roleDescription.value,
    "columnName": "",
    "sortOrder": ""
  }
  console.log(this.requestpages);
  this.getUsersList(this.requestpages, '', '')
  //this.AdminUserList();
}
//metaData: any;
// AdminUserList() {
//   this.adminUserService.AllAdminUser(this.requestpages).subscribe((res: any) => {
//     if(res.status > 0){
//       console.log(res);
//       this.usersData = res.responseData;
//       this.metaData = res.meta;
     
//     }else{
//       this.metaData = new Metadata();
//     }
   
//   });
// }

onTableActionClick(actionObj?: any) {
  const id = actionObj.data && actionObj.data.roleID;
  switch ((actionObj.action || '').toUpperCase()) {
    case 'EDIT':
      this.editCall(id);
      break;
    case 'DELETE':
      {
        
        this.dialogService
          .confirm(`Are you sure you want to delete this role?`)
          .subscribe((result: any) => {
            if (result == true) {
              this.deleteCall(id)
            }
          });
      }
      break;
      case 'DETAILS':
      //this.infoCall(id);
      break;
    default:
      break;
  }
}
applyFilter(searchText: string = '') {
  this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  if (searchText.trim() == '' || searchText.trim().length >= 3) {
    this.getUsersList(this.filterModel, '', '');
  }
}

getUsersList(filterModel: any, tags: any, roleIds: any) {
  this.manageRolesService.GetRolesList(filterModel).subscribe((res: any) => {
    if(res.status==0){
      this.toaster.error(res.message);
    }
    if(res.status > 0){
      console.log(res);
      this.usersData = res.responseData;
      this.metaData = res.meta;
     
    }else{
      this.metaData = new Metadata();
    }
   
  });
}

setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
  this.filterModel.pageNumber = pageNumber;
  this.filterModel.pageSize = pageSize;
  this.filterModel.sortOrder = sortOrder;
  this.filterModel.sortColumn = sortColumn;
  this.filterModel.columnName = sortColumn;
  this.filterModel.searchText = searchText;
}

// infoCall(staffId: any){
//   //this.router.navigate(['/view-staff/']);
//   this.router.navigate(["/view-staff/"], { queryParams: { id: staffId } });
// }
// editCall(staffId: any){
//   //this.router.navigate(['/view-staff/']);
//   this.router.navigate(["/edit-staff/"], { queryParams: { id: staffId } });
// }

deleteId:any;
actionDialogPopup(templateRef: TemplateRef<any>,id:any) {
  this.dialog.open(templateRef,{
    data:id

  });
  this.deleteId = id;
}

deleteCall(id: any) {
  this.manageRolesService.deleteRole(id).subscribe((res: any) => {

    console.log(res);
    if (res.status > 0) {
      this.toaster.success(res.message, 'Success');
      //this.reset();
      this.getUsersList(this.filterModel, '', '', );
      //this.dialog.closeAll();

    } else {
      this.toaster.error(res.message, 'Error');
    }
  });
}


reset() {
  this.form.reset();
  this.InitialGridCall();
 
  this.getUsersList(this.requestpages, '', '')
}

InitialGridCall() {
  this.requestpages = {
    "pageSize": 10,
    "pageNumber": 1,
    "roleName": "",
    "roleDescription": "",
    "columnName": "",
    "sortOrder": ""
  }
}


editCall(id:any){
  this.router.navigate(["/edit-role/"], { queryParams: { id: id } });

}







}


