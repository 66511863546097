import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, Enquiries } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EnquiriesService {
  constructor(private http : HttpClient) { }

  GetEnquiriesList(data:any) {
    return this.http.post(`${environment.baseUrl}${Enquiries.getEnquiriesList}`,data);
  }

  GetEnqueryDetailsById(Id:any) {
    return this.http.get(`${environment.baseUrl}${Enquiries.getEnquiryDetailsByIdList}${Id}`);
 }

AllowedDemo(id:number) {
  return this.http.get(`${environment.baseUrl}${Enquiries.allowedDemoAPI}${'?id=' + id}`);
}

denyDemo(id:number) {
  return this.http.get(`${environment.baseUrl}${Enquiries.denyDemoAPI}${'?id=' + id}`);
}

sendResponse(data:any) {
  debugger;
  return this.http.post(`${environment.baseUrl}${Enquiries.sendResponseAPI}`,data);
}

getResponse(data:any) {
  return this.http.post(`${environment.baseUrl}${Enquiries.getResponseAPI}`,data);
}

}
