export class PlanTypeDropdown
{
    
    public PlanID :  Number
 
    public PlanTypeName: string
} 


export class PlanTypeStautusDropdown
{
    
    public PlanStatusID :  Number
 
    public PlanStatusName: string
} 

export class Metadata
{
    totalRecords:number = 0;
    currentPage:number  = 1;
    pageSize :number = 5;
    defaultPageSize :number = 5;
    totalPages :number  = 0;
}
export class FilterModel
{
    pageNumber:number=1;
    pageSize:number=10;
    sortColumn:string='';
    sortOrder:string='';
    searchText:string='';
    columnName:string='';
}

//  this.obj= {
//       pageSize: 25,
//       pageNumber: 1,
//       columnName: "",
//       templateName: "",
//       templateSubject: "",
//       sortOrder: "string"
//     }
export class FilterEmailModel {
    pageNumber: number = 1;
    pageSize: number = 10;
    sortColumn: string = '';
    sortOrder: string = 'string';
    searchText: string = '';
    columnName: string = '';
    templateSubject: string = '';
    templateName: string = '';
}
export  class Meta {
    totalPages?: number = 0;
    pageSize?: number = 5;
    currentPage?: number = 1;
    defaultPageSize?: number = 5
    totalRecords?: number = 0
  }

  export class FilterPageModel {
    totalRecords: number = 0;
    currentPage: number = 0;
    indexPage: number = 0;
    pageSize: number = 5;
    totalPages: number = 0;
    pageNumber: number = 0;
    sortColumn: string = '';
    sortOrder: string = '';
    searchText: string = '';
    columnName: string = '';
    planName: string = '';
    planType: string = '';
    isSercureH3TechPlan:boolean= true;
    isTwilioPlan:boolean= true;
  }



