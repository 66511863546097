<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title">Manage Staff</h2>

    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Staff</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Staffs</h3>
        <a routerLink="/add-staff" class="btn btn-primary btnsmall">Add Staff</a>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <span (click)="toggleField()"><i class="fas fa-filter" ></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Name</mat-label>
                      <input maxlength="20" (keypress)="allowAlphabetOnly($event)" matInput type="text" formControlName="adminName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput maxlength="15" (keypress)="allowOnlyNumber($event)" type="text" formControlName="contactNumber">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                       <mat-label>Select Role</mat-label>
                                            <mat-select placeholder="Role" formControlName="roleName">
                                                <mat-option value="Select Role">Roles</mat-option>
                                                <mat-option *ngFor="let role of roles" [value]="role.roleName">
                                                    {{role.roleName}}
                                                </mat-option>
                                            </mat-select>
                                            </mat-form-field>
                  </div>
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>

                </div>
              </form>
            </div>
          </div>
          <div class="rightTableActions centerItemVert">
            <div class="viewColumns tableActions">
              <a (click)="actionDialogPopup(hideShowColPopup,$event)" title="Hide/Show Column"><i class="fas fa-columns"></i>
              </a>
            </div>

          </div>

        </div>
        <div class="mat-elevation-z8">

        </div>
      </div>

      <app-data-table [inputColumns]="displayedColumns" [inputSource]="usersDatas" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table>
    </div>
  </div>
</main>

<ng-template #loginPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Reset Password</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <form>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline" floatLabel="always">
            <mat-label>Username</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
            <input matInput value="Sharma">
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17 center">
          <button type="button" class="btn btn-primary" mat-dialog-close>Send</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #hideShowColPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Hide/Show Colums</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a (click)="selectAll()" class="underlinedLink">Select All</a> / <a (click)="unselectAll()"
          class="underlinedLink">Unselect All</a>
      </p>
      <form [formGroup]="columnForm">
        <div class="row-flex">
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" (change)="onSelectColumn()"
              formControlName="name">
              Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="userName">
              Username
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="role">
              Role
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="email">
              Email
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="phoneNumber">
              Phone Number
            </mat-checkbox>
          </div>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>
<ng-template #resetPassword>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Reset Password</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="resetForm" (ngSubmit)="savePassword()">
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline" floatLabel="always">
            <mat-label>Username</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
            <input matInput readonly="true" formControlName="userName" required />
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Password</mat-label>
            <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required 
            pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
            <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            <mat-error *ngIf="resetForm.get('password')?.touched  && resetForm.get('password')?.errors?.required">
                                              Password is required
                                          </mat-error>
            <mat-error *ngIf="resetForm.get('password')?.errors?.pattern">
              Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <!-- <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span> -->
            <input matInput formControlName="confirmPassword" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
            <span mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
              <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
            </span>
            <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
          </mat-form-field>
        </div>
        <div class="form-group17 center">
          <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid"  mat-dialog-close>Send</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>

<ng-template #deletePopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h5 mat-dialog-title> Do you want to delete ?</h5>
    </div>
    <mat-dialog-content class="mat-typography">
      <!-- <p class="mb-4 center ">Do you want to delete ?</p> -->
      <div class="btnsWidget form-group center">
        <!-- <button (click)=confirmDelete() class="btn btn-primary btn-sm">Yes</button> -->
        <button mat-dialog-close class="btn btn-reset btn-sm">NO</button>

      </div>
    </mat-dialog-content>
  </div>
</ng-template>