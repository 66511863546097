import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment, siteSubscriptionPlan } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionPlanService {

  constructor(private http : HttpClient) { }

  AddSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSubscriptionPlan.addSubscriptionPlanAPI}`,data);
  }

  AllSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSubscriptionPlan.allSubscriptionPlanAPI}`,data);
  }
  
  GetSubscriptionPlanById(Id:any) {
     return this.http.get(`${environment.baseUrl}${siteSubscriptionPlan.getSubscriptionPlanByIdAPI}${Id}`);
  }

  UpdateSubscriptionPlan(data:any) {
    return this.http.post(`${environment.baseUrl}${siteSubscriptionPlan.updateSubscriptionPlanAPI}`,data);
  }

  DeleteSubscriptionPlan(Id:any) {
    return this.http.get(`${environment.baseUrl}${siteSubscriptionPlan.deleteSubscriptionPlanAPI}${'?id=' + Id}`);
  }

  AddPlanType(data:any) {
    debugger;
    return this.http.post(`${environment.baseUrl}${siteSubscriptionPlan.addPlanTypeAPI}`,data);
  }
}
