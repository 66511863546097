import { Component, OnInit, ViewChild,TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ManageStaffService } from 'src/app/common/services/manage-staff.service';
import { MasterServiceService } from 'src/app/common/services/master-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {

  form: FormGroup;
  adminUserID:number;
  data:string;
  previewUrl:any = '../../../assets/img/display.jpg';


  @ViewChild('phone') phone: any;
  constructor(
    private formBuilder : FormBuilder,
    private activatedRoute:ActivatedRoute,
    private manageStaffService:ManageStaffService,
    private masterServiceService:MasterServiceService,
    private router: Router,
    private toaster: ToastrService,
    private commonService :CommonService,
    private dialog: MatDialog,
    
    
  ) { }
  countries:any;
 
  roles:any;
  bloodGroups:any;
  genders:any;
  maxDate = new Date();
  minDate = new Date(1900, 0, 1);
  hide = true;
  hide1 = true;
  ngOnInit(): void {
  
     //this.adminUserID = this.activatedRoute.snapshot.params.Id 
     this.activatedRoute.queryParams.subscribe(params => {
      this.adminUserID = params.id==undefined?null:params.id;
    });
  

     this.form = this.formBuilder.group({
     adminUserID:this.adminUserID,
     firstName: ['', Validators.required],
     lastName: ['', Validators.required],
  
     dob: ['', Validators.required],
      
      gender: [0, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      
     
      username: ['', Validators.required],
      
      bloodGroupID: [0, Validators.required],
      address:['', Validators.required],
      city:['', Validators.required],
      contactNumber: ['', Validators.required],
      countryID: [0, Validators.required],
      stateID: [0, Validators.required],
      zipCode: ['', Validators.required],
      roleID: [0, Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
       profilePic:[''],
      isVerified:[true]
  
  
  
    });
    // let date = new Date();
    // date.setFullYear( date.getFullYear() - 1 );
    // this.maxDate =  new Date(date);
    this.GetStaffById();

this.GetCountiresList();
this.GetRolesList();
this.GetBloodGroupList();
this.GetGenderList();

}


  GetStaffById(){
        this.manageStaffService.GetStaffById(this.adminUserID).subscribe((res:any) => {
          if(res.responseData.profilePic != ''){
            this.croppedImage=res.responseData.profilePic ? res.responseData.profilePic : '../../../assets/img/display.jpg';
          }
          
      this.form.patchValue({
        adminUserID:res.responseData['adminUserID'],

        firstName: res.responseData['firstName'],
        lastName: res.responseData['lastName'],
        
        dob: res.responseData['dob'],
          
         gender:res.responseData['gender'],
         email: res.responseData['email'],
         
        
         username:res.responseData['username'],
         
         bloodGroupID: res.responseData['bloodGroupID'],
         address:res.responseData['address'],
         city:res.responseData['city'],
         contactNumber: res.responseData['contactNumber'],
         countryID: res.responseData['countryID'],
         stateID: res.responseData['stateID'],
         zipCode: res.responseData['zipCode'],
         roleID: res.responseData['roleID'],
         password: res.responseData['password'],
         confirmPassword: res.responseData['password'],
        // profilePic:res['profilePic'],
          isVerified:res.responseData['isVerified']
  



      }); 
      this.GetStateList(res.responseData['countryID']);
      this.data = res.responseData;
      console.log("sae",this.data)
    });
}
GetCountiresList() {
  this.masterServiceService.GetCountryDropdown().subscribe((res: any) => {
    this.countries = res.responseData;
  })
  }
  
  GetRolesList() {
  this.manageStaffService.GetRolesDropdown().subscribe((res: any) => {
    this.roles = res.responseData;
  })
  }
  
  GetBloodGroupList() {
  this.masterServiceService.GetBloodGroupDropdown().subscribe((res: any) => {
    this.bloodGroups = res.responseData;
  })
  }
  


onSubmit(){
  if (this.form.invalid) {
    return;
  }
  this.updateStaff();
}
onPasswordChange() {
  if (this.confirm_password.value == this.password.value) {
    this.confirm_password.setErrors(null);
  } else {
    this.confirm_password.setErrors({ mismatch: true });
  }
}

// getting the form control elements
get password(): AbstractControl {
  return this.form.controls['password'];
}

get confirm_password(): AbstractControl {
  return this.form.controls['confirmPassword'];
}
profileImage: string = '';
updateStaff(){
  if (this.profileImage != '') {
    this.form.controls.profilePic.setValue(this.profileImage);
  }
  console.log("PHONENO",this.phone)
  this.form.value.contactNumber = this.phone.numberInstance.nationalNumber
  this.form.value.contactNumberCountryCode = this.phone.numberInstance.countryCallingCode;
  this.form.value.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
  
  this.manageStaffService.UpdateStaff(this.form.value).subscribe((res: any) =>{
  console.log("update", res);
  console.log(res);
  if(res.status > 0){
    this.toaster.success(res.message, 'Success');
    this.router.navigate(['/manage-staff']);

  }else{
    this.toaster.error(res.message, 'Error');
  }

  })
}
GetGenderList() {
  this.masterServiceService.GetGenderDropdown().subscribe((res: any) => {
    this.genders= res.responseData;
  })
}

states:any;
GetStateList(countryId:any) {
  this.masterServiceService.GetStateDropdown(countryId).subscribe((res: any) => {
    this.states = res.responseData;
    console.log(res);
  })
}


allowOnlyNumber(event:any) {
  this.commonService.allowOnlyNumber(event);
}
allowAlphabetOnly(event:any) {
  this.commonService.allowAlphabetOnly(event);
}
noWhitespaceValidator(event:any){
  console.log("CONTROL",event.target.value)
  const isWhitespace = (event.target.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : event.target.value = '';
}
fileChangeEvent(event: any): void {
  this.imageChangedEvent = event;
}
actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
  this.dialog.open(templateRef, {
    data: data

  });

  this.fileChangeEvent(data);

}
finalUpload() {
  //alert('called');
  this.uploadFile(this.imageFile);
}
imageChangedEvent: any = '';
croppedImage: any = '../../../assets/img/display.jpg';
imageFile: any;
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.imageFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,

    )
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  uploadFile(event: any) {
    let file = event;

    const formData = new FormData();
    //for (let file of this.filesdata)
    formData.append('files', file);
    this.AddProfile(formData);
  }
  AddProfile(file: any) {
    this.masterServiceService.postData(file).subscribe((res: any) => {
      if (res.body.status > 0) {
        this.profileImage = res.body.responseData.imageName;
        console.log(this.profileImage);
      }

    })
  }
  base64ToFile(data: any, filename: any) {

    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}

