import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterEmailModel, FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { EmailTemplateModel } from './EmailTemplateModel';
import { EmailTemplateService } from '../common/services/emailTemplateService/email-template.service';
import { DialogService } from '../common/core-services/DialogService';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  // classToggled = false;
  // dataSource:any;
  // requestpages:any;
  // form: FormGroup;

  // public toggleField() {
  //   this.classToggled = !this.classToggled;  
  // }

  // @ViewChild(MatSort)
  // sort!: MatSort;
  // constructor(
  //   private dialog: MatDialog,
  //   private emailTemplateService:EmailTemplateService,
  //   private formBuilder: FormBuilder,
  //   private activatedRoute: ActivatedRoute,
  //   private router: Router

  //   ) { }
  // displayedColumns: string[] = ['templateName', 'templateSubject', 'actions'];

  // ngAfterViewInit(): void {
  //   this.dataSource.sort = this.sort;	
  // }

  // ngOnInit(): void {
  //   this.form = this.formBuilder.group({
  //     templateName: [''],
  //     templateSubject: [''],

  //   });
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "templateName": "",
  //     "templateSubject": "",
  //     "sortOrder": "string"
  //   }

  // this.EmailTemplateList();

  // }

  // actionDialogPopup(templateRef: TemplateRef<any>) {
  //   this.dialog.open(templateRef);
  // }

  // onSubmit(){
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "templateName": this.form.controls.templateName.value,
  //     "templateSubject": this.form.controls.templateSubject.value,
  //     "sortOrder": "string"
  //   }
  //   this.EmailTemplateList()
  // }

  // reset(){
  //   this.form.reset();
  // }


  // EmailTemplateList(){
  //   this.emailTemplateService.AllEmailTemplate(this.requestpages).subscribe((res:any) => {
  //     this.dataSource = res;
  //     console.log(this.dataSource)
  //   });
  // }

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterEmailModel;
  emailTemplateListData: EmailTemplateModel[];

  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  // displayedColumns: Array<any> = [
  //   { displayName: 'Sr No.', key: 'SrNo', class: '', width: '10%' },
  //   { displayName: 'Name', key: 'templateName', isSort: true, class: '', width: '20%' },
  //   { displayName: 'Type', key: 'templateSubject', isSort: true, class: '', width: '20%' },
  //   { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  // ];
  // actionButtons: Array<any> = [
  //   //{ displayName: 'Info', key: 'Info', class: 'fas fa-info-circle' },
  //   { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
  //   // { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  // ];


  constructor(
    private dialog: MatDialog,
    private router: Router,
    private emailTemplateService: EmailTemplateService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private toaster: ToastrService,
  ) {
    this.filterModel = new FilterEmailModel();
    this.emailTemplateListData = new Array<EmailTemplateModel>();
  }
  displayedColumns: string[] = [ 'templateName', 'emailFrom', 'modifiedDate', 'action'];


  sortData(event: any) {
    console.log(event);
    this.filterModel.sortColumn = event.active;
    this.filterModel.sortOrder = event.direction;
    this.getEmailTemplateList(this.filterModel);
  }

  public handlePage(e: any) {
   
    //console.log( "Search Form Value",this.assessmentSerchingObj)
    if(this.requestpages===undefined){
     this.filterModel.pageNumber = e.pageIndex + 1;
     this.filterModel.pageSize = e.pageSize;
     this.getEmailTemplateList(this.filterModel);
 
    }
    else{
     this.filterModel.pageNumber = e.pageIndex + 1;
     this.filterModel.pageSize = e.pageSize;
     this.getEmailTemplateList(this.filterModel);
    }
   }
  ngOnInit(): void {
    this.metaData = new Metadata();

    this.form = this.formBuilder.group({
      templateName: [''],
      templateSubject: [''],

    });
    this.getEmailTemplateList(this.filterModel);
    // this.InitialGridCall()
    // this.AdminUserList();
  }

  // onPageOrSortChange(changeState?: any) {
  //   this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
  //   this.getEmailTemplateList(this.filterModel)
  // }

  onSubmit() {

    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "columnName": "",
      "templateName": this.form.controls.templateName.value,
      "templateSubject": this.form.controls.templateSubject.value,
      "sortOrder": "string"
    }
    console.log(this.requestpages);
    this.getEmailTemplateList(this.requestpages)
  }
  // onTableActionClick(actionObj?: any) {
  //   const id = actionObj.data && actionObj.data.emailTemplateID;
  //   switch ((actionObj.action || '').toUpperCase()) {
  //     case 'EDIT':
  //       this.editCall(id);
  //       break;
  //     // case 'DELETE':
  //     //   {

  //     //     this.dialogService
  //     //       .confirm(`Are you sure you want to delete this user?`)
  //     //       .subscribe((result: any) => {
  //     //         if (result == true) {
  //     //           this.DeleteStaff(id);
  //     //         }
  //     //       });
  //     //   }
  //     //   break;
  //     // case 'INFO':
  //     //   this.infoCall(id);
  //     //   break;
  //     // default:
  //     //   break;
  //   }
  // }
  // applyFilter(searchText: string = '') {
  //   this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
  //   if (searchText.trim() == '' || searchText.trim().length >= 3) {
  //     this.getEmailTemplateList(this.filterModel);
  //   }
  // }
  // obj:any
  getEmailTemplateList(filterModel: any) {
  //  this.obj= {
  //     pageSize: 25,
  //     pageNumber: 1,
  //     columnName: "",
  //     templateName: "",
  //     templateSubject: "",
  //     sortOrder: "string"
  //   }
    this.emailTemplateService.AllEmailTemplate(filterModel).subscribe((res: any) => {
      console.log("Email",res);
      if(res.status==0){
        this.toaster.error(res.message);
      }
      if (res.status > 0) {
        console.log("Email",res);
        this.emailTemplateListData = res.responseData;
        this.metaData = res.meta;

        //Here are adding new property
        this.emailTemplateListData.forEach((element, index) => {
          element.SrNo = (index) + 1;
        });
      } else {
        this.metaData = new Metadata();
      }
      console.log(this.emailTemplateListData)
    });
  }

  deleteEmailTemplate(id: any) {
    this.dialogService.confirm(`Are you sure you want to template ?`).subscribe((result: any) => {
      if (result == true) {
        debugger;
        this.emailTemplateService.DeleteEmailTemplates(id).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.getEmailTemplateList(this.filterModel);
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }
    });
  }


  // setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
  //   this.filterModel.pageNumber = pageNumber;
  //   this.filterModel.pageSize = pageSize;
  //   this.filterModel.sortOrder = sortOrder;
  //   this.filterModel.sortColumn = sortColumn;
  //   this.filterModel.columnName = sortColumn;
  //   this.filterModel.searchText = searchText;
  // }

  // infoCall(transactionID: any) {
  //   //this.router.navigate(['/view-staff/']);
  //   this.router.navigate(["/transaction-details/"], { queryParams: { id: transactionID } });
  // }

  editCall(emailTemplateID: any){
    this.router.navigate(["/edit-template/"], { queryParams: { id: emailTemplateID } });
  }


  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getEmailTemplateList(this.requestpages)
  }

  InitialGridCall() {
    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "columnName": "",
      "templateName": "",
      "templateSubject": "",
      "sortOrder": "string"
    }
  }
}


