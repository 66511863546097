export class RoleModelBind{
    roleScreenPermissions:parentNode[]
}

export class parentNode{
    screenID:number = 0;
    screenFeature:childNode[]
}

export class childNode{
    screenFeatureID: number = 0;
}