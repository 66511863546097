import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ManageRolesService } from 'src/app/common/manage-roles.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleModel } from '../RoleModel';
import { CommonService } from 'src/app/common/core-services/common.Service';


 @Component({
  selector: 'app-edit-role-and-permission',
  templateUrl: './edit-role-and-permission.component.html',
  styleUrls: ['./edit-role-and-permission.component.css']
  
})


export class EditRoleAndPermissionComponent implements OnInit  {
  
  form: FormGroup;

  constructor(private manageRolesService:ManageRolesService,
    private formBuilder:FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private activatedRoute:ActivatedRoute,
    private commonService :CommonService
    ) {  }

  roleData:any;
  dropdownEnabled = true;
  items: TreeviewItem[];
  values: number[];
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: false,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight:3000
  });
  buttonClasses = [
    'btn-outline-primary',
    'btn-outline-secondary',
    'btn-outline-success',
   
  ];
  buttonClass = this.buttonClasses[0];
  roleId:any;
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      roleName: ['', [Validators.required]],
      roleDescription: [''],
      
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.roleId = params.id==undefined?null:params.id;
    });
    this.RoleScreensList(this.roleId);

  }

  RoleScreensList(Id:any){
    this.manageRolesService.GetEditRoleScreens(Id).subscribe((res:any) => {
      console.log(res);
      if(res.status > 0){

        let data =res.responseData[1]
        this.items =  [  new TreeviewItem({
          text: 'Select All', value: 0,checked: false,
          children:res.responseData[0]
        })]
       
        this.form.patchValue({
          roleName: data.roleName,
          roleDescription: data.roleDescription
        });
        console.log(this.roleData );
      }
      
    });
  }

  onFilterChange(value: any): void {
    console.log('filter:', value);
  }
  roleList :any = [];
  onSelectedChange(value: any) {
    this.roleList = []
    console.log(value);
    this.roleList = value;
  }

  filterRolebyData(){

    let parentArr = [];
    let childArr = [];
    let xx = ['3#1', '3#3', '3#6', '3#9', '4#2', '4#4', '4#7', '4#10'];
    let numCheck='0';
    for (let i = 0; i < xx.length; i++) {
      const element = xx[i];
      let str = element.split('#');
      let childData ={
        "screenFeatureID": str[1]
      }
      // if(str[0] == numCheck){
        
      //    childArr.push(childData)
      // }else{
      //     if(numCheck == '0'){
      //       childArr.push(childData)
      //     }
      // }
      childArr.push(childData)
      
      let data ={
        "screenID": str[0],
        "screenFeature": childArr
      };
      //parentArr.push(data);
      if(numCheck != '0'){
        let findVal = parentArr.find(x => x.screenID == numCheck);
        if(findVal){
          for (let j = 0; j < parentArr.length; j++) {
            const elementx = parentArr[j];
            
          }
        }
      }else{
        parentArr.push(data);
      }
      numCheck = str[0];
      
    }

  }

  submitted = false;
  formRequest:any;
  onSubmit(){
    this.submitted = true;
   
    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;
    }
    if(this.roleList.length == 0){
      this.toaster.error('Atleast one permission select!', 'Error');
      return;
    }
   
    let parentArr = [];
    let childArr = [];
    let childData ={
      "screenFeatureID": 3
    }
    childArr.push(childData);
    let data ={
      "screenID": 3,
      "screenFeature": childArr
    };
    parentArr.push(data);
    console.log(this.form.value);
    let bdata = "'"+this.roleList +"'";
    this.formRequest = {     
      "roleID":this.roleId,
      "roleName": this.form.controls.roleName.value.trim(),
      "roleDescription": this.form.controls.roleDescription.value,
      //"roleScreenPermissions":parentArr
      "stringList": bdata// this.roleList
    }

    this.RoleAdd();
  
  }
  RoleAdd(){
    this.manageRolesService.EditRolesdata(this.formRequest).subscribe((res:any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        this.router.navigate(['/manage-role']);
      }else{

        this.toaster.error(res.message, 'Error');
      }
      
    });
  }


  // arrParentval :any[] = [];
  // uniqueArray:any[] = [];
  // rowArrayval:any[] = [];
  bindData(){
    let arr = ['1#11','1#22','1#33','2#2002','2#2003','2#2004','3#330033','3#330034','3#330034' ];

    let arrParentval = [];
    let arrChild = [];
    let  uniqueArray = [];
    let rowArrayval:any[] = [];

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];

      let parentId = element.split('#');

      arrParentval.push(parentId[0]);



    }
    uniqueArray = arrParentval.filter((v:any, i:any, a:any) => a.indexOf(v) === i);
    let parentData;
    
    let rowArrtemp:any[] = [];
    uniqueArray.forEach((parentId :any)=> {

       parentData = {
        "screenID": parentId,
        "screenFeature": rowArrtemp
      }
      rowArrayval.push(parentData);
    });

    let childObj  =[];
    let finalparent = [];
    for (let j = 0; j < arr.length; j++) {
      const element = arr[j];
      let getId = element.split('#');

      let childData = {
        "screenFeatureID": getId[1]
      }
      let test = rowArrayval.find((x :any) => x.screenID === getId[0]).screenFeature.push(childData);

      // for (let k = 0; k < rowArrayval.length; k++) {
      //   const ele = rowArrayval[k];
      //   if(ele.screenID == getId[0]){
      //     rowArrayval[k].screenFeature.push(childData);
      //   }
        
      // }

    }

    console.log(rowArrayval);
 }



 allowOnlyNumber(event:any) {
  this.commonService.allowOnlyNumber(event);
}
allowAlphabetOnly(event:any) {
  this.commonService.allowAlphabetOnly(event);
}
noWhitespaceValidator(event:any){
  console.log("CONTROL",event.target.value)
  const isWhitespace = (event.target.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : event.target.value = '';
}
}
