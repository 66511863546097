import { Component, OnInit, TemplateRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdminUserService } from '../common/services/adminUserService/admin-user.service';
import { ManageStaffService } from '../common/services/manage-staff.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  siteKey: string;
  IsExitEmail: FormGroup;
  forgotPasswordForm: FormGroup;
  hide = true;
  hide1 = true;
  constructor(
    private router: Router,
    private adminUserService: AdminUserService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
    this.siteKey = '6Lfaa4McAAAAALd9OmYu1P2YChQWVI7NEuG1K1U6';
    // this.router.navigate(['path/to'])
    // .then(() => {
    //   window.location.reload();
    // });
  }
  Email:any;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.Email = params.id == undefined ? null : params.id;
    });

    console.log("Email", this.Email);
    
    this.IsExitEmail = this.formBuilder.group({
      email: ['', Validators.required],
    });

    this.forgotPasswordForm = this.formBuilder.group({
      password: ['', Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')],
      confirmPassword: ['', Validators.required]
    });
  }

  email: any;
  userEmail: any;
  confirmEmail(linkSentPopup: TemplateRef<any>) {
    this.adminUserService.CheckAdminEmail(this.IsExitEmail.value).subscribe((res: any) => {
      if (res.status > 0) {
        this.userEmail = res.responseData
        this.forgotPasswordForm.reset();
        this.dialog.open(linkSentPopup);
      }
      else {
        this.toaster.error(res.message);
      }
    });
  }

  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
      this.forgotPasswordForm.setErrors({ mismatch: true });
    }
  }

  forgotPasswordObj: any;
  HidePop=true;
  submit() {
    debugger;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.forgotPasswordObj = {
      "email": this.userEmail,
      "password": this.forgotPasswordForm.value.password,
    }
    console.log("ONSAVEPASSWORD", this.forgotPasswordObj)
    this.adminUserService.ForgotPassword(this.forgotPasswordObj).subscribe((res: any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
        this.userEmail=null;
        this.forgotPasswordForm.reset();
        this.HidePop = true;
        this.router.navigate(['']);
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }
  // getting the form control elements
  get password(): AbstractControl {
    return this.forgotPasswordForm.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.forgotPasswordForm.controls['confirmPassword'];
  }

}
