import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AccountServiceService } from '../common/services/AccountService/account-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../common/core-services/common.Service';
//import { BehaviorSubject, Observable } from 'rxjs';
//import { User } from '../common/Models/user';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //private currentUserSubject: BehaviorSubject<User>;
  //public currentUser: Observable<User>;
  form: FormGroup;
  submitted = false;
  //siteKey:string;
  rememberMe: any;
  formRequest: any;
  show = false;
  dataSource: any;

  
  constructor(
    private formBuilder: FormBuilder,
    private accountServiceService: AccountServiceService,
    private router: Router,
    private toaster: ToastrService,
    private commonService: CommonService
  ) {
    //this.siteKey = '6Lfaa4McAAAAALd9OmYu1P2YChQWVI7NEuG1K1U6';
    this.router.navigate(['path/to'])
      .then(() => {
        window.location.reload();
      });
  }


  ngOnInit(): void {
    //this.siteKey = '6Lfaa4McAAAAALd9OmYu1P2YChQWVI7NEuG1K1U6';

    //Get Client-IP
    this.commonService.getClientIP();

    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      //recaptcha: [null, Validators.required],
      RememberMe: []
    });

    this.rememberMe = localStorage.getItem('RememberMe')

    if (JSON.parse(this.rememberMe) !== null) {
      this.form = this.formBuilder.group({
        email: localStorage.getItem('Username'),
        password: localStorage.getItem('Password'),
        // recaptcha: [null, Validators.required],
        RememberMe: JSON.parse(this.rememberMe),
      })
    } 
    else {
      this.form = this.formBuilder.group({
        email: ['', [Validators.required]],
        password: ['', [Validators.required]],
        //recaptcha: [null, Validators.required],
        RememberMe: false
      });
    }
  }


  get fx() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;

    this.formRequest = {
      "email": this.form.controls.email.value,
      "password": this.form.controls.password.value,
      "ClientIP": localStorage.getItem('Client-IP')
      //"recaptcha": this.form.controls.recaptcha.value
    }

    if (this.form.invalid) {
      //this.SpinnerService.hide();
      return;
    }

    console.log('request', this.formRequest);
    this.show = true;
    this.GetLoginuser();
  }

  
  GetLoginuser() {
    this.accountServiceService.LoginUser(this.formRequest).subscribe((res: any) => {
      if (res.status > 0) {

        let data = {
          "email": res.responseData.email,
          "roleName": res.responseData.roleName,
          "roleID": res.responseData.roleID,
          "username": res.responseData.username,
          "userId": res.responseData.userId,
          "firstName": res.responseData.firstName,
          "lastName": res.responseData.lastName,
          "imagePathres": res.responseData.imagePathres
        }

        sessionStorage.setItem('AccessToken', res.responseData.token);
        localStorage.setItem("currentUser", JSON.stringify(data));

        this.dataSource = res;

        console.log(this.dataSource);
        console.log(JSON.stringify(res));

        this.show = false;
        this.router.navigate(['/dashboard']);
      } 
      else {
        this.toaster.error('UserName & Password incorrect !', 'Error');
        //this.form.controls.email.setValue('');
        //this.form.controls.password.setValue('');
        this.show = false;
        //this.form.clearValidators();
        //this.ngOnInit();
      }

      if (this.form.value.RememberMe) {
        localStorage.setItem('Username', this.form.value.email);
        localStorage.setItem('Password', this.form.value.password);
        localStorage.setItem('RememberMe', JSON.stringify(this.form.value.RememberMe));
      }
      else {
        localStorage.removeItem('Username');
        localStorage.removeItem('RememberMe');
      }
    });
  }
}
