import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { MasterServiceService } from '../common/services/master-service.service';
import { DataLogsModel } from './data-logs.model';

@Component({
  selector: 'data-logs',
  templateUrl: './data-logs.component.html',
  styleUrls: ['./data-logs.component.css']
})
export class DataLogsComponent implements OnInit {
  metaData: any;
  private filterModel: FilterModel;
  dataLogs: any = [];
  roleDropDown: any = [];
  adminUsersList: any = [];
  form: FormGroup;
  classToggled = false;
  maxDate = new Date();

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [

    { displayName: 'Time', key: 'activityTime', isSort: false, class: '', width: '15%' },
    { displayName: 'User Name', key: 'userName', isSort: true, class: '', width: '20%' },
    { displayName: 'Activity Type', key: 'activityType', isSort: true, class: '', width: '10%', },
    // { displayName: 'Record Type', key: 'recordType', isSort: true, class: '', width: '10%' },
    { displayName: 'Description', key: 'description', isSort: false, class: '', width: '40%' },
    { displayName: 'System IP Address', key: 'ipAddress', isSort: true, class: '', width: '40%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '1%' }

  ];


  constructor(
    private masterService: MasterServiceService,
    private formBuilder: FormBuilder,
  ) {
    this.filterModel = new FilterModel();
    this.dataLogs = new Array<DataLogsModel>();
  }



  ngOnInit(): void {
    this.form = this.formBuilder.group({
      userName: [''],
      activities: [''],
      from: [''],
      to: [''],
    });

    this.metaData = new Metadata();

    this.getDataLogs(this.filterModel);
    this.getRolesListDropdown();
    this.getAlladminUsersList();

    
  }

  getDataLogs(filterModel: any) {

    filterModel["userName"] = this.form.controls.userName.value,
    filterModel["activities"] = this.form.controls.activities.value,
    filterModel["from"] = this.form.controls.from.value,
    filterModel["to"] = this.form.controls.to.value,

    this.masterService.GetDataLogs(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.dataLogs = res.responseData;
        this.metaData = res.meta;

      } else {
        this.metaData = new Metadata();
      }
    })
  }

  onSubmit() {
    this.getDataLogs(this.filterModel);
  }


  reset() {
    this.form.reset();
    this.filterModel = new FilterModel();
    this.getDataLogs(this.filterModel);
  }

  getRolesListDropdown() {
    var filterForRole = {
      'PageSize': 100,
      'PageNumber': 1,
      'SearchValue':'',
      'RoleName':'',
      'RoleDescription':'',
      'ColumnName':'',
      'SortOrder':''
    }
    
    this.masterService.GetRolesListDropdown(filterForRole).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("GetRolesListDropdown", res);
        this.roleDropDown = res.responseData;

      } else {
        this.roleDropDown = [];
      }
    })
  }

  getAlladminUsersList() {
    var filterForAdmin = {
      'PageSize': 100,
      'PageNumber': 1,
      'AdminName':'',
      'Email':'',
      'ContactNumber':'',
      'ColumnName':'',
      'SortOrder':'',
      'Role':''
    }

    this.masterService.GetAdminUsersList(filterForAdmin).subscribe((res: any) => {
      if (res.status > 0) {
        console.log("GetAdminUsersList", res);
        this.adminUsersList = res.responseData;

      } else {
        this.adminUsersList = [];
      }
    })
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getDataLogs(this.filterModel);
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }
}
