import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-obe',
  templateUrl: './obe.component.html',
  styleUrls: ['./obe.component.css']
})
export class ObeComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
      'LowerCase', 'UpperCase', 'SuperScript', 'SubScript', '|',
      'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
      'Outdent', 'Indent', '|',
      'CreateTable', 'CreateLink', 'Image', '|',
      'SourceCode', '|', 'Undo', 'Redo']
  };
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      obeid: 0,
      title: ['', [Validators.required]],
      mainText: ['', [Validators.required]],
      subText: ['', [Validators.required]],
      obeImageName: [],
      obeImageAlt: ['', [Validators.required]],
      obeImageDescription: ['', [Validators.required]],
      obeKeyFeatureTitle: ['', [Validators.required]],
      obeKeyFeatureSubTitle: ['', [Validators.required]],

      obeFeaturesID:0,
      featuresTitle: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
      imageName: [],
      imageAlt: ['', [Validators.required]],
      imageDescription: ['', [Validators.required]],
    });

    this.getOBE();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if(this.image1 != ''){
      this.form.controls.obeImageName.setValue(this.image1);
    }else{
      if(this.form.controls.obeid.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
      
    }
    if(this.image2 != ''){
      this.form.controls.imageName.setValue(this.image2);
    }else{
      if(this.form.controls.obeFeaturesID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);

    if(this.id > 0){
      
      //this.form.controls.contactUsID.setValue(this.id);
    }
    //this.form.controls.contactUsID.setValue(this.id);
    this.contentServiceService.addOBE(this.form.value).subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.toaster.success(res.message, 'Success');
        this.id = res.responseData;
        this.form.controls.obeFeaturesID.setValue(this.id);
        this.handleTabChange.next({ tab: "OBE", id: 0});

      } else {
        this.toaster.error(res.message, 'Error');
      }
    });

  }

  


  /// crop image
   /// crop image
   croppedImagetest: any = '../../../assets/img/imgPrev.PNG';
   imageChangedEvent: any = '';
   croppedImage1: any = '../../../assets/img/banner-img.png';
   croppedImage2: any = '../../../assets/img/banner-img.png';
   imageNumber :any;
   fileChangeEvent(event: any , imgNum:any): void {
     this.imageChangedEvent = event;
     this.imageNumber = imgNum;
   }
   imageFile: any;
   imageCropped(event: ImageCroppedEvent,img:any) {
     if(this.imageNumber == '1'){
       this.croppedImage1 = event.base64;
     }else if(this.imageNumber == '2'){
       this.croppedImage2 = event.base64;
     }
     
 
     this.imageFile = this.base64ToFile(
       event.base64,
       this.imageChangedEvent.target.files[0].name,
 
     )
   }
   imageLoaded(image: LoadedImage) {
     // show cropper
   }
   cropperReady() {
     // cropper ready
   }
   loadImageFailed() {
     // show message
   }
   finalUpload(){
     //alert('called');
     this.uploadFile(this.imageFile);
   }
   actionDialogPopup(templateRef: TemplateRef<any>,data:any,imgNumner:any) {
     this.dialog.open(templateRef,{
       data:data
 
     });
     
     this.fileChangeEvent(data,imgNumner);
     
   }
 
   base64ToFile(data: any, filename: any) {
 
     const arr = data.split(',');
     const mime = arr[0].match(/:(.*?);/)[1];
     const bstr = atob(arr[1]);
     let n = bstr.length;
     let u8arr = new Uint8Array(n);
 
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
 
     return new File([u8arr], filename, { type: mime });
   }
 
 
   imageUrl: any;
   uploadFile(event:any) {
     let reader = new FileReader(); // HTML5 FileReader API
     //let file = event.target.files[0];
     let file = event;
 
     const formData = new FormData();
    
       formData.append('files', file);
     this.AddOBEImage(formData);
         
   
   }
 
   image1: string = '';
   image2: string = '';
   AddOBEImage(file:any) {
     this.contentServiceService.postDataOBEImg(file).subscribe((res: any) => {
       console.log(res);
       if(res.body.status > 0){
 
         switch (this.imageNumber) {
           case '1':
             this.image1 = res.body.responseData.imageName;
             break;
             case '2':
             this.image2 = res.body.responseData.imageName;
             break;
           default:
             break;
         }
         this.imageNumber = '';
         console.log(res.body.responseData.imageName);
       }
       
     })
   }
  //obeImage: string = '';

  getOBE(){
    this.contentServiceService.getOBEFeatureByIddata(this.id).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        let obe = res.responseData[0];
        this.croppedImage1 = obe.obeImageBase64;
        console.log(obe);
        if(res.responseData.length > 1){
          let obefeatures = res.responseData[1];
          this.croppedImage2 = obefeatures.imageBase64;
          console.log(obefeatures);
          this.form.patchValue({
            obeid: obe.obeid,
            mainText: obe.mainText ,
            obeImageAlt: obe.obeImageAlt ,
            obeImageDescription: obe.obeImageDescription ,
            obeImageName:obe.obeImageName ,
            obeKeyFeatureSubTitle: obe.obeKeyFeatureSubTitle ,
            obeKeyFeatureTitle: obe.obeKeyFeatureTitle ,
            subText: obe.subText ,
            title: obe.title ,
  
      
            obeFeaturesID:obefeatures.obeFeaturesID,
            featuresTitle: obefeatures.featuresTitle ,
            subTitle: obefeatures.subTitle ,
            imageName: obefeatures.imageName ,
            imageAlt: obefeatures.imageAlt ,
            imageDescription: obefeatures.imageDescription ,
          });
        }else{
          this.form.patchValue({
            obeid: obe.obeid,
            mainText: obe.mainText ,
            obeImageAlt: obe.obeImageAlt ,
            obeImageDescription: obe.obeImageDescription ,
            obeImageName:obe.obeImageName ,
            obeKeyFeatureSubTitle: obe.obeKeyFeatureSubTitle ,
            obeKeyFeatureTitle: obe.obeKeyFeatureTitle ,
            subText: obe.subText ,
            title: obe.title ,
  
          });
        }
       

      }else{
        this.toaster.warning("Please add record!", 'Warning');
      }
    });
  }
  goto(){
    this.handleTabChange.emit({ tab: "OBE", id: 0});
  }
  url:any = '';
  onSelectFile(event:any) {
    if (event.target.files && event.target.files[0]) {
      debugger;
      this.uploadFile(event.target.files[0]);
      this.imageNumber = '1';
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event:any) => { // called once readAsDataURL is completed
        this.url = event.target.result;
        this.croppedImagetest = this.url;
      }
    }
  }
 
}
