<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title">Manage Organizations</h2>
    <!-- <app-breadcrumb></app-breadcrumb> -->
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Manage Organizations</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Organizations</h3>
       <a routerLink="/enroll-institute" class="btn btn-primary btnsmall">Add Organization</a> 
      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
           
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="row">
                  
                  <div class="col s12 m6"> 
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Organization Name</mat-label>
                      <input maxlength="15" (keypress)="allowAlphabetOnly($event)" matInput type="text" formControlName="organizationName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label>Organization Type<span class="req">*</span></mat-label>
                      <mat-select placeholder="Organization Type" formControlName="organizationType">
                        <mat-option value="Select Organization Type">Organization Type</mat-option>
                        <mat-option *ngFor="let planType of organizationTypess" [value]="planType.organizationTypeName">
                          {{planType.organizationTypeName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Organization Type</mat-label>
                      <input matInput type="text" formControlName="organizationType">
                    </mat-form-field> -->
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Email Address</mat-label>
                      <input matInput type="text" formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Subdomain Name</mat-label>
                      <input matInput type="text" formControlName="subDomainName">
                    </mat-form-field>
                  </div>
                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
            </div>
          
          </div>
          <div class="rightTableActions centerItemVert">
            <div class="viewColumns tableActions">
              <a (click)="actionDialogPopup(hideShowColPopup,$event)" title="Hide/Show Column"><i class="fas fa-columns"></i></a>
            </div>
                       
          </div>

        </div>

        <div class="matCardBody">
          <div class="tabularWidget">
              <mat-tab-group mat-align-tabs="start" (selectedTabChange)="notAllowedOrgTab($event)">
                <mat-tab  label="Active Organizations">
                  <app-data-table [inputColumns]="displayedColumn" [inputSource]="allowedOrganizationList" [inputMeta]="metaData"
                  [inputButtons]='actionButton' (onChange)="onPageOrSortChange($event)"
                  (onTableActionClick)="onTableActionClick($event)">
                </app-data-table>
                </mat-tab>
                  <mat-tab label="Enquiry Organizations" >
                    <app-data-table [inputColumns]="displayedColumns" [inputSource]="notAllowedOrganizationList" [inputMeta]="metaData"
                    [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
                    (onTableActionClick)="onTableActionClick($event)">
                  </app-data-table>
                  </mat-tab>
              </mat-tab-group>
          </div>
      </div>
        <div class="mat-elevation-z8 tdWhiteSpace">
         
        </div>
   
      </div>
     
    </div>
  </div>
</main>
<ng-template #hideShowColPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Hide/Show Colums</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <p><a (click)="selectAll()" class="underlinedLink">Select All</a> / <a (click)="unselectAll()"
          class="underlinedLink">Unselect All</a>
      </p>
      <form *ngIf="tableCheck == 0" [formGroup]="columnForm">
        <div class="row-flex">
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" (change)="onSelectColumn()"
              formControlName="name">
              Organization Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="type">
              Organization Type
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="subdomainName">
              SubDomain
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="email">
              Email
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="phoneNumber">
              Phone Number
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="userName">
              Username
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="subscriptionPlan">
              Subscription plan
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="isEnable">
              Is-Enable
            </mat-checkbox>
          </div>
        </div>
      </form>
      <form *ngIf="tableCheck == 1" [formGroup]="enquiryTable">
        <div class="row-flex">
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" [disabled]="true" (change)="onSelectColumn()"
              formControlName="name">
              Organization Name
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="type">
              Organization Type
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="subdomainName">
              SubDomain
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="email">
              Email
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="phoneNumber">
              Phone Number
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="userName">
              Username
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="subscriptionPlan">
              Subscription plan
            </mat-checkbox>
          </div>
          <div class="col s12 m6 form-group">
            <mat-checkbox class="example-margin" (change)="onSelectColumn()" formControlName="isDemo">
              Is-Demo
            </mat-checkbox>
          </div>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>
<ng-template #resetPasswordPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Reset Password</h2>
    </div>
    <mat-dialog-content class="mat-typography">
      <form>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline" floatLabel="always">
            <mat-label>Username</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-user"></i></span>
            <input matInput value="Sharma">
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Confirm Password</mat-label>
            <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
            <input matInput>
          </mat-form-field>
        </div>
        <div class="form-group17 center">
          <button type="button" class="btn btn-primary" mat-dialog-close>Send</button>
        </div>
      </form>

    </mat-dialog-content>
  </div>
</ng-template>
<ng-template #orgInvoice>
  <div class="dialog-xll">
    <i class="material-icons popupClose" mat-dialog-close>clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h6 mat-dialog-title>Renewal Notifications</h6>
    </div>
    <mat-dialog-content class="mat-typography">
      <!-- <div class="proSection">
        <div class="row d-flexeds">
          <div class="col s12 m6 form-group">
            <div class="detailsContent">
              <label>Organization Name:</label>
              <span>Addup</span>
            </div>
          </div>
          <div class="col s12 m6 form-group">
            <div class="detailsContent">
              <label>Plan Name:</label>
              <span>Emails subs</span>
            </div>
          </div>
          <div class="col s12 m6 form-group">
            <div class="detailsContent">
              <label>Price:</label>
              <span>$ 22</span>
            </div>
          </div>
          <div class="col s12 m6 form-group">
            <div class="detailsContent">
              <label>Expiry Date:</label>
              <span>23-Oct-2022</span>
            </div>
          </div>
         
        </div>
        <div class="text-right mt-2">
          <button class="btn btn-primary btn-sm">Send Invoice </button>
        </div>
      </div> -->
      <div class="row">
        <div class="col-lg-6 ml-2">
          <div class="invoice-title">
            <img src="../../assets/img/logo.png" alt="orglogo" width="100px">
          </div>
        </div>
        <div class="col-lg-6 text-right">
        </div>
      </div>
      <div class="mt-3 d-flex justify-content-between text-details">
        <div class="col-lg-6">
          <strong>Organization Name:</strong> {{organizationName}}<br>
          <strong>Email:</strong> {{email}}<br>
          <strong>Contact Person Name:</strong> {{contactPersonFullName}}<br>
          <strong>Contact Details:</strong>{{contactNumber}}<br>
          <!-- <strong>Address:</strong>42 Street London<br> -->

        </div>
        <div class="col-lg-6 text-right">

            <strong>Current Plan Name:</strong>
            {{planName}}
            <br>
            <strong>Plan Price:</strong>
            $ {{price}}
            <br>
            <strong>Expiry Date:</strong>
            {{subscriptionEndDate | date:'dd/MM/yyyy'}}
            <br>
            <strong>Date of Notification</strong>
            {{notificationDate | date:'dd/MM/yyyy'}}
            <br>

        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-condensed mt-3">
          <thead>
            <tr>
              <td><strong>Plan Description:</strong></td>
              <td class="text-center"><strong>Price</strong></td>
              <!-- <td class="text-center"><strong>Expiry Date:</strong></td> -->
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="">{{planName}} <br>Subscription Period expire on {{subscriptionEndDate | date:'dd/MM/yyyy'}}.
             </td>
              <td class="text-center">$ {{price}}</td>
              <!-- <td class="text-center">{{subscriptionEndDate | date:'dd/MM/yyyy'}}</td> -->
            </tr>
          </tbody>
        </table>
        <hr class="specific">
        <div class="text-right">
          <button class="btn btn-primary btn-sm" mat-dialog-close (click)="submitSendOrgInvoice()">Send Notification</button>
        </div>
      </div>

    </mat-dialog-content>
  </div>
</ng-template>

 <!--<div class="dialog-xll">
  <i class="material-icons popupClose" mat-dialog-close>clear</i>

  <div class="row">
    <div class="col-lg-6">
      <div class="invoice-title">
        <img src="../../../../../../assets/img/admin/logo.png" alt="">
      </div>
    </div>
    <div class="col-lg-6 text-right">
      <p class="lead">Invoice</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-6">
      <address>
        <strong>{{dialogData?.organizationName}}</strong> <br>
        <strong>Payee:</strong>{{dialogData?.payerName}}
      </address>
    </div>
    <div class="col-lg-6 text-right">
      <address>
        <strong>Invoice Nunber:</strong>
        {{dialogData?.invoiceNumber}}
        <br>
        <strong>Invoice Status:</strong>
        Paid
        <br>
        <strong>Date Of Issue:</strong>
        {{dialogData?.createdDate| date:'d/M/yy'}}
        <br>
        <strong>Date Due:</strong>
        {{dialogData?.dueDate| date:'d/M/yy'}}
        <br>
      </address>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-6">
      <address>
        <p class="lead">Invoice To:</p>
        <strong>Transaction ID:</strong> {{dialogData?.psTransactionID}}<br>
        <strong>{{dialogData?.pPayername}}</strong><br>
        <strong>{{dialogData?.pPayername == dialogData?.payerEmail?'':dialogData?.payerEmail}}</strong><br>
      </address>
    </div>
</div>
  <div class="row mt-3">
    <div class="col-md-12">
      <div class="panel panel-default">
        <div class="panel-heading mb-3">
        </div>
        <div class="panel-body">
          <div class="table-responsive">
            <table class="table table-condensed">
              <thead>
                <tr>
                  <td><strong>S.No:</strong></td>
                  <td><strong>Description:</strong></td>
                  <td class="text-center"><strong>Qty</strong></td>
                  <td class="text-center"><strong>Unit Price:</strong></td>
                  <td class="text-center"><strong>Amount:</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td class="">{{dialogData?.planName}} <br>Subscription Period expire on
                    -{{dialogData?.planExpiryDate| date:'d/M/yy'}} For Max {{dialogData?.numberofcount}} Count.</td>
                  <td class="text-center">1</td>
                  <td class="text-center">${{dialogData?.amount}}</td>
                  <td class="text-center">${{dialogData?.amount}}</td>
                </tr>
                <tr>
                  <td class="thick-line"></td>
                  <td class="thick-line"></td>
                  <td class="thick-line"></td>
                  <td class="thick-line text-center"><strong>Subtotal</strong></td>
                  <td class="thick-line text-center">${{dialogData?.amount}}</td>
                </tr>

                <tr>

                  <td class="thick-line"></td>
                  <td class="thick-line"></td>
                  <td class="thick-line"></td>
                  <td class="thick-line text-center"><strong>Discount</strong></td>
                  <td class="thick-line text-center">$0</td>
                </tr>
                <tr>
                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"><strong>Toytal Amount Paid</strong></td>
                  <td class="no-line text-center">${{dialogData?.amount}}</td>
                </tr>

                <tr>

                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"></td>
                  <td class="no-line text-center"><strong>Due Amount</strong></td>
                  <td class="no-line text-center">$0</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->