import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { TransactionListModel } from './TransactionListModel';
import { TransactionService } from '../common/services/transactionService/transaction.service';
@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit {

  
  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  transactionListData: TransactionListModel[];

  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    { displayName: 'Invoice Number', key: 'invoiceNumber', isSort: true, class: '', width: '10%' },
    { displayName: 'Payer Name', key: 'payerName', isSort: true, class: '', width: '20%' },
    { displayName: 'Organization Name', key: 'organizationName', isSort: true, class: '', width: '20%' },
    //{ displayName: 'Plan Type', key: 'planType', isSort: true, class: '', width: '10%' },
    { displayName: 'Payment Method', key: 'paymentMethod', isSort: true, class: '', width: '10%' },
    { displayName: 'Due Date', key: 'dueDate', isSort: true, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' }

  ];
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'Details', class: 'fas fa-eye' },
    // { displayName: 'Edit', key: 'edit', class: 'fas fa-edit' },
    // { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];
 

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private transactionService: TransactionService,
    private formBuilder: FormBuilder,
  ) {
    this.filterModel = new FilterModel();
    this.transactionListData = new Array<TransactionListModel>();
  }
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];



  ngOnInit(): void {
    this.metaData = new Metadata();
   
    this.form = this.formBuilder.group({
      payerName: [''],
      planType: [''],
      invoiceNumber: [''],
    });
    this.getTransactionList(this.filterModel);
    // this.InitialGridCall()
    // this.AdminUserList();
  }

  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getTransactionList(this.filterModel)
  }

  onSubmit() {

    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "payerName": this.form.controls.payerName.value,
      "planType": this.form.controls.planType.value,
      "invoiceNumber": this.form.controls.invoiceNumber.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getTransactionList(this.requestpages)
  }
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.transactionID;
    switch ((actionObj.action || '').toUpperCase()) {
      // case 'EDIT':
      //   this.editCall(id);
      //   break;
      // case 'DELETE':
      //   {

      //     this.dialogService
      //       .confirm(`Are you sure you want to delete this user?`)
      //       .subscribe((result: any) => {
      //         if (result == true) {
      //           this.DeleteStaff(id);
      //         }
      //       });
      //   }
      //   break;
      case 'DETAILS':
        this.infoCall(id);
        break;
      default:
        break;
    }
  }
  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getTransactionList(this.filterModel);
    }
  }
 
  getTransactionList(filterModel: any) {
    this.transactionService.AllTransaction(filterModel).subscribe((res: any) => {
      if(res.status > 0){
        console.log("LIST",res);
        this.transactionListData = res.responseData;
        this.metaData = res.meta;
       
      }else{
        this.metaData = new Metadata();
      }
      console.log(this.transactionListData)
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  infoCall(transactionID: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/transaction-details/"], { queryParams: { id: transactionID } });
  }
  

  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getTransactionList(this.requestpages)
  }

  InitialGridCall() {
    
    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "payerName": "",
      "planType": "",
      "invoiceNumber": "",
      "columnName": "",
      "sortOrder": ""
    }

  }


}

