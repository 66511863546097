<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Email Subscription Plan</h2>
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item active" aria-current="page">Email-Subscription</li>
            </ol>
        </div>
    </div>
    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Manage Email Subscription</h3>
                <a routerLink="/add-email-subs" class="btn btn-primary btnsmall">Add Subscription</a>
            </div>

            <div class="matCardBody">
                <div class="tableActionTop mb-3">
                    <div class="tableFilter">
                      <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
                      <div class="filterForm" [class.toggled]="classToggled">
                        <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                          <!-- <div class="col s12 m6">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Plan Name</mat-label>
                              <input matInput type="text">
                            </mat-form-field>
                          </div>
          
                          <div class="col s12 m6 form-group">
                            <mat-form-field class="w-100" appearance="outline">
                              <mat-label>SMS Pack</mat-label>
                              <mat-select>
                                <mat-option value="one">500/Day</mat-option>
                                <mat-option value="two">1000/Day</mat-option>
                                <mat-option value="two">1500/Day</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div> -->
                          <div class="col s12 m6">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label>Plan Name</mat-label>
                              <input (keypress)="allowAlphabetOnly($event)" maxlength="20" matInput type="text" formControlName="planName">
                            </mat-form-field>
                          </div>
                          <div class="col s12 m6">
                            <mat-form-field class="w-100" appearance="outline">
                              <mat-label>Plan Type<span class="req">*</span></mat-label>
                              <mat-select placeholder="Plan Type" formControlName="planTypeName">
                                <mat-option value="Select plan Type">Plan Type</mat-option>
                                <mat-option *ngFor="let planType of planTypeDropdown " [value]="planType.planTypeName">
                                  {{planType.planTypeName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" class="w-100">
                                         <mat-label>Plan Name</mat-label>
                                         <input matInput type="text" formControlName="planTypeName">
                                       </mat-form-field> -->
                          </div>
                          <!-- <div class="col s12 m6">
                            <mat-form-field appearance="outline" class="w-100">
                              <mat-label >Pricing($)</mat-label>
                              <input matInput formControlName="pricing">
                            </mat-form-field>
                          </div> -->
          
                        </div>
                        <div class="btnsWidget form-group">
                          <button type="submit" class="btn btn-primary btn-sm">Search</button>
                          <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                        </div>
                      </form>
                      </div>
                    </div>
                    <div class="rightTableActions centerItemVert">
                      <div class="viewColumns tableActions">
                        <!-- <a (click)="actionDialogPopup(hideShowColPopup)"><i class="fas fa-columns"></i></a>                            -->
                      </div>
                      <div class="downloadTable tableActions">
                        <a><i class="fas fa-download" (click)="ExportTOExcel()"></i></a>
                      </div>
                      <!-- <div class="enrolmentTable tableActions">
                                    <a mat-button [matMenuTriggerFor]="enrollment" class="ddButtonType">Enrollment <i class="fa fa-caret-down rightItem"></i></a>
                                    <mat-menu #enrollment="matMenu" xPosition="before" class="actionSelectedDD">
                                      <button mat-menu-item>Upcoming</button>
                                      <button mat-menu-item>In Progress</button>
                                      <button mat-menu-item>Completed</button>
                                      <button mat-menu-item>In Complete</button>
                                    </mat-menu>
                                   </div> -->
                      <!-- <div class="selectedColTable tableActions">
                                    <a mat-button [matMenuTriggerFor]="beforeMenu" class="ddButtonType"><span class="leftItem conterColumns">0</span> <span class="mobileHidden">Selected</span> <i class="fa fa-caret-down rightItem"></i></a>
                                    <mat-menu #beforeMenu="matMenu" xPosition="before" class="actionSelectedDD">
                                      <h4 class="actionSelected">Subjects</h4>
                                      <button mat-menu-item>Subjects Allocation</button>
                                      <h4 class="actionSelected">Communications</h4>
                                      <button mat-menu-item>Send Email</button>
                                      <button mat-menu-item>Send SMS</button>
                                      <button mat-menu-item>Invite to Login (Email)</button>
                                      <button mat-menu-item>Invite to Login (SMS)</button>
                                      <h4 class="actionSelected">Academics</h4>
                                      <button mat-menu-item>Print ID Cards</button>
                                      <button mat-menu-item>Print Report Cards</button>
                                      <button mat-menu-item>Enable Report Cards</button>
                                      <button mat-menu-item>Disable Report Cards</button>
                                      <h4 class="actionSelected">Other Actions</h4>
                                      <button mat-menu-item>Block</button>
                                      <button mat-menu-item>Unblock</button>
                                      <button mat-menu-item>Move to Alumni</button>
                                      <button mat-menu-item>Delete</button>
                                    </mat-menu>
                                   </div> -->
                    </div>
          
                  </div>
                <div class="mat-elevation-z8">
         
                </div>
                <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator> -->

            </div>
            <app-data-table [inputColumns]="displayedColumns" [inputSource]="siteEmailSubscriptionListData" [inputMeta]="metaData"
                [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
                (onTableActionClick)="onTableActionClick($event)">
            </app-data-table>
        </div>
    </div>
</main>
