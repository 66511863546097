<div class="loginWidget">
  <div>
    <p class="login-logo center"><img src="../../assets/img/logo.png" alt=""></p>
    <div class="matCard">
      <form [formGroup]="IsExitEmail" (ngSubmit)="confirmEmail(linkSentPopup)">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label> Email</mat-label>
          <input type="text" formControlName="email" pattern="^[^\s]+(\s+[^\s]+)*$" matInput placeholder="Email">
          <mat-error *ngIf="IsExitEmail.get('email')?.touched  && IsExitEmail.get('email')?.errors?.required">
            Email is required!
          </mat-error>
        </mat-form-field>
        <!-- <div class="form-group17">
                    <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" required>
                    </ngx-recaptcha2>
                </div> -->
        <div class="forgotpassword form-group17">
          <!-- <button routerLink="/dashboard" type="button" class="btn btn-primary">Log In</button>     -->
          <button type="submit" [disabled]="IsExitEmail.invalid" class="btn btn-primary">Continue</button>
          <a routerLink="" class="underlinedLink primaryText">Back</a>
        </div>
      </form>
    </div>
  </div>

</div>

<!-- <div class="loginWidget">
    <div>
        <p class="login-logo center"><img src="../../assets/img/logo.png" alt=""></p>
        <div class="matCard">
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
                <div class="form-group17">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Password</mat-label>
                    <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
                    <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required autocomplete="off" 
                    pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}" />
                    <button mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                      <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="forgotPasswordForm.get('password')?.touched  && forgotPasswordForm.get('password')?.errors?.required">
                                                      Password is required
                                                  </mat-error>
                    <mat-error *ngIf="forgotPasswordForm.get('password')?.errors?.pattern">
                      Password must contain minimum eight characters,at least one uppercase and lowercase letter,one number and one special characters<strong>(!@#$%_^&*~)</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group17">
                  <mat-form-field class="w-100" appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <span matSuffix class="faIcon"><i class="fas fa-lock"></i></span>
                    <input matInput formControlName="confirmPassword" required autocomplete="off" [type]="hide1 ? 'password' : 'text'" (input)="onPasswordChange()" required />
                    <button mat-icon-button matSuffix style="background: transparent;border: none;" (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                      <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="confirm_password.hasError('mismatch')">Passwords don't match</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group17 center">
                  <button type="submit" class="btn btn-primary" [disabled]="forgotPasswordForm.invalid" >Update</button>
                </div>
              </form>
        </div> 
    </div>
       
</div> -->

<ng-template #linkSentPopup>
  <div class="dialog-md">
    <i class="material-icons popupClose" mat-dialog-close routerLink="">clear</i>
    <div class="dialogTitle center-align loginTtile">
      <h2 mat-dialog-title>Forgot Password?</h2>
    </div>

    <mat-dialog-content class="mat-typography center-align">
      <p class="emailSentIcon form-group35">
        <!-- <svg width="118" height="117.998" class="svgicon">
                    <use xlink:href="#emailsent-icon"></use>
                </svg> -->
        <img src="../../assets/img/sendemail.gif" alt="email" width="200">
      </p>
      <p class="form-group17">We have sent password recover link on your email Id.<br>
        Please check your email and reset the password.</p>
    </mat-dialog-content>
  </div>
</ng-template>