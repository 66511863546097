import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../content-service.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
    public tools: ToolbarModule = {
    items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase','SuperScript', 'SubScript', '|',
        'Formats', 'Alignments', 'NumberFormatList', 'BulletFormatList',
        'Outdent', 'Indent', '|',
        'CreateTable', 'CreateLink', 'Image', '|',
        'SourceCode', '|', 'Undo', 'Redo']
};
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) { 
     
    }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      aboutUsID: 0,
      title: ['', [Validators.required]],
      subTitle: ['', [Validators.required]],
      aboutUsImageName: [],
      aboutUsImageDescription: ['', [Validators.required]],
      aboutUsImageAlt: ['', [Validators.required]],
      aboutUsMainText: ['', [Validators.required]],
      aboutUsDescription: ['', [Validators.required]],
      additionalText: ['', [Validators.required]],
      additionalDescription: ['', [Validators.required]],
      goalText: ['', [Validators.required]],
      goalDescription: ['', [Validators.required]],
      goalImageName: [],
      goalImageDescription: ['', [Validators.required]],
      goalImageAlt: ['', [Validators.required]],
      customerSayText: ['', [Validators.required]],
      customerSayDescription: ['', [Validators.required]],
      partnesText: ['', [Validators.required]],
      partnesDescription: ['', [Validators.required]]
    });

   this.getAboutUsdata();
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    if(this.image1 != ''){
      this.form.controls.aboutUsImageName.setValue(this.image1);
    }else{
      if(this.form.controls.aboutUsID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
      
    }
    if(this.image2 != ''){
      this.form.controls.goalImageName.setValue(this.image2);
    }else{
      if(this.form.controls.aboutUsID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);

    this.contentServiceService.addAboutUSdata(this.form.value).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        this.id = res.responseData;
        this.form.controls.aboutUsID.setValue(this.id);
      }else{
        this.toaster.error(res.message, 'Error');
      }
    });

  }
  /// crop image
   /// crop image
   imageChangedEvent: any = '';
   croppedImage1: any = '../../../assets/img/banner-img.png';
   croppedImage2: any = '../../../assets/img/banner-img.png';
   imageNumber :any;
   fileChangeEvent(event: any , imgNum:any): void {
     this.imageChangedEvent = event;
     this.imageNumber = imgNum;
   }
   imageFile: any;
   imageCropped(event: ImageCroppedEvent,img:any) {
     if(this.imageNumber == '1'){
       this.croppedImage1 = event.base64;
     }else if(this.imageNumber == '2'){
       this.croppedImage2 = event.base64;
     }
     
 
     this.imageFile = this.base64ToFile(
       event.base64,
       this.imageChangedEvent.target.files[0].name,
 
     )
   }
   imageLoaded(image: LoadedImage) {
     // show cropper
   }
   cropperReady() {
     // cropper ready
   }
   loadImageFailed() {
     // show message
   }
   finalUpload(){
     //alert('called');
     this.uploadFile(this.imageFile);
   }
   actionDialogPopup(templateRef: TemplateRef<any>,data:any,imgNumner:any) {
     this.dialog.open(templateRef,{
       data:data
 
     });
     
     this.fileChangeEvent(data,imgNumner);
     
   }
 
   base64ToFile(data: any, filename: any) {
 
     const arr = data.split(',');
     const mime = arr[0].match(/:(.*?);/)[1];
     const bstr = atob(arr[1]);
     let n = bstr.length;
     let u8arr = new Uint8Array(n);
 
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
 
     return new File([u8arr], filename, { type: mime });
   }
 
 
   imageUrl: any;
   uploadFile(event:any) {
     let reader = new FileReader(); // HTML5 FileReader API
     //let file = event.target.files[0];
     let file = event;
 
     const formData = new FormData();
    
       formData.append('files', file);
     this.AddAboutUsImage(formData);
         
   
   }
 
   image1: string = '';
   image2: string = '';
   AddAboutUsImage(file:any) {
     this.contentServiceService.postDataAboutImg(file).subscribe((res: any) => {
       console.log(res);
       if(res.body.status > 0){  
         switch (this.imageNumber) {
           case '1':
             this.image1 = res.body.responseData.imageName;
             break;
             case '2':
             this.image2 = res.body.responseData.imageName;
             break;
           default:
             break;
         }
         this.imageNumber = '';
         console.log(res.body.responseData.imageName);
       }
       
     })
   }

   getAboutUsdata() {
    this.contentServiceService.getAboutUsdata().subscribe((res: any) => {
      console.log(res);
      if (res.status > 0) {
        this.croppedImage1 =  res.responseData.aboutUsImageBase64;
        this.croppedImage2 =   res.responseData.goalImageBase64;
        //this.id = res.responseData['homePageID'];
        this.id = res.responseData['aboutUsID'];
        this.form.patchValue({
          aboutUsID: res.responseData['aboutUsID'],
          title: res.responseData['title'],
          subTitle: res.responseData['subTitle'],
          aboutUsImageName: res.responseData['aboutUsImageName'],
          aboutUsImageDescription: res.responseData['aboutUsImageDescription'],
          aboutUsImageAlt: res.responseData['aboutUsImageAlt'],
          aboutUsMainText: res.responseData['aboutUsMainText'],
          aboutUsDescription: res.responseData['aboutUsDescription'],
          additionalText: res.responseData['additionalText'],
          additionalDescription: res.responseData['additionalDescription'],
          goalText: res.responseData['goalText'],
          goalDescription: res.responseData['goalDescription'],
          goalImageDescription: res.responseData['goalImageDescription'],
          goalImageAlt: res.responseData['goalImageAlt'],
          customerSayText: res.responseData['customerSayText'],
          customerSayDescription: res.responseData['customerSayDescription'],
          partnesText: res.responseData['partnesText'],
          partnesDescription: res.responseData['partnesDescription'],
          goalImageName:res.responseData['goalImageName']
          
        });
      } else {
        this.toaster.warning("Please add record!", 'Warning');
      }


    });
  }

}
