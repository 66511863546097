<main>
    <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">My Profile</h2> 
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-staff">Manage Staff</a></li>
                <li class="breadcrumb-item active" aria-current="page">Edit Staff</li>
            </ol>
        </div>
    </div>


    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Edit Profile</h3>
                <a routerLink="/manage-staff" class="btn btn-primary btnsmall">Back</a>
            </div>
            <div class="matCardBody">
                <div class="profileDetailsWidget pt-4 d-md-flex">
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="profilePicLeft form-group30">
                            <!-- <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Profile pic <span class="req">*</span></mat-label>
                            <input matInput placeholder="Profile" required name="Profile" formControlName="profilePic">
                        </mat-form-field>     -->
                            <p class="chngePic">
                                <input type="file" accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                <span class="icon centerItem">
                                    <mat-icon>edit</mat-icon>
                                </span>
                                <!-- <img src="../../../assets/img/display.jpg" alt=""> -->
                                <img [src]="previewUrl" alt="" />
                            </p>
                        </div>
                        <div class="profileContentRight flex-grow-1">

                            <div class="proSection">
                                <h5>Profile Details</h5>

                                <div class="row">

                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>First Name </mat-label>
                                            <input matInput placeholder="First Name" required name="firstname"
                                                formControlName="firstName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Last Name</mat-label>
                                            <input matInput placeholder="Last Name" required name="lastName"
                                                formControlName="lastName">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>User Name</mat-label>
                                            <input [readonly]="true" matInput placeholder="User Name" required name="username"
                                                formControlName="username">
                                        </mat-form-field>
                                    </div>
                                <!-- <div class="col s12 m6 form-group">
                                    <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Password </mat-label>
                                        <input matInput placeholder="Password" required name="Password" formControlName="password" type="password">
                                    </mat-form-field>                                   
                                </div> -->
                                 <!-- <div class="col s12 m6 form-group">
                                      <mat-form-field class="w-100" appearance="outline">
                                        <mat-label>Confirm Password</mat-label>
                                        <input matInput placeholder="Confirm Password" formControlName="confirmPassword" type="password">
                        
                                      </mat-form-field>
                                    </div> -->


                                    <!-- <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Password</mat-label>
                                            <input type="password" matInput placeholder="Password" required name="password"
                                                formControlName="password">
                                        </mat-form-field>
                                    </div> -->

                                    <!-- <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone Number</mat-label>
                                            <input matInput placeholder="Phone Number" required name="contactNumber"
                                                formControlName="contactNumber">
                                        </mat-form-field>
                                    </div> -->

                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Phone Number</mat-label>
                                            <ngx-mat-intl-tel-input [preferredCountries]="['ae', 'us']"
                                                [enablePlaceholder]="true" [enableSearch]="true" name="ContactNumber"
                                                formControlName="contactNumber" #phone>
                                            </ngx-mat-intl-tel-input>
                                            <mat-hint>eg : {{phone.selectedCountry.placeHolder}}</mat-hint>

                                            <!-- <mat-error
                                                *ngIf="form.get('contactNumber')?.touched  && form.get('contactNumber')?.errors?.required">
                                                Phone Number is required
                                            </mat-error> -->
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Date Of Birth</mat-label>
                                            <input matInput placeholder="DateOfBirth" required name="dob"
                                                formControlName="dob" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Email</mat-label>
                                            <input [readonly]="true" matInput placeholder="email" required name="email"
                                                formControlName="email">
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Gender</mat-label>
                                            <mat-select placeholder="Gender" formControlName="gender">
                                                <mat-option *ngFor="let gender of genders "
                                                [value]="gender.genderID">
                                                   {{gender.genderName}}
                                               </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>



                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Role</mat-label>
                                            <mat-select placeholder="Role" formControlName="roleID">
                                                <mat-option value="Select Role">Roles</mat-option>
                                                <mat-option *ngFor="let role of roles" [value]="role.roleID">
                                                    {{role.roleName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="proSection">
                                <h5>Additional Information</h5>
                                <div class="row">
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Select Blood Group</mat-label>
                                            <mat-select placeholder="Blood Group" required name="bloodGroup"
                                            formControlName="bloodGroupID">
                                            <mat-option value="Select Blood Group">Blood Group</mat-option>
                                            <mat-option *ngFor="let bloodGroup of bloodGroups"
                                                [value]="bloodGroup.bloodGroupID">
                                                {{bloodGroup.bloodGroupName}}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Address</mat-label>
                                            <input matInput placeholder="address" required name="address"
                                                formControlName="address">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                   
                                    <div class="col s12 m6 form-group">
                                     
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Country</mat-label>
                                            <mat-select placeholder="Country" required name="country"
                                            (valueChange)="GetStateList($event)"  formControlName="countryID">
                                                <mat-option value="Select Country">Country</mat-option>
                                                <mat-option *ngFor="let country of countries"
                                                    [value]="country.countryID">
                                                    {{country.countryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>State</mat-label>
                                            <mat-select placeholder="State" required name="state"
                                                formControlName="stateID">
                                                <mat-option *ngFor="let states of states "
                                                [value]="states.stateID">
                                                   {{states.stateName}}
                                               </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>City</mat-label>
                                            <input matInput placeholder="city" required name="city"
                                                formControlName="city">
                                        </mat-form-field>
                                    </div>


                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Zip Code</mat-label>
                                            <input matInput placeholder="Zip Code" required name="zipcode"
                                                formControlName="zipCode">
                                        </mat-form-field>
                                    </div>

                                   


                                </div>
                            </div>

                            <div class="proSection center">
                                <button type="submit" class="btn btn-primary">Update</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<ng-template #uploadPopup>
    <div class="dialog-md">
        <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
        <div class="dialogTitle center-align loginTtile">
            <h5 mat-dialog-title>Select Image Area</h5>
        </div>
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [aspectRatio]="4 / 4" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png"
                    (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded($event)"
                    (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()" [roundCropper]="true">
                </image-cropper>

                <!-- <img [src]="croppedImage" /> -->
            </div>

        </mat-dialog-content>
    </div>
    <div class="proSection text-right">
        <button class="btn btn-primary btn-sm" mat-dialog-close (click)="finalUpload()">Save</button>
    </div>
</ng-template>