<main>
  <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
    <h2 class="page-title"> Email Templates</h2>
    <div class="breadcrumbWidget">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
        <li class="breadcrumb-item active" aria-current="page">Email Templates</li>
      </ol>
    </div>
  </div>
  <div class="container-fluid">
    <div class="matCard">
      <div class="matCrdTitle centerItemVert justify-content-between">
        <h3 class="matCardTitle">Templates</h3>
        <a routerLink="/placeholder" class="btn btn-primary btnsmall">Placeholder</a>
      </div>

      <div class="matCardBody">
        <div class="tableActionTop mb-3">
          <div class="tableFilter">
            <span (click)="toggleField()"><i class="fas fa-filter"></i> <span class="mobileHidden cursor-pointer">Filter</span></span>
            <div class="filterForm" [class.toggled]="classToggled">
              <form [formGroup]="form" (ngSubmit)="onSubmit()" matSort (matSortChange)="sortData($event)">
                <div class="row">
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Template Name</mat-label>
                      <input matInput type="text" formControlName="templateName">
                    </mat-form-field>
                  </div>
                  <div class="col s12 m6">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Template Subject</mat-label>
                      <input matInput type="text" formControlName="templateSubject">
                    </mat-form-field>
                  </div>

                </div>
                <div class="btnsWidget form-group">
                  <button type="submit" class="btn btn-primary btn-sm">Search</button>
                  <button type="button" (click)="reset()" class="btn btn-reset btn-sm">Reset</button>
                </div>
              </form>
            </div>
          </div>
          <div class="rightTableActions centerItemVert">
            <a  routerLink="/create_template" class="btn btn-primary btnsmall">Add Custom Template</a>      
          </div>

        </div>
        <div class="mat-elevation-z8">
          
        </div>
        
      </div>
      <!-- <table id="students_table" class="table">
        <thead>
            <tr>
                <th class="left one">Email Title</th>
                <th class="left two">From Email</th>
                <th class="left three">Last Modified</th>
                <th class="left four">Actions</th>
            </tr>
        </thead>
        <tbody  id="table_rows">
            <tr>
                <td class="left one" >Email 1</td>
                <td class="left two "  ><span class="label label-info custom mr-1">Custom Email</span>vineet@yopmail.com</td>
                <td class="left three" >24-April-2022</td>
              
                <td class="left four"><a 
                        title="Edit Email Template" style="font-size:15px;" data-toggle="tooltip">
                        <i class="fa fa-edit primary"></i></a> &nbsp; <a                         title="Delete Email Template" style="font-size:15px;" data-toggle="tooltip">
                        <i class="fa-trash fas primary"></i></a></td>
            </tr>

        </tbody>
    </table> -->

    <table mat-table [dataSource]="emailTemplateListData" class="w-100" matSort >
      <!-- <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            (change)="changee($event,row)" [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container> -->
      <!-- <ng-container matColumnDef="SrNo">
        <th mat-header-cell *matHeaderCellDef >Sr. No.</th>
        <td mat-cell *matCellDef="let element"> {{element.rowNumber}} </td>
       
      </ng-container> -->
      <ng-container matColumnDef="templateName">
        <th mat-header-cell *matHeaderCellDef >Email Title</th>
        <td mat-cell *matCellDef="let element">{{element.templateName}}</td>
      </ng-container>

      <!-- <ng-container matColumnDef="Emailtype">
        <th mat-header-cell *matHeaderCellDef >Email Type</th>
        <td mat-cell *matCellDef="let element"><span class="label label-info institute" *ngIf="element.isEmailTemplateCustom==false">Default</span><span class="label label-info institute"  *ngIf="element.isEmailTemplateCustom==true">Custom</span>  </td>
        <td mat-cell *matCellDef="let element"><span class="label label-info institute"  *ngIf="element.isEmailTemplateCustom==false">Custom</span>  </td>
      </ng-container> -->
      <ng-container matColumnDef="emailFrom">
        <th mat-header-cell *matHeaderCellDef >From Email </th>
        <td mat-cell *matCellDef="let element">
          <span class="label label-info institute" *ngIf="element.isEmailTemplateCustom==false">Default</span>
          <span class="label label-info custom mr-1"  *ngIf="element.isEmailTemplateCustom==true">Custom</span> 
          {{element.emailFrom}} </td>
        
      </ng-container>
      <ng-container matColumnDef="modifiedDate">
        <th mat-header-cell *matHeaderCellDef >Last Modified </th>
        <td mat-cell *matCellDef="let element"><span *ngIf="element.modifiedDate!=null">{{element.modifiedDate | date: 'dd/MM/yyyy'}}</span><span *ngIf="element.modifiedDate==null">{{element.createdDate | date: 'dd/MM/yyyy'}}</span>  </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <!-- mat-sort-header =>To Add A sorting in mat table -->
        <th mat-header-cell *matHeaderCellDef > Action </th>
        <td mat-cell *matCellDef="let element">
          <div class="actionsCol">
            <!-- <mat-icon routerLink="/Org-details">info</mat-icon> -->
           
            <mat-icon  matTooltip="Edit Template" [routerLink]="['/edit-template', element.emailTemplateID]"
             >edit</mat-icon>
            <mat-icon *ngIf="element.isFromEmailDefault==false" matTooltip="Delete" (click)="deleteEmailTemplate(element.emailTemplateID)"
              >delete</mat-icon>
          </div>
        </td>
      </ng-container>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9999">
          No Assessment Found
        </td>
      </tr>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
    <!-- pagination -->
    <mat-paginator [length]="metaData['totalRecords']" [pageIndex]="metaData['currentPage'] - 1"
      (page)="handlePage($event)" [pageSize]="metaData['pageSize']" showFirstLastButtons>
    </mat-paginator>
      <!-- <app-data-table [inputColumns]="displayedColumns" [inputSource]="emailTemplateListData" [inputMeta]="metaData"
        [inputButtons]='actionButtons' (onChange)="onPageOrSortChange($event)"
        (onTableActionClick)="onTableActionClick($event)">
      </app-data-table> -->



    </div>
  </div>
  
</main>