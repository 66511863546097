import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FilterModel, Metadata } from '../common/MasterModel/masterModel';
import { EnquiryModel } from './InquiryModel';
import { ToastrService } from 'ngx-toastr';
import { EnquiriesService } from '../common/services/enquiriesServices/enquiries.service';
import { InquiryTemplateComponent } from './inquiry-template/inquiry-template.component';
import { MasterDropdownService } from '../common/services/MasterDropdownServices/master-dropdown.service';
@Component({
  selector: 'app-inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: ['./inquiry.component.css']
})
export class InquiryComponent implements OnInit {
  //isDemoAllowed=true;
  // collapseDeptFlag = false;
  // classToggled = false;
  // dataSource = new MatTableDataSource;
  // requestpages: any;
  // form: FormGroup;
   responseForm: FormGroup;
  // checkedDemo: any;
  // demoButtonHide: boolean = false;
   enquiryID:number;
   enquiryResponseData:any;
  toggle: boolean = false;
  enquiryStatusDropdown: any;

  // public toggleField() {
  //   this.classToggled = !this.classToggled;
  // }

  // @ViewChild(MatSort)
  // sort!: MatSort;
  // constructor(
  //   private dialog: MatDialog,
  //   private enquiriesService: EnquiriesService,
  //   private router: Router,
  //   private toaster: ToastrService,
  //   private formBuilder: FormBuilder,
  // ) { }
  // displayedColumns: string[] = ['name', 'enquiryTypeName', 'email', 'phoneNumber', 'address', 'status', 'allowdemo', 'actions'];

  // ngAfterViewInit(): void {

  //   this.dataSource.sort = this.sort;
  // }

  actionDialogPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  // ngOnInit(): void {
  //   this.form = this.formBuilder.group({
  //     name: [''],
  //     enquiryStatusName: [''],
  //     email: [''],
  //     phoneNumber: [''],
  //   });


  //   this.dataSource.sort = this.sort;
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "sortOrder": "",
  //     "name": "",
  //     "enquiryStatusName": "",
  //     "email": "",
  //     "phoneNumber": ""
  //   }
  //   this.GetEnquiriesList()
  // }

  openSectionModal(templateRef: TemplateRef<any>, id: number) {
    this.dialog.open(templateRef);
    this.enquiryID = id;
    this.responseForm = this.formBuilder.group({
      responseDetails: ['', Validators.required],
    });
  }


  // GetEnquiriesList() {
  //   this.enquiriesService.GetEnquiriesList(this.requestpages).subscribe((res: any) => {
  //     this.dataSource = res.responseData;
  //     this.checkedDemo = res.responseData;
  //   });
  // }

  // onSubmit() {
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "name": this.form.controls.name.value,
  //     "enquiryStatusName": this.form.controls.enquiryStatusName.value,
  //     "phoneNumber": this.form.controls.phoneNumber.value,
  //     "email": this.form.controls.email.value,
  //     "columnName": "",
  //     "sortOrder": ""
  //   }
  //   this.GetEnquiriesList()
  // }

  // reset() {
  //   this.requestpages = {
  //     "pageSize": 25,
  //     "pageNumber": 1,
  //     "columnName": "",
  //     "sortOrder": "",
  //     "name": "",
  //     "enquiryStatusName": "",
  //     "email": "",
  //     "phoneNumber": ""
  //   }
  //   this.form.reset();
  //   this.GetEnquiriesList()
  // }

  // slideToggle(id: number) {
  //   this.enquiriesService.AllowedDemo(id).subscribe(res => {
  //     console.log(res);
  //   })

  //   this.enquiriesService.denyDemo(id).subscribe(res => {
  //     console.log(res);
  //   })
  // }


  sendEnquiryResponse() {
    this.enquiryResponseData ={
      "enquiryID":this.enquiryID,
      "responseDetails": this.responseForm.controls.responseDetails.value,
    }
    console.log("hi", this.enquiryResponseData);
    this.enquiriesService.sendResponse(this.enquiryResponseData).subscribe((res:any) => {
      if (res.status > 0) {
        this.toaster.success(res.message);
      }
      else {
        this.toaster.error(res.message);
      }
    })
  }

  requestpages: any;
  form: FormGroup;
  metaData: any;
  private filterModel: FilterModel;
  enquiryListData: EnquiryModel[];
  classToggled = false;

  public toggleField() {
    this.classToggled = !this.classToggled;
  }
  displayedColumns: Array<any> = [
    { displayName: 'Sr No.', key: 'SrNo', class: '', width: '10%' },
    { displayName: 'Name', key: 'name', isSort: true, class: '', width: '20%' },
    { displayName: 'Enquiry Type Name', key: 'enquiryTypeName', isSort: false, class: '', width: '20%' },
    { displayName: 'Email', key: 'email', isSort: true, class: '', width: '10%' },
    { displayName: 'Phone Number', key: 'phoneNumber', isSort: false, class: '', width: '10%' },
    //{ displayName: 'Address', key: 'address', isSort: true, class: '', width: '10%' },
    { displayName: 'Status', key: 'enquiryStatusName', isSort: true, class: '', width: '10%' },
    { displayName: 'Response By', key: 'responseBy', isSort: false, class: '', width: '10%' },
    { displayName: 'Actions', key: 'Actions', class: '', width: '10%' },
    // {
    //   displayName: "Manage_Client.Patients_List.ACTIVE",
    //   key: "isActive",
    //   class: "",
    //   width: "10%",
    //   type: ["togglespan"],
    //   permission: true,
    // },
  ];
  actionButtons: Array<any> = [
    { displayName: 'Details', key: 'details', class: 'fas fa-eye' },
    { displayName: 'Reply', key: 'reply', class: 'fas fa-reply' },
    // { displayName: 'Delete', key: 'delete', class: 'fas fa-trash' },
  ];//<i class="fas fa-reply"></i>

 // <i class="fal fa-reply"></i><i class="fas fa-eye"></i>

//  advanceFilter: Array<any> = [
//   { value: "active", id: "active" },
//   { value: "inactive", id: "inactive" }
// ];

toggleAdvancedFilter(toggle?: boolean) {
  this.toggle = toggle != null ? toggle : !this.toggle;
}

  constructor(
    private dialog: MatDialog,
    private enquiriesService: EnquiriesService,
    private router: Router,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private masterDropdownService: MasterDropdownService,
  ) {
    this.filterModel = new FilterModel();
    this.enquiryListData = new Array<EnquiryModel>();
  }
  //displayedColumns: string[] = ['profilepic', 'adminName', 'username', 'email', 'contactNumber', 'roleName', 'actions'];



  ngOnInit(): void {
    this.metaData = new Metadata();

    this.form = this.formBuilder.group({
      name: [''],
      enquiryStatusName: [''],
      email: [''],
      phoneNumber: [''],
    });
    this.getEnquiryList(this.filterModel);
    this.enquiryStatusDropDown();
    // this.InitialGridCall()
    // this.AdminUserList();
  }
enquiryStatusDropDown(){
  this.masterDropdownService.getEnquiryStatusDropdown().subscribe((res:any)=>{
    this.enquiryStatusDropdown = res.responseData;
  })
}
  onPageOrSortChange(changeState?: any) {
    this.setPaginatorModel(changeState.pageNumber, this.filterModel.pageSize, changeState.sort, changeState.order, this.filterModel.searchText);
    this.getEnquiryList(this.filterModel)
  }

  onSubmit() {

    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "name": this.form.controls.name.value,
      "enquiryStatusName": this.form.controls.enquiryStatusName.value,
      "phoneNumber": this.form.controls.phoneNumber.value,
      "email": this.form.controls.email.value,
      "columnName": "",
      "sortOrder": ""
    }
    console.log(this.requestpages);
    this.getEnquiryList(this.requestpages)
  }
  onTableActionClick(actionObj?: any) {
    const id = actionObj.data && actionObj.data.enquiryID;
    switch ((actionObj.action || '').toUpperCase()) {
      case 'REPLY':
        this.ReplyCall(id);
        break;
      // case 'DELETE':
      //   {

      //     this.dialogService
      //       .confirm(`Are you sure you want to delete this user?`)
      //       .subscribe((result: any) => {
      //         if (result == true) {
      //           this.DeleteStaff(id);
      //         }
      //       });
      //   }
      //   break;
      case 'DETAILS':
        this.DetailsCall(id);
        break;
      default:
        break;
    }
  }

  applyFilter(searchText: string = '') {
    this.setPaginatorModel(1, this.filterModel.pageSize, this.filterModel.sortColumn, this.filterModel.sortOrder, searchText);
    if (searchText.trim() == '' || searchText.trim().length >= 3) {
      this.getEnquiryList(this.filterModel);
    }
  }

  getEnquiryList(filterModel: any) {
    this.enquiriesService.GetEnquiriesList(filterModel).subscribe((res: any) => {
      if (res.status > 0) {
        console.log(res);
        this.enquiryListData = res.responseData;
        this.metaData = res.meta;

        //Here are adding new property
        this.enquiryListData.forEach((element, index) => {
          element.SrNo = index + 1;
        });
      } else {
        this.metaData = new Metadata();
      }
      console.log(this.enquiryListData)
    });
  }

  setPaginatorModel(pageNumber: number, pageSize: number, sortColumn: string, sortOrder: string, searchText: string) {
    this.filterModel.pageNumber = pageNumber;
    this.filterModel.pageSize = pageSize;
    this.filterModel.sortOrder = sortOrder;
    this.filterModel.sortColumn = sortColumn;
    this.filterModel.columnName = sortColumn;
    this.filterModel.searchText = searchText;
  }

  DetailsCall(enquiryID: any) {
    //this.router.navigate(['/view-staff/']);
    this.router.navigate(["/enquiry-details/"], { queryParams: { id: enquiryID } });
  }
  expression:boolean;
  ReplyCall(enquiryID: any) {
    console.log("IDD",enquiryID)
  //   const dRef = this.dialog.open(InquiryTemplateComponent, {data : {id: enquiryID}});
  //   dRef.componentInstance.onSubmit.subscribe(() => {
  //     this.getEnquiryList(this.filterModel);
  //  });
  this.router.navigate(["/enquiry-response/"], { queryParams: { id: enquiryID } });
      // this.router.navigate(['/enquiry-response/']);
  }


  reset() {
    this.form.reset();
    this.InitialGridCall();

    this.getEnquiryList(this.requestpages)
  }

  InitialGridCall() {

    this.requestpages = {
      "pageSize": 25,
      "pageNumber": 1,
      "columnName": "",
      "sortOrder": "",
      "name": "",
      "enquiryStatusName": "",
      "email": "",
      "phoneNumber": ""
    }

  }
}
