import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder, AbstractControl, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MasterDropdownService } from "../../common/services/MasterDropdownServices/master-dropdown.service";
import { ManageOrganizationService } from "../../common/services/manage-organization.service";
import { CommonService } from 'src/app/common/core-services/common.Service';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-organization',
  templateUrl: './add-organization.component.html',
  styleUrls: ['./add-organization.component.css']
})
export class AddOrganizationComponent implements OnInit {
  //siteKey:string;
  signUpForm: FormGroup;
  connectionStringName: string;
  organizationDatabaseName: string;
  subscriptionID: number;
  statusID: number;
  siteSubscriptionPlan: any;
  emailSubscriptionPlan: any;
  SMSSubscriptionPlan: any;
  hide = true;
  hide1 = true;
  @ViewChild('phone') phone: NgxMatIntlTelInputComponent;
  newOrganizationClassModel: any;
  organizationType: any;
  students: any;
  passFlag: boolean;
  wasClicked = false;
  addOrgDetailsObject: any;
  passMatch: boolean;
  isEnable: boolean;
  isDemoAllowed: boolean;
  // onClick() {
  //   this.wasClicked = !this.wasClicked;
  // }
  constructor(private dialog: MatDialog, public organizationSignupService: ManageOrganizationService,private toaster: ToastrService, private fb: FormBuilder, private masterService: MasterDropdownService, private commonService: CommonService) {
    //this.siteKey = '6Lfaa4McAAAAALd9OmYu1P2YChQWVI7NEuG1K1U6';
    this.signUpForm = this.fb.group({
      organizationName: ['', Validators.required],
      organizationShortName: ['', Validators.required],
      maximumStudentAllowed: ['', Validators.required],
      organizationType: ['', Validators.required],
      contactPersonFullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      contactNumber: ['', Validators.required],
      userName: ['', Validators.required],
      subscriptionID: ['', Validators.required],
      emailSubscriptionPlanID: ['', Validators.required],
      smsSubscriptionPlanID: ['', Validators.required],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
      subDomainName: ['', Validators.required],
     // recaptcha: [null, Validators.required],
    }
    )
  }
  ngOnInit(): void {
    this.passMatch = false;
    this.masterDropdown();
  }

  onPasswordChange() {
    if (this.confirm_password.value == this.password.value) {
      this.confirm_password.setErrors(null);
    } else {
      this.confirm_password.setErrors({ mismatch: true });
    }
  }

  // getting the form control elements
  get password(): AbstractControl {
    return this.signUpForm.controls['password'];
  }

  get confirm_password(): AbstractControl {
    return this.signUpForm.controls['confirmPassword'];
  }
  openCreateFolderDialogue(templateRef: TemplateRef<any>) {
    console.log("text");
    this.dialog.open(templateRef);
  }

  openSetupOrg(templateRef: TemplateRef<any>) {
    //this.newOrganizationClassModel = new OrganizationModel();
    this.dialog.open(templateRef);
    this.masterDropdown();
  }

  registerOrgApiCall(successPopup: TemplateRef<any>) {
    if (this.signUpForm.invalid) {
      return;
    }
    this.addOrgDetailsObject = {};
    this.addOrgDetailsObject = this.signUpForm.value;
    this.addOrgDetailsObject.password = this.signUpForm.value.password;
    this.addOrgDetailsObject.contactNumber = this.phone.numberInstance.nationalNumber
    this.addOrgDetailsObject.contactNumberCountryCode = '+' + (this.phone.numberInstance.countryCallingCode);
    console.log('its submit', this.phone.numberInstance.countryCallingCode);
    this.organizationSignupService.addOrganization(this.addOrgDetailsObject).subscribe((resp: any) => {
      console.log(resp);
      this.signUpForm.reset();
      this.phone.reset();
      this.dialog.open(successPopup);
    });

  }
  get confirm_subdomain(): AbstractControl {
    return this.signUpForm.controls['subDomainName'];
  }
  onBlurMethodtxt(value: any) {
    this.datas = value.target.value;
    console.log("eventDomain", value.target.value);
    this.checkDomain(value.target.value)
  }
  subdomainExist: boolean;
  subdomainAvailable: boolean;
  datas: any;
  domainObj: any
  checkDomain(data: any) {
    const regexp = new RegExp('^[a-zA-Z0-9]+$');
    const result = regexp.test(data);
    if(!result){
      this.signUpForm.patchValue({
        subDomainName:''
      })
      this.toaster.error("Special characters not allowed")
      return
    }
    this.domainObj = {
      subdomain: data
    }
    this.organizationSignupService.CheckSubdomain(this.domainObj).subscribe((res: any) => {
      console.log(res);
      debugger;
      if (res.status == 1) {
        this.confirm_subdomain.setErrors({ mismatchSubdomain: true });
        this.signUpForm.setErrors({ mismatchSubdomain: true });
        this.subdomainExist = true
        this.subdomainAvailable = false;
      } else {
        this.subdomainExist = false
        this.subdomainAvailable = true;
      }
    });
  }
  masterDropdown() {
    this.masterService.getOrganizationType().subscribe((res: any) => {
      this.organizationType = res.responseData
    });

    this.masterService.getAllowedStudent().subscribe((res: any) => {
      this.students = res.responseData
    });
    this.masterService.GetSiteSubscriptionPlanDropdown().subscribe((res: any) => {
      this.siteSubscriptionPlan = res.responseData
      console.log("this.siteSubscriptionPlan ", this.siteSubscriptionPlan);

    });

    this.masterService.GetEmailSubscriptionPlanDropdown().subscribe((res: any) => {
      this.emailSubscriptionPlan = res.responseData
      console.log("this.emailSubscriptionPlan", this.emailSubscriptionPlan);

    });
    this.masterService.GetSMSSubscriptionPlanDropdown().subscribe((res: any) => {
      this.SMSSubscriptionPlan = res.responseData
      console.log("this.SMSSubscriptionPlan", this.SMSSubscriptionPlan);

    });
  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }
  removespace(event:any){
    this.commonService.removeSpace(event);
  }
  allowAlphaNumber(event:any){
    this.commonService.allowAlphaNumber(event);
  }
  maxDate = new Date();

}
