import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { contentManagement, environment, masters, profile } from 'src/environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError as observableThrowError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentServiceService {

  constructor(private http : HttpClient, ) { }

  // attach base url
  private getUrl(url: string): string {
    return environment.baseUrl + url;
  }
   // common error handling method
   public handleError = (error: Response) => {
    // Do messaging and error handling here
    //this.errorhandler.handleError(error.status);
    return observableThrowError(error);
  };
  postDataBanner<T>(data: {}): Observable<any> {
    let url = contentManagement.postBannerImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  addbannerdata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postBannerData}`,data);
  }

  getbannerdata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getBannerData}`);
  }
  updatebannerdata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.updateBannerData}`,data);
  }

  addQuotedata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postQuoteData}`,data);
  }
  getQuotedata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.getQuoteData}`,data);
  }

  DeleteQuote(Id:any) {
   
    return this.http.get(`${environment.baseUrl}${contentManagement.deleteQuoteData}`+'?id='+Id);
  }

  updateQuotedata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.updateQuoteData}`,data);
  }

  getQuote(Id:any) {
   
    return this.http.get(`${environment.baseUrl}${contentManagement.getByIDQuoteData}`+'?id='+Id);
  }

  postDataFeatureImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postFeatureImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  addFeaturedata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postFeatureData}`,data);
  }
  updateFeaturedata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.updateFeatureData}`,data);
  }

  getFeaturedata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getFeatureData}`);
  }
  getFeatureListdata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.getFeauteListData}`,data);
  }

  updateFeatureBasicdata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.updateFeatureBasicData}`,data);
  }

  getFeatureDetailsById(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.getFeauteDetailsByIdData}`+'?id='+Id);
  }
  deleteFeatureDetailsById(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.deleteFeatureDetailsById}`+'?id='+Id);
  }

  addPricedata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postPriceData}`,data);
  }
  getPricedata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getPriceData}`);
  }

  postDataPriceImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postPriceImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  addContactUSdata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postContactUS}`,data);
  }
  getContactUSdata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getContactUSData}`);
  }
  addPolicy(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postPolicy}`,data);
  }
  getPolicy() {
   
    return this.http.get(`${environment.baseUrl}${contentManagement.getPolicy}`);
  }

  addTerm(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postTerms}`,data);
  }
  getTerm() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getTerms}`);
  }

  postDataOBEImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postOBEImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  addOBE(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postOBEdata}`,data);
  }

  getOBEFeaturedata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.getOBEData}`,data);
  }

  getOBEFeatureByIddata(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.getOBEFeatureByIdData}`+'?id='+Id);
  }

  DeleteOBEFeature(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.deteleOBEFeatureByIdData}`+'?id='+Id);
  }

  addAboutUSdata(data:any){

    return this.http.post(`${environment.baseUrl}${contentManagement.postAboutUsData}`,data);
  }


  postDataAboutImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postAboutImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  getAboutUsdata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getAboutUsData}`);
  }

  postDataCustomerImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postCustomerImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }

  addCustomerdata(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postCustomerSay}`,data);
  }
  getCustomerById(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.getByIDCustomerData}`+'?id='+Id);
  }
  getCustomerdata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.getCustomerData}`,data);
  }
  deleteCustomer(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.deleteCustomerData}`+'?id='+Id);
  }
  postDataPartnerImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postPartnerImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  addPartnerdata(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postPartnerSay}`,data);
  }
  getPartnerdata(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.getPartnerData}`,data);
  }
  DeletePartner(Id:any) {
   
    return this.http.get(`${environment.baseUrl}${contentManagement.deletePartnerData}`+'?id='+Id);
  }
  getPartnerById(Id:any) {
    return this.http.get(`${environment.baseUrl}${contentManagement.getByIDPartnersData}`+'?id='+Id);
  }
  addRequestDemoata(data:any){
    return this.http.post(`${environment.baseUrl}${contentManagement.postRequestDemoData}`,data);
  }
  postDataRequestDemoImg<T>(data: {}): Observable<any> {
    let url = contentManagement.postRequestDemoImages;
    let newHTTPParams = new HttpParams();
    return this.http
      .post<T>(this.getUrl(url), data, { params: newHTTPParams, observe: 'response' })
      .pipe(catchError(this.handleError));
  }
  getRequestDemodata() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getRequestDemoData}`);
  }
 

  getAllSocialMediaLink() {
    return this.http.get(`${environment.baseUrl}${contentManagement.getAllSocialMediaLink}`);
  }
  setSocialMediaLink(data:any) {
    return this.http.post(`${environment.baseUrl}${contentManagement.setSocialMediaLink}`,data);
  }
}
