import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MatDialog } from '@angular/material/dialog';
import { ContentServiceService } from '../../../content-service.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.css']
})
export class PartnersComponent implements OnInit {

  
  @Output() handleTabChange: EventEmitter<any> = new EventEmitter<any>();
  id = 0;
  form: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toaster: ToastrService,
    private dialog: MatDialog,
    private contentServiceService: ContentServiceService,) { 
     
    }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      partnesID: 0,
      partnesName: ['', [Validators.required]],
      partnesImageName: [],
      partnesImageDescription: ['', [Validators.required]],
      partnesImageAlt: ['', [Validators.required]],
    });

    if(this.id > 0){
      this.getPartner();
    }
  }

  submitted = false;
  formRequest: any;
  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if(this.customerImage != ''){
      this.form.controls.partnesImageName.setValue(this.customerImage);
    }else{
      if(this.form.controls.partnesID.value  == 0){
        this.toaster.error('Upload images first', 'Error');
        return;
      }
    }
    console.log(this.form.value);

    //this.form.controls.customerSayID.setValue(this.id);
    this.contentServiceService.addPartnerdata(this.form.value).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.toaster.success(res.message, 'Success');
        this.handleTabChange.next({ tab: "Partners", id: 0});

      }else{
        this.toaster.error(res.message, 'Error');
      }
    });



  }

   /// crop image
   imageChangedEvent: any = '';
   croppedImage: any = '../../../assets/img/banner-img.png';
 
   fileChangeEvent(event: any): void {
     this.imageChangedEvent = event;
   }
   imageFile: any;
   imageCropped(event: ImageCroppedEvent) {
     this.croppedImage = event.base64;
 
     this.imageFile = this.base64ToFile(
       event.base64,
       this.imageChangedEvent.target.files[0].name,
 
     )
   }
   imageLoaded(image: LoadedImage) {
     // show cropper
   }
   cropperReady() {
     // cropper ready
   }
   loadImageFailed() {
     // show message
   }
 
   actionDialogPopup(templateRef: TemplateRef<any>, data: any) {
     this.dialog.open(templateRef, {
       data: data
 
     });
 
     this.fileChangeEvent(data);
 
   }
   finalUpload() {
     //alert('called');
     this.uploadFile(this.imageFile);
   }
   base64ToFile(data: any, filename: any) {
 
     const arr = data.split(',');
     const mime = arr[0].match(/:(.*?);/)[1];
     const bstr = atob(arr[1]);
     let n = bstr.length;
     let u8arr = new Uint8Array(n);
 
     while (n--) {
       u8arr[n] = bstr.charCodeAt(n);
     }
 
     return new File([u8arr], filename, { type: mime });
   }
 
   uploadFile(event: any) {
     let reader = new FileReader(); // HTML5 FileReader API
     //let file = event.target.files[0];
     let file = event;
 
     const formData = new FormData();
 
     formData.append('files', file);
     this.AddImage(formData);
 
 
   }
   customerImage: string = '';
   AddImage(file: any) {
     this.contentServiceService.postDataPartnerImg(file).subscribe((res: any) => {
       console.log(res);
       if (res.body.status > 0) {
         this.customerImage = res.body.responseData.imageName;
         console.log(this.customerImage);
       }
 
     })
   }


   getPartner(){
    this.contentServiceService.getPartnerById(this.id).subscribe((res: any) => {
      console.log(res);
      if(res.status > 0){
        this.croppedImage = res.responseData.partnesImageBase64;
        this.form.patchValue({
          partnesID:res.responseData['partnesID'],
          partnesName:res.responseData['partnesName'],
          partnesImageName:res.responseData['partnesImageName'],
          partnesImageDescription:res.responseData['partnesImageDescription'],
          partnesImageAlt:res.responseData['partnesImageAlt'],
        });

      }else{
        this.toaster.error(res.message, 'Error');
      }
    });
  }
  goto(){
    this.handleTabChange.emit({ tab: "Partners", id: 0});
  }

}
