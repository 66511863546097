import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http : HttpClient) { }
  clientIP: any;


  


  /** Date Format*/
  GetFormattedDate(date:any) {
    const todayTime = new Date(date);
    const month = todayTime.getMonth() + 1;
    const day = todayTime.getDate();
    const year = todayTime.getFullYear();
    return month + '/' + day + '/' + year;
  }

  /**Reject numbers */
  checkForNumber(res:any) {
    const regexp = new RegExp('^[1-9]\d{0,2}$');
    const result = regexp.test(res.key);
    if (result) {
      res.preventDefault();
      return false;
    }else{
      return true
    }
  }



  /** Reject Special Symbol */
  checkForNameSpecialSymbol(res:any) {
    const regexp = new RegExp('^[A-Za-z0-9\@\.\_\&\*\#\%\!\$\(\)\,\-\/\ ]*$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    }else{
      return true
    }
  }

  // Allow only alphabet
  allowAlphabetOnly(res:any) {
    const regexp = new RegExp('^[0-9!@#$&()\\-`.+,/\"]*$');
    const result = regexp.test(res.key);
    // console.log("RES",res.key)
    // if(!this.noWhitespaceValidator(res) ){

    //   // res.target.value = [...res.target.value]
    //   // res.target.value[0]='';
    //   return res.target.value = '';
    // }
    // else if (result) {
    //   res.preventDefault();
    //   return false;
    // }
    // else{
    //   return true
    // }
    
    if (result) {
      res.preventDefault();
      this.noWhitespaceValidator(res)
      return false;
    }else{
      this.noWhitespaceValidator(res)
      return true
    }
  }


  noWhitespaceValidator(event:any){
    console.log("CONTROL",event.target.value)
    const isWhitespace = (event.target.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid  ? null : event.target.value = '';
  }

  /** Allow only Number */
  allowOnlyNumber(res:any) {
    const regexp = new RegExp('^([0-9])$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    }else{
      return true
    }
  }


  /** Allow only Aplpha Number */
  allowAlphaNumber(res:any) {
    const regexp = new RegExp('^[a-zA-Z0-9]+$');
    const result = regexp.test(res.key);
    if (!result) {
      res.preventDefault();
      return false;
    }else{
      return true
    }
  }

  /**Method to get first day date of month */
  getFirstDayOfMonth(value:any) {
    const date = value,
      y = date.getFullYear(), m = date.getMonth();
    const firstDay = new Date(y, m, 1);
    console.log('firs', firstDay.toDateString());
    return firstDay.toDateString();
  }

  getLastDayOfMonth(value:any) {
    const date = value,
      y = date.getFullYear(), m = date.getMonth();
    const lastDay = new Date(y, m + 1, 0);
    console.log('last', lastDay.toDateString());
    return lastDay.toDateString();
  }


  removeSpace(res:any) {
    console.log("inside",res);
    if (res.keyCode == 32) {
      res.preventDefault();
      return false;
    }else{
      return true
    }
  }

  getClientIP() {
    this.http.get('https://jsonip.com').subscribe((data: any) => {
      console.log("getClientIP", data);
      this.clientIP = data.ip;
      localStorage.setItem('Client-IP', data.ip);
    });
  }
}
