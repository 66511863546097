import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubscriptionPlanService } from "../../../common/services/subscriptionPlanService/subscription-plan.service";
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterDropdownService } from "../../../common/services/MasterDropdownServices/master-dropdown.service";
import { PlanTypeDropdown, PlanTypeStautusDropdown } from 'src/app/common/MasterModel/masterModel';
import { CommonService } from 'src/app/common/core-services/common.Service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-add-site-subscription',
  templateUrl: './add-site-subscription.component.html',
  styleUrls: ['./add-site-subscription.component.css']
})
export class AddSiteSubscriptionComponent implements OnInit {
  form: FormGroup;
  planTypeForm: FormGroup;
  isSubmitted: boolean;
  planTypeDropdown: any;
  planTypeStautusDropdown: any;
  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private subscriptionPlanService: SubscriptionPlanService,
    private toaster: ToastrService,
    private router: Router,
    private masterDropdownService: MasterDropdownService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      planID: [0, Validators.min(1)],
      planName: ['', Validators.required], 
      price: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      planStatusID: [0, Validators.required],
      numberOfUserAllowed: ['', [Validators.required, Validators.pattern('[0-9]+'), Validators.min(1)]],
      description: [null, Validators.required],
      isPublic: [null, Validators.required],
    });
    this.planTypeForm = this.formBuilder.group({
      planTypeName: ['', Validators.required],
      noOfDays: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    });
    this.masterDropdown();
  }
  onSubmit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    } 
    else if(Number.isInteger(this.form.value.planID) == false){
      console.log("planID", this.form.value.planID)
      this.toaster.error("Check Plan");
      return;
    }
    else {
      this.form.value.numberOfUserAllowed = Number(this.form.value.numberOfUserAllowed);
      this.subscriptionPlanService.AddSubscriptionPlan(this.form.value).subscribe((res: any) => {
        if (res.status > 0) {
          this.toaster.success(res.message);
          this.router.navigate(['/subscription-plan'])
        }
        else {
          this.toaster.error(res.message);
        }
      });

      console.log("this.form.value", this.form.value)
    // this.form.reset();
    // this.form.controls.planID.clearValidators();
    // this.form.controls.planName.clearValidators();
    // this.form.controls.price.clearValidators();
    // this.form.controls.planStatusID.clearValidators();
    // this.form.controls.numberOfUserAllowed.clearValidators();
    // this.form.controls.description.clearValidators();
    // this.form.controls.isPublic.clearValidators();
    }
  }

  masterDropdown() {
    this.masterDropdownService.GetPlanTypeDropdown().subscribe((res: any) => {
      this.planTypeDropdown = res.responseData;
    });
    this.masterDropdownService.GetPlanTypestausDropdown().subscribe((res: any) => {
      this.planTypeStautusDropdown = res.responseData
    })

  }
  allowOnlyNumber(event: any) {
    this.commonService.allowOnlyNumber(event);
  }
  allowAlphabetOnly(event: any) {
    this.commonService.allowAlphabetOnly(event);
  }

  maxDate = new Date();


  AddPlanTypeDialog(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef);
  }

  planTypeSubmit() {
    if (this.planTypeForm.invalid) {
      return;
    }
    else {
      //debugger;
      var checkStringCount = this.planTypeForm.value.planTypeName.trim()
      if (Number(checkStringCount.length) == 0) {
        this.toaster.error("Check Plan Name");
        return;
      }
      else {
        this.subscriptionPlanService.AddPlanType(this.planTypeForm.value).subscribe((res: any) => {
          if (res.status > 0) {
            this.toaster.success(res.message);
            this.reset();
            this.masterDropdown();
          }
          else {
            this.toaster.error(res.message);
          }
        });
      }

    }
  }

  reset() {
    this.planTypeForm.reset();
  }


}
