<main>
    <!-- <div class="page-breadcrumb d-md-flex align-items-center justify-content-between">
        <h2 class="page-title">Manage Role </h2>
        <div class="breadcrumbWidget">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
                <li class="breadcrumb-item"><a routerLink="/manage-role">Manage Role</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Role</li>
            </ol>
        </div>
    </div> -->

    <div class="container-fluid">
        <div class="matCard">
            <div class="matCrdTitle centerItemVert justify-content-between">
                <h3 class="matCardTitle">Add Request Demo</h3>
                <!-- <a routerLink="/manage-role" class="btn btn-primary btnsmall">Back</a> -->
            </div>
            <form [formGroup]="form" >
                <div class="matCardBody">
                    <div class="profileDetailsWidget pt-4 d-md-flex">
                        <div class="profileContentRight flex-grow-1">
                            <div class="proSection">
                                <div class="row">
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Title <span class="req">*</span></mat-label>
                                            <input maxlength="100" formControlName="title" matInput placeholder="title">
                                            <mat-error *ngIf="form.get('title')?.touched  && form.get('title')?.errors?.required">
                                                Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col s12 m12 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Sub Title <span class="req">*</span></mat-label>
                                            <textarea maxlength="1000" formControlName="subTitle" matInput placeholder="Sub Title" ></textarea>
                                            <mat-error *ngIf="form.get('subTitle')?.touched  && form.get('subTitle')?.errors?.required">
                                                Sub Title is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    


                                    <!-- Enquiry Details -->

                                    <div class="col s12 m12 form-group">
                                        <p>Request Demo Image</p>
                                    </div>
                                    <div class="col s12 m6 form-group">
                                       
                                        <!-- <img [src]="croppedImage" style="height: 200px;width: 200px;" alt="Add Image">
                                       
                                        &nbsp;&nbsp;&nbsp;
                                        <br>
                                        &nbsp;&nbsp;&nbsp;
                                        <label for="file-upload3" class="custom-file-upload">
                                            <i class="fa fa-cloud-upload"></i>Upload Image
                                            <input id="file-upload3" type="file"  accept="image/*" (change)="actionDialogPopup(uploadPopup,$event)">
                                        </label> -->
                                        <div class="col s12 m6 form-group " *ngIf="flag">
                                            <img [src]="croppedImage" style="height: 200px;width: 200px;" alt="Add Image">
                                        </div>
                                        <div class="col s12 m6 form-group position-relative">
                                            <ngx-drag-drop-crop-resize 
                                            (croppedImage)="getCroppedImage($event)" oImgHeight = "11rem" 
                                            (isImageDeleted) = "isTheImageDeleted($event)"
                                            oImgWidth = "15rem" >
                                           
                                            </ngx-drag-drop-crop-resize>
                                        </div>
                                        

                                       

                                        
                                    </div>
                                    <div class="col s12 m6 form-group">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Alt</mat-label>
                                            <input maxlength="20"  matInput placeholder="Image Alt" required name="Image Alt"
                                                formControlName="requestDemoImageAlt">
                                            <mat-error
                                                *ngIf="form.get('requestDemoImageAlt')?.touched  && form.get('requestDemoImageAlt')?.errors?.required">
                                                Alternative name is required
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Image Description</mat-label>
                                            <textarea maxlength="100" formControlName="requestDemoImageDescription" matInput placeholder="Description" ></textarea>
                                            <mat-error
                                                *ngIf="form.get('requestDemoImageDescription')?.touched  && form.get('requestDemoImageDescription')?.errors?.required">
                                                Description is required
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <br>
                            </div>
    
                            <div class="proSection center">
                                <button [disabled]="form.invalid" (click)="onSubmit()"  type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
            

            
        </div>
    </div>
</main>

<ng-template #uploadPopup>
    <div class="dialog-md">
      <i class="material-icons popupClose" (click)="finalUpload()" mat-dialog-close>clear</i>
      <div class="dialogTitle center-align loginTtile">
        <h5 mat-dialog-title>Select Image Area</h5>
      </div>
      <mat-dialog-content class="mat-typography">
        <div class="row">
         <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                        [aspectRatio]="10.1 / 8" [resizeToWidth]="322" [cropperMinWidth]="1600" format="png" (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded($event)" (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"
                            [roundCropper]="false">
                        </image-cropper>

                        <!-- <img [src]="croppedImage" /> -->
        </div>
  
      </mat-dialog-content>
    </div>
    <div class="proSection center">
        <button class="btn btn-primary" mat-dialog-close (click)="finalUpload()"> close </button>
    </div>
  </ng-template>


